var tag = document.createElement('script');
tag.src = `https://c.la2-c2-dfw.salesforceliveagent.com/content/g/js/43.0/deployment.js`;
tag.async = 1;
var lastScript = document.getElementsByTagName('script')[0];
lastScript.parentNode.insertBefore(tag, lastScript);

window.liveAgentInit = function () {
  let button = window.liveagent_config.button;
  if (!window._laq) {
    window._laq = [];
  }
  window._laq.push(function () {
    liveagent.showWhenOnline(button, document.getElementById(`liveagent_button_online_${button}`));

    liveagent.showWhenOffline(
      button,
      document.getElementById(`liveagent_button_offline_${button}`),
    );
  });
  liveagent.init(window.liveagent_config.url, '5720B0000008TbW', '00DU0000000Ya1k');
};
