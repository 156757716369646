/* @flow */

import { observable, action } from 'mobx';

export default class Model {
  @observable id: string | number;

  constructor() {
    this.id = '';
  }

  /**
   * This function is used to do bulk updates of a models properties, with edits passed in via a collection
   * of key-value pairs.
   *
   * @param {Object} newData
   * @returns void
   * @throws {Error} if the provided key in not inside of the model
   */
  @action.bound
  update(newData: Object): void {
    Object.entries(newData).forEach(([key, value]) => {
      if (typeof this[key] === 'undefined') {
        throw new Error('You cannot attempt to update a non-existing property');
      }
      this[key] = value;
    });
  }
}
