const alertTypeValues = Object.freeze({
  CLIENT: 'client',
  MEMBER: 'member',
  PRIMARY_RETAIL: 'primary-retail',
  PRIMARY_RETAIL_CONFIRM: 'primary-retail-confirm',
  PRIMARY_RETAIL_CONFIRMED: 'primary-retail-confirmed',
  PRIMARY_RETAIL_IGNORED: 'primary-retail-ignored',
  PRIMARY_RETAIL_UPDATE: 'primary-retail-update',
  PRIMARY_RETAIL_UPDATED: 'primary-retail-updated',
  CONTACT_PRESCRIBER: 'contact-prescriber',
  CONTACT_PRESCRIBER_ACTIVE: 'contact-prescriber-active',
  CONTACT_PRESCRIBER_COMPLETE: 'contact-prescriber-complete',
  GENERAL_MESSAGE: 'general-message',
  MAIL_ORDER: 'mail-order',
  PERMISSION_REQUEST: 'permission-request',
  REFILL: 'refill',
  EXPRESS_SUGGESTION: 'express-suggestion',
});

export { alertTypeValues };
