/* @flow */

const emailSmsNormalizer = (data: Object, value: string, type?: ?string): Object => {
  let rx_bin;
  let rx_pcn;
  let rx_grp;
  let rx_mem_id;
  let user_input = value;

  if (type === 'text') {
    user_input = user_input.replace(/\D/g, '');
  }

  data.voucherInfo.forEach((info) => {
    switch (info.label) {
      case 'rx bin':
        rx_bin = info.value;
        break;
      case 'rx pcn':
        rx_pcn = info.value;
        break;
      case 'rx grp':
        rx_grp = info.value;
        break;
      case 'mem id':
        rx_mem_id = info.value;
        break;
      default:
        console.error(`label ${info.label} not a valid key`);
    }
  });

  return {
    pharmacy_id: data.pharmacy.id,
    product_id: data.productWithPackage.id,
    quantity: data.quantity,
    day_supply: data.daySupply,
    est_price: data.cost,
    user_input,
    rx_bin,
    rx_pcn,
    rx_grp,
    rx_mem_id,
  };
};

export default emailSmsNormalizer;
