export const opportunityStatic = {
  type: 'member-savings',
  savings_type: 'member',
  is_rewardable: false,
  is_pbm_max_cost: false,
  alert_type: 'savings',
  opportunity_type: 'savings',
  member_savings: 13.1,
  client_savings: 13.69,
  refill_savings: 13.1,
  viewed_at: null,
  created_at: '2022-12-02T13:39:38.000000Z',
  member: {
    id: 'mb-vf726np',
    first_name: 'TEST_24702307',
    last_name: 'USER',
    date_of_birth: '1973-02-17',
    phone_number: '9137821234',
    mobile_number: null,
    email: 'TEST_24702307@rxss.io',
    age: 49,
    seen_sms_modal: 0,
    sms_welcome_sent: 0,
    accessible: null,
    can_be_invited: false,
    registered_since: '2022-11-10 23:33:43',
    notification_preferences: [
      {
        notification_type_id: 1,
        notification: 'Saving Notifications',
        allow_email: true,
        allow_sms: false,
        allow_push: null,
      },
    ],
    address: {
      line1: '123 Fake Street',
      line2: '',
      city: 'PORTLAND',
      state: 'OR',
      zip_code: '97206',
      latitude: 45.4856372,
      longitude: -122.5946256,
    },
    is_primary_account_holder: true,
    permissions: {
      access_phi: false,
      access_non_phi: false,
      make_health_care_decisions: false,
      admin_logged_in: false,
    },
  },
  medication_claim: {
    id: 4251246072,
    product_id: 128,
    ncpdp: 1486516,
    day_supply: 90,
    quantity: 90,
    member_cost: 20,
    member_cost_per_day: 0.22,
    employer_cost: 13.69,
    employer_cost_per_day: 0.15,
    quantity_with_unit: '90 Extended-Release (XL) Tablets',
    filled_at: '2022-10-21',
    contact_prescriber: null,
    prescriber: {
      prescriber_npi: 1407083215,
      last_name: 'WRIGHT',
      first_name: 'KELLY',
      middle_name: 'SHERIDAN',
      gender: 'F',
      prefix: null,
      credential: 'MD',
      business_mailing_address_first_line: 'PO BOX 568',
      business_mailing_address_second_line: null,
      business_mailing_address_city_name: 'CORNELIUS',
      business_mailing_address_state_name: 'OR',
      business_mailing_address_postal_cd: '97113-0568',
      business_mailing_address_cntry_cd: 'US',
      business_mailing_address_telephone_no: '5033595564',
      business_mailing_address_fax_no: '5033574371',
      business_practice_location_address_first_line: '8332 SE 13TH AVE',
      business_practice_location_address_second_line: null,
      business_practice_location_address_city: 'PORTLAND',
      business_practice_location_address_state: 'OR',
      business_practice_location_address_postal_cd: '97202-7102',
      business_practice_location_address_cntry_cd: 'US',
      business_practice_location_address_telephone_no: '5035959300',
      business_practice_location_address_fax_no: null,
      enumuration_date: '2009-06-18 00:00:00',
      deactivation_date: null,
      reactivation_Date: null,
    },
    package: {
      details: {
        id: 14021,
        ndc: '69097087512',
        imprint_front: 'I',
        imprint_back: '13',
        color: 'white',
        shape: 'oblong',
        manufacturer: null,
        image_url: 'https://images.rxsavingssolutions.com/DrugItem_25570.JPG',
      },
      product: {
        name: {
          id: 58,
          trademarked: 0,
          name: 'bupropion (generic of Wellbutrin, Wellbutrin SR, Wellbutrin XL, Forfivo)',
        },
        id: 128,
        description: 'Bupropion Hydrochloride 150mg Extended-Release (XL) Tablet',
        form: 'Extended-Release (XL) Tablet',
        strength: '150mg',
        dispensable_unit: 'Extended-Release (XL) Tablet(s)',
        image_url: 'https://images.rxsavingssolutions.com/DrugItem_24130.JPG',
        normalized_dispensable_unit: {
          singular: 'Extended-Release (XL) Tablet',
          plural: 'Extended-Release (XL) Tablets',
        },
        form_plural: 'Extended-Release (XL) Tablet',
        form_string: null,
        product_name: 'bupropion (generic of Wellbutrin, Wellbutrin SR, Wellbutrin XL, Forfivo)',
        primary_name: 'Bupropion Extended Release',
        secondary_name: '(Generic of Wellbutrin XL)',
        strength_string: null,
        brand: 0,
        maintenance_med: 1,
        off_market: 0,
        brand_name: 'Wellbutrin XL',
        medication_details: {
          side_effects:
            'Side effects that you should report to your doctor or health care professional as soon as possible:\n\n  * allergic reactions like skin rash, itching or hives, swelling of the face, lips, or tongue\n  * breathing problems\n  * changes in vision\n  * confusion\n  * elevated mood, decreased need for sleep, racing thoughts, impulsive behavior\n  * fast or irregular heartbeat\n  * hallucinations, loss of contact with reality\n  * increased blood pressure\n  * redness, blistering, peeling or loosening of the skin, including inside the mouth\n  * seizures\n  * suicidal thoughts or other mood changes\n  * unusually weak or tired\n  * vomiting \nSide effects that usually do not require medical attention (report to your doctor or health care professional if they continue or are bothersome):\n\n  * constipation\n  * headache\n  * loss of appetite\n  * nausea\n  * tremors\n  * weight loss',
          details: 'BUPROPION (byoo PROE pee on) is used to treat depression.',
          interactions:
            "Do not take this medicine with any of the following medications:\n\n  * linezolid\n  * MAOIs like Azilect, Carbex, Eldepryl, Marplan, Nardil, and Parnate\n  * methylene blue (injected into a vein)\n  * other medicines that contain bupropion like Zyban \nThis medicine may also interact with the following medications:\n\n  * alcohol\n  * certain medicines for anxiety or sleep\n  * certain medicines for blood pressure like metoprolol, propranolol\n  * certain medicines for depression or psychotic disturbances\n  * certain medicines for HIV or AIDS like efavirenz, lopinavir, nelfinavir, ritonavir\n  * certain medicines for irregular heart beat like propafenone, flecainide\n  * certain medicines for Parkinson's disease like amantadine, levodopa\n  * certain medicines for seizures like carbamazepine, phenytoin, phenobarbital\n  * cimetidine\n  * clopidogrel\n  * cyclophosphamide\n  * digoxin\n  * furazolidone\n  * isoniazid\n  * nicotine\n  * orphenadrine\n  * procarbazine\n  * steroid medicines like prednisone or cortisone\n  * stimulant medicines for attention disorders, weight loss, or to stay awake\n  * tamoxifen\n  * theophylline\n  * thiotepa\n  * ticlopidine\n  * tramadol\n  * warfarin",
        },
      },
      id: 1326,
      ndc: '69097087512',
      description: 'bottle, 500 each Bupropion HCl 150mg, Oral tablet, extended release 24 hour',
      unit: 'ea',
      size: '500.000',
      mdq: 1,
      inner_count: null,
      clone: 0,
      otc: 0,
    },
    pharmacy: {
      id: 16989,
      ncpdp: '1486516',
      npi: '1629341177',
      name: 'CVS CAREMARK ADVANCED TECHNOLOGY PHARMACY,  LLC',
      pharmacy_type: 'mail_order',
      type: {
        id: 2,
        label: 'mail_order',
      },
      type_id: 2,
      address: {
        street: '1780 WALL STREET',
        city: 'MOUNT PROSPECT',
        state: 'IL',
        zipcode: '60056',
        latitude: '42.03215400',
        longitude: '-87.94435000',
      },
      phone: '8005528159',
      extension: null,
      fax: '8003780323',
      email: 'SANDRA.CHAVEZ@CAREMARK.COM',
      directions: null,
      open_24_hrs: 'N',
      language: 'en|es|||',
      distance: null,
      opening_hours: {
        periods: [
          {
            open: {
              day: 0,
              time: null,
            },
            close: {
              day: 0,
              time: null,
            },
          },
          {
            open: {
              day: 1,
              time: '0800',
            },
            close: {
              day: 1,
              time: '1700',
            },
          },
          {
            open: {
              day: 2,
              time: '0800',
            },
            close: {
              day: 2,
              time: '1700',
            },
          },
          {
            open: {
              day: 3,
              time: '0800',
            },
            close: {
              day: 3,
              time: '1700',
            },
          },
          {
            open: {
              day: 4,
              time: '0800',
            },
            close: {
              day: 4,
              time: '1700',
            },
          },
          {
            open: {
              day: 5,
              time: '0800',
            },
            close: {
              day: 5,
              time: '1700',
            },
          },
          {
            open: {
              day: 6,
              time: null,
            },
            close: {
              day: 6,
              time: null,
            },
          },
        ],
        weekday_text: {
          Sunday: 'Closed',
          Monday: '8:00 a.m. - 5:00 p.m.',
          Tuesday: '8:00 a.m. - 5:00 p.m.',
          Wednesday: '8:00 a.m. - 5:00 p.m.',
          Thursday: '8:00 a.m. - 5:00 p.m.',
          Friday: '8:00 a.m. - 5:00 p.m.',
          Saturday: 'Closed',
        },
      },
      home_delivery_provider: null,
    },
    product: {
      name: {
        id: 58,
        trademarked: 0,
        name: 'bupropion (generic of Wellbutrin, Wellbutrin SR, Wellbutrin XL, Forfivo)',
      },
      id: 128,
      description: 'Bupropion Hydrochloride 150mg Extended-Release (XL) Tablet',
      form: 'Extended-Release (XL) Tablet',
      strength: '150mg',
      dispensable_unit: 'Extended-Release (XL) Tablet(s)',
      image_url: 'https://images.rxsavingssolutions.com/DrugItem_24130.JPG',
      normalized_dispensable_unit: {
        singular: 'Extended-Release (XL) Tablet',
        plural: 'Extended-Release (XL) Tablets',
      },
      form_plural: 'Extended-Release (XL) Tablet',
      form_string: null,
      product_name: 'bupropion (generic of Wellbutrin, Wellbutrin SR, Wellbutrin XL, Forfivo)',
      primary_name: 'Bupropion Extended Release',
      secondary_name: '(Generic of Wellbutrin XL)',
      strength_string: null,
      brand: 0,
      maintenance_med: 1,
      off_market: 0,
      brand_name: 'Wellbutrin XL',
      medication_details: {
        side_effects:
          'Side effects that you should report to your doctor or health care professional as soon as possible:\n\n  * allergic reactions like skin rash, itching or hives, swelling of the face, lips, or tongue\n  * breathing problems\n  * changes in vision\n  * confusion\n  * elevated mood, decreased need for sleep, racing thoughts, impulsive behavior\n  * fast or irregular heartbeat\n  * hallucinations, loss of contact with reality\n  * increased blood pressure\n  * redness, blistering, peeling or loosening of the skin, including inside the mouth\n  * seizures\n  * suicidal thoughts or other mood changes\n  * unusually weak or tired\n  * vomiting \nSide effects that usually do not require medical attention (report to your doctor or health care professional if they continue or are bothersome):\n\n  * constipation\n  * headache\n  * loss of appetite\n  * nausea\n  * tremors\n  * weight loss',
        details: 'BUPROPION (byoo PROE pee on) is used to treat depression.',
        interactions:
          "Do not take this medicine with any of the following medications:\n\n  * linezolid\n  * MAOIs like Azilect, Carbex, Eldepryl, Marplan, Nardil, and Parnate\n  * methylene blue (injected into a vein)\n  * other medicines that contain bupropion like Zyban \nThis medicine may also interact with the following medications:\n\n  * alcohol\n  * certain medicines for anxiety or sleep\n  * certain medicines for blood pressure like metoprolol, propranolol\n  * certain medicines for depression or psychotic disturbances\n  * certain medicines for HIV or AIDS like efavirenz, lopinavir, nelfinavir, ritonavir\n  * certain medicines for irregular heart beat like propafenone, flecainide\n  * certain medicines for Parkinson's disease like amantadine, levodopa\n  * certain medicines for seizures like carbamazepine, phenytoin, phenobarbital\n  * cimetidine\n  * clopidogrel\n  * cyclophosphamide\n  * digoxin\n  * furazolidone\n  * isoniazid\n  * nicotine\n  * orphenadrine\n  * procarbazine\n  * steroid medicines like prednisone or cortisone\n  * stimulant medicines for attention disorders, weight loss, or to stay awake\n  * tamoxifen\n  * theophylline\n  * thiotepa\n  * ticlopidine\n  * tramadol\n  * warfarin",
      },
    },
  },
};
