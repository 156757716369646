/* @flow */
import { formatMoney } from '@utils/Functions';
import queryString from 'query-string';
import Model from '@data/models/Model';
import { MemberStore } from '@stores_old/memberStore/memberStore';
import type { BaseClaimResponse } from '@features/savings/types/Claim';

class BaseAlert extends Model {
  translate: Function;

  alertObject: {
    medication_claim?: BaseClaimResponse,
  };

  icon: string;

  alertType: string;

  buttonText: string;

  viewed_at: string | null;

  hasSavings: boolean;

  bodyText: ?string;

  _footerText: ?string;

  showPrescriptionDetailsLink: boolean;

  showInviteDependentButtonArea: boolean;

  buttonLinkTextDesktop: string;

  buttonLinkTextMobile: string;

  id: string;

  alertSuggestions: Array<Object>;

  showRemoveAlertBar: boolean;

  product: Object;

  memberStore: MemberStore;

  removeAlert: Function;

  router: Object;

  hasFeature: Function;

  api: Object;

  constructor({ alertInfo, removeAlert, hasFeature, memberStore, translate, api }) {
    super();
    this.translate = translate;
    this.alertObject = alertInfo;
    this.icon = 'dollar';
    this.alertType = alertInfo.alert_type;
    this.buttonText = this.translate('savings-alert.bestSavingsSuggestionLinkTextDesktop');
    this.viewed_at = alertInfo.viewed_at;
    this.hasSavings = true;
    this.bodyText = null;
    this._footerText = null;
    this.showPrescriptionDetailsLink = false;
    this.showInviteDependentButtonArea = false;
    this.buttonLinkTextDesktop = '';
    this.buttonLinkTextMobile = '';
    this.id = alertInfo.id;
    // TODO : MOVE USAGES OF THESE TWO TO this.id AND THEN DELETE
    this.key = alertInfo.id;
    this.index = alertInfo.id;
    this.alertSuggestions = [];
    this.showRemoveAlertBar = true;
    this.product = alertInfo.medication_claim
      ? {
          name: alertInfo.medication_claim.package.product.name.name,
          primaryName: alertInfo.medication_claim.package.product.primary_name,
          secondaryName: alertInfo.medication_claim.package.product.secondary_name,
          strength: alertInfo.medication_claim.package.product.strength,
          quantityWithUnit: alertInfo.medication_claim.quantity_with_unit,
        }
      : {
          name: '',
          strength: '',
        };
    this.memberStore = memberStore;
    this.removeAlert = removeAlert;
    this.router = {};
    this.hasFeature = hasFeature;
    this.api = api;
    this.onClickAction = this.onClickAction.bind(this);
    this.deleteAlert = this.deleteAlert.bind(this);
  }

  get headerText() {
    const { is_pbm_max_cost: maxSavings } = this.alertObject;
    return this.translate(
      this.isDependent
        ? 'savings-alert.foundChildSavings'
        : `savings-alert.foundSavings${maxSavings ? '-up-to' : ''}`,
      {
        amount: formatMoney(this.alertObject.refill_savings),
        name: this.activeMember.first_name,
      },
    );
  }

  get footerText() {
    return this._footerText;
  }

  get buttonAreaText() {
    const { is_pbm_max_cost: maxSavings } = this.alertObject;
    return this.translate(`savings-alert.buttonMobile${maxSavings ? '-up-to' : ''}`);
  }

  get mobileButtonText() {
    const { is_pbm_max_cost: maxSavings } = this.alertObject;
    return this.translate(`savings-alert.buttonMobile${maxSavings ? '-up-to' : ''}`);
  }

  get activeMember() {
    return this.memberStore.activeMember;
  }

  get medicationClaim() {
    return this.alertObject.medication_claim || {};
  }

  get doctorFullName(): string {
    const prescriber = this.alertObject.medication_claim?.prescriber;
    if (!prescriber) return '';
    const {
      first_name: firstName = '',
      last_name: lastName = '',
      prefix = '',
      credential = '',
    } = prescriber;

    return [prefix, firstName, lastName, credential].filter(Boolean).join(' ');
  }

  get showRemovalAlertBar() {
    return this.hasFeature('show_turn_off_alert_option');
  }

  get isDependent() {
    return this.memberStore.activeMember.id !== this.memberStore.currentMember.id;
  }

  get isMajorDependent() {
    return !this.activeMember.permissions.make_healthcare_decisions;
  }

  injectRouter(history) {
    this.router = { history };
  }

  onClickAction() {
    this.router.history.push(this.navigate());
    return true;
  }

  /**
   * This invokes the passed removeAlert function with the passed memberId and alertId
   *
   * @param {string} memberId
   * @param {string} alertId
   * @returns void
   */
  deleteAlert(memberId, alertId): void {
    this.removeAlert(alertId, memberId);
  }

  /**
   * This will update the alertType property, both at the top level and in the alertObject property
   *
   * @param {string} newType
   */
  updateType(newType: string): void {
    this.alertType = newType;
    this.alertObject.alert_type = newType;
  }

  /**
   * This will mark the alert as viewed and return a navigation object based on the alert's internal
   * data
   *
   * @returns {{pathname: string, search: *}}
   */
  navigate(): Object {
    const {
      id: alertId,
      medication_claim: {
        id: claimId,
        member_cost: lastPaid,
        day_supply: daySupply,
        package: {
          product: {
            strength: dosage,
            form,
            name: { id: nameId },
          },
        },
        quantity,
        employer_cost: employerCost,
      },
      savings_type: alertType,
    } = this.alertObject;
    this.view();
    return {
      pathname: '/prices/suggestions',
      search: queryString.stringify({
        daySupply,
        dosage,
        form,
        nameId,
        quantity,
        member: this.memberStore.activeMember.id,
        zip: this.memberStore.activeMember.address.zip_code,
        claimId,
        lastPaid,
        alertType,
        employerCost,
        alert_id: alertId,
      }),
    };
  }

  /**
   * This will update the viewed_at property at the high level and on the alertObject property to the
   * current time
   */
  view() {
    const now = new Date().toLocaleDateString();
    this.viewed_at = now;
    this.alertObject.viewed_at = now;
    return this.api.markAlertViewed(this.id);
  }
}

export default BaseAlert;
