import { formatMoney } from '@utils/Functions';
import queryString from 'query-string';
import { SessionStorageManager } from '@utils/storage';
import BaseAlert from '../BaseAlert/BaseAlert';
import AlertTypes from '../AlertTypes';

class ExpressAlert extends BaseAlert {
  constructor(info) {
    super(info);
    const { alertInfo, couponSuggestionsStore } = info;
    const {
      is_pbm_max_cost: maxSavings,
      express_suggestion: {
        display_info: { savings },
      },
    } = alertInfo;
    this.alertType = 'express-suggestion';
    this.modelName = AlertTypes.EXPRESS;
    this.couponSuggestionsStore = couponSuggestionsStore;
    this.onClickAllSavings = super.onClickAction.bind(this);
    this.buttonText = this.translate('savings-alert.bestSavingsSuggestionButtonText');
    this.buttonLinkTextMobile = this.translate('savings-alert.bestSavingsSuggestionLinkTextMobile');
    this.buttonLinkTextDesktop = this.translate(
      'savings-alert.bestSavingsSuggestionLinkTextDesktop',
    );
    this._headerText = this.translate(
      `savings-alert.bestSavingsSuggestionHeader${maxSavings ? '-up-to' : ''}`,
      { amount: formatMoney(savings) },
    );
    this.onClickAction = this.onClickAction.bind(this);
  }

  /**
   * Creates react router history object to navigate to the active express flow for the current express alert.
   * @param {Object} state - any additional data you want to pass to the route but not have it be in the URL.
   *    See history docs for more info {@link https://github.com/remix-run/history/blob/dev/docs/api-reference.md#locationstate}
   * @return {{ pathname: string, search: string, state: Object,}} history object
   */
  makeExpressNavigation(state = {}) {
    const sessionConfig = SessionStorageManager.get('config');
    const hasUniversalSavingsFlowFlag =
      sessionConfig?.features_enabled?.includes('universal_savings_flow_beta') ?? false;

    return hasUniversalSavingsFlowFlag
      ? this.makeUniversalSavingsNavigation(state)
      : this.makeSuperAlertNavigation(state);
  }

  /**
   * Creates react router history object to navigate to the Super Alert Flow for the current express alert
   * @param {Object} state - any additional data you want to pass to the route but not have it be in the URL.
   *    See history docs for more info {@link https://github.com/remix-run/history/blob/dev/docs/api-reference.md#locationstate}
   * @return {{ pathname: string, search: string, state: Object,}}
   */
  makeSuperAlertNavigation(state = {}) {
    const suggestion_id = this.alertObject.express_suggestion.suggestion.id;
    this.view();
    this.couponSuggestionsStore.getSuggestions(
      this.activeMember.id,
      { suggestion_id },
      this.alertObject,
    );

    this.couponSuggestionsStore.setSelectedPricing('retail', suggestion_id);

    return {
      pathname: '/express-alert',
      search: queryString.stringify({
        alert_id: this.alertObject.id,
        suggestion_id,
        member: this.memberStore.activeMember.id,
        claimId: this.alertObject.medication_claim.id,
      }),
      state,
    };
  }

  /**
   * Creates react router history object to navigate to the Universal Savings Flow for the current express alert
   * @param {Object} state - any additional data you want to pass to the route but not have it be in the URL.
   *    See history docs for more info {@link https://github.com/remix-run/history/blob/dev/docs/api-reference.md#locationstate}
   * @return {{ pathname: string, search: string, state: Object,}}
   */
  makeUniversalSavingsNavigation(state = {}) {
    this.view();
    return {
      pathname: '/savings/suggestion',
      search: queryString.stringify({
        member: this.memberStore.activeMember.id,
        alertId: this.alertObject.id,
        suggestionId: this.alertObject.express_suggestion.suggestion.id,
      }),
      state,
    };
  }

  get headerText() {
    return this._headerText;
  }

  onClickAction() {
    this.router.history.push(this.makeExpressNavigation());
    return true;
  }
}

export default ExpressAlert;
