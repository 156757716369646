/* @flow */

import { observable, computed } from 'mobx';
import { generateMockId } from '@utils/Functions';
import Model from '../Model';
import Alerts from '../../collections/Alerts/Alerts';

class AlertResponse extends Model {
  @observable
  id: string;

  @observable
  response: Object;

  @observable
  alertsCollection: Alerts;

  constructor({ response, member, childStores, api }) {
    super();
    this.id = generateMockId('alr');
    this.response = response;
    this.alertsCollection = new Alerts({
      alerts: response.alerts,
      isContactPrescriberEnabled: response.isContactPrescriberEnabled,
      member,
      stores: childStores,
      api,
    });
  }

  @computed
  get claims(): Object {
    return this.response.claims;
  }

  @computed
  get hasClaims(): boolean {
    return this.response.claims.length > 0;
  }

  @computed
  get alerts(): Alerts {
    return this.alertsCollection;
  }

  @computed
  get isContactPrescriberEnabled(): boolean {
    return this.response.isContactPrescriberEnabled;
  }
}

export default AlertResponse;
