/* @flow */

import { observable, action } from 'mobx';
import { union, find } from 'lodash';
import axios from '@utils/axios/axios';

import { SessionManager } from '../../utils/session-manager';

class ItemStore {
  @observable items: Array<any> = [];

  sessionManager: Object;

  name: string;

  appUrl: string;

  constructor(name: string) {
    this.sessionManager = new SessionManager(sessionStorage);
    this.name = name;
    this.appUrl = window.app_url || global.app_url;
  }

  @action
  add(items: Array<any>, clear) {
    this.items = clear ? items : union(this.items, items);
  }

  @action
  delete(item: Object) {
    const omfg = this.items;
    const jesus = omfg.filter((itemObj) => {
      return itemObj.id !== item.id;
    });

    this.items = jesus;
  }

  // assumes that the response.data is an array of the requested items
  callApi(ids: Array<string>, queryString: string, memberId: ?string) {
    // ex. call http://baseurl/name/id=['123','456','789']

    let idQuery = ids.join();
    if (ids.length === 1) {
      [id1, ...restIds] = ids;
      idQuery = id1;
    }

    const { name } = this;
    // if (this.name === 'claims') {
    //   name = 'members/' + this.name;
    // }

    const query = queryString ? `&${queryString}` : '';

    const apiUrls = {
      claims: `/v2/${memberId || 'fubar'}/claims?id=${idQuery}${query}`,
      alerts: `/v2/${memberId || 'fubar'}/alerts?id=${idQuery}${query}`,
      members: `/v2/${memberId || 'fubar'}/members`,
    };

    const url = apiUrls[name] || `/${name}?id=${idQuery}${query}`;
    return axios
      .setCheckAuthorization(true)
      .get(url)
      .then((response) => {
        // add to store
        const isArray = Array.isArray(response.data);
        if (isArray) {
          this.add(response.data[0].response || response.data[0]);
          return response.data[0].response || response.data[0];
        }
        this.add(response.data.response || response.data);
        return response.data.response || response.data;
      })
      .catch(() => []);
  }

  /*
   * Checks currently saved items for ids and fetches ones not found
   */
  async getItemsById(ids: Array<string>, queryString: ?string, memberId: ?string) {
    const notFoundItems = [];
    let foundItems = [];
    let query = '';

    if (queryString) {
      query = queryString;
    }

    ids.forEach((id) => {
      const item = find(this.items, { id: parseInt(id) });

      if (item) {
        // Add found items
        foundItems.push(item);
      } else {
        // Ids of not found items
        notFoundItems.push(id);
      }
    });

    if (notFoundItems.length) {
      // Call generic api then add to found items
      const newItems = await this.callApi(notFoundItems, query, memberId);
      foundItems = foundItems.concat(newItems);
    }

    return foundItems;
  }
}

export { ItemStore };
