/* @flow */

import { action, computed, observable } from 'mobx';
import axios from '@utils/axios/axios';
import { SessionManager } from '@utils/session-manager';

class DrugSearchStore {
  constructor(memberStore) {
    this.apiBase = window.api_url || global.api_url;
    this.sessionManager = new SessionManager(sessionStorage);
    this.memberStore = memberStore;
    const sessionMed = this.sessionManager.get('last-medication');
    if (sessionMed === null) {
      if (this.urlParams().nameId) {
        axios.get(`${this.urls.searchBase}id=${this.urlParams().nameId}`).then((resp) => {
          this.sessionManager.set('last-medication', resp.data.response[0]);
          this.loadCacheFromURL();
        });
      }
    } else {
      this.loadCacheFromURL();
    }
  }

  // TODO (PORTAL-1988): change, minLength & suggestionLimit, make const above the constructor so that the search does not have a min
  // length only to start but instead a min length for all searches
  minLength = 3;

  suggestionLimit = 4;

  urls = {
    searchBase: '/names?',
    formBase: '/search-result/',
    recent: '/members/search-history?include=products',
  };

  // TIMEOUT SETTINGS
  timeout = 0;

  searchDelay = 300;

  /**
   * @private
   * The list of medicines rendered when searching
   */
  @observable _suggestions = [];

  /**
   * Gets the array of suggestions from the search
   */
  @computed get suggestions() {
    return this._suggestions.length > this.suggestionLimit
      ? this._suggestions.slice(0, this.suggestionLimit)
      : this._suggestions;
  }

  /**
   * Boolean to toggle whether or not the suggestionsContainer should be rendered
   */
  @observable isAutocompleteOpen = true;

  @action openAutocomplete() {
    this.isAutocompleteOpen = true;
  }

  @action closeAutocomplete() {
    this.isAutocompleteOpen = false;
  }

  /**
   * Updates the suggestions list with the new suggestion
   *
   * @param {Object} newSuggestions - list of new selections
   */
  @action updateSuggestions(newSuggestions) {
    this._suggestions = newSuggestions;
  }

  /**
   * Searches for drugs when the user types in a query
   *
   * @param {String} queryString - value to be searched on
   */
  @action searchDrugs(queryString) {
    if (queryString.length >= this.minLength) {
      window.clearTimeout(this.timeout);
      this.timeout = window.setTimeout(() => {
        this.hasAutocompleteError = false;
        axios
          .setIsVerbose(false)
          .get(`${this.urls.searchBase}name=${queryString}`)
          .then(this.drugSearchSuccess)
          .catch(this.drugSearchFailure);
      }, this.searchDelay);
    }
  }

  /**
   * Updates the drug suggestion list after successful search.
   *
   * @param {Object} param0
   * @param {Object} param0.response - response object from the drug search call
   */
  @action.bound drugSearchSuccess({ response }) {
    this.updateSuggestions(response);
  }

  /**
   * Resets the drug suggestion list after a unsuccessful search.
   */
  @action.bound drugSearchFailure() {
    this.updateSuggestions([]);
    this.hasAutocompleteError = true;
  }

  /**
   * @private
   * Value of user input, the text of the search medicine the user is searching for
   */
  @observable _autocompleteValue = '';

  /**
   * The value of the search input form
   */
  @computed get autocompleteValue() {
    return this._autocompleteValue;
  }

  /**
   * Updates search box to have the newest value and checks to see if a selected medicine was deleted.
   *
   * @param {String} newAutocompleteValue - newest value in the search box.
   */
  @action updateAutocompleteValue(newAutocompleteValue) {
    this._autocompleteValue = newAutocompleteValue;

    // if there was a selected medicine and that medicine value was deleted then it needs to be reset
    if (!this._autocompleteValue && this.selectedMedication !== null) {
      this.selectedMedication = null;
      this.openAutocomplete();
      this.updateSuggestions([]);
    }
  }

  /**
   * Adds drug to recent search list.
   */
  @action addDrugtoRecentSearch() {
    return axios.setCheckAuthorization(true).post(this.urls.recent, {
      product_id: this.selectedDosageInfo.product_id,
      quantity: parseInt(this.formQuantity),
      day_supply: parseInt(this.daySupply),
      location: this.zipCode,
      package_type_id: 666,
    });
  }

  /**
   * Boolean indicating if an error occurred when autocomplete is triggered.
   */
  @observable hasAutocompleteError = false;

  /**
   * User selected medication.
   */
  @observable selectedMedication = null;

  /**
   * Sets the forms selected medication and clears current form data.
   *
   * @param {Object} newMedication - The user selected medication
   */
  @action setSelectedMedication(newMedication) {
    this.selectedMedication = newMedication;
    this.setSelectedFormulary('');
    this.setSelectedDosage('');
    this.setSelectedQuantity('');
    this.setCustomQuantity('');
    this.closeAutocomplete();
  }

  /**
   * Boolean indicating whether the form has a selected medication or not.
   */
  @computed get hasSelectedMedication() {
    return this.selectedMedication !== null;
  }

  /**
   * Stores the last medication for a given session.
   *
   * @param {Object} medication - The last medication take to store
   */
  @action sessionStoreMedication(medication) {
    this.sessionManager.set('last-medication', medication);
  }

  /**
   * Boolean indicating if the search form is open or not
   */
  @observable isOpen = false;

  /**
   * Used to open the search form and set to defaults.
   */
  @action openForm() {
    this.isOpen = true;
    this.resetQuantityError();
    this.resetZipError();
    this.setCustomQuantityError(false);
    this.hasAutocompleteError = false;
  }

  /**
   * Used to close the search form.
   */
  @action closeForm() {
    this.isOpen = false;
  }

  /**
   * Resets the search form to default values.
   */
  @action resetForm() {
    this.updateAutocompleteValue('');
    this.setSelectedMedication(null);
    this.setSelectedFormulary('');
    this.setSelectedDosage('');
    this.setSelectedQuantity('');
    this.setZipCode(null);
    this.openAutocomplete();
  }

  /**
   * @private
   * The user’s selected Formulary, I.E. "Tablet", "Capsule"
   *
   * NOTE: Not related to drug formulary used by plan but instead "formulary" is used
   *   to diff the drug form (Tablet) vs the HTML drug search form.
   */
  @observable _selectedFormulary = '';

  /**
   * Returns users selectedFormulary (or forms value).
   */
  @computed get selectedFormulary() {
    let returnVal = '';
    if (this._selectedFormulary !== '') {
      returnVal = this._selectedFormulary;
    } else if (this.hasSelectedMedication) {
      returnVal = this.formFormularies[0].value;
    }
    return returnVal;
  }

  /**
   * Sets the "formulary" value and updates the form in real time
   * @see _selectedFormulary
   * @param {Object} newFormulary - The formulary to be set
   */
  @action setSelectedFormulary(newFormulary) {
    this.resetQuantityError();
    this._selectedFormulary = newFormulary.value || newFormulary;
    this.setSelectedDosage('');
    this.setSelectedQuantity('');
  }

  /**
   * Returns selected "formulary" information
   * @see _selectedFormulary
   */
  @computed get selectedFormularyInfo() {
    try {
      const formFormulary = this.formFormularies.filter((form) => {
        return form.label === this.selectedFormulary;
      });
      return formFormulary[0].info;
    } catch (err) {}
  }

  /**
   * Gets all the "formularies" for the selected medication
   * @see _selectedFormulary
   */
  @computed get formFormularies() {
    return !this.hasSelectedMedication
      ? []
      : this.selectedMedication.forms.map((form) => {
          return {
            label: form.description,
            value: form.description,
            info: form,
          };
        });
  }

  /**
   * @private
   * Selected medication's dosage
   */
  @observable _selectedDosage = '';

  /**
   * Returns user's selected dosage or forms value
   */
  @computed get selectedDosage() {
    let returnVal = '';
    if (this._selectedDosage !== '') {
      returnVal = this._selectedDosage;
    } else if (this.hasSelectedMedication) {
      returnVal = this.formDosages[0].value;
    }
    return returnVal;
  }

  /**
   * Sets the dosage value and updates the form in real time
   *
   * @param {Object} newDosage - The dosage to be set
   */
  @action setSelectedDosage(newDosage) {
    this.resetQuantityError();
    this._selectedDosage = newDosage.value || newDosage;
    this.setSelectedQuantity('');
  }

  /**
   * Returns selected dosage information
   */
  @computed get selectedDosageInfo() {
    try {
      return this.formDosages.filter((dosage) => {
        return dosage.label === this.selectedDosage;
      })[0].info;
    } catch (err) {
      Rollbar.warn(err);
      return null;
    }
  }

  /**
   * Gets all dosage information from the selected "formulary"
   * @see _selectedFormulary
   */
  @computed get formDosages() {
    return !this.hasSelectedMedication
      ? []
      : this.selectedFormularyInfo.strengths.map((strength) => {
          return {
            label: strength.description,
            value: strength.description,
            info: strength,
          };
        });
  }

  /**
   * @private
   * Selected medication's quantity
   */
  @observable _selectedQuantity = '';

  /**
   * Selected medication's custom quantity
   */
  @observable customQuantity = '';

  /**
   * Flag if there is an error with _selectedQuantity
   */
  @observable quantityError = false;

  /**
   * Flag if there is an error with customQuantity
   */
  @observable customQuantityError = false;

  /**
   * Sets the custom quantity error field if an error occurred with customQuantity
   *
   * @param {Boolean} bool - bool to set if an error occurred
   */
  @action setCustomQuantityError(bool) {
    this.customQuantityError = bool;
  }

  /**
   * Gets the form quantity for custom or selected
   */
  @computed get formQuantity() {
    return this.selectedQuantity === 'custom' ? this.customQuantity : this.selectedQuantity;
  }

  /**
   * Gets the selected quantity or the form's quantity's value
   */
  @computed get selectedQuantity() {
    let returnVal = '';
    if (this._selectedQuantity !== '') {
      returnVal = this._selectedQuantity;
    } else if (this.hasSelectedMedication) {
      if (this.formQuantities.filter((quant) => parseInt(quant.value) === 30).length > 0) {
        returnVal = 30;
      } else {
        returnVal = this.formQuantities[0] ? this.formQuantities[0].value : '';
      }
    }
    return returnVal;
  }

  /**
   * Sets the selected quantity and custom flag if need be
   *
   * @param {Object} newQuantity - The new quantity to update
   */
  @action setSelectedQuantity(newQuantity) {
    if (newQuantity === null) {
      this._selectedQuantity = '';
    } else {
      const validatedNewQuantity = newQuantity.value ? newQuantity.value : newQuantity;
      this._selectedQuantity = validatedNewQuantity;
      if (newQuantity !== 'custom') {
        this.setCustomQuantity('');
      }
    }
  }

  /**
   * Sets the form's custom quantity
   *
   * @param {Object} newCustom - The new custom quantity to update
   */
  @action setCustomQuantity(newCustom) {
    if (newCustom !== null && newCustom !== this.customQuantity) {
      this.customQuantity = newCustom;
    }
  }

  /**
   * Sets quantity error if there was an error with _selectedQuantity
   */
  @action setQuantityError() {
    this.quantityError = isNaN(parseInt(this.formQuantity)) || parseInt(this.formQuantity) < 1;
  }

  /**
   * Resets quantityError to default.
   */
  @action resetQuantityError() {
    this.quantityError = false;
  }

  /**
   * Gets the form quantities from the dosage
   */
  @computed get formQuantities() {
    let quantities = !this.hasSelectedMedication
      ? []
      : this.formDosages
          .filter((dosage) => {
            return dosage.label === this.selectedDosage;
          })[0]
          .info.quantities.map((quantity) => {
            return {
              label: parseInt(quantity),
              value: parseInt(quantity),
            };
          });

    quantities.length
      ? (quantities = quantities.concat({
          label: 'Custom Quantity',
          value: 'custom',
        }))
      : '';
    return quantities;
  }

  /**
   * Gets the supply in days for selected dosage or sets to default.
   * @default 30
   */
  @computed get daySupply() {
    return this.selectedDosageInfo.day_supply[this.formQuantity] || '30';
  }

  /**
   * Gets the dispensable unit for quantity label.
   */
  @computed get quantityLabel() {
    return this.selectedDosageInfo.dispensable_unit;
  }

  /**
   * @private
   * Forms zip code.
   */
  @observable _zipCode = null;

  /**
   * Boolean indicating if an error occurred when changing the zip code.
   */
  @observable zipError = false;

  @action setZipCode(newZipCode) {
    this._zipCode = newZipCode;
    this.setZipError();
  }

  /**
   * Get the zip code if not null, otherwise member's preferred pharmacy zip code.
   */
  @computed get zipCode() {
    return this._zipCode !== null
      ? this._zipCode
      : this.memberStore.currentMember.preferred_retail_pharmacy.address.zipcode;
  }

  @action setZipError() {
    this.zipError = this.zipCode === '';
  }

  @action resetZipError() {
    this.zipError = false;
  }

  /**
   * Forms the path of the url
   * TODO: remove not used?
   */
  @computed get formURLPath() {
    return !this.hasSelectedMedication
      ? ''
      : `${this.urls.formBase}${this.selectedDosageInfo.product_id}`;
  }

  /**
   * Forms the query params of the url
   */
  @computed get formParams() {
    return !this.hasSelectedMedication
      ? {}
      : {
          day_supply: this.daySupply,
          dosage: this.selectedDosage,
          form: this.selectedFormulary,
          nameId: this.selectedMedication.id,
          quantity: this.formQuantity,
          zip: this.zipCode,
          product_id: this.selectedDosageInfo.product_id,
        };
  }

  /**
   * Takes all query params and wraps them in a shallow object.
   */
  urlParams() {
    return window.location.search
      .substring(1)
      .split('&')
      .reduce((data, param) => {
        const parts = param.split('=');
        data[parts[0]] = decodeURIComponent(parts[1]);
        return data;
      }, {});
  }

  @observable cachedAutocompleteValue = '';

  @action setCachedAutocompleteValue(newAutocompleteValue) {
    this.cachedAutocompleteValue = newAutocompleteValue;
  }

  @observable cachedMedication = null;

  @action setCachedMedication(newMedication) {
    this.cachedMedication = newMedication;
  }

  @observable cachedFormulary = '';

  @action setCachedFormulary(newFormulary) {
    this.cachedFormulary = newFormulary;
  }

  @observable cachedDosage = '';

  @action setCachedDosage(newDosage) {
    this.cachedDosage = newDosage;
  }

  @observable cachedDosageInfo = '';

  @action setCachedDosageInfo(newDosageInfo) {
    this.cachedDosageInfo = newDosageInfo;
  }

  @observable cachedQuantity = '';

  @action setCachedQuantity(newQuantity) {
    this.cachedQuantity = newQuantity;
  }

  @observable cachedZipCode = '';

  @action setCachedZipCode(newZipCode) {
    this.cachedZipCode = newZipCode;
  }

  @observable cachedCustomQuantity = '';

  @action setCachedCustomQuantity(newQuantity) {
    this.cachedCustomQuantity = newQuantity;
  }

  @observable cachedQuantityLabel = '';

  @action setCachedQuantityLabel(newLabel) {
    this.cachedQuantityLabel = newLabel;
  }

  /**
   * Set cache values
   */
  @action setCache() {
    this.sessionManager.set('last-medication', this.selectedMedication);
    this.setCachedMedication(this.selectedMedication);
    this.setCachedFormulary(this.selectedFormulary);
    this.setCachedDosage(this.selectedDosage);
    this.setCachedDosageInfo(this.selectedDosageInfo);
    this.setCachedQuantity(this.customQuantity ? 'custom' : this.formQuantity);
    this.setCachedCustomQuantity(this.customQuantity);
    this.setCachedQuantityLabel(this.quantityLabel);
    this.setCachedZipCode(this.zipCode);
  }

  /**
   * Updates the form with values from the URL cache
   */
  @action loadCacheFromURL() {
    const sessionMed = this.sessionManager.get('last-medication');
    let prom = false;
    const { nameId, form, dosage, product_id: productId, quantity, zip } = this.urlParams();

    if (sessionMed.id === parseInt(nameId)) {
      this.setCachedAutocompleteValue(sessionMed.name);
      this.setCachedMedication(sessionMed);

      if (form) {
        let urlStrength = '';
        const urlForm = form.split('+').join(' ');
        const urlProductId = parseInt(productId);

        try {
          urlStrength = decodeURIComponent(dosage).split('+').join(' ');
        } catch (e) {
          urlStrength = dosage.split('+').join(' ');
        }

        const { dispensable_unit: dispensableUnit } = sessionMed.forms
          .find((formObj) => formObj.description === urlForm)
          .strengths.find(
            (strength) =>
              strength.description === urlStrength || strength.product_id === urlProductId,
          );

        this.setCachedQuantityLabel(dispensableUnit);
      }
    } else if (nameId !== undefined) {
      prom = axios
        .setIsVerbose(false)
        .get(`${this.urls.searchBase}id=${nameId}`)
        .then(({ response }) => {
          this.sessionManager.set('last-medication', response[0]);
          this.loadCacheFromURL();
        });
    }

    if (form) {
      this.setCachedFormulary(form.split('+').join(' '));
    }
    if (dosage) {
      this.setCachedDosage(dosage.split('+').join(' '));
    }

    if (quantity) {
      this.setCachedQuantity({
        label: parseInt(quantity.split('+').join(' ')),
        value: parseInt(quantity.split('+').join(' ')),
      });
      this.setCustomQuantity(quantity);
    }
    if (zip) {
      this.setCachedZipCode(zip.split('+').join(' '));
    }
    return prom;
  }

  /**
   * Updates the form with values from cache
   */
  @action loadFromCache() {
    this.updateAutocompleteValue(this.cachedAutocompleteValue);
    this.setSelectedMedication(this.cachedMedication);
    this.setSelectedFormulary(this.cachedFormulary);
    this.setSelectedDosage(this.cachedDosage);
    this.setZipCode(this.cachedZipCode);
    this.setCustomQuantity(this.cachedCustomQuantity);
    this.setSelectedQuantity(this.cachedQuantity);

    const hasCustomValue = !this.hasSelectedMedication
      ? []
      : this.formDosages
          .filter((dosage) => {
            return dosage.label === this.selectedDosage;
          })[0]
          .info.quantities.map((quantity) => {
            return {
              label: parseInt(quantity),
              value: parseInt(quantity),
            };
          })
          .filter((item) => {
            return item.label === this.selectedQuantity;
          });

    if (hasCustomValue.length === 0) {
      if (this.selectedQuantity !== 'custom') {
        this.setCustomQuantity(this.selectedQuantity);
      }
      this.setSelectedQuantity('custom');
    } else {
      this.setSelectedQuantity(this.cachedQuantity);
    }
  }

  /**
   * Clears Cache
   * TODO: remove not used.
   */
  @action clearCache() {
    this.setCachedMedication(null);
    this.setCachedFormulary('');
    this.setCachedDosage('');
    this.setCachedQuantity('');
    this.setCachedZipCode('');
    this.setCachedCustomQuantity('');
    this.setCachedQuantityLabel('');
  }
}

export { DrugSearchStore };
