/* @flow */

import { observable, action, computed, runInAction } from 'mobx';
import axios from '@utils/axios/axios';
import { generateSuggestionIdFromProductId } from '@utils/Functions';
import type { SuggestedProducts } from '@features/savings/types/Product';

/**
 * Interface defining minimum parameters a store needs to call the submit method.
 *
 * Marked as read-only in order to allow it to accept an object with additional fields not defined in the interface.
 * This is needed to make the suggestionStore parameter accept either
 * the couponSuggestionsStore or the savingsDomainStore.
 * {@link https://flow.org/en/docs/types/utilities/#toc-readonly Flow Docs on ReadOnly}
 */
type SuggestionStore = $ReadOnly<{
  pricingKey: 'adjudilite' | 'coupon',
  selectedSuggestionId: number,
  selectedSuggestion: {},
  selectedPharmacy: {
    ncpdp: string,
    suggested_products: SuggestedProducts,
    id: number,
  },
}>;

type SubmitParams = {|
  currentAlertId: string,
  contactData: ?string,
  type: 'sms' | 'email',
  suggestionsStore: SuggestionStore,
  errorHandler: function,
  callback: function,
|};

type HomeDeliverySubmitParams = {|
  ...SubmitParams,
  currentClaimId: null,
  rxHomeTransferStore: {||},
|};

class ContactPrescriberModalStore {
  @observable show: boolean;

  @observable _loading: boolean = false;

  @observable suggestion: Object;

  @observable success: boolean;

  @observable submitted: boolean = true;

  @observable error: boolean = false;

  @observable errorStatus: null | number;

  @observable result: Object;

  @observable mobileUpdated: boolean = false;

  @observable pendingStatus: boolean = false;

  @observable completedStatus: boolean = false;

  @observable expressPendingStatus: boolean = false;

  @observable expressCompletedStatus: boolean = false;

  @observable isMailOrderAlert: boolean = false;

  @observable homeDeliveryUri: '';

  _contactId: number;

  _apiBase: string;

  constructor() {
    this.show = false;
    this.result = {};
  }

  @computed
  get loading() {
    return this._loading;
  }

  @action
  open() {
    this.show = true;
  }

  @action
  close() {
    this.show = false;
  }

  @action
  reset() {
    this.submitted = false;
    this.success = undefined;
    this.suggestion = null;
    this.setProp('loading', false);
    this.error = false;
    this.errorStatus = null;
    this.homeDeliveryUri = '';
  }

  @action
  setHomeDeliveryUri(uri) {
    this.homeDeliveryUri = uri;
  }

  @action
  setProp(prop, val) {
    this[`_${prop}`] = val;
  }

  @action
  async submit({
    currentAlertId,
    currentClaimId,
    contactData,
    type,
    suggestionsStore,
    rxHomeTransferStore,
    errorHandler,
    callback,
  }: SubmitParams | HomeDeliverySubmitParams) {
    this.setProp('loading', true);
    this.error = false;
    this.open();

    const data = {
      contact_type: type.toUpperCase(),
      contact_info: contactData,
    };

    try {
      if (currentAlertId) {
        if (
          !suggestionsStore.selectedPharmacy.suggested_products[0].prices[
            suggestionsStore.pricingKey
          ]
        ) {
          throw new Error(
            `Prices not found for pharmacy. pricingKey: ${suggestionsStore.pricingKey}, pharmacy: ${suggestionsStore.selectedPharmacy.id}, suggestion: ${suggestionsStore.selectedSuggestionId}`,
          );
        }
        this.suggestion = suggestionsStore.selectedSuggestion;
        const { quantity, day_supply } =
          suggestionsStore.selectedPharmacy.suggested_products[0].prices[
            suggestionsStore.pricingKey
          ][0];
        data.alert_id = currentAlertId;
        data.quantity = parseFloat(quantity);
        data.days_supply = day_supply;
        data.suggestion_id = suggestionsStore.selectedSuggestionId;
        data.ncpdp = suggestionsStore.selectedPharmacy.ncpdp;
        data.prices = suggestionsStore.selectedPharmacy.suggested_products.map((product) => {
          return {
            ndc: parseInt(product.prices[suggestionsStore.pricingKey][0].ndc),
            bin: product.prices[suggestionsStore.pricingKey][0].attributes.bin,
          };
        });
      } else {
        const {
          price: { ncpdp, day_supply, quantity, ndc },
          productId,
        } = rxHomeTransferStore.currentTransferClaim.details;
        data.claim_id = currentClaimId;
        data.ncpdp = ncpdp;
        data.days_supply = day_supply;
        data.quantity = parseFloat(quantity);
        data.prices = [{ ndc: parseInt(ndc) }];
        data.suggestion_id = generateSuggestionIdFromProductId(productId);
      }
      const response = await axios.post('/contact-prescriber', data);
      /**
       * runInAction needed for MobX to properly track asynchronous state updates
       * {@link https://github.com/mobxjs/mobx/blob/mobx4and5/docs/best/actions.md#writing-asynchronous-actions}
       */
      runInAction(() => {
        this.expressPendingStatus === true
          ? this.expressSuggestionCompletedRequest()
          : (this.submitted = true);
        this._contactId = response.data.response.id;
        this.result = response.data.response;
        if (callback) {
          callback(response);
        }
        this.error = false;
        this.setProp('loading', false);
      });
    } catch (error) {
      /**
       * runInAction needed for MobX to properly track asynchronous state updates
       * {@link https://github.com/mobxjs/mobx/blob/mobx4and5/docs/best/actions.md#writing-asynchronous-actions}
       */
      runInAction(() => {
        errorHandler();
        this.close();
        this.setProp('loading', false);
        this.error = true;
        this.errorStatus = error.response?.status;
        this.submitted = false;
        Rollbar.error(error);
      });
    }
  }

  @action
  async cancelContactPrescriber(callback: () => void) {
    if (!this._contactId) {
      return;
    }

    try {
      await axios.delete(`/contact-prescriber?id=${this._contactId}`);
      /**
       * runInAction needed for MobX to properly track asynchronous state updates
       * {@link https://github.com/mobxjs/mobx/blob/mobx4and5/docs/best/actions.md#writing-asynchronous-actions}
       */
      runInAction(() => {
        this.success = true;
        if (typeof callback === 'function') {
          callback();
        }
      });
    } catch (error) {
      Rollbar.error(error);
    }
  }

  @action pendingPrescriberRequest() {
    this.pendingStatus = true;
    this.completedStatus = false;
  }

  @action expressSuggestionPendingRequest() {
    this.expressPendingStatus = true;
  }

  @action expressSuggestionCompletedRequest() {
    this.expressPendingStatus = false;
    this.expressCompletedStatus = true;
  }

  @action completedPrescriberRequest() {
    this.completedStatus = true;
    this.pendingStatus = false;
  }

  @action mailOrderContactPrescriberRequest() {
    this.isMailOrderAlert = true;
  }

  @action resetPrescriberRequests() {
    this.pendingStatus = false;
    this.completedStatus = false;
    this.expressPendingStatus = false;
    this.expressCompletedStatus = false;
    this.isMailOrderAlert = false;
  }
}

export { ContactPrescriberModalStore };
