/* @flow */
/**
 * Enum representing pricing modules returned from pricing API.
 * @enum {string}
 */
const PRICING_MODULES = Object.freeze({
  /**
   * Enum value for cash pricing module.
   */
  CASH: 'module_cash_pricing',
});

export default PRICING_MODULES;
