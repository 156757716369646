import { toFixed } from 'accounting';

export default function formatMoney(amount) {
  if (amount === null) {
    return '$-.--';
  }

  if (typeof amount === 'string') {
    return amount;
  }
  if (typeof amount === 'number') {
    const str = toFixed(amount, 2).split('.'); // rounding it off to 2 digits all the time
    str[0] = str[0].replace(/\B(?=(\d{3})+(?!\d))/g, ','); // insert commas for amounts >=1000
    return '$'.concat(str.join('.'));
  }
}
