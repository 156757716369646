/* @flow */

import { observable } from 'mobx';
import { ItemStore } from '../itemStore/itemStore';

class ProductStore extends ItemStore {
  @observable placeholder: string;

  constructor() {
    super(window.api_url || global.api_url, 'products');
  }
}

const productStore = new ProductStore();

export default productStore;
export { ProductStore };
