import { detect } from 'detect-browser';

/**
 * Utility function for ensuring that we can open a link in a new tab with cross-browser support.
 */
function getWindowOpenTriggerFunction() {
  const browser = detect();

  if (browser.name !== 'safari') {
    return (updatedUrl) => {
      return window.open(updatedUrl, '_blank');
    };
  }

  const windowReference = window.open('about:blank', '_blank');
  return (updatedUrl) => {
    windowReference.location = updatedUrl;
  };
}

export default getWindowOpenTriggerFunction;
