/**
 * Pendo Event Types
 *
 * @constant
 * @type {object}
 */
export const eventType = {
  SAVINGS_REDIRECT: 'Savings Redirect',
};

/**
 * Redirect Types for Savings Redirect Pendo Events
 *
 * @constant
 * @type {object}
 */
export const redirectType = {
  NO_DEPENDENT_PERMISSION: 'No Dependent PHI Permission',
  NO_SAVINGS: 'No Savings Alert',
  NOT_SUPER_ALERT: 'Not Super Alert',
  MULTIPLE_SAVINGS: 'Multiple Savings Alerts',
};
