/* @flow */
import { AlertItemTypes } from '@data/models';
import type { PricedPharmacy } from '@features/savings/types/Pharmacy';
import type { SuggestedProducts } from '@features/savings/types/Product';
import type { suggestionsTypesAll } from '@features/savings/types/Suggestion';
import Collection from '../Collection';
import { alertTypeValues } from './alertTypeValues';

type AlertsProps = {
  alerts: Array,
  isContactPrescriberEnabled: boolean,
  member: Object,
  stores: Object,
  api: Object,
};
class Alerts extends Collection {
  constructor(args: AlertsProps) {
    super();
    this.member = args.member;
    this.isContactPrescriberEnabled = args.isContactPrescriberEnabled;
    this.api = args.api;
    this.collectionName = 'Alerts';
    this.profileStore = args.stores.profileStore;
    this.gatekeeperStore = args.stores.gatekeeperStore;
    this.globalModalStore = args.stores.globalModalStore;
    this.memberStore = args.stores.memberStore;
    this.familyPermissionsStore = args.stores.familyPermissionsStore;
    this.translationStore = args.stores.translationStore;
    this.couponSuggestionsStore = args.stores.couponSuggestionsStore;
    this.markRefillAsFilled = this.markRefillAsFilled.bind(this);
    this.excludePharmacy = this.excludePharmacy.bind(this);
    this.makeNavigationToUserSettings = this.makeNavigationToUserSettings.bind(this);
    this.updatePrimaryRetailPharmacyUpdatedAt =
      this.updatePrimaryRetailPharmacyUpdatedAt.bind(this);
    this.updatePharmacy = this.updatePharmacy.bind(this);
    this.items = args.alerts.map(this.mapAlert.bind(this));
    this.alertCache = {};
  }

  mapAlert(alertInfo) {
    const {
      alert_type: alertType,
      is_rewardable: isRewardable,
      express_suggestion: expressSuggestion,
    } = alertInfo;
    const hasFeature = this.gatekeeperStore.isFeatureEnabled;

    if (alertType.includes(alertTypeValues.PRIMARY_RETAIL)) {
      const navigate =
        alertInfo.alert_type === alertTypeValues.PRIMARY_RETAIL_CONFIRM
          ? this.updatePrimaryRetailPharmacyUpdatedAt
          : this.updatePharmacy;
      return new AlertItemTypes.PrimaryRetailAlert({
        alertInfo,
        navigate,
        hasFeature,
        exclude: this.excludePharmacy,
        navigateToUser: this.makeNavigationToUserSettings,
        removeAlert: this.delete,
        translate: this.translationStore.translate,
        memberStore: this.memberStore,
        api: this.api,
      });
    }
    if (alertType.includes(alertTypeValues.CONTACT_PRESCRIBER)) {
      return new AlertItemTypes.ContactPrescriberAlert({
        alertInfo,
        hasFeature,
        removeAlert: this.delete,
        translate: this.translationStore.translate,
        memberStore: this.memberStore,
        api: this.api,
      });
    }
    if (alertType === alertTypeValues.GENERAL_MESSAGE) {
      this.api.markMessageRead(this.member, alertInfo.id);
      return new AlertItemTypes.MessageAlert({
        alertInfo,
        hasFeature,
        removeAlert: this.delete,
        translate: this.translationStore.translate,
        memberStore: this.memberStore,
        api: this.api,
      });
    }

    if (alertType === alertTypeValues.CLIENT || isRewardable) {
      return new AlertItemTypes.RewardableAlert({
        alertInfo,
        hasFeature,
        removeAlert: this.delete,
        translate: this.translationStore.translate,
        memberStore: this.memberStore,
        api: this.api,
      });
    }
    if (alertType === alertTypeValues.MAIL_ORDER) {
      return new AlertItemTypes.MailOrderAlert({
        alertInfo,
        hasFeature,
        removeAlert: this.delete,
        translate: this.translationStore.translate,
        memberStore: this.memberStore,
        api: this.api,
      });
    }
    if (alertType === alertTypeValues.PERMISSION_REQUEST) {
      return new AlertItemTypes.PermissionRequestAlert({
        alertInfo,
        hasFeature,
        removeAlert: this.delete,
        grant: this.familyPermissionsStore.grantPermission,
        revoke: this.familyPermissionsStore.revokePermission,
        translate: this.translationStore.translate,
        memberStore: this.memberStore,
        api: this.api,
      });
    }
    if (expressSuggestion && this.isContactPrescriberEnabled && hasFeature('express_suggestion')) {
      return new AlertItemTypes.ExpressAlert({
        alertInfo,
        hasFeature,
        removeAlert: this.delete,
        translate: this.translationStore.translate,
        memberStore: this.memberStore,
        couponSuggestionsStore: this.couponSuggestionsStore,
        api: this.api,
      });
    }
    if (alertType === alertTypeValues.REFILL) {
      return new AlertItemTypes.RefillAlert({
        alertInfo,
        hasFeature,
        removeAlert: this.delete,
        hasExpressSuggestion: expressSuggestion,
        markRefillAsFilled: this.markRefillAsFilled,
        translate: this.translationStore.translate,
        memberStore: this.memberStore,
        api: this.api,
      });
    }
    return new AlertItemTypes.MemberAlert({
      alertInfo,
      hasFeature,
      removeAlert: this.delete,
      translate: this.translationStore.translate,
      memberStore: this.memberStore,
      api: this.api,
    });
  }

  excludePharmacy(pharmacyNcpdp, alertId) {
    return this.api.ignorePharmacy(this.member, pharmacyNcpdp).then((resp) => {
      this.update(alertId, { alert_type: alertTypeValues.PRIMARY_RETAIL_IGNORED });
      this.replace(alertId, this.mapAlert(this.getById(alertId).alertObject));
      return resp;
    });
  }

  updatePharmacy({ id: alertId, pharmacy, alert_type: alertType }) {
    this.profileStore
      .updatePreferredPharmacy(pharmacy)
      .then(() => {
        this.getById(alertId).updateType(
          alertType === alertTypeValues.PRIMARY_RETAIL_UPDATE
            ? alertTypeValues.PRIMARY_RETAIL_UPDATED
            : alertTypeValues.PRIMARY_RETAIL_CONFIRMED,
        );
        this.rebuildAlert(alertId);
      })
      .catch((err) => {
        Rollbar.warn(err);
        return err;
      });
  }

  updatePrimaryRetailPharmacyUpdatedAt({ id: alertId }, alertType) {
    return this.api.confirmPharmacy(this.member).then((resp) => {
      this.getById(alertId).updateType(
        alertType === alertTypeValues.PRIMARY_RETAIL_UPDATE
          ? alertTypeValues.PRIMARY_RETAIL_UPDATED
          : alertTypeValues.PRIMARY_RETAIL_CONFIRMED,
      );
      this.rebuildAlert(alertId);
      return resp;
    });
  }

  rebuildAlert(alertId) {
    this.replace(alertId, this.mapAlert(this.getById(alertId).alertObject));
  }

  makeNavigationToUserSettings({ alert_type: alertType, id: alertId }) {
    this.profileStore.navigatedFromPrimaryRetailPharmacyAlerts();
    alertType !== alertTypeValues.PRIMARY_RETAIL_CONFIRM && this.delete(alertId);
    return {
      pathname: '/profile',
    };
  }

  markRefillAsFilled(member, alertId) {
    return this.api.markRefillFilled(member, alertId).then(() => {
      this.delete(alertId);
      this.globalModalStore.kill();
    });
  }

  cacheAlert(alertId: string): void {
    this.alertCache[alertId] = JSON.parse(JSON.stringify(this.getById(alertId).alertObject));
  }

  revertContactPrescriberAlert(alertId) {
    this.alertCache[alertId] && this.replace(alertId, this.mapAlert(this.alertCache[alertId]));
  }

  updateContactPrescriberAlert(
    alertId: number,
    suggestionsStore: {
      +selectedPharmacy: PricedPharmacy,
      +selectedSuggestion: { type: suggestionsTypesAll },
    },
    suggestedDrugs: SuggestedProducts,
  ) {
    this.cacheAlert(alertId);
    // MAKE MODIFICATIONS
    let alertInfo = this.getById(alertId).alertObject;
    alertInfo = {
      ...alertInfo,
      alert_type: alertTypeValues.CONTACT_PRESCRIBER_ACTIVE,
      medication_claim: {
        ...alertInfo.medication_claim,
        contact_prescriber_temp: {
          couponSuggestionsStore: {
            pharmacy: suggestionsStore.selectedPharmacy,
            type: suggestionsStore.selectedSuggestion.type,
          },
          suggestedDrugs,
        },
      },
    };
    this.replace(alertId, this.mapAlert(alertInfo));
  }
}

export default Alerts;
