/* @flow */

import { observable, action } from 'mobx';
import axios from '@utils/axios/axios';

import emailSmsNormalizer from '../../utils/email-sms-normalizer';

class EmailModalStore {
  @observable show: boolean;

  @observable value: string = '';

  @observable success: boolean;

  @observable vouchers: Array<Object>;

  @observable error: boolean;

  @observable errorMessage: string = '';

  @observable alert: string = 'email';

  constructor() {
    this.show = false;
    this.success = false;
    this.error = false;
  }

  @action
  open() {
    this.show = true;
  }

  @action
  close() {
    this.show = false;
    this.success = false;
    this.error = false;
  }

  @action
  update(id: string, value: string) {
    this.value = value;
  }

  @action
  send(data: Array<Object>) {
    if (!this.validateEmail(this.value)) {
      this.error = true;
      this.success = false;
      this.errorMessage = 'Please enter a valid email address';
      return;
    }

    const normalizedData = data.map((item) => {
      return emailSmsNormalizer(item, this.value);
    });

    return Promise.all(
      normalizedData.map((data) => {
        return axios.setCheckAuthorization(true).post('/emailVoucher/', data);
      }),
    )
      .then(() => {
        this.success = true;
        this.error = false;
        this.errorMessage = 'null';
        this.value = '';
      })
      .catch(() => {
        this.errorMessage = `There was a problem sending your savings voucher to the above ${this.alert}. If this problem persists please give us a call at 1-800-268-4476.`;
        this.error = true;
        this.success = false;
      });
  }

  validateEmail(email: string): boolean {
    const re =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    return re.test(email);
  }
}

const emailModalStore = new EmailModalStore();

export default emailModalStore;
export { EmailModalStore };
