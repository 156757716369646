/* @flow */

import { observable, action } from 'mobx';

class PrescriberModalStore {
  @observable show: boolean;

  constructor() {
    this.show = false;
  }

  @action
  open() {
    this.show = true;
  }

  @action
  close() {
    this.show = false;
  }
}

const prescriberModalStore = new PrescriberModalStore();

export default prescriberModalStore;
export { PrescriberModalStore };
