import BaseAlert from '../BaseAlert/BaseAlert';
import AlertTypes from '../AlertTypes';

class MemberAlert extends BaseAlert {
  constructor(info) {
    super(info);
    this.modelName = AlertTypes.MEMBER;
    this.alertType = 'memberSavings';
  }
}

export default MemberAlert;
