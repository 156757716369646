export const suggestionStatic = {
  type: 'TA',
  long_type: 'Therapeutic Alternative',
  pharmacist_note: null,
  product_id: 128,
  best_prices: {
    retail: {
      description: 'module_plan_modifiers',
      cost_per_refill: 6.9,
      cost_per_day: 0.076667,
      quantity: 90,
      day_supply: 90,
      base_price_source: null,
      attributes: {
        is_pbm_max_cost: false,
        validation_score: null,
        coverage_alerts: [],
        is_blocker: false,
        plan_pay: 0,
        plan_pay_per_day: 0,
        accumulator_type: null,
        deductible: {
          applied: null,
          remaining: null,
        },
        out_of_pocket: {
          applied: null,
          remaining: null,
        },
      },
    },
    mail: {
      description: 'module_plan_modifiers',
      cost_per_refill: 6.9,
      cost_per_day: 0.076667,
      quantity: 90,
      day_supply: 90,
      base_price_source: null,
      attributes: {
        is_pbm_max_cost: false,
        validation_score: null,
        coverage_alerts: [],
        is_blocker: false,
        plan_pay: 0,
        plan_pay_per_day: 0,
        accumulator_type: null,
        deductible: {
          applied: null,
          remaining: null,
        },
        out_of_pocket: {
          applied: null,
          remaining: null,
        },
      },
    },
    coupon: null,
  },
  suggested_products: [
    {
      prices: {
        adjudilite: [
          {
            origin_id: 'adjudilite',
            product_id: 249,
            medication_id: 133,
            description: 'module_plan_modifiers',
            ndc: '13107003134',
            cost_per_refill: 6.9,
            cost_per_day: 0.076667,
            quantity: 90,
            day_supply: 90,
            base_price_source: 'module_pharmacy_discount_mac',
            attributes: {
              phase: 1,
              package_id: 2697,
              is_pbm_max_cost: false,
              validation_score: 1,
              coverage_alerts: [],
              is_blocker: false,
              plan_pay: 0,
              plan_pay_per_day: 0,
              accumulator_type: null,
              deductible: {
                applied: null,
                remaining: null,
              },
              out_of_pocket: {
                applied: null,
                remaining: null,
              },
            },
          },
        ],
        coupon: [],
        manufacturer_copay: [],
      },
      name: {
        id: 122,
        trademarked: 0,
        name: 'mirtazapine (generic of Remeron, Remeron SolTab)',
      },
      id: 249,
      description: 'Mirtazapine 15mg Tablet',
      form: 'Tablet',
      strength: '15mg',
      dispensable_unit: 'Tablet(s)',
      image_url: 'https://images.rxsavingssolutions.com/DrugItem_6983.JPG',
      normalized_dispensable_unit: {
        singular: 'Tablet',
        plural: 'Tablets',
      },
      form_plural: 'Tablets',
      form_string: null,
      product_name: 'mirtazapine (generic of Remeron, Remeron SolTab)',
      primary_name: 'Mirtazapine',
      secondary_name: '(Generic of Remeron)',
      strength_string: null,
      brand: 0,
      maintenance_med: 1,
      off_market: 0,
      brand_name: 'Remeron',
      medication_details: {
        side_effects:
          'Side effects that you should report to your doctor or health care professional as soon as possible:\n\n  * allergic reactions like skin rash, itching or hives, swelling of the face, lips, or tongue\n  * anxious\n  * changes in vision\n  * chest pain\n  * confusion\n  * elevated mood, decreased need for sleep, racing thoughts, impulsive behavior\n  * eye pain\n  * fast, irregular heartbeat\n  * feeling faint or lightheaded, falls\n  * feeling agitated, angry, or irritable\n  * fever or chills, sore throat\n  * hallucination, loss of contact with reality\n  * loss of balance or coordination\n  * mouth sores\n  * redness, blistering, peeling or loosening of the skin, including inside the mouth\n  * restlessness, pacing, inability to keep still\n  * seizures\n  * stiff muscles\n  * suicidal thoughts or other mood changes\n  * trouble passing urine or change in the amount of urine\n  * trouble sleeping\n  * unusual bleeding or bruising\n  * unusually weak or tired\n  * vomiting \nSide effects that usually do not require medical attention (report to your doctor or health care professional if they continue or are bothersome):\n\n  * change in appetite\n  * constipation\n  * dizziness\n  * dry mouth\n  * muscle aches or pains\n  * nausea\n  * tired\n  * weight gain',
        details: 'MIRTAZAPINE (mir TAZ a peen) is used to treat depression.',
        interactions:
          "Do not take this medicine with any of the following medications:\n* linezolid\n* MAOIs like Carbex, Eldepryl, Marplan, Nardil, and Parnate\n* methylene blue (injected into a vein)\n \nThis medicine may also interact with the following medications:\n* alcohol\n* antiviral medicines for HIV or AIDS\n* certain medicines that treat or prevent blood clots like warfarin\n* certain medicines for depression, anxiety, or psychotic disturbances\n* certain medicines for fungal infections like ketoconazole and itraconazole\n* certain medicines for migraine headache like almotriptan, eletriptan, frovatriptan, naratriptan, rizatriptan, sumatriptan, zolmitriptan\n* certain medicines for seizures like carbamazepine or phenytoin\n* certain medicines for sleep\n* cimetidine\n* erythromycin\n* fentanyl\n* lithium\n* medicines for blood pressure\n* nefazodone\n* rasagiline\n* rifampin\n* supplements like St. John's wort, kava kava, valerian\n* tramadol\n* tryptophan",
      },
    },
  ],
  pharmacies: [
    {
      suggested_products: [
        {
          prices: {
            adjudilite: [
              {
                origin_id: 'adjudilite',
                product_id: 249,
                medication_id: 133,
                description: 'module_plan_modifiers',
                ndc: '13107003134',
                cost_per_refill: 6.9,
                cost_per_day: 0.076667,
                quantity: 90,
                day_supply: 90,
                base_price_source: 'module_pharmacy_discount_mac',
                attributes: {
                  phase: 1,
                  package_id: 2697,
                  is_pbm_max_cost: false,
                  validation_score: 1,
                  coverage_alerts: [],
                  is_blocker: false,
                  plan_pay: 0,
                  plan_pay_per_day: 0,
                  accumulator_type: null,
                  deductible: {
                    applied: null,
                    remaining: null,
                  },
                  out_of_pocket: {
                    applied: null,
                    remaining: null,
                  },
                },
              },
            ],
            coupon: [],
            manufacturer_copay: [],
          },
          name: {
            id: 122,
            trademarked: 0,
            name: 'mirtazapine (generic of Remeron, Remeron SolTab)',
          },
          id: 249,
          description: 'Mirtazapine 15mg Tablet',
          form: 'Tablet',
          strength: '15mg',
          dispensable_unit: 'Tablet(s)',
          image_url: 'https://images.rxsavingssolutions.com/DrugItem_6983.JPG',
          quantity_with_unit: '90 Tablets',
          normalized_dispensable_unit: {
            singular: 'Tablet',
            plural: 'Tablets',
          },
          form_plural: 'Tablets',
          form_string: null,
          product_name: 'mirtazapine (generic of Remeron, Remeron SolTab)',
          primary_name: 'Mirtazapine',
          secondary_name: '(Generic of Remeron)',
          strength_string: null,
          brand: 0,
          maintenance_med: 1,
          off_market: 0,
          brand_name: 'Remeron',
          medication_details: {
            side_effects:
              'Side effects that you should report to your doctor or health care professional as soon as possible:\n\n  * allergic reactions like skin rash, itching or hives, swelling of the face, lips, or tongue\n  * anxious\n  * changes in vision\n  * chest pain\n  * confusion\n  * elevated mood, decreased need for sleep, racing thoughts, impulsive behavior\n  * eye pain\n  * fast, irregular heartbeat\n  * feeling faint or lightheaded, falls\n  * feeling agitated, angry, or irritable\n  * fever or chills, sore throat\n  * hallucination, loss of contact with reality\n  * loss of balance or coordination\n  * mouth sores\n  * redness, blistering, peeling or loosening of the skin, including inside the mouth\n  * restlessness, pacing, inability to keep still\n  * seizures\n  * stiff muscles\n  * suicidal thoughts or other mood changes\n  * trouble passing urine or change in the amount of urine\n  * trouble sleeping\n  * unusual bleeding or bruising\n  * unusually weak or tired\n  * vomiting \nSide effects that usually do not require medical attention (report to your doctor or health care professional if they continue or are bothersome):\n\n  * change in appetite\n  * constipation\n  * dizziness\n  * dry mouth\n  * muscle aches or pains\n  * nausea\n  * tired\n  * weight gain',
            details: 'MIRTAZAPINE (mir TAZ a peen) is used to treat depression.',
            interactions:
              "Do not take this medicine with any of the following medications:\n* linezolid\n* MAOIs like Carbex, Eldepryl, Marplan, Nardil, and Parnate\n* methylene blue (injected into a vein)\n \nThis medicine may also interact with the following medications:\n* alcohol\n* antiviral medicines for HIV or AIDS\n* certain medicines that treat or prevent blood clots like warfarin\n* certain medicines for depression, anxiety, or psychotic disturbances\n* certain medicines for fungal infections like ketoconazole and itraconazole\n* certain medicines for migraine headache like almotriptan, eletriptan, frovatriptan, naratriptan, rizatriptan, sumatriptan, zolmitriptan\n* certain medicines for seizures like carbamazepine or phenytoin\n* certain medicines for sleep\n* cimetidine\n* erythromycin\n* fentanyl\n* lithium\n* medicines for blood pressure\n* nefazodone\n* rasagiline\n* rifampin\n* supplements like St. John's wort, kava kava, valerian\n* tramadol\n* tryptophan",
          },
        },
      ],
      prices: {
        adjudilite: {
          description: 'module_plan_modifiers',
          cost_per_refill: 6.9,
          cost_per_day: 0.076667,
          quantity: 90,
          day_supply: 90,
          base_price_source: null,
          attributes: {
            is_pbm_max_cost: false,
            validation_score: null,
            coverage_alerts: [],
            is_blocker: false,
            plan_pay: 0,
            plan_pay_per_day: 0,
            accumulator_type: null,
            deductible: {
              applied: null,
              remaining: null,
            },
            out_of_pocket: {
              applied: null,
              remaining: null,
            },
          },
        },
      },
      id: 95448,
      ncpdp: '3846394',
      npi: '1588140321',
      name: 'CVS PHARMACY #17731',
      pharmacy_type: 'retail',
      type: {
        id: 1,
        label: 'retail',
      },
      type_id: 1,
      address: {
        street: '3031 SE POWELL BLVD',
        city: 'PORTLAND',
        state: 'OR',
        zipcode: '97202',
        latitude: '45.49731000',
        longitude: '-122.63407000',
      },
      phone: '5032314756',
      extension: null,
      fax: '5039463075',
      email: 'ENROLLMENT_RETAIL@CVSHEALTH.COM',
      directions: null,
      open_24_hrs: 'Y',
      language: 'en||||',
      distance: null,
      opening_hours: {
        periods: [
          {
            open: {
              day: 0,
              time: '0000',
            },
            close: {
              day: 0,
              time: '0000',
            },
          },
          {
            open: {
              day: 1,
              time: '0000',
            },
            close: {
              day: 1,
              time: '0000',
            },
          },
          {
            open: {
              day: 2,
              time: '0000',
            },
            close: {
              day: 2,
              time: '0000',
            },
          },
          {
            open: {
              day: 3,
              time: '0000',
            },
            close: {
              day: 3,
              time: '0000',
            },
          },
          {
            open: {
              day: 4,
              time: '0000',
            },
            close: {
              day: 4,
              time: '0000',
            },
          },
          {
            open: {
              day: 5,
              time: '0000',
            },
            close: {
              day: 5,
              time: '0000',
            },
          },
          {
            open: {
              day: 6,
              time: '0000',
            },
            close: {
              day: 6,
              time: '0000',
            },
          },
        ],
        weekday_text: {
          Sunday: 'Open 24 Hours',
          Monday: 'Open 24 Hours',
          Tuesday: 'Open 24 Hours',
          Wednesday: 'Open 24 Hours',
          Thursday: 'Open 24 Hours',
          Friday: 'Open 24 Hours',
          Saturday: 'Open 24 Hours',
        },
      },
      home_delivery_provider: null,
    },
    {
      suggested_products: [
        {
          prices: {
            adjudilite: [
              {
                origin_id: 'adjudilite',
                product_id: 249,
                medication_id: 133,
                description: 'module_plan_modifiers',
                ndc: '13107003134',
                cost_per_refill: 6.9,
                cost_per_day: 0.076667,
                quantity: 90,
                day_supply: 90,
                base_price_source: 'module_pharmacy_discount_mac',
                attributes: {
                  phase: 1,
                  package_id: 2697,
                  is_pbm_max_cost: false,
                  validation_score: 1,
                  coverage_alerts: [],
                  is_blocker: false,
                  plan_pay: 0,
                  plan_pay_per_day: 0,
                  accumulator_type: null,
                  deductible: {
                    applied: null,
                    remaining: null,
                  },
                  out_of_pocket: {
                    applied: null,
                    remaining: null,
                  },
                },
              },
            ],
            coupon: [],
            manufacturer_copay: [],
          },
          name: {
            id: 122,
            trademarked: 0,
            name: 'mirtazapine (generic of Remeron, Remeron SolTab)',
          },
          id: 249,
          description: 'Mirtazapine 15mg Tablet',
          form: 'Tablet',
          strength: '15mg',
          dispensable_unit: 'Tablet(s)',
          image_url: 'https://images.rxsavingssolutions.com/DrugItem_6983.JPG',
          quantity_with_unit: '90 Tablets',
          normalized_dispensable_unit: {
            singular: 'Tablet',
            plural: 'Tablets',
          },
          form_plural: 'Tablets',
          form_string: null,
          product_name: 'mirtazapine (generic of Remeron, Remeron SolTab)',
          primary_name: 'Mirtazapine',
          secondary_name: '(Generic of Remeron)',
          strength_string: null,
          brand: 0,
          maintenance_med: 1,
          off_market: 0,
          brand_name: 'Remeron',
          medication_details: {
            side_effects:
              'Side effects that you should report to your doctor or health care professional as soon as possible:\n\n  * allergic reactions like skin rash, itching or hives, swelling of the face, lips, or tongue\n  * anxious\n  * changes in vision\n  * chest pain\n  * confusion\n  * elevated mood, decreased need for sleep, racing thoughts, impulsive behavior\n  * eye pain\n  * fast, irregular heartbeat\n  * feeling faint or lightheaded, falls\n  * feeling agitated, angry, or irritable\n  * fever or chills, sore throat\n  * hallucination, loss of contact with reality\n  * loss of balance or coordination\n  * mouth sores\n  * redness, blistering, peeling or loosening of the skin, including inside the mouth\n  * restlessness, pacing, inability to keep still\n  * seizures\n  * stiff muscles\n  * suicidal thoughts or other mood changes\n  * trouble passing urine or change in the amount of urine\n  * trouble sleeping\n  * unusual bleeding or bruising\n  * unusually weak or tired\n  * vomiting \nSide effects that usually do not require medical attention (report to your doctor or health care professional if they continue or are bothersome):\n\n  * change in appetite\n  * constipation\n  * dizziness\n  * dry mouth\n  * muscle aches or pains\n  * nausea\n  * tired\n  * weight gain',
            details: 'MIRTAZAPINE (mir TAZ a peen) is used to treat depression.',
            interactions:
              "Do not take this medicine with any of the following medications:\n* linezolid\n* MAOIs like Carbex, Eldepryl, Marplan, Nardil, and Parnate\n* methylene blue (injected into a vein)\n \nThis medicine may also interact with the following medications:\n* alcohol\n* antiviral medicines for HIV or AIDS\n* certain medicines that treat or prevent blood clots like warfarin\n* certain medicines for depression, anxiety, or psychotic disturbances\n* certain medicines for fungal infections like ketoconazole and itraconazole\n* certain medicines for migraine headache like almotriptan, eletriptan, frovatriptan, naratriptan, rizatriptan, sumatriptan, zolmitriptan\n* certain medicines for seizures like carbamazepine or phenytoin\n* certain medicines for sleep\n* cimetidine\n* erythromycin\n* fentanyl\n* lithium\n* medicines for blood pressure\n* nefazodone\n* rasagiline\n* rifampin\n* supplements like St. John's wort, kava kava, valerian\n* tramadol\n* tryptophan",
          },
        },
      ],
      prices: {
        adjudilite: {
          description: 'module_plan_modifiers',
          cost_per_refill: 6.9,
          cost_per_day: 0.076667,
          quantity: 90,
          day_supply: 90,
          base_price_source: null,
          attributes: {
            is_pbm_max_cost: false,
            validation_score: null,
            coverage_alerts: [],
            is_blocker: false,
            plan_pay: 0,
            plan_pay_per_day: 0,
            accumulator_type: null,
            deductible: {
              applied: null,
              remaining: null,
            },
            out_of_pocket: {
              applied: null,
              remaining: null,
            },
          },
        },
      },
      id: 95449,
      ncpdp: '3846397',
      npi: '1588140321',
      name: 'CVS PHARMACY #17731 TEST',
      pharmacy_type: 'retail',
      type: {
        id: 1,
        label: 'retail',
      },
      type_id: 1,
      address: {
        street: '3031 SE POWELL BLVD',
        city: 'PORTLAND',
        state: 'OR',
        zipcode: '97202',
        latitude: '45.49731000',
        longitude: '-122.63407000',
      },
      phone: '5032314756',
      extension: null,
      fax: '5039463075',
      email: 'ENROLLMENT_RETAIL@CVSHEALTH.COM',
      directions: null,
      open_24_hrs: 'Y',
      language: 'en||||',
      distance: null,
      opening_hours: {
        periods: [
          {
            open: {
              day: 0,
              time: '0000',
            },
            close: {
              day: 0,
              time: '0000',
            },
          },
          {
            open: {
              day: 1,
              time: '0000',
            },
            close: {
              day: 1,
              time: '0000',
            },
          },
          {
            open: {
              day: 2,
              time: '0000',
            },
            close: {
              day: 2,
              time: '0000',
            },
          },
          {
            open: {
              day: 3,
              time: '0000',
            },
            close: {
              day: 3,
              time: '0000',
            },
          },
          {
            open: {
              day: 4,
              time: '0000',
            },
            close: {
              day: 4,
              time: '0000',
            },
          },
          {
            open: {
              day: 5,
              time: '0000',
            },
            close: {
              day: 5,
              time: '0000',
            },
          },
          {
            open: {
              day: 6,
              time: '0000',
            },
            close: {
              day: 6,
              time: '0000',
            },
          },
        ],
        weekday_text: {
          Sunday: 'Open 24 Hours',
          Monday: 'Open 24 Hours',
          Tuesday: 'Open 24 Hours',
          Wednesday: 'Open 24 Hours',
          Thursday: 'Open 24 Hours',
          Friday: 'Open 24 Hours',
          Saturday: 'Open 24 Hours',
        },
      },
      home_delivery_provider: null,
    },
    {
      suggested_products: [
        {
          prices: {
            adjudilite: [
              {
                origin_id: 'adjudilite',
                product_id: 249,
                medication_id: 133,
                description: 'module_plan_modifiers',
                ndc: '13107003134',
                cost_per_refill: 6.9,
                cost_per_day: 0.076667,
                quantity: 90,
                day_supply: 90,
                base_price_source: 'module_pharmacy_discount_mac',
                attributes: {
                  phase: 1,
                  package_id: 2697,
                  is_pbm_max_cost: false,
                  validation_score: 1,
                  coverage_alerts: [],
                  is_blocker: false,
                  plan_pay: 0,
                  plan_pay_per_day: 0,
                  accumulator_type: null,
                  deductible: {
                    applied: null,
                    remaining: null,
                  },
                  out_of_pocket: {
                    applied: null,
                    remaining: null,
                  },
                },
              },
            ],
            coupon: [],
            manufacturer_copay: [],
          },
          name: {
            id: 122,
            trademarked: 0,
            name: 'mirtazapine (generic of Remeron, Remeron SolTab)',
          },
          id: 249,
          description: 'Mirtazapine 15mg Tablet',
          form: 'Tablet',
          strength: '15mg',
          dispensable_unit: 'Tablet(s)',
          image_url: 'https://images.rxsavingssolutions.com/DrugItem_6983.JPG',
          quantity_with_unit: '90 Tablets',
          normalized_dispensable_unit: {
            singular: 'Tablet',
            plural: 'Tablets',
          },
          form_plural: 'Tablets',
          form_string: null,
          product_name: 'mirtazapine (generic of Remeron, Remeron SolTab)',
          primary_name: 'Mirtazapine',
          secondary_name: '(Generic of Remeron)',
          strength_string: null,
          brand: 0,
          maintenance_med: 1,
          off_market: 0,
          brand_name: 'Remeron',
          medication_details: {
            side_effects:
              'Side effects that you should report to your doctor or health care professional as soon as possible:\n\n  * allergic reactions like skin rash, itching or hives, swelling of the face, lips, or tongue\n  * anxious\n  * changes in vision\n  * chest pain\n  * confusion\n  * elevated mood, decreased need for sleep, racing thoughts, impulsive behavior\n  * eye pain\n  * fast, irregular heartbeat\n  * feeling faint or lightheaded, falls\n  * feeling agitated, angry, or irritable\n  * fever or chills, sore throat\n  * hallucination, loss of contact with reality\n  * loss of balance or coordination\n  * mouth sores\n  * redness, blistering, peeling or loosening of the skin, including inside the mouth\n  * restlessness, pacing, inability to keep still\n  * seizures\n  * stiff muscles\n  * suicidal thoughts or other mood changes\n  * trouble passing urine or change in the amount of urine\n  * trouble sleeping\n  * unusual bleeding or bruising\n  * unusually weak or tired\n  * vomiting \nSide effects that usually do not require medical attention (report to your doctor or health care professional if they continue or are bothersome):\n\n  * change in appetite\n  * constipation\n  * dizziness\n  * dry mouth\n  * muscle aches or pains\n  * nausea\n  * tired\n  * weight gain',
            details: 'MIRTAZAPINE (mir TAZ a peen) is used to treat depression.',
            interactions:
              "Do not take this medicine with any of the following medications:\n* linezolid\n* MAOIs like Carbex, Eldepryl, Marplan, Nardil, and Parnate\n* methylene blue (injected into a vein)\n \nThis medicine may also interact with the following medications:\n* alcohol\n* antiviral medicines for HIV or AIDS\n* certain medicines that treat or prevent blood clots like warfarin\n* certain medicines for depression, anxiety, or psychotic disturbances\n* certain medicines for fungal infections like ketoconazole and itraconazole\n* certain medicines for migraine headache like almotriptan, eletriptan, frovatriptan, naratriptan, rizatriptan, sumatriptan, zolmitriptan\n* certain medicines for seizures like carbamazepine or phenytoin\n* certain medicines for sleep\n* cimetidine\n* erythromycin\n* fentanyl\n* lithium\n* medicines for blood pressure\n* nefazodone\n* rasagiline\n* rifampin\n* supplements like St. John's wort, kava kava, valerian\n* tramadol\n* tryptophan",
          },
        },
      ],
      prices: {
        adjudilite: {
          description: 'module_plan_modifiers',
          cost_per_refill: 6.9,
          cost_per_day: 0.076667,
          quantity: 90,
          day_supply: 90,
          base_price_source: null,
          attributes: {
            is_pbm_max_cost: false,
            validation_score: null,
            coverage_alerts: [],
            is_blocker: false,
            plan_pay: 0,
            plan_pay_per_day: 0,
            accumulator_type: null,
            deductible: {
              applied: null,
              remaining: null,
            },
            out_of_pocket: {
              applied: null,
              remaining: null,
            },
          },
        },
      },
      id: 95450,
      ncpdp: '3846395',
      npi: '1588140321',
      name: 'TRUEPILL',
      pharmacy_type: 'retail',
      type: {
        id: 1,
        label: 'retail',
      },
      type_id: 1,
      address: {
        street: '3031 SE POWELL BLVD',
        city: 'PORTLAND',
        state: 'OR',
        zipcode: '97202',
        latitude: '45.49731000',
        longitude: '-122.63407000',
      },
      phone: '5032314756',
      extension: null,
      fax: '5039463075',
      email: 'ENROLLMENT_RETAIL@CVSHEALTH.COM',
      directions: null,
      open_24_hrs: 'Y',
      language: 'en||||',
      distance: null,
      opening_hours: {
        periods: [
          {
            open: {
              day: 0,
              time: '0000',
            },
            close: {
              day: 0,
              time: '0000',
            },
          },
          {
            open: {
              day: 1,
              time: '0000',
            },
            close: {
              day: 1,
              time: '0000',
            },
          },
          {
            open: {
              day: 2,
              time: '0000',
            },
            close: {
              day: 2,
              time: '0000',
            },
          },
          {
            open: {
              day: 3,
              time: '0000',
            },
            close: {
              day: 3,
              time: '0000',
            },
          },
          {
            open: {
              day: 4,
              time: '0000',
            },
            close: {
              day: 4,
              time: '0000',
            },
          },
          {
            open: {
              day: 5,
              time: '0000',
            },
            close: {
              day: 5,
              time: '0000',
            },
          },
          {
            open: {
              day: 6,
              time: '0000',
            },
            close: {
              day: 6,
              time: '0000',
            },
          },
        ],
        weekday_text: {
          Sunday: 'Open 24 Hours',
          Monday: 'Open 24 Hours',
          Tuesday: 'Open 24 Hours',
          Wednesday: 'Open 24 Hours',
          Thursday: 'Open 24 Hours',
          Friday: 'Open 24 Hours',
          Saturday: 'Open 24 Hours',
        },
      },
      home_delivery_provider: 'TRUEPILL',
    },
    {
      suggested_products: [
        {
          prices: {
            adjudilite: [
              {
                origin_id: 'adjudilite',
                product_id: 249,
                medication_id: 133,
                description: 'module_plan_modifiers',
                ndc: '13107003134',
                cost_per_refill: 6.9,
                cost_per_day: 0.076667,
                quantity: 90,
                day_supply: 90,
                base_price_source: 'module_pharmacy_discount_mac',
                attributes: {
                  phase: 1,
                  package_id: 2697,
                  is_pbm_max_cost: false,
                  validation_score: 1,
                  coverage_alerts: [],
                  is_blocker: false,
                  plan_pay: 0,
                  plan_pay_per_day: 0,
                  accumulator_type: null,
                  deductible: {
                    applied: null,
                    remaining: null,
                  },
                  out_of_pocket: {
                    applied: null,
                    remaining: null,
                  },
                },
              },
            ],
            coupon: [],
            manufacturer_copay: [],
          },
          name: {
            id: 122,
            trademarked: 0,
            name: 'mirtazapine (generic of Remeron, Remeron SolTab)',
          },
          id: 249,
          description: 'Mirtazapine 15mg Tablet',
          form: 'Tablet',
          strength: '15mg',
          dispensable_unit: 'Tablet(s)',
          image_url: 'https://images.rxsavingssolutions.com/DrugItem_6983.JPG',
          quantity_with_unit: '90 Tablets',
          normalized_dispensable_unit: {
            singular: 'Tablet',
            plural: 'Tablets',
          },
          form_plural: 'Tablets',
          form_string: null,
          product_name: 'mirtazapine (generic of Remeron, Remeron SolTab)',
          primary_name: 'Mirtazapine',
          secondary_name: '(Generic of Remeron)',
          strength_string: null,
          brand: 0,
          maintenance_med: 1,
          off_market: 0,
          brand_name: 'Remeron',
          medication_details: {
            side_effects:
              'Side effects that you should report to your doctor or health care professional as soon as possible:\n\n  * allergic reactions like skin rash, itching or hives, swelling of the face, lips, or tongue\n  * anxious\n  * changes in vision\n  * chest pain\n  * confusion\n  * elevated mood, decreased need for sleep, racing thoughts, impulsive behavior\n  * eye pain\n  * fast, irregular heartbeat\n  * feeling faint or lightheaded, falls\n  * feeling agitated, angry, or irritable\n  * fever or chills, sore throat\n  * hallucination, loss of contact with reality\n  * loss of balance or coordination\n  * mouth sores\n  * redness, blistering, peeling or loosening of the skin, including inside the mouth\n  * restlessness, pacing, inability to keep still\n  * seizures\n  * stiff muscles\n  * suicidal thoughts or other mood changes\n  * trouble passing urine or change in the amount of urine\n  * trouble sleeping\n  * unusual bleeding or bruising\n  * unusually weak or tired\n  * vomiting \nSide effects that usually do not require medical attention (report to your doctor or health care professional if they continue or are bothersome):\n\n  * change in appetite\n  * constipation\n  * dizziness\n  * dry mouth\n  * muscle aches or pains\n  * nausea\n  * tired\n  * weight gain',
            details: 'MIRTAZAPINE (mir TAZ a peen) is used to treat depression.',
            interactions:
              "Do not take this medicine with any of the following medications:\n* linezolid\n* MAOIs like Carbex, Eldepryl, Marplan, Nardil, and Parnate\n* methylene blue (injected into a vein)\n \nThis medicine may also interact with the following medications:\n* alcohol\n* antiviral medicines for HIV or AIDS\n* certain medicines that treat or prevent blood clots like warfarin\n* certain medicines for depression, anxiety, or psychotic disturbances\n* certain medicines for fungal infections like ketoconazole and itraconazole\n* certain medicines for migraine headache like almotriptan, eletriptan, frovatriptan, naratriptan, rizatriptan, sumatriptan, zolmitriptan\n* certain medicines for seizures like carbamazepine or phenytoin\n* certain medicines for sleep\n* cimetidine\n* erythromycin\n* fentanyl\n* lithium\n* medicines for blood pressure\n* nefazodone\n* rasagiline\n* rifampin\n* supplements like St. John's wort, kava kava, valerian\n* tramadol\n* tryptophan",
          },
        },
      ],
      prices: {
        adjudilite: {
          description: 'module_cash_pricing',
          cost_per_refill: 6.9,
          cost_per_day: 0.076667,
          quantity: 90,
          day_supply: 90,
          base_price_source: null,
          attributes: {
            is_pbm_max_cost: false,
            validation_score: null,
            coverage_alerts: [],
            is_blocker: false,
            plan_pay: 0,
            plan_pay_per_day: 0,
            accumulator_type: null,
            deductible: {
              applied: null,
              remaining: null,
            },
            out_of_pocket: {
              applied: null,
              remaining: null,
            },
          },
        },
      },
      id: 95451,
      ncpdp: '3846396',
      npi: '1588140321',
      name: 'RxSS, delivered by ScriptHero Pharmacy',
      pharmacy_type: 'retail',
      type: {
        id: 1,
        label: 'retail',
      },
      type_id: 1,
      address: {
        street: '3031 SE POWELL BLVD',
        city: 'PORTLAND',
        state: 'OR',
        zipcode: '97202',
        latitude: '45.49731000',
        longitude: '-122.63407000',
      },
      phone: '5032314756',
      extension: null,
      fax: '5039463075',
      email: 'ENROLLMENT_RETAIL@CVSHEALTH.COM',
      directions: null,
      open_24_hrs: 'Y',
      language: 'en||||',
      distance: null,
      opening_hours: {
        periods: [
          {
            open: {
              day: 0,
              time: '0000',
            },
            close: {
              day: 0,
              time: '0000',
            },
          },
          {
            open: {
              day: 1,
              time: '0000',
            },
            close: {
              day: 1,
              time: '0000',
            },
          },
          {
            open: {
              day: 2,
              time: '0000',
            },
            close: {
              day: 2,
              time: '0000',
            },
          },
          {
            open: {
              day: 3,
              time: '0000',
            },
            close: {
              day: 3,
              time: '0000',
            },
          },
          {
            open: {
              day: 4,
              time: '0000',
            },
            close: {
              day: 4,
              time: '0000',
            },
          },
          {
            open: {
              day: 5,
              time: '0000',
            },
            close: {
              day: 5,
              time: '0000',
            },
          },
          {
            open: {
              day: 6,
              time: '0000',
            },
            close: {
              day: 6,
              time: '0000',
            },
          },
        ],
        weekday_text: {
          Sunday: 'Open 24 Hours',
          Monday: 'Open 24 Hours',
          Tuesday: 'Open 24 Hours',
          Wednesday: 'Open 24 Hours',
          Thursday: 'Open 24 Hours',
          Friday: 'Open 24 Hours',
          Saturday: 'Open 24 Hours',
        },
      },
      home_delivery_provider: 'RxSS, delivered by ScriptHero Pharmacy',
    },
  ],
  coverage_alerts: [],
  express_display_info: {
    prescriber: 'KELLY WRIGHT MD',
    savings: 13.1,
    from: {
      simple_name: 'Bupropion Extended Release',
      price: 20,
      display_strength: '150mg',
      image_url: 'https://images.rxsavingssolutions.com/DrugItem_25570.JPG',
      compare_info: {
        primary_name: 'Bupropion Extended Release',
        supplemental_name: '(Generic of Wellbutrin XL)',
        side_effects:
          'Side effects that you should report to your doctor or health care professional as soon as possible:\n\n  * allergic reactions like skin rash, itching or hives, swelling of the face, lips, or tongue\n  * breathing problems\n  * changes in vision\n  * confusion\n  * elevated mood, decreased need for sleep, racing thoughts, impulsive behavior\n  * fast or irregular heartbeat\n  * hallucinations, loss of contact with reality\n  * increased blood pressure\n  * redness, blistering, peeling or loosening of the skin, including inside the mouth\n  * seizures\n  * suicidal thoughts or other mood changes\n  * unusually weak or tired\n  * vomiting \nSide effects that usually do not require medical attention (report to your doctor or health care professional if they continue or are bothersome):\n\n  * constipation\n  * headache\n  * loss of appetite\n  * nausea\n  * tremors\n  * weight loss',
        details: 'BUPROPION (byoo PROE pee on) is used to treat depression.',
        interactions:
          "Do not take this medicine with any of the following medications:\n\n  * linezolid\n  * MAOIs like Azilect, Carbex, Eldepryl, Marplan, Nardil, and Parnate\n  * methylene blue (injected into a vein)\n  * other medicines that contain bupropion like Zyban \nThis medicine may also interact with the following medications:\n\n  * alcohol\n  * certain medicines for anxiety or sleep\n  * certain medicines for blood pressure like metoprolol, propranolol\n  * certain medicines for depression or psychotic disturbances\n  * certain medicines for HIV or AIDS like efavirenz, lopinavir, nelfinavir, ritonavir\n  * certain medicines for irregular heart beat like propafenone, flecainide\n  * certain medicines for Parkinson's disease like amantadine, levodopa\n  * certain medicines for seizures like carbamazepine, phenytoin, phenobarbital\n  * cimetidine\n  * clopidogrel\n  * cyclophosphamide\n  * digoxin\n  * furazolidone\n  * isoniazid\n  * nicotine\n  * orphenadrine\n  * procarbazine\n  * steroid medicines like prednisone or cortisone\n  * stimulant medicines for attention disorders, weight loss, or to stay awake\n  * tamoxifen\n  * theophylline\n  * thiotepa\n  * ticlopidine\n  * tramadol\n  * warfarin",
      },
    },
    to: {
      simple_name: 'Mirtazapine',
      price: 6.9,
      display_strength: '15mg',
      image_url: 'https://images.rxsavingssolutions.com/DrugItem_6983.JPG',
      compare_info: {
        primary_name: 'Mirtazapine',
        supplemental_name: '(Generic of Remeron)',
        side_effects:
          'Side effects that you should report to your doctor or health care professional as soon as possible:\n\n  * allergic reactions like skin rash, itching or hives, swelling of the face, lips, or tongue\n  * anxious\n  * changes in vision\n  * chest pain\n  * confusion\n  * elevated mood, decreased need for sleep, racing thoughts, impulsive behavior\n  * eye pain\n  * fast, irregular heartbeat\n  * feeling faint or lightheaded, falls\n  * feeling agitated, angry, or irritable\n  * fever or chills, sore throat\n  * hallucination, loss of contact with reality\n  * loss of balance or coordination\n  * mouth sores\n  * redness, blistering, peeling or loosening of the skin, including inside the mouth\n  * restlessness, pacing, inability to keep still\n  * seizures\n  * stiff muscles\n  * suicidal thoughts or other mood changes\n  * trouble passing urine or change in the amount of urine\n  * trouble sleeping\n  * unusual bleeding or bruising\n  * unusually weak or tired\n  * vomiting \nSide effects that usually do not require medical attention (report to your doctor or health care professional if they continue or are bothersome):\n\n  * change in appetite\n  * constipation\n  * dizziness\n  * dry mouth\n  * muscle aches or pains\n  * nausea\n  * tired\n  * weight gain',
        details: 'MIRTAZAPINE (mir TAZ a peen) is used to treat depression.',
        interactions:
          "Do not take this medicine with any of the following medications:\n* linezolid\n* MAOIs like Carbex, Eldepryl, Marplan, Nardil, and Parnate\n* methylene blue (injected into a vein)\n \nThis medicine may also interact with the following medications:\n* alcohol\n* antiviral medicines for HIV or AIDS\n* certain medicines that treat or prevent blood clots like warfarin\n* certain medicines for depression, anxiety, or psychotic disturbances\n* certain medicines for fungal infections like ketoconazole and itraconazole\n* certain medicines for migraine headache like almotriptan, eletriptan, frovatriptan, naratriptan, rizatriptan, sumatriptan, zolmitriptan\n* certain medicines for seizures like carbamazepine or phenytoin\n* certain medicines for sleep\n* cimetidine\n* erythromycin\n* fentanyl\n* lithium\n* medicines for blood pressure\n* nefazodone\n* rasagiline\n* rifampin\n* supplements like St. John's wort, kava kava, valerian\n* tramadol\n* tryptophan",
      },
    },
  },
};
