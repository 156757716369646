export default {
  footer: {
    leftText: {
      body: `
        For assistance with a complete listing of pharmacies within your network or additional information on your 
        benefit coverage, please call the phone number on your Member ID card.
        <br />
        <br />
        Alternatively, if your ID card isn't handy you may call Rx Savings Solutions at 
        <a href='tel:{{number}}'>{{number}}</a> 
        (TTY: 
        <a href="tel:18008778973">1-800-877-8973</a>)
        (Monday-Friday, 7 a.m.-8 p.m CT), or 
        <a style='cursor: pointer; text-decoration: underline;'>
          <span data-modal-id="contactmodal" tabindex="0">send us a message</span>
        </a> 
        and we\'ll get back to you shortly. Please keep in mind additional validation may be required.`,
    },
  },
  'prices-section': {
    cta: {
      'coupon-note':
        'The cost applied to a GoodRx coupon does not go through your insurance and therefore won’t be applied towards your deductible or any out-of-pocket maximum. While GoodRx prices are usually accurate, there can be instances when the price may vary, however only by a few dollars. If there are any questions or concerns, please reach out to our Pharmacy Support team at 1-800-268-4476',
    },
  },
  'pharmacy-picker-body': {
    discountTitleText:
      'The cost applied to a GoodRx coupon does not go through your insurance and therefore won’t be applied towards your deductible or any out-of-pocket maximum. While GoodRx prices are usually accurate, there can be instances when the price may vary, however only by a few dollars. If there are any questions or concerns, please reach out to our Pharmacy Support team at 1-800-268-4476',
  },
};
