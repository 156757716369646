import React from 'react';
import { withRouter } from 'react-router-dom';

const LocationContext = React.createContext({});

export default function asyncComponent(getComponent) {
  class AsyncComponent extends React.Component {
    static Component = null;

    state = { Component: AsyncComponent.Component };

    componentWillMount() {
      if (!this.state.Component) {
        getComponent()
          .then((Component) => {
            AsyncComponent.Component = Component;
            this.setState({ Component });
          })
          .catch((e) => {
            // TODO PORTAL-4720 Monitor this Rollbar error to determine a suitable fix to the reload loop
            Rollbar.warn('Page Refresh triggered by error in AsyncComponent');
            Rollbar.error(e);
            window.location.reload();
          });
      }
    }

    render() {
      const { Component } = this.state;
      return Component ? <Component {...this.props} /> : null;
    }
  }

  return withRouter((props) => (
    <LocationContext.Provider value={props.location}>
      <AsyncComponent {...props} />
    </LocationContext.Provider>
  ));
}
