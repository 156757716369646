/* @flow */

import { observable, action } from 'mobx';

class MfCardModalStore {
  @observable show: boolean;

  @observable message: string;

  constructor() {
    this.show = false;
  }

  @action
  open() {
    this.show = true;
  }

  @action
  close() {
    this.show = false;
  }
}

const mfCardModalStore = new MfCardModalStore();

export default mfCardModalStore;
export { MfCardModalStore };
