/* @flow */

import { observable, action } from 'mobx';

class GlobalModalStore {
  @observable open: Boolean;

  @observable name: string;

  @observable props: Object;

  @observable size: string;

  constructor() {
    this.open = false;
    this.name = '';
    this.props = {};
    this.size = null;
  }

  @action
  make(name, props, size = 'large') {
    this.open = true;
    this.name = name;
    this.props = props;
    this.size = size !== 'medium' ? size : null;
  }

  @action
  kill() {
    this.open = false;
    this.name = '';
    this.props = {};
    this.size = '';
  }
}

const globalModalStore = new GlobalModalStore();

export default globalModalStore;
export { GlobalModalStore };
