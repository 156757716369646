export default {
  videos: {
    alerts: {
      title: 'title-alerts-bnc-needs-translated',
      description: 'description-alerts-bnc-needs-translated',
    },
    noAlerts: {
      title: 'title-no-alerts-bnc-needs-translated',
      description: 'description-no-alerts-bnc-needs-translated',
    },
  },
};
