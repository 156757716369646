export default {
  questions: {
    header: '',
    text: 'For assistance, our customer service is available to help 8 a.m. to 8 p.m., Monday through Friday. \
                The fastest way to obtain service is by using the phone number and member info on your Member ID card.',
    'additional-text':
      "Alternatively, if your ID card isn't handy you may also use our general line at {{phoneNumber}}. \
      Please keep in mind additional validation may be required.",
  },
  footer: {
    leftText: {
      body: `
          For assistance, our customer service is available to help 8 a.m. to 8 p.m., Monday through Friday. 
          The fastest way to obtain service is by using the phone number and member info on your Member ID card. <br />
          Alternatively, if your ID card isn't handy you may also use our general line at <a href="tel:{{number}}">{{number}}</a>. 
          Please keep in mind additional validation may be required.
        `,
    },
  },
  'family-signup': {
    verified:
      'Due to the age of this dependent, privacy laws prevent us from showing you their medications or savings alerts. To access this feature, your dependent needs to log in to their own account.',
    activate: 'Should we ask them to activate their account?',
    emailOnBehalf:
      'We can send them an email on your behalf. Just enter their email address below.',
    emailRequired: 'Email Address Required',
    ccMe: 'Please CC me on this email.',
    messageSent:
      'We’ve sent a message to {{firstName}} for you, and it should arrive any time now. This dependent can reply directly to you with any questions or contact Blue Cross and Blue Shield Member Services at {{phone}} or {{email}}.',
    email: "Dependent's Email Address",
    button: 'Send Email',
  },
};
