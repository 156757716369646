/* @flow */

import { observable, action } from 'mobx';
import setupI18nLibrary from '@assets/translations/setupI18nLibrary';

export type TranslateFunction = (translationKey: string, translationData?: {}) => string;

/**
 * Store responsible for providing utilities for translating various messages to different languages.
 */
class TranslationStore {
  @observable translation: { t: TranslateFunction };

  /**
   * Sets the client-specific translations to use.
   *
   * @param {String} clientLanguageId - The client language ID to use, i.e. "boe" or "shc".
   */
  @action.bound
  setTranslation(clientLanguageId: string) {
    this.translation = setupI18nLibrary({
      clientLanguageId,
    });
  }

  /**
   * Function for retrieving the translation for a specific key.
   *
   * @param {String} key - The key of the internationalized message to retrieve.
   * @param {Object} [data] - The object used to interpolate any data into the message.
   * @returns {String} - The internationalized message for the key provided.
   */
  translate: TranslateFunction = (key, data) => {
    return this.translation.t(key, data);
  };
}

export { TranslationStore };
