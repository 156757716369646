/* @flow */
import { computed, observable } from 'mobx';
import type {
  ComputedPriceProduct,
  BaseProduct,
  SuggestedNdcs,
} from '@features/savings/types/Product';
import { stringToTitleCase } from '@utils/Functions/titleCaseFunction/titleCaseFunction';
import type { BasePharmacy } from '@features/savings/types/Pharmacy';
import type { BaseClaimResponse } from '@features/savings/types/Claim';

class BaseClaim {
  @observable id: number;

  @observable product_id: number;

  @observable ncpdp: number;

  @observable day_supply: number;

  @observable quantity: number;

  @observable member_cost: number;

  @observable member_cost_per_day: number;

  @observable employer_cost: number;

  @observable employer_cost_per_day: number;

  @observable quantity_with_unit: string;

  @observable filled_at: string;

  @observable contact_prescriber: ?{
    id: number,
    member_id: number,
    medication_claim_id: number,
    suggestion_id: number,
    ncpdp: string,
    member_cost: number,
    employer_cost: number,
    completion_status: number,
    contact_type: string,
    contact_info: string,
    created_at: string,
    updated_at: string,
    deleted_at: ?string,
    suggested_ndcs: SuggestedNdcs,
    suggestion: {
      id: string,
      type: string,
      long_type: string,
      pharmacist_note: ?string,
      product_id: number,
    },
    pharmacy: BasePharmacy,
  };

  @observable prescriber: {
    first_name: ?string,
    last_name: ?string,
    prefix: ?string,
    credential: ?string,
  };

  @observable package: {||};

  @observable pharmacy: BasePharmacy;

  @observable product: BaseProduct;

  @observable member: {||};

  constructor({ json }: { json: BaseClaimResponse }) {
    this.id = json.id;
    this.updateFromJson(json);
  }

  updateFromJson(json: BaseClaimResponse) {
    // Assign all json keys to 'this' properties
    Object.assign(this, json);
  }

  @computed
  get doctorFullName(): string {
    const { first_name, last_name, prefix, credential } = this.prescriber;

    const doctorName = [
      stringToTitleCase(prefix),
      stringToTitleCase(first_name),
      stringToTitleCase(last_name),
    ]
      .filter(Boolean)
      .join(' ');

    return credential ? `${doctorName}, ${credential}` : doctorName;
  }

  /**
   * Returns the product info of the members current product
   *
   * @type {Object}
   */
  @computed
  get currentProduct(): ComputedPriceProduct {
    const {
      quantity,
      day_supply,
      member_cost,
      member_cost_per_day,
      product: { primary_name, secondary_name, form, strength },
    } = this;

    return {
      quantity,
      day_supply,
      cost_per_refill: member_cost,
      cost_per_day: member_cost_per_day,
      primary_name,
      secondary_name,
      form,
      strength,
    };
  }
}

export default BaseClaim;
