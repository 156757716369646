import { Factory, trait } from 'miragejs';
import { suggestionStatic } from './suggestionStatic';

/**
 * Recursively sets all `is_pbm_max_cost` keys to true
 */
function setPbmMaxCost(o) {
  Object.keys(o).forEach((k) => {
    const v = o[k];
    if (Array.isArray(v) || v instanceof Object) setPbmMaxCost(v);
    if (k === 'is_pbm_max_cost') {
      o[k] = true;
    }
  });
}

/**
 * Creates an individual suggestion with prices, suggested_product, and pharmacies.
 *
 * - `withPbmMaxCost` trait to make all prices PbmMaxCost
 */
export const suggestionFactory = Factory.extend({
  ...suggestionStatic,
  withPbmMaxCost: trait({
    afterCreate(suggestion) {
      setPbmMaxCost(suggestion.attrs);
    },
  }),
});
