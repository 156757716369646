/**
 * Adds the best savings tag to the "tags" array of a single pharmacy, based on various criteria and tiebreakers.
 *
 * @param {Object} arg1 - An object with the following properties:
 * @param {Object[]} arg1.pharmacies - A list of pharmacy objects.
 * @param {String} arg1.pricingMethod - The method of pricing, such as "adjudilite", "coupon", "manufacturer_copay", etc.
 * @param {String} arg1.memberSetPrimaryPharmacyNcpdp - The NCPDP of the member's primary pharmacy.
 * @param {Number} arg1.planPreferredPharmacyId - The Id of the plan's preferred pharmacy.
 * @returns {Object[]} - The original list of pharmacies. The original entries were mutated as part of this function
 *    invocation; the array is just returned for chaining convenience.
 */
function addBestSavingsTagToPharmacy({
  pharmacies,
  pricingMethod,
  memberSetPrimaryPharmacyNcpdp,
  planPreferredPharmacyId,
}) {
  let lowestPrice = Number.MAX_SAFE_INTEGER;

  pharmacies.forEach((pharmacy) => {
    const pharmacyPrice = pharmacy.prices[pricingMethod].cost_per_day;

    if (pharmacyPrice !== null && pharmacyPrice < lowestPrice) {
      lowestPrice = pharmacyPrice;
    }
  });

  const lowestPricePharmacies = pharmacies.filter(
    (pharmacy) => pharmacy.prices[pricingMethod].cost_per_day === lowestPrice,
  );

  let bestPricePharmacy = null;

  // If there is only one winner for best price, just use that one
  if (lowestPricePharmacies.length === 1) {
    // eslint-disable-next-line prefer-destructuring
    bestPricePharmacy = lowestPricePharmacies[0];
  }
  // If there are tie breakers for best price
  else {
    const primaryPharmacy = lowestPricePharmacies.find(
      (pharmacy) => pharmacy.ncpdp === memberSetPrimaryPharmacyNcpdp,
    );

    if (primaryPharmacy) {
      bestPricePharmacy = primaryPharmacy;
    } else {
      const planPreferredPharmacy = lowestPricePharmacies.find(
        (pharmacy) => pharmacy.id === planPreferredPharmacyId,
      );

      if (planPreferredPharmacy) {
        bestPricePharmacy = planPreferredPharmacy;
      } else {
        // eslint-disable-next-line prefer-destructuring
        bestPricePharmacy = lowestPricePharmacies[0];
      }
    }
  }

  if (bestPricePharmacy) {
    bestPricePharmacy.tags = [
      ...bestPricePharmacy.tags,
      {
        type: 'best-savings',
        text: 'best-savings',
      },
    ];
  }

  return pharmacies;
}

export default addBestSavingsTagToPharmacy;
