export default {
  id: 'daySupply',
  label: 'Day Supply',
  type: 'select',
  values: [
    {
      label: '30',
      badge: '',
      text: '',
    },
    {
      label: '60',
      badge: '',
      text: '',
    },
    {
      label: '90',
      badge: '',
      text: '',
    },
  ],
};
