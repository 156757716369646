export default {
  footer: {
    whitelabel: `
    Rx Savings Solutions (RxSS) is an independent company that contracts directly with Prime to provide Rx consultations. RxSS does not offer other health plan products or services and is solely responsible for the products and services that it provides. There are no endorsements, representations or warranties made regarding third-party vendors. Members should contact the vendor directly with questions about the products or services offered by third parties.
    <br />
    <br />
    Prime Therapeutics LLC is a separate pharmacy benefit management company contracted by Blue Cross and Blue Shield of Oklahoma (BCBSOK) to provide pharmacy benefit management and related other services. BCBSOK, as well as several independent Blue Cross and Blue Shield Plans, has an ownership interest in Prime Therapeutics.
    <br />
    <br />
    BCBSOK makes no endorsement, representations or warranties regarding third-party vendors and the products and services offered by them.
    <br />
    <br />
    Blue Cross and Blue Shield of Oklahoma, a Division of Health Care Service Corporation, a Mutual Legal Reserve Company, an Independent Licensee of the Blue Cross and Blue Shield Association
    `,
  },
  'prices-section': {
    'earn-rewards': 'Earn a ${{amount}} cash reward with Member Rewards!',
    'rewardable-header': 'This option qualifies for the ${{amount}} Member Rewards cash reward.',
  },
  'savings-alert': {
    unsavingsBodyHeader: 'Earn a ${{amount}} cash reward with Member Rewards!',
    childUnsavingsBodyHeader:
      '<span data-private>{{name}}</span> can earn a ${{amount}} cash reward with Member Rewards!',
    unsavingsBodyFooter:
      'Receive a ${{amount}} cash reward by check when you switch to a lower-cost alternative (first refill only). The Member Rewards program is part of your health plan benefits and administered by Sapphire Digital.',
    childUnsavingsBodyFooter:
      'For switching to a lower-cost alternative (first refill only), <span data-private>{{name}}</span> can receive a ${{amount}} cash reward by check. The Member Rewards program is part of your health plan benefits and administered by Sapphire Digital.',
    unsavingsIconAltText: 'rewards check icon',
  },
  'rx-rewards-ppp': {
    and: '',
    comma: '',
    period: '',
    header:
      '<strong>Yes, please send me a ${{amount}} reward for switching to the new, reward-eligible prescription!</strong>',
    terms:
      'By selecting "{{button}}," I agree to participate in the Member Rewards* program offered through my health plan benefits and understand I will receive a ${{amount}} cash reward by check mailed directly to my home from program administrator Sapphire Digital after the claim is paid and verified.',
    'agreement-link': '',
    'tos-link': '',
    'privacy-link': '',
    disclaimer:
      '*Cash reward amounts received through Member Rewards may be taxable. Reward-eligible options and reward amounts are subject to change.',
  },
  'family-permission': {
    'contact-prescriber': {
      'how-reward': '<strong>How to Switch to Receive a ${{amount}} Cash Reward</strong>',
      toCompleteReward:
        'To complete this switch to the new, reward-eligible prescription and receive a ${{amount}} cash reward, ask <span data-private>{{name}}</span> to sign in to our web portal.',
    },
  },
};
