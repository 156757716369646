/* @flow */
import { SessionManager } from '@utils/session-manager';

/**
 * @description A helper class that holds all the general error status code handlers and the required
 * configuration info needed to deal with said errors
 * @tutorial https://rxsavings.atlassian.net/wiki/spaces/MP/pages/1763639820/Interceptors
 */

export default class Interceptors {
  constructor(axios) {
    this.axios = axios;
    this.HEARTBEAT_ENDPOINT = '/v2/config';
    this.HEARTBEAT_TIMEOUT = 30;
    this.VALIDATION_ENDPOINT = '/v2/config';
  }

  /**
   * @description Handles a 401 unauthorized error by (if currentOptions.checkAuthorization is set
   * to  true) making a verification call to double check that the member is logged out and either
   * reloading the page if they are still logged in, or kicking them to the login page.
   * @param {Error} error
   * @param {Object} currentOptions
   * @returns {Promise}
   */
  401(error: Error, currentOptions: Object) {
    if (currentOptions.checkAuthorization) {
      // make test call to config
      return (
        this.axios
          .get(this.VALIDATION_ENDPOINT)
          // on success, reload page
          .then(() => {
            window.location.reload();
            return Promise.reject(error);
          })
          // on failure logout
          .catch(() => {
            const sessionManager = new SessionManager(sessionStorage);
            sessionManager.clear();
            return this.axios.get(window.logout_url).finally((resp) => {
              window.location.replace(
                window.api_url + '?session=expired' || global.api_url + '?session=expired',
              );
              throw error;
            });
          })
      );
    }
    return Promise.reject(error);
  }

  /**
   * @description This handles the 503 response code, by setting the page to the maintenance mode
   * html and setting up a heartbeat call. If a successful call is made the page will reload, and if
   * a 401 is returned the user is kicked to the login page.
   * @param {Error} error
   * @returns {Promise}
   */
  503(error: Error) {
    const domRoot = document.querySelector('html');
    return new Promise((resolve, reject) => {
      const interval = window.setInterval(() => {
        this.axios
          .get(this.HEARTBEAT_ENDPOINT)
          .then((response) => {
            window.clearInterval(interval);
            window.setTimeout(() => {
              window.location.reload();
              reject(error);
            }, this.HEARTBEAT_TIMEOUT * 100);
          })
          .catch((error2) => {
            if (error2.response && error2.response.status === 401) {
              window.location.replace(window.logout_url || global.logout_url);
              reject(error);
            }
          });
      }, this.HEARTBEAT_TIMEOUT * 1000);
      if (domRoot && domRoot.innerHTML && error.response.data) {
        domRoot.innerHTML = error.response.data;
      }
    });
  }

  /**
   * @description This handles a 504 (timeout) error by logging it to both the console, as well as
   * filing the error with Rollbar.
   * @param {Error} error
   * @param {Object} currentOptions
   * @returns {Promise}
   */
  504(error: Error, currentOptions: Object) {
    const errorMessage = `504 from call to: ${currentOptions.requestURL}`;
    console.error(errorMessage);
    Rollbar.error(errorMessage);
    return Promise.reject(error);
  }
}
