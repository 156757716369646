/* @flow */

import { observable, action } from 'mobx';
import axios from '@utils/axios/axios';

import emailSmsNormalizer from '../../utils/email-sms-normalizer';

class TextModalStore {
  @observable show: boolean;

  @observable value: string = '';

  @observable success: boolean = false;

  @observable vouchers: Array<Object>;

  @observable alert: string = 'phone number';

  @observable error: boolean = false;

  @observable errorMessage: string = '';

  constructor() {
    this.show = false;
    this.success = false;
    this.error = false;
  }

  @action
  open() {
    this.show = true;
  }

  @action
  close() {
    this.show = false;
    this.success = false;
    this.error = false;
  }

  @action
  update(id: string, value: string) {
    this.value = value;
  }

  @action
  send(data: Array<Object>) {
    if (!this.validatePhoneNumber(this.value)) {
      this.error = true;
      this.success = false;
      this.errorMessage = 'Please enter a valid phone number.';
      return;
    }

    const normalizedData = data.map((item) => {
      return emailSmsNormalizer(item, this.value, 'text');
    });

    return Promise.all(
      normalizedData.map((data) => {
        return axios.setCheckAuthorization(true).post('/smsVoucher/', data);
      }),
    )
      .then(() => {
        this.success = true;
        this.error = false;
        this.errorMessage = 'null';
        this.value = '';
      })
      .catch((error) => {
        this.errorMessage =
          error.response && error.response.data && error.response.data.error
            ? error.response.data.error.message
            : `There was a problem sending your savings voucher to the above ${this.alert}. If this problem persists please give us a call at 1-800-268-4476.`;
        this.error = true;
        this.success = false;
      });
  }

  validatePhoneNumber(number: string): boolean {
    const re = /^(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$/;

    return re.test(number);
  }
}

const textModalStore = new TextModalStore();

export default textModalStore;
export { TextModalStore };
