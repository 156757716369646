function allSettled(promises) {
  return Promise.all(
    promises.map((prom) => {
      return Promise.resolve(prom).then(
        (val) => ({ status: 'fulfilled', value: val }),
        (err) => ({ status: 'rejected', reason: err }),
      );
    }),
  );
}

export default allSettled;
