/* @flow */

/**
 * Enum representing each possible Rx Home Delivery status.
 * @enum {string}
 */
const RxHomeDeliveryStatuses = Object.freeze({
  /**
   * Enum value to provide when the application is still determining the status of Rx Home Delivery.
   */
  LOADING: 'LOADING',

  /**
   * Enum value to provide when the product does not qualify for Rx Home Delivery and nothing should be shown.
   */
  DOES_NOT_QUALIFY: 'DOES_NOT_QUALIFY',

  /**
   * Enum value to provide when the product qualifies for Rx Home Delivery and the "Switch to Rx Home Delivery" button
   * should be shown.
   */
  QUALIFIES: 'QUALIFIES',

  /**
   * Enum value to provide when the product already has an Rx Home Delivery request switch submitted.
   */
  SWITCH_REQUEST_SUBMITTED: 'SWITCH_REQUEST_SUBMITTED',

  /**
   * Enum value to provide when the product has an Rx Home Delivery request completed for the member.
   */
  SWITCH_COMPLETED: 'SWITCH_COMPLETED',

  /**
   * Enum value to provide when the product has an Rx Home Delivery request completed with a failure.
   */
  ERROR: 'ERROR',
});

/**
 * Frontend Rx Home Delivery status types
 */
export type RxHomeDeliveryStatusesType = $Values<typeof RxHomeDeliveryStatuses>;

export default RxHomeDeliveryStatuses;
