/**
 * Adds the 3 month supply tag to the "tags" array of a single pharmacy, based on whether there is a home delivery pharmacy
 * in the list of pharmacies that has a day supply less than 90.
 *
 * @param {Object[]} pharmacies - A list of pharmacy objects
 * @param {string} pricingMethod - The method of pricing, such as "adjudilite", "coupon", "manufacturer_copay", etc.
 * @returns {Object[]} - The original list of pharmacies with potentially modified tags
 */
import { addTag } from '../modifyPharmacyTags/modifyPharmacyTags';

function addThreeMonthSupplyTagToPharmacy(pharmacies, pricingMethod) {
  const isRxHomeDeliveryPharmacy = (pharmacy) => !!pharmacy.home_delivery_provider;
  const isNinetyDaySupply = (pharmacy) => pharmacy.prices[pricingMethod].day_supply === 90;
  const threeMonthSupplyTag = { type: 'three-month-supply', text: 'three-month-supply' };

  const nonNinetyDayRxHomeDeliveryPharmacy = pharmacies.find(
    (pharmacy) => isRxHomeDeliveryPharmacy(pharmacy) && !isNinetyDaySupply(pharmacy),
  );

  if (nonNinetyDayRxHomeDeliveryPharmacy) {
    pharmacies
      .filter((pharmacy) => !isRxHomeDeliveryPharmacy(pharmacy))
      .filter(isNinetyDaySupply)
      .forEach((pharmacy) => addTag(pharmacy, threeMonthSupplyTag));
  }

  return pharmacies;
}

export default addThreeMonthSupplyTagToPharmacy;
