/* @flow */

import axios from '@utils/axios/axios';
import AlertResponse from '@data/models/AlertResponse/AlertResponse';

const alertApi = {
  /**
   * Updates the api to flag the pharmacy of the passed ncpdp as ignored and sends a Rollbar error if the call fails
   *
   * @param {Object} member
   * @param {string} ncpdp
   * @returns Promise
   */
  ignorePharmacy(member: Object, ncpdp: string): Promise<void> {
    return axios
      .post(`/v2/${member.id}/primary-pharmacy/ignore`, {
        ncpdp,
      })
      .catch((err) => {
        window.Rollbar.warn(err);
        return err;
      });
  },

  /**
   * This loads the alerts and the claims (for determining if the user has any claims) from the API
   * and converts them into a combined promise that returns an AlertResponse object, and then returns
   * that promise in an array with a cancel function.
   *
   * @param {Object} member
   * @param {string} endpoint
   * @param {Object} childStores
   * @returns {any[]}
   */
  loadMember(member: Object, endpoint: string, childStores: Object): Array<Promise, Function> {
    const [alertPromise, alertCancel] = axios
      .setIsCancellable(true)
      .setIsVerbose(false)
      .get(`/v2/${member.id}/${endpoint}`);

    const [claimPromise, claimCancel] = axios
      .setIsCancellable(true)
      .setIsVerbose(false)
      .get(`/v2/${member.id}/claims`);

    const cancel = () => {
      alertCancel();
      claimCancel();
    };

    const combinedPromise = Promise.all([alertPromise, claimPromise]).then(
      ([{ alerts, contact_prescriber_enabled: isContactPrescriberEnabled }, { claims }]) => {
        return new AlertResponse({
          response: {
            alerts,
            isContactPrescriberEnabled,
            claims,
          },
          member,
          childStores,
          api: this,
        });
      },
    );

    return [combinedPromise, cancel];
  },

  /**
   * This loads a single alert corresponding to the id passed into it and stores it in an AlertResponse.
   * Since it is only a single alert, there is no caching of the promise, as a navigation to the
   * dashboard should still load in the entirety of the members alerts.
   *
   * @param { string } alert_id
   * @param { Object } member
   * @param { Object } childStores
   * @param { boolean } isContactPrescriberEnabled
   * @returns Promise
   */
  getSingleAlert(
    alert_id: string,
    member: Object,
    childStores: Object,
    isContactPrescriberEnabled: boolean,
  ) {
    return axios
      .setIsVerbose(false)
      .get(`/v2/${member.id}/alerts?id=${alert_id}`)
      .then((alert) => {
        return new AlertResponse({
          response: {
            alerts: [alert],
            isContactPrescriberEnabled,
            claims: [],
          },
          member,
          childStores,
          api: this,
        });
      });
  },

  /**
   * Updates the api to flag the primary pharmacy as confirmed and sends a Rollbar error if the call fails
   *
   * @param {Object} member
   * @returns Promise
   */
  confirmPharmacy(member): Promise {
    return axios.put(`/v2/${member.id}/primary-pharmacy/confirm`).catch((err) => {
      window.Rollbar.warn(err);
      return err;
    });
  },

  /**
   * This flags the message with the passed id as having been seen by the user and persists it in
   * the backend.
   *
   * @param {Object} member
   * @param {string} messageId
   * @returns Promise<void>
   */
  markMessageRead(member: Object, messageId: string): Promise<void> {
    return axios.put(`/v2/${member.id}/message/${messageId}`, { viewed: true });
  },

  /**
   * This flags the alert as having been filled and persists that into the backend.
   *
   * @param {Object} member
   * @param {string} alertId
   * @returns Promise<void>
   */
  markRefillFilled(member: Object, alertId: string): Promise<void> {
    return axios.put(`/v2/${member.id}/alerts/${alertId}/refilled`);
  },

  /**
   * This flags the alert as viewed.
   *
   * @param {string} alertId
   * @returns Promise<void>
   */
  markAlertViewed(alertId: string): Promise<void> {
    return axios.put('/members/alerts?id=' + alertId);
  },
};

export default alertApi;
