import BaseAlert from '../BaseAlert/BaseAlert';
import AlertTypes from '../AlertTypes';

class RewardableAlert extends BaseAlert {
  static REWARD_AMOUNT = 100;

  constructor(info) {
    super(info);
    this.modelName = AlertTypes.REWARDABLE;
    this.icon = 'rewardIcon';
    this.alertType = 'clientSavings';
    this._buttonAreaText = '';
    this._mobileButtonText = '';
    this.buttonText = this.translate('savings-alert.unsavingsButton');
    this.data = {
      name: info.memberStore.activeMember.first_name,
      amount: RewardableAlert.REWARD_AMOUNT,
    };
  }

  get buttonAreaText() {
    return this._buttonAreaText;
  }

  get mobileButtonText() {
    return this._mobileButtonText;
  }

  get headerText() {
    return this.translate(
      `savings-alert.${this.isMajorDependent ? 'childUnsavings' : 'unsavings'}BodyHeader`,
      this.data,
    );
  }

  get footerText() {
    return this.translate(
      `savings-alert.${this.isMajorDependent ? 'childUnsavings' : 'unsavings'}BodyFooter`,
      this.data,
    );
  }
}

export default RewardableAlert;
