export default {
  SMSOptIn: {
    disclaimer: `
         By clicking Sign Up, I agree to share my mobile phone number with Rx Savings Solutions and Blue Cross and Blue Shield of Louisiana, from whom I agree to receive information via text and pre-recorded or real-time calls about my account and general marketing messages. Opt-in is voluntary and not a condition of my coverage.
         <br />
         <br />
         Up to 4 messages per member per month. Message and data rates may apply. Text STOP to cancel, text HELP for assistance.
         `,
  },
  footer: {
    leftText: {
      body: `<span class='disclaimer-text'>To reach the Blue Cross Rx Savings Solutions toll-free help line, call {{number}}, 
            <span style="white-space: nowrap;">8 a.m. to 5 p.m.</span>, Monday through Friday, except office holidays. 
            <br /><br />Rx Savings Solutions is an independent company that provides retail prescription drug cost information to many Blue Cross and Blue Shield of Louisiana and HMO Louisiana, Inc. members.</span>`,
    },
    whitelabel: `
        Blue Cross and Blue Shield of Louisiana is an independent licensee of the Blue Cross and Blue Shield Association and incorporated as Louisiana Health Service & Indemnity Company. 
        Copyright © {{copyrightyear}} Blue Cross and Blue Shield of Louisiana. Blue Cross and Blue Shield of Louisiana is licensed to sell products only in the state of Louisiana. 
        <br />
        <br />
        Blue Cross and Blue Shield of Louisiana and its subsidiaries, HMO Louisiana, Inc. and Southern National Life Insurance Company, Inc., 
        comply with applicable federal civil rights laws and do not exclude people or treat them differently on the basis of race, color, national origin, 
        age, disability or sex. <a href='https://www.bcbsla.com/footer/nondiscrimination-notice' target='_blank'>More information.</a>
        <br />
        <br />
        For language assistance: 
        <a href='https://www.bcbsla.com/footer/language-assistance-notice' target='_blank'>English</a> | 
        <a href='https://www.bcbsla.com/footer/language-assistance-notice' target='_blank'>Español</a> | 
        <a href='https://www.bcbsla.com/footer/language-assistance-notice' target='_blank'>Français</a> | 
        <a href='https://www.bcbsla.com/footer/language-assistance-notice' target='_blank'>Tiếng Việt</a> | 
        <a href='https://www.bcbsla.com/footer/language-assistance-notice' target='_blank'>中文</a> | 
        <a href='https://www.bcbsla.com/footer/language-assistance-notice' target='_blank'>العربية</a> | 
        <a href='https://www.bcbsla.com/footer/language-assistance-notice' target='_blank'>Pilipino</a> | 
        <a href='https://www.bcbsla.com/footer/language-assistance-notice' target='_blank'>한국어</a> | 
        <a href='https://www.bcbsla.com/footer/language-assistance-notice' target='_blank'>Português</a> | 
        <a href='https://www.bcbsla.com/footer/language-assistance-notice' target='_blank'>ລາວ</a> |
        <a href='https://www.bcbsla.com/footer/language-assistance-notice' target='_blank'>日本語</a> |
        <a href='https://www.bcbsla.com/footer/language-assistance-notice' target='_blank'>اردو</a> | 
        <a href='https://www.bcbsla.com/footer/language-assistance-notice' target='_blank'>Deutsche</a> | 
        <a href='https://www.bcbsla.com/footer/language-assistance-notice' target='_blank'>فارسی</a> | 
        <a href='https://www.bcbsla.com/footer/language-assistance-notice' target='_blank'>русский</a> | 
        <a href='https://www.bcbsla.com/footer/language-assistance-notice' target='_blank'>ไทย</a>
      `,
  },
  'family-signup': {
    verified:
      'Due to the age of this dependent, privacy laws prevent us from showing you their medications or savings alerts. To access this feature, your dependent needs to log in to their own account.',
    activate: 'Should we ask them to activate their account?',
    emailOnBehalf:
      'We can send them an email on your behalf. Just enter their email address below.',
    emailRequired: 'Email Address Required',
    ccMe: 'Please CC me on this email.',
    messageSent:
      'We’ve sent a message to {{firstName}} for you, and it should arrive any time now. This dependent can reply directly to you with any questions or contact Member Services at {{phone}} or {{email}}.',
    email: "Dependent's Email Address",
    button: 'Send Email',
  },
  'suggestion-item': {
    saveEmployer:
      'Total Savings <span class=“suggestion-detail-text--price”>{{price}}</span> <span style=“white-space:nowrap;“>per fill</span>',
  },
  claims: {
    'plan-paid': 'Your Plan Last Paid',
  },
};
