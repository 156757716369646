/* @flow */

import { computed } from 'mobx';
import { TranslationFactory } from '@features/savings/stores/TranslationFactory/TranslationFactory';
import { SavingsDomainStore } from '@features/savings/stores/SavingsDomainStore/SavingsDomainStore';
import { TranslationStore } from '@stores_old/translationStore/translationStore';
import { formatMoney } from '@utils/Functions';
import themeStore from '@stores_old/themeStore/themeStore';
import type { CompareMedicationData } from '@features/savings/types/CompareMedicationData';

export class SavingsUiStore {
  savingsDomainStore: SavingsDomainStore;

  translationStore: TranslationStore;

  translationFactory: TranslationFactory;

  constructor({
    savingsDomainStore,
    translationStore,
  }: {
    savingsDomainStore: SavingsDomainStore,
    translationStore: TranslationStore,
  }) {
    this.savingsDomainStore = savingsDomainStore;
    this.translationStore = translationStore;
    this.translationFactory = new TranslationFactory({
      savingsDomainStore,
      memberStore: savingsDomainStore.memberStore,
      translationStore,
    });
  }

  onContactPrescriberError = (args) => {
    Rollbar.log('error', args);
  };

  @computed
  get suggestionPage() {
    const { translate } = this.translationStore;

    const header = translate('universalSavings.suggestionPage.header');
    const disclaimer = translate('universalSavings.suggestionPage.disclaimer');

    let intro;
    switch (this.savingsDomainStore.selectedSuggestion.type) {
      case 'TA':
      case 'TS':
      case 'DF':
        intro = this.translationFactory
          .newTranslation('universalSavings.suggestionPage.intro', {
            savings: formatMoney(
              this.savingsDomainStore.selectedSuggestion.express_display_info.savings,
            ),
          })
          .addSuggestionType()
          .addMemberKey()
          .addDoctorNameKey()
          .getTranslation();
        break;
      case 'GS':
      case 'GC':
        intro = this.translationFactory
          .newTranslation('universalSavings.suggestionPage.intro', {
            savings: formatMoney(
              this.savingsDomainStore.selectedSuggestion.express_display_info.savings,
            ),
          })
          .addSuggestionType()
          .addMemberKey()
          .getTranslation();
        break;
    }

    const cardCurrentTag = translate('universalSavings.suggestionPage.tags.current');

    const cardHighestTag = translate('universalSavings.suggestionPage.tags.highest');

    const helpMeSwitchBtnLabel = translate('universalSavings.suggestionPage.linkCopy.helpMeSwitch');

    const compareMedicationsBtnLabel = translate(
      'universalSavings.suggestionPage.linkCopy.compareMedication',
    );

    const seeAllSavingsBtnLabel = translate(
      'universalSavings.suggestionPage.linkCopy.seeAllSavings',
    );

    const quantityLabel = translate('drugDetails.quantityAbbreviation');

    const daySupplyLabel = translate('drugDetails.daySupplyLabel', {
      daySupply:
        this.savingsDomainStore.selectedSuggestion.best_prices[
          this.savingsDomainStore.selectedPricing
        ].day_supply,
    });

    return {
      translations: {
        header,
        intro,
        disclaimer,
        cardCurrentTag,
        cardHighestTag,
        helpMeSwitchBtnLabel,
        compareMedicationsBtnLabel,
        seeAllSavingsBtnLabel,
        quantityLabel,
        daySupplyLabel,
      },
    };
  }

  @computed
  get fulfillmentPage() {
    const { translate } = this.translationStore;

    const header = translate('universalSavings.fulfillmentPage.header.default');
    const disclaimer = translate('universalSavings.fulfillmentPage.disclaimer');

    let intro;
    switch (this.savingsDomainStore.selectedSuggestion.type) {
      case 'TA':
      case 'TS':
      case 'DF':
        intro = this.translationFactory
          .newTranslation('universalSavings.fulfillmentPage.intro.drugChange')
          .addMemberKey()
          .addDoctorNameKey()
          .getTranslation();
        break;
      case 'GS':
      case 'GC':
        intro = translate('universalSavings.fulfillmentPage.intro.genericChange');
        break;
    }

    const quantityLabel = translate('drugDetails.quantityAbbreviation', {
      quantity:
        this.savingsDomainStore.selectedSuggestion.best_prices[
          this.savingsDomainStore.selectedPricing
        ].quantity,
    });

    const confirmBtnLabel = this.translationFactory
      .newTranslation('medicine-cabinet.condition-management.confirm')
      .getTranslation();

    const daySupplyLabel = translate('drugDetails.daySupplyLabel', {
      daySupply:
        this.savingsDomainStore.selectedSuggestion.best_prices[
          this.savingsDomainStore.selectedPricing
        ].day_supply,
    });

    const perDayLabel = translate('drugDetails.costPerDayLabel', {
      costPerDay: formatMoney(
        this.savingsDomainStore.selectedSuggestion.best_prices[
          this.savingsDomainStore.selectedPricing
        ].cost_per_day,
      ),
    });

    const goToHomeDeliveryCheckoutPage = (location, history) => {
      const {
        selectedMember,
        selectedAlert,
        selectedClaim,
        selectedSuggestion,
        selectedPharmacy,
        selectedPricing,
        pricingKey,
      } = this.savingsDomainStore;

      // Construct query params HD needs to render the checkout page
      const query = new URLSearchParams({
        member: selectedMember.id,
        claimId: selectedClaim.id,
        alert_id: selectedAlert.id,
        suggestion: selectedSuggestion.id,
        pricing: selectedPricing,
        daySupply: selectedPharmacy?.prices[pricingKey]?.day_supply,
        ncpdp: selectedPharmacy.ncpdp,
        pharmacyId: selectedPharmacy.id,
      }).toString();

      history.push({
        pathname: '/home-delivery-request',
        search: query,
      });
    };

    const goToContactInfoPage = (location, history) => {
      const params = new URLSearchParams(location.search);
      params.set('pharmacyId', this.savingsDomainStore.selectedPharmacy.id.toString());
      history.push({ pathname: '/savings/contact-info', search: params.toString() });
    };

    const onConfirmClick = (location, history) => {
      if (
        this.savingsDomainStore.isNewHomeDeliveryEnabled &&
        !!this.savingsDomainStore.selectedPharmacy.home_delivery_provider
      ) {
        return () => goToHomeDeliveryCheckoutPage(location, history);
      } else {
        return () => goToContactInfoPage(location, history);
      }
    };

    return {
      translations: {
        header,
        intro,
        disclaimer,
        confirmBtnLabel,
        quantityLabel,
        daySupplyLabel,
        perDayLabel,
      },
      onConfirmClick,
    };
  }

  @computed
  get compareMedicationsPage() {
    const { translate } = this.translationStore;

    const header = translate('universalSavings.comparePage.header');
    const disclaimer = translate('universalSavings.comparePage.disclaimer');

    let intro;
    switch (this.savingsDomainStore.selectedSuggestion.type) {
      case 'TA':
      case 'TS':
      case 'DF':
        intro = this.translationFactory
          .newTranslation('universalSavings.comparePage.intro', {
            savings: formatMoney(
              this.savingsDomainStore.selectedSuggestion.express_display_info.savings,
            ),
          })
          .addSuggestionType()
          .addMemberKey()
          .addDoctorNameKey()
          .getTranslation();
        break;
      case 'GS':
      case 'GC':
        intro = this.translationFactory
          .newTranslation('universalSavings.comparePage.intro', {
            savings: formatMoney(
              this.savingsDomainStore.selectedSuggestion.express_display_info.savings,
            ),
          })
          .addSuggestionType()
          .addMemberKey()
          .getTranslation();
        break;
    }

    const helpMeSwitchBtnLabel = translate('universalSavings.linkCopy.helpMeSwitch');
    const seeAllSavingsBtnLabel = translate('universalSavings.linkCopy.seeAllSavings');
    const seeAllOptionsBtnLabel = translate('universalSavings.linkCopy.seeAllOptions');
    const cardCurrentTag = translate('universalSavings.comparePage.tags.current');
    const cardHighestTag = translate('universalSavings.comparePage.tags.highest');
    const compareMedicationsBtnLabel = translate(
      'universalSavings.comparePage.linkCopy.compareMedication',
    );
    const quantityLabel = translate('drugDetails.quantityAbbreviation');
    const daySupplyLabel = translate('drugDetails.daySupplyLabel', {
      daySupply:
        this.savingsDomainStore.selectedSuggestion.best_prices[
          this.savingsDomainStore.selectedPricing
        ].day_supply,
    });

    return {
      translations: {
        header,
        intro,
        disclaimer,
        cardCurrentTag,
        cardHighestTag,
        helpMeSwitchBtnLabel,
        seeAllSavingsBtnLabel,
        seeAllOptionsBtnLabel,
        quantityLabel,
        daySupplyLabel,
      },
    };
  }

  @computed
  get pharmacistNote() {
    const avatarDrGreen = themeStore.imageTokens.jeff;
    const avatarDrStone = themeStore.imageTokens.stone;
    const { translate } = this.translationStore;
    const { type, selectedProduct } = this.savingsDomainStore.selectedSuggestion;
    const STONE = 'universalSavings.pharmacistNote.stone';
    const GREEN = 'universalSavings.pharmacistNote.green';

    let card = '';
    let image_src;

    switch (type) {
      case 'DF':
      case 'TS':
        card = GREEN;
        image_src = avatarDrGreen;
        break;
      case 'TA':
      case 'GS':
      case 'GC':
        card = STONE;
        image_src = avatarDrStone;
        break;
    }

    const pharmacist_note = this.translationFactory
      .newTranslation('universalSavings.pharmacistNote.note', {
        drugName: selectedProduct.primary_name,
      })
      .addSuggestionType()
      .addMemberKey()
      .getTranslation();

    const name = translate(`${card}.name`);
    const jobDetails = translate(`${card}.jobDetails`);
    const note = pharmacist_note;
    const imageAltText = translate(`${card}.imageAltText`);
    return {
      image_src,
      translations: {
        name,
        jobDetails,
        note,
        imageAltText,
      },
    };
  }

  commonMedicationDataTranslations() {
    const { translate } = this.translationStore;

    return {
      memberSpendLabel: translate('drugDetails.memberSpendLabel'),
      planSpendLabel: translate('drugDetails.planSpendLabel'),
      sideEffectsLabel: translate('drugDetails.sideEffectsLabel'),
      interactionsLabel: translate('drugDetails.interactionsLabel'),
    };
  }

  @computed
  get claimMedicationDataToBeCompared(): CompareMedicationData {
    const { translate } = this.translationStore;

    const tag = translate('universalSavings.tags.currentMedication');

    const quantityLabel = translate('drugDetails.quantityAbbreviation', {
      quantity: this.savingsDomainStore.selectedClaim.quantity,
    });

    const daySupplyLabel = translate('drugDetails.daySupplyLabel', {
      daySupply: this.savingsDomainStore.selectedClaim.day_supply,
    });

    const planSpend = formatMoney(this.savingsDomainStore.selectedClaim.employer_cost);

    const memberSpend = this.savingsDomainStore.selectedClaim.member_cost;

    const memberSpendPerDayLabel = `${translate('drugDetails.costPerDayLabel', {
      costPerDay: formatMoney(this.savingsDomainStore.selectedClaim.member_cost_per_day),
    })}`;

    const sideEffects =
      this.savingsDomainStore.selectedClaim.product.medication_details.side_effects;

    const { interactions } = this.savingsDomainStore.selectedClaim.product.medication_details;

    const medicationDescription =
      this.savingsDomainStore.selectedClaim.product.medication_details.details;

    return {
      translations: {
        tag,
        quantityLabel,
        daySupplyLabel,
        planSpend,
        memberSpend,
        memberSpendPerDayLabel,
        sideEffects,
        interactions,
        medicationDescription,
        ...this.commonMedicationDataTranslations(),
      },
    };
  }

  @computed
  get suggestedMedicationToBeCompared(): CompareMedicationData {
    const { translate } = this.translationStore;

    const tag = translate('universalSavings.tags.highestSavingsOption');

    const quantityLabel = translate('drugDetails.quantityAbbreviation', {
      quantity: this.savingsDomainStore.selectedSuggestion.selectedProduct.quantity,
    });

    const daySupplyLabel = translate('drugDetails.daySupplyLabel', {
      daySupply: this.savingsDomainStore.selectedSuggestion.selectedProduct.day_supply,
    });

    const planSpend =
      this.savingsDomainStore.selectedSuggestion.selectedProduct.attributes.plan_pay !== null
        ? formatMoney(
            this.savingsDomainStore.selectedSuggestion.selectedProduct.attributes.plan_pay,
          )
        : null;

    const memberSpend = this.savingsDomainStore.selectedSuggestion.selectedProduct.cost_per_refill;

    const memberSpendPerDayLabel = `${translate('drugDetails.costPerDayLabel', {
      costPerDay: formatMoney(
        this.savingsDomainStore.selectedSuggestion.selectedProduct.cost_per_day,
      ),
    })}`;

    const sideEffects =
      this.savingsDomainStore.selectedSuggestion.suggested_products[0].medication_details
        .side_effects;

    const { interactions } =
      this.savingsDomainStore.selectedSuggestion.suggested_products[0].medication_details;

    const medicationDescription =
      this.savingsDomainStore.selectedSuggestion.suggested_products[0].medication_details.details;

    return {
      translations: {
        tag,
        quantityLabel,
        daySupplyLabel,
        planSpend,
        memberSpend,
        memberSpendPerDayLabel,
        sideEffects,
        interactions,
        medicationDescription,
        ...this.commonMedicationDataTranslations(),
      },
    };
  }
}
