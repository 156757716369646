/* @flow */
import { observable, action } from 'mobx';
import axios from '@utils/axios/axios';
import _ from 'lodash';
import overrideGlobalVariables from '../../utils/designDictionary/overrideGlobalVariables';
import SessionManager from '../../utils/session-manager';
import '../../assets/themes/rxss.scss';

class ThemeStore {
  @observable currentTheme: Object;

  @observable loaded = false;

  constructor() {
    this.logo = null;
    this.mobileLogo = null;
    this.imageTokens = null;
    this.loaded = false;
  }

  @action
  async init() {
    const sessionManager = new SessionManager(sessionStorage);
    let dictionaryResponse = {};
    const sessionTokens = sessionManager.get('tokens');
    try {
      if (!_.isEmpty(sessionTokens)) {
        overrideGlobalVariables(sessionTokens?.tokens);
        dictionaryResponse.data = sessionTokens;
      } else {
        dictionaryResponse = await axios.get('/design-dictionaries/members');
        window.sessionStorage.setItem('tokens', JSON.stringify(dictionaryResponse.data));
        if (dictionaryResponse?.data?.tokens) {
          overrideGlobalVariables(dictionaryResponse.data.tokens);
        }
      }
    } catch (e) {
      Rollbar.log('error', e);
    }
    this.setLogos(dictionaryResponse?.data);
    this.imageTokens = dictionaryResponse?.data?.tokens?.images?.portal;
    this.loaded = true;
  }

  /**
   * setLogos - sets logo and mobile logo in theme store for use across the application
   * @param {Object} designData - the response from the design dictionary service with color and logo tokens
   *
   * Fallback strategy:
   * If there is a logo id in the DDS response and that logo id is NOT rxss,
   * then we will pull the logo from the codebase based on the logo id.
   * Otherwise, we will set the source of the logo to what was returned from DDS (if it exists)
   * or fall back to the default rxss logo.
   */
  @action
  setLogos(designData: Object): void {
    this.logo = designData?.tokens?.images?.portal?.primaryLogo || '/logos/rxss-logo.svg';
    this.mobileLogo =
      designData?.tokens?.images?.portal?.webMobileLogo || '/logos/rxss-mobile-logo.svg';
  }
}

const themeStore = new ThemeStore();

export default themeStore;
export { ThemeStore };
