/* @flow */

import { observable, action } from 'mobx';
import axios from '@utils/axios/axios';

class GeneralMessagingStore {
  @observable breadCrumbText: string;

  @observable title: string;

  @observable messageBody: string;

  @observable linkUrl: string;

  @observable linkText: string;

  @observable alertId: string;

  @observable loaded: boolean;

  @action
  setAllGeneralMessagingProps(
    headerText,
    bodyText,
    alertLinkUrl,
    alertLinkText,
    alertId,
    breadCrumbText,
  ) {
    this.breadCrumbText = breadCrumbText;
    this.title = headerText;
    this.messageBody = bodyText;
    this.linkUrl = alertLinkUrl;
    this.linkText = alertLinkText;
    this.alertId = alertId;
    this.loaded = true;
  }

  @action
  generateGeneralMessagingProps(memberId, alertId) {
    axios
      .setIsVerbose(false)
      .get(`/v2/${memberId}/combined-alerts`)
      .then((response) => {
        response.alerts.map((alert) => {
          if (alert.id.toString() === alertId) {
            this.setAllGeneralMessagingProps(
              alert.header_text,
              alert.body_text,
              alert.link_url,
              alert.link_text,
              alert.id,
              alert.breadcrumb_text,
            );
          }
        });
      })
      .catch((err) => {
        Rollbar.warn(err);
      });
  }
}

const generalMessagingStore = new GeneralMessagingStore();

export default generalMessagingStore;
export { GeneralMessagingStore };
