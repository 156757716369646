/* @flow */
import React from 'react';
import ReactDOM from 'react-dom';
import { Route, Switch } from 'react-router-dom';
import { Provider } from 'mobx-react';
import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';
import { datadogRum } from '@datadog/browser-rum';
import axios from '@utils/axios/axios';
import { createServer, Response } from 'miragejs';
import * as stores from './stores';
import asyncComponent from './widgets/AsyncComponent';
import AppRouter from './containers/AppRouter/AppRouter';
import AppAnalytics from './analytics/AppAnalytics';
import { makeServer } from './mockServer';

// Pull in all the analytics!
require('./analytics/rollbar.js');
require('./analytics/pendo.js');
require('./analytics/chat.js');

const appAnalytics = new AppAnalytics();

// Check if pa11y-ci is running
if (window.location.href.includes('http://localhost:8089/')) {
  const server = makeServer({ environment: 'test' }, true);
  server.create('config', 'withUniversalSavings');
  server.create('opportunity', 'withSuperAlert');
  window.api_url = 'http://localhost:8089/'; // changing our api_url so that it aligns with pa11y-ci testing so mirage endpoints can be hit
}

if (window.Cypress) {
  // If your app makes requests to domains other than / (the current domain), add them
  // here so that they are also proxied from your app to the handleFromCypress function.
  // For example: let otherDomains = ["https://my-backend.herokuapp.com/"]
  const otherDomains = ['https://assets.rxss.design/'];
  const methods = ['get', 'put', 'patch', 'post', 'delete'];

  createServer({
    environment: 'test',
    routes() {
      for (const domain of ['/', ...otherDomains]) {
        for (const method of methods) {
          this[method](`${domain}*`, async (schema, request) => {
            const [status, headers, body] = await window.handleFromCypress(request);
            return new Response(status, headers, body);
          });
        }
      }

      // If your central server has any calls to passthrough(), you'll need to duplicate them here
      this.passthrough('https://assets.rxss.design/**');
      this.passthrough('https://images.rxsavingssolutions.com/**');
    },
  });
}

if (window.use_mirage_development_server && !window.Cypress) {
  makeServer({ environment: 'development' }, true);
}

if (window.isInDevelopment) {
  const axe = require('react-axe');
  axe(React, ReactDOM, 1000);
}

const { whyDidYouUpdate } = require('why-did-you-update');

if (window.performance_suite) {
  whyDidYouUpdate(React);
}

window.gonative_onesignal_info = function (info) {
  try {
    axios.post('/mobile-platform', info);
  } catch (err) {
    // lol
  }
};

if (
  window.datadog_config &&
  window.datadog_config.applicationId &&
  window.datadog_config.clientToken
) {
  datadogRum.init({
    applicationId: window.datadog_config.applicationId,
    clientToken: window.datadog_config.clientToken,
    datacenter: 'us',
    sampleRate: 100,
  });
}

if (window.logrocket_config && window.logrocket_config.key && window.logrocket_config.hostname) {
  LogRocket.init(window.logrocket_config.key, {
    rootHostname: window.logrocket_config.hostname,
    network: {
      responseSanitizer: (response) => {
        response.body = null;
        return response;
      },
      requestSanitizer: (request) => {
        request.body = null;
        request.headers = null;
        return request;
      },
    },
  });

  setupLogRocketReact(LogRocket);

  LogRocket.getSessionURL((sessionURL) => {
    Rollbar.configure({
      transform(obj) {
        obj['sessionURL'] = sessionURL;
        return obj;
      },
    });
  });
}

// eslint-disable-next-line no-unused-vars
function errorLoading(error) {
  Rollbar.error(error);
}

// Loading modules!
// eslint-disable-next-line no-unused-vars
function loadRoute(cb) {
  return (module) => cb(null, module.default);
}

const historyListeners = [
  (location, action) => {
    appAnalytics.registerPageView(location, action);
  },
];

const Err = asyncComponent(() =>
  import('./containers/ErrorPage/ErrorPage.js').then((module) => module.default),
);
const LogOut = asyncComponent(() =>
  import('./screens/Logout/Logout.js').then((module) => module.default),
);
const GetApp = asyncComponent(() =>
  import('./containers/LogoutPage/LogoutPage.js').then((module) => module.default),
);
const DownloadApp = asyncComponent(() =>
  import('./containers/DownloadAppPage/DownloadAppPage.js').then((module) => module.default),
);
const Reminders = asyncComponent(() =>
  import('./containers/ReminderAcknowledgement/ReminderAcknowledgement.js').then(
    (module) => module.default,
  ),
);
const App = asyncComponent(() =>
  import('./containers/App/App.js').then((module) => module.default),
);

ReactDOM.render(
  <Provider {...stores} LogRocket={LogRocket} Rollbar={Rollbar}>
    <AppRouter navigationListeners={historyListeners}>
      <Switch>
        <Route path='/error' component={Err} />
        <Route path='/logout' component={LogOut} />
        <Route path='/get-app' component={GetApp} />
        <Route path='/download-app' component={DownloadApp} />
        <Route path='/ra/:reminder_id/:auth_hash' component={Reminders} />
        <Route path='/' component={App} />
      </Switch>
    </AppRouter>
  </Provider>,
  document.getElementById('app'),
);
