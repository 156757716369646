// eslint-disable-next-line import/no-unresolved
import { titleCase } from 'title-case';

/**
 * function to convert a string into title case when provided a string.
 * @param {string} anyString - String passed to function to be title cased.
 * @return {string} returns updated title case string or an empty string if anyString is null or empty
 */
export function stringToTitleCase(anyString) {
  return anyString ? titleCase(anyString.toLowerCase()) : anyString;
}
/**
 * function to convert first and last name of all members and family  from member object.
 * @param {object} member - member object passed from memberStore.js .
 * @return {object} returns updated member object with the primary member and  family member names title cased.
 */
export function titleCaseMemberNames(member) {
  if (member) {
    member.first_name = stringToTitleCase(member.first_name);
    member.last_name = stringToTitleCase(member.last_name);
    if (member.family) {
      for (let i = 0; i < member.family?.length; i++) {
        member.family[i].first_name = stringToTitleCase(member.family[i].first_name);
        member.family[i].last_name = stringToTitleCase(member.family[i].last_name);
      }
    }
  }
  return member;
}
