/* @flow */
import { observable, action, computed } from 'mobx';
import Model from '../models/Model';

class Collection {
  @observable items: Array<Model>;

  constructor(items = []) {
    this.items = items;
  }

  @computed get length(): number {
    return this.items.length;
  }

  @computed get isEmpty(): boolean {
    return this.length === 0;
  }

  // region ACCESSOR METHODS
  /**
   * This is an alias of the built in array `find` function on this.items, making invoking it
   * equivalent to invoking this.items.find(finderFunction)
   *
   * @param finderFunction
   * @returns {Model}
   */
  find(finderFunction: Function): Model {
    return this.items.find(finderFunction);
  }

  /**
   * This is an alias of the built in array `filter` function on this.items, making invoking it
   * equivalent to invoking this.items.filter(filterFunction)
   *
   * @param filterFunction
   * @returns {Model[]}
   */
  filter(filterFunction: Function): Array<Model> {
    return this.items.filter(filterFunction);
  }

  /**
   * This invokes the `find` function on this.items and looks for an entry with an id field
   * corresponding to the id passed in via the `searchId` parameter
   *
   * @param searchId
   * @returns {Model}
   */
  getById(searchId: string): Model {
    return this.items.find((item) => item.id === searchId);
  }

  /**
   * This is an alias of the built in array `map` function on this.items, making invoking it
   * equivalent to invoking this.items.map(mapFunction)
   *
   * @param mapFunction
   * @returns {any[]}
   */
  map(mapFunction: Function): Array {
    return this.items.map(mapFunction);
  }

  /**
   * This is an alias of the built in array `reduce` function on this.items, making invoking it
   * equivalent to invoking this.items.reduce(reduceFunction, accumulator)
   *
   * @param {Function} reduceFunction
   * @param any accumulator
   * @returns {any} - where any is the type of items inside of the items array
   */
  reduce(reduceFunction: Function, accumulator: any): any {
    return this.items.reduce(reduceFunction, accumulator);
  }

  /**
   * This is an alias of the built in array `sort` function on this.items, making invoking it
   * equivalent to invoking this.items.sort(sortFunction)
   *
   * @param sortFunction
   * @returns {Array<Model>}
   */
  sort(sortFunction: Function): Array<Model> {
    return this.items.sort(sortFunction);
  }
  // endregion

  // region MODIFICATION METHODS
  /**
   * This adds a new model to the end of the collection's items array
   *
   * @param {Model} newItem
   */
  @action.bound
  add(newItem: Model): void {
    this.items = [...this.items, newItem];
  }

  /**
   * This adds an array of several new models to the end of the collection's items array
   * @param {Model[]} newItems
   */
  @action.bound
  join(newItems: Array<Model>): void {
    this.items = [...this.items, ...newItems];
  }

  /**
   * This removes all items in the Collection
   */
  @action.bound
  clear(): void {
    this.items = [];
  }

  /**
   * This removes the model with the id corresponding to the one passed in and returns true if an
   * item has, in fact, been removed, otherwise it does not update the item list and returns false
   *
   * @param {string} filteredId
   * @returns boolean
   */
  @action.bound
  delete(filteredId: string): boolean {
    const newItems = this.items.filter((item) => item.id !== filteredId);
    if (newItems.length === this.items.length) {
      return false;
    }
    this.items = newItems;
    return true;
  }

  /**
   * This calls the update() method of the contained model with an id corresponding to the one passed
   * in, passing the new values in as the parameter. The newly updated model is then returned by this
   * function.
   *
   * @param {string} updatedId
   * @param {Object} newValues
   * @returns {Model}
   */
  @action.bound
  update(updatedId: string, newValues: Object): Model {
    this.items = this.items.map((item) => {
      if (item.id === updatedId) {
        item.update(newValues);
      }
      return item;
    });
    return this.getById(updatedId);
  }

  /**
   * This replaces a model in the `items` array with the replacement passed in.
   * @param {string} replacementId
   * @param {Model} newModel
   */
  @action.bound
  replace(replacementId: string, newModel: Model): void {
    this.items = this.items.map((item) => {
      return item.id === replacementId ? newModel : item;
    });
  }
  // endregion
}

export default Collection;
