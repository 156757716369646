/* @flow */
import { observable } from 'mobx';
import type { RxHomeDeliveryStatusesType } from '@data/RxHomeTransfer/RxHomeDeliveryStatuses';
import RxHomeDeliveryStatuses from '@data/RxHomeTransfer/RxHomeDeliveryStatuses';
import type {
  MultiplePharmacyData,
  RxHomeDeliveryApiStatuses,
  RxHomeDeliveryUtilityStatuses,
  SinglePharmacyData,
  AdditionalDataFromClaim,
} from '@data/RxHomeTransfer/RxHomeDeliveryTypes';
import Model from '../models/Model';

type SinglePharmacyDetails = {| ...SinglePharmacyData, ...AdditionalDataFromClaim |};

type MultiplePharmacyDetails = {| ...MultiplePharmacyData, ...AdditionalDataFromClaim |};

type ResponseDetails =
  | SinglePharmacyDetails
  | MultiplePharmacyDetails
  | AdditionalDataFromClaim
  | null;

class RxHomeTransferResponse extends Model {
  @observable status = null;

  @observable details: ResponseDetails;

  /**
   * A container for the relevant data returned from the API. This is also used to hold the loading
   * status (which is set manually when a call is initiated), as well as the error status when an API
   * call fails
   *
   * @param response
   * @param { string } response.claimId - The claim id associated with the call
   * @param { string } response.status - The home delivery eligibility status of the claim
   * @param { Object } [response.details] - The optional home delivery details of the claim
   */

  constructor({
    claimId,
    status,
    details,
  }: {
    claimId: number,
    status: RxHomeDeliveryApiStatuses | RxHomeDeliveryUtilityStatuses,
    details: ResponseDetails,
  }) {
    super();
    this.id = claimId;
    this.status = this.mapStatus(status);
    this.details = details;
  }

  /**
   * Maps various statuses from the backend to values that are supported on the frontend.
   */
  mapStatus(
    status: RxHomeDeliveryApiStatuses | RxHomeDeliveryUtilityStatuses,
  ): RxHomeDeliveryStatusesType {
    switch (status) {
      case 'RECEIVED':
        return RxHomeDeliveryStatuses.SWITCH_REQUEST_SUBMITTED;
      case 'IN-PROGRESS':
        return RxHomeDeliveryStatuses.SWITCH_REQUEST_SUBMITTED;
      case 'COMPLETE':
        return RxHomeDeliveryStatuses.SWITCH_COMPLETED;
      default:
        return status;
    }
  }
}

export default RxHomeTransferResponse;
