/* @flow */
import { observable, action, computed, observe } from 'mobx';
import { formatPhoneNumber } from '@utils/Functions';
import axios from '@utils/axios/axios';
import { medicineCabinetApi } from '@data/api';
import type { PricedPharmacy } from '@features/savings/types/Pharmacy';
import type { suggestionsTypesAll } from '@features/savings/types/Suggestion';
import type { SuggestedProducts } from '@features/savings/types/Product';

const SUCCESS = 'MEDICATION-CONDITION-SUCCESS';
const FAILURE = 'MEDICATION-CONDITION-FAILURE';

class MedicineCabinetStore {
  @observable message: string;

  @observable _cabinetInfo: Object;

  @observable _selectedUserId = null;

  @observable cabinetHasLoaded = false;

  @observable prescriptionHasLoaded = false;

  @observable _conditions: Array;

  @observable _activeProduct = null;

  constructor(memberStore: Object, translationStore: Object, userMessageStore: Object) {
    this._conditions = [];
    this.cabinetLoaded = this.cabinetLoaded.bind(this);
    this.memberStore = memberStore;
    this.translationStore = translationStore;
    if (this.memberStore) {
      observe(this.memberStore, 'currentMember', () => {
        this.loadCabinet();
      });
    }

    if (userMessageStore) {
      this.userMessageStore = userMessageStore;
    }
  }

  @action setActiveProduct(newProd) {
    this._activeProduct = newProd;
  }

  @action loadCabinet() {
    if (!!this.memberStore.currentMember) {
      medicineCabinetApi
        .getMemberMedicineCabinet(this.memberStore.currentMember.id)
        .then(this.cabinetLoaded)
        .catch((err) => Rollbar.log('NOPE', err));
    }
  }

  @action cabinetLoaded(resp) {
    this.cabinetHasLoaded = true;
    this._cabinetInfo = resp;
  }

  @action setCondition(medication, id) {
    const member = this.memberStore.activeMember.id;
    const data = { product_id: medication };
    const url = `/v2/${member}/medicine-cabinets/associate/${id}`;

    this.userMessageStore.registerMessage({
      type: 'success',
      message: this.translationStore.translate('medicine-cabinet.condition-updated'),
      name: SUCCESS,
    });
    this.userMessageStore.registerMessage({
      type: 'danger',
      message: this.translationStore.translate(
        'medicine-cabinet.problem-associating-with-condition',
      ),
      name: FAILURE,
    });
    axios
      .post(url, data)
      .then(() => {
        this.userMessageStore.open(SUCCESS);
        this.toggleShowConditionSelector(false);
        this.loadCabinet();
        axios
          .setIsVerbose(false)
          .get(`/v2/product-info/${this._claimProductId}?claim=${this._claimId}`)
          .then(this.claimFetchSuccess)
          .catch(this.claimFetchFailure);
      })
      .catch(() => {
        this.userMessageStore.open(FAILURE);
      });
  }

  @observable showConditionSelector = false;

  @action toggleShowConditionSelector(newVal) {
    this.showConditionSelector = newVal !== null ? newVal : !this.showConditionSelector;
  }

  @computed get cabinets() {
    return this._cabinetInfo ? this._cabinetInfo.medicine_cabinets : [];
  }

  @computed get hasMeds() {
    return this.selectedFamilyMember.prescription_drugs.length > 0;
  }

  @computed get prescriptionMeds() {
    const tr = this.translationStore.translation;
    return this.selectedFamilyMember.prescription_drugs
      .reduce(
        (agg, drug) => {
          if (drug.condition !== null) {
            const index = agg.findIndex((el) => {
              return el.condition === drug.condition;
            });
            if (index !== -1) {
              agg[index].meds.push(drug);
            } else {
              agg.push({
                condition: drug.condition,
                meds: [drug],
              });
            }
          } else {
            agg[0].meds.push(drug);
          }

          return agg;
        },
        [
          {
            condition: tr.t('medicine-cabinet.uncategorized'),
            meds: [],
          },
        ],
      )
      .sort((a, b) => {
        const unassociated = tr.t('medicine-cabinet.uncategorized');
        if (a.condition === unassociated) {
          return 1;
        }
        if (b.condition === unassociated) {
          return -1;
        }
        return a.condition.localeCompare(b.condition);
      });
  }

  @computed get conditions() {
    const memberID = !this.memberStore.activeMember ? null : this.memberStore.activeMember.id;
    const productID = this._activeProduct && this._activeProduct.id ? this._activeProduct.id : null;
    let conditions = [];
    if (memberID !== null && productID !== null) {
      const filtered = this._cabinetInfo.medicine_cabinets
        .filter((cabinet) => cabinet.member_id === memberID)[0]
        .prescription_drugs.filter((script) => script.product_id === productID)[0];

      if (typeof filtered !== 'undefined' && typeof filtered.available_conditions !== 'undefined') {
        conditions = filtered.available_conditions
          .map((condition) => {
            return {
              label: condition.condition,
              value: condition.id,
            };
          })
          .sort((a, b) => {
            if (a.label === 'unassociated') {
              return -1;
            }
            if (b.label === 'unassociated') {
              return 1;
            }
            return a.label.localeCompare(b.label);
          });
      }
    }

    return conditions;
  }

  // FAMILY MEMBERS
  @computed get selectedFamilyMember() {
    return this.cabinets.filter(
      (member) => member.member_id === this.memberStore.activeMember.id,
    )[0];
  }

  @action changeFamilyMember(newMember) {
    this.memberStore.setActiveMemberById(newMember);
  }

  // CLAIMS
  @observable _claimId = null;

  @observable _claimProductId = null;

  @observable _currentClaim = null;

  @computed get currentClaim() {
    return this._currentClaim;
  }

  @computed get claimPrescription() {
    return !!this.currentClaim ? this.currentClaim.prescription : null;
  }

  @action reset() {
    this._claimId = null;
    this._claimProductId = null;
    this._currentClaim = null;
  }

  @computed get claimPharmacy() {
    if (this.claimPrescription) {
      const { pharmacy } = this.claimPrescription;
      return {
        pharmacyName: pharmacy.name,
        pharmacyStreetAddress: pharmacy.address.street,
        pharmacyCity: pharmacy.address.city,
        pharmacyState: pharmacy.address.state,
        pharmacyZip: pharmacy.address.zipcode,
        pharmacyNumber: formatPhoneNumber(pharmacy.phone),
        isPharmacyHomeDelivery: !!pharmacy.home_delivery_provider,
      };
    }
  }

  @computed get claimBreakdown() {
    if (this.claimPrescription) {
      const payment = this.claimPrescription;
      return {
        retailCost: payment.employer_cost + payment.member_cost,
        planPays: payment.employer_cost,
        memberCost: payment.member_cost,
      };
    }
  }

  @computed get claimMedication() {
    return this.currentClaim ? this.currentClaim.medication : null;
  }

  @computed get claimImage() {
    if (
      !!this.claimMedication &&
      this.claimMedication.packages &&
      this.claimMedication.packages[0] &&
      !!this.claimMedication.packages[0].details
    ) {
      return this.claimMedication.packages[0].details.image_url;
    }
  }

  @computed get claimLink() {
    if (!!this.currentClaim) {
      const { medication } = this.currentClaim;
      const { prescription } = this.currentClaim;
      const params = {
        alertType: prescription.alert.savings_type,
        alert_id: prescription.alert.id,
        claimId: prescription.id,
        daySupply: prescription.day_supply,
        dosage: medication.strength,
        employerCost: prescription.employer_cost,
        form: medication.form,
        lastPaid: prescription.member_cost,
        nameId: medication.name.id,
        quantity: prescription.quantity,
        zip: this.memberStore.activeMember.zip,
        member: this.memberStore.activeMember.id,
      };

      if (this.claimAlert.savings_type === 'client') {
        params.alertType = this.claimAlert.savings_type;
        params.employerCost = prescription.employer_cost;
      }

      let pathname = '/prices/suggestions';
      if (this.claimAlert.alert_type === 'mail-order') {
        pathname = '/prices/mail-order';
        params.pricing = 'mail_order';
        params.suggestion = this.claimAlert.suggestions.find(
          (suggestion) => suggestion.type === 'MO',
        ).id;
      }

      return {
        pathname,
        search: params,
      };
    }
  }

  @computed get claimAlert() {
    return !!this.claimPrescription ? this.claimPrescription.alert : null;
  }

  @computed get claimMedicationName() {
    let name = '';
    if (typeof this.claimMedication.name === 'string') {
      name = this.claimMedication.name;
    }
    if (typeof this.claimMedication.name.name !== 'undefined') {
      name = this.claimMedication.name.name;
    }
    return name;
  }

  @computed get claimMedicationPrimaryName() {
    let primaryName = '';
    if (typeof this.claimMedication.primary_name === 'string') {
      primaryName = this.claimMedication.primary_name;
    }
    return primaryName;
  }

  @computed get claimMedicationSecondaryName() {
    let secondaryName = '';
    if (typeof this.claimMedication.secondary_name === 'string') {
      secondaryName = this.claimMedication.secondary_name;
    }
    return secondaryName;
  }

  @computed get claimQuantity() {
    return !!this.claimPrescription ? this.claimPrescription.quantity_with_unit : null;
  }

  @computed get claimStrength() {
    if (!!this.claimMedication) {
      return this.claimMedication.strength;
    }
  }

  // FIX THIS AFTER TALKING TO TELLSWORTH
  @computed get claimDate() {
    return !!this.claimPrescription ? this.claimPrescription.filled_at : null;
  }

  @action.bound claimFetchSuccess(resp) {
    this._currentClaim = resp;
    this.prescriptionHasLoaded = true;
  }

  @action.bound claimFetchFailure() {
    this.prescriptionHasLoaded = true;
  }

  @action setCurrentClaim(claimProductId, claimId) {
    if (claimProductId !== this._claimProductId || claimId !== this._claimId) {
      this.prescriptionHasLoaded = false;
      this._claimId = claimId;
      this._claimProductId = claimProductId;
      axios
        .setIsVerbose(false)
        .get(`/v2/product-info/${this._claimProductId}?claim=${this._claimId}`)
        .then(this.claimFetchSuccess)
        .catch(this.claimFetchFailure);
    }
  }

  updateMedicineCabinetAlert(
    alertId: number,
    suggestionsStore: {
      +selectedPharmacy: PricedPharmacy,
      +selectedSuggestion: { type: suggestionsTypesAll },
    },
    suggestedDrugs: SuggestedProducts,
  ) {
    const contactPrescriberValues = {
      pharmacy: suggestionsStore.selectedPharmacy,
      type: suggestionsStore.selectedSuggestion.type,
    };
    if (this.claimAlert && this.claimAlert.id === alertId) {
      this.claimAlert.alert_type = 'contact-prescriber-active';
      const contact_prescriber_temp = {
        couponSuggestionsStore: contactPrescriberValues,
        suggestedDrugs,
      };
      this.claimAlert.medication_claim.contact_prescriber_temp = contact_prescriber_temp;
    }
  }

  resetMedicineCabinetAlert(alertId: number) {
    if (this.claimAlert && this.claimAlert.id === alertId) {
      this.claimAlert.alert_type = null;
    }
  }
}

const medicineCabinetStore = new MedicineCabinetStore();

export default medicineCabinetStore;
export { MedicineCabinetStore };
