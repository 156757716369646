/* @flow */

import { observable, action } from 'mobx';
import axios from '@utils/axios/axios';

class RecentSearchStore {
  /**
   * Boolean use to toggle recentSearch on and off
   */
  @observable wasRecentSearchCalled: boolean = false;

  /**
   * Hold previous searched medications.
   */
  @observable recentSearches: Array<Object> = [];

  /**
   * @private
   * ProductStore
   */
  _productStore: Object;

  constructor(productStore: Object) {
    this._productStore = productStore;
  }

  /**
   * Get all recentSearches for a given members history
   */
  @action
  async getRecentSearches() {
    // TODO - move recent searches to it's own store
    this.wasRecentSearchCalled = true;

    axios
      .setCheckAuthorization(true)
      .setIsVerbose(false)
      .get('/members/search-history?include=products&limit=3')
      .then((response) => {
        const meta = response.metadata;
        const searches = response.response;
        const searchesWithProduct = searches.map((search) => {
          const product = meta.products.filter((product) => product.id === search.product_id);
          return { product: product[0], ...search };
        });
        this._productStore.add(meta.products);
        this.recentSearches = searchesWithProduct;
        this.wasRecentSearchCalled = false;
      })
      .catch((error) => {
        this.wasRecentSearchCalled = false;
        this.recentSearches = [];
      });
  }

  /**
   * TODO - find out why this is not used....
   * Should log recent searches when a search was completed
   *
   * @param {Object} result -
   * @param {Object} query -
   */
  @action
  logRecentSearch(result: Object, query: Object) {
    const data = {
      product_id: result.productId,
      quantity: query.quantity,
      day_supply: query.daySupply,
      location: query.zip,
      package_type_id: 666, // todo lol figure this out
    };
    return axios.setCheckAuthorization(true).post('/members/search-history?include=products', data);
  }
}

export { RecentSearchStore };
