/* @flow */

import { observable } from 'mobx';
import { findIndex } from 'lodash';
import SessionManager from '../../utils/session-manager';

class BreadcrumbStore {
  @observable namespaces: Array<string> = [];

  @observable
  breadcrumbs: Array<Object> = [];

  _sessionManager = new SessionManager(sessionStorage);

  /**
   * Constructs an instance of a BreadcrumbStore.
   * @param {Object} param0 - A configuration object
   * @param {Object} param0.translationStore - An instance of a {@link TranslationStore}.
   */
  constructor({ translationStore }) {
    this.translationStore = translationStore;
    this.breadcrumbs.push({
      name: this.translationStore.translate('BreadcrumbStore.Dashboard'),
      query: false,
      pathname: '/dashboard',
    });
  }

  addLocation(location: Object) {
    const storageKey = this.getStorageKey(location);
    const locationStorage = this._sessionManager.get(storageKey);

    if (!this.namespaces.includes(storageKey)) {
      this.namespaces.push(storageKey);
    }

    if (location.name !== this.translationStore.translate('BreadcrumbStore.Dashboard')) {
      if (locationStorage) {
        this.breadcrumbs = locationStorage;
        this.setBreadcrumbs(location, locationStorage);
      } else {
        this.setBreadcrumbs(location, this.breadcrumbs);
      }
    }

    this._sessionManager.set(storageKey, this.breadcrumbs);
  }

  navigateTo(location: Object, callback: ?Function) {
    if (callback) {
      callback();
    }

    const storageKey = this.getStorageKey(location);
    const locationIdx = findIndex(this.breadcrumbs.slice(0), location);

    this.breadcrumbs = this.breadcrumbs.slice(0, locationIdx + 1);

    this._sessionManager.set(storageKey, this.breadcrumbs);
  }

  getStorageKey(location: Object): string {
    return `breadcrumb-${location.group || ''}`;
  }

  // Prevents Doubling
  setBreadcrumbs(location: Object, locations: Array<Object>) {
    const filteredLocations = locations.filter((loc) => {
      return loc.name !== location.name;
    });

    this.breadcrumbs = filteredLocations.concat(location);
  }

  reset() {
    this.breadcrumbs = [
      {
        name: this.translationStore.translate('BreadcrumbStore.Dashboard'),
        query: false,
        pathname: '/dashboard',
      },
    ];

    this.namespaces.forEach((namespace) => {
      this._sessionManager.clear(namespace);
    });
  }
}

export { BreadcrumbStore };
