/* @flow */
import Model from '@data/models/Model';
import { computed, observable } from 'mobx';
import { getDistance } from '@utils/Functions';
import { hasAdjudilitePriceBlocker } from '@utils/Functions/addNoPriceAvailableTagToPharmacies/addNoPriceAvailableTagToPharmacies';
import BaseMember from '@data/models/Member/BaseMember/BaseMember';
import type { PricedProduct } from '@features/savings/types/Product';
import type { BasePrice } from '@features/savings/types/Price';
import type { PricedPharmacy } from '@features/savings/types/Pharmacy';
import type { suggestionsTypesAll } from '@features/savings/types/Suggestion';
import { SuggestedProducts } from '@features/savings/types/Product';

class BaseSuggestion {
  /**
   * The member the suggestion is for
   * @type {BaseMember}
   */
  suggestionMember: BaseMember;

  authenticatedMember: BaseMember;

  id: number;

  @observable type: suggestionsTypesAll;

  @observable long_type: string;

  @observable suggested_products: SuggestedProducts;

  @observable product_id: number;

  @observable pharmacist_note: string;

  @observable pharmacies: Array<PricedPharmacy>;

  @observable express_display_info: {
    prescriber: string,
    savings: number,
    from: {
      simple_name: string,
      price: number,
      display_strength: string,
      image_url: string,
      compare_info: {
        primary_name: string,
        supplemental_name: string,
        side_effects: string,
        details: string,
        interactions: string,
      },
    },
    to: {
      simple_name: string,
      price: number,
      display_strength: string,
      image_url: string,
      compare_info: {
        primary_name: string,
        supplemental_name: string,
        side_effects: string,
        details: string,
        interactions: string,
      },
    },
  };

  @observable coverage_alerts: Array<{||}>;

  @observable best_prices: { retail: ?BasePrice, mail: ?BasePrice, coupon: ?BasePrice };

  constructor({
    json,
    suggestionMember,
    authenticatedMember,
  }: {
    json: {||},
    suggestionMember: BaseMember,
    authenticatedMember: BaseMember,
  }) {
    this.id = json.id;
    this.suggestionMember = suggestionMember;
    this.authenticatedMember = authenticatedMember;
    this.addDistance = this.addDistance.bind(this);
    this.setBlockedPricesToNull = this.setBlockedPricesToNull.bind(this);
    this.updateFromJson(json);
  }

  updateFromJson(json: {||}) {
    this.type = json.type;
    this.long_type = json.long_type;
    this.suggested_products = json.suggested_products;
    this.product_id = json.product_id;
    this.pharmacist_note = json.pharmacist_note;
    this.pharmacies = this.decoratePharmacies(json.pharmacies);
    this.express_display_info = json.express_display_info;
    this.coverage_alerts = json.coverage_alerts;
    this.best_prices = json.best_prices;
  }

  getPharmacyById(pharmacyId: ?number): ?PricedPharmacy {
    return this.pharmacies.find((pharm) => pharm.id === pharmacyId);
  }

  getPharmacyByNcpdp(pharmacyNcpdp: ?string): ?PricedPharmacy {
    return this.pharmacies.find((pharm) => pharm.ncpdp === pharmacyNcpdp);
  }

  addDistance(pharmacy) {
    return {
      ...pharmacy,
      distance: getDistance(pharmacy.address, this.authenticatedMember.address),
    };
  }

  /**
   * Provides a new copy of the pharmacy object with a "tags" property used to show the users different features of
   * this pharmacy.
   *
   * @param {Object} pharmacy - An individual object matching the structure of a pharmacy.
   * @returns {Object} - A copy of the original pharmacy with an added "tag" property.
   */
  addTagsToPharmacies = (pharmacy) => {
    const { tags = [], home_delivery_provider, ncpdp } = pharmacy;

    if (!!home_delivery_provider) {
      tags.push({ type: 'primary', text: 'home-delivery' });
    }
    if (ncpdp === this.suggestionMember.primaryPharmacyNcpdp) {
      tags.push({ type: 'primary', text: 'primary-pharmacy' });
    }

    return {
      ...pharmacy,
      tags,
    };
  };

  /**
   * Transforms pharmacy costs for price blocked pharmacies
   * @param {Object} pharmacy to examine for price blockers
   * @returns {Object} pharmacy with adjudilite prices set to null if price is blocked
   * @private
   */
  setBlockedPricesToNull(pharmacy) {
    if (hasAdjudilitePriceBlocker(pharmacy)) {
      pharmacy.prices.adjudilite.cost_per_refill = null;
      pharmacy.prices.adjudilite.cost_per_day = null;
      pharmacy.prices.adjudilite.attributes.plan_pay = null;
      pharmacy.prices.adjudilite.attributes.plan_pay_per_day = null;
    }

    return pharmacy;
  }

  /**
   * Decorates pharmacy information to be attached to the suggestion object.
   * @param pharmacies The list of pharmacies.
   * @throws TypeError If the given pharmacies list is undefined or null.
   * @returns {*} A mapped list of pharmacy objects with all decorated info provided.
   */
  decoratePharmacies = (pharmacies) => {
    if (pharmacies) {
      return pharmacies
        .map(this.addTagsToPharmacies)
        .map(this.addDistance)
        .map(this.setBlockedPricesToNull);
    }
    throw new TypeError('A suggestion is missing pharmacies info.');
  };

  /**
   * The pharmacy object for the primary pharmacy of the member the suggestion is for
   */
  @computed get primaryPharmacy() {
    try {
      return this.getPharmacyByNcpdp(this.suggestionMember.primaryPharmacyNcpdp);
    } catch (err) {
      return null;
    }
  }

  /**
   * The pharmacy object for the plan preferred pharmacy of the members subgroup
   */
  @computed get planPreferredPharmacy() {
    try {
      return this.getPharmacyById(this.suggestionMember.planPreferredPharmacyId);
    } catch (err) {
      return null;
    }
  }
}

export default BaseSuggestion;
