/**
 * Creates a new list that repositions the Rx Home Delivery pharmacies to the position provided.
 *
 * @param {Object[]} sortedPharmacies - A pre-sorted list of pharmacies, accounting for all other criteria for sorting.
 * @param {Number} position - A zero-indexed based number of where the new Home Delivery Pharmacy options should be.
 * @param {Function} isPharmacyHomeDeliveryPredicate - A function that takes a "pharmacy" parameter. This can be used
 *    to customize what determines an object as a "Home Delivery" pharmacy.
 *
 * @returns {Object[]} - A list of pharmacies with the Rx Home Delivery pharmacies set at the specific position.
 */
function positionHomeDeliveryPharmacies({
  sortedPharmacies = [],
  position = 0,
  isPharmacyHomeDeliveryPredicate = (pharmacy) => !!pharmacy.home_delivery_provider,
}) {
  const isPharmacyRetailPharmacy = (pharmacy) => !isPharmacyHomeDeliveryPredicate(pharmacy);

  const homeDeliveryPharmacies = sortedPharmacies.filter(isPharmacyHomeDeliveryPredicate);
  const retailPharmacy = sortedPharmacies.filter(isPharmacyRetailPharmacy);

  retailPharmacy.splice(position, 0, ...homeDeliveryPharmacies);

  return retailPharmacy;
}

export default positionHomeDeliveryPharmacies;
