/* @flow */

import { SavingsDomainStore } from '@features/savings/stores/SavingsDomainStore/SavingsDomainStore';
import { MemberStore } from '@stores_old/memberStore/memberStore';
import { TranslationStore } from '@stores_old/translationStore/translationStore';

export class TranslationFactory {
  translationStore: TranslationStore;

  memberStore: MemberStore;

  savingsDomainStore: SavingsDomainStore;

  translationKey: string;

  translationData: Object;

  constructor({
    savingsDomainStore,
    memberStore,
    translationStore,
  }: {
    savingsDomainStore: SavingsDomainStore,
    memberStore: MemberStore,
    translationStore: TranslationStore,
  }) {
    this.savingsDomainStore = savingsDomainStore;
    this.memberStore = memberStore;
    this.translationStore = translationStore;
  }

  newTranslation(initialKey: string, initialData: ?Object): TranslationFactory {
    this.translationKey = initialKey;
    this.translationData = initialData;
    return this;
  }

  addConditionalKey(
    trueKey: string,
    falseKey: string,
    condition: boolean,
    conditionalData: Object,
  ): void {
    if (condition) {
      this.translationKey = this.translationKey + '.' + trueKey;
      this.translationData = { ...this.translationData, ...conditionalData };
    } else {
      this.translationKey = this.translationKey + '.' + falseKey;
    }
  }

  addSuggestionType(): TranslationFactory {
    this.translationKey =
      this.translationKey + '.' + this.savingsDomainStore.selectedSuggestion.type;
    return this;
  }

  addMemberKey(): TranslationFactory {
    const isFamilyAlert =
      this.memberStore.currentMember.id !== this.savingsDomainStore.selectedMember.id;
    const dataToAdd = { memberName: this.savingsDomainStore.selectedMember.first_name };
    this.addConditionalKey('forFamily', 'forSelf', isFamilyAlert, dataToAdd);
    return this;
  }

  addDoctorNameKey(): TranslationFactory {
    const dataToAdd = { doctorName: this.savingsDomainStore.selectedClaim.doctorFullName };
    const hasDoctorName = Boolean(dataToAdd.doctorName);
    this.addConditionalKey('withDoctorName', 'withoutDoctorName', hasDoctorName, dataToAdd);
    return this;
  }

  getTranslation(): string {
    return this.translationStore.translate(this.translationKey, this.translationData);
  }
}
