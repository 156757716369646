import { observable, action } from 'mobx';
import checkUserIdentityApi from '@features/homeDelivery/apis/checkUserIdentityApi';

class UserIdentityStore {
  @observable usingUserImpersonation = false; // Set reasonable default

  @observable doesEnvironmentEnforceUserImpersonationRestrictions = true; // Set reasonable default

  @observable isLoading;

  @action
  async init() {
    this.isLoading = true;

    try {
      const response = await checkUserIdentityApi.checkIdentity();

      if (response?.meta) {
        this.usingUserImpersonation = response.meta.usingUserImpersonation;
        this.doesEnvironmentEnforceUserImpersonationRestrictions =
          response.meta.doesEnvironmentEnforceUserImpersonationRestrictions;
      }
    } catch (err) {
      Rollbar.error(err);
    }
  }
}

export { UserIdentityStore };
