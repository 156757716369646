/* @flow */

import { observable } from 'mobx';

class LocatorOptionStore {
  @observable memberStore: Object;

  @observable location: Object;

  @observable type: string;

  @observable geolocation: Object;

  @observable zip: string;

  constructor(memberStore) {
    this.memberStore = memberStore;

    // Set Default Options
    this.location = {};
    this.type = 'address';
    this.geolocation = {};
    this.zip = null;
  }
}

export { LocatorOptionStore };
