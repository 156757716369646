export default {
  dash: {
    header: 'Bienvenido a Rx Savings Solutions',
    'get-started': 'Para comenzar, busque ahorros en sus medicamentos.',
  },
  'add-up': {
    cost: 'Su costo',
    'you-pay': 'Su pago:',
    projected: 'Impacto del plan a partir de {{date, "MM/DD/YY"}}',
    'not-available': 'N/A',
    'pbm-disclaimer':
      'El impacto del plan se basa en un costo de bolsillo de ${{amount}}. Su costo real de bolsillo puede ser menor.',
  },
  app: {
    dashboard: 'Panel principal',
    contact: 'Ayuda',
    profile: 'Configuraciones de usuario',
    logout: 'Cerrar sesión',
    more: 'Más',
    medication: 'Medicamento',
  },
  navSlide: {
    profile: 'Configuraciones de usuario',
    logout: 'Cerrar sesión',
    closeDrawer: 'Cerrar',
    reminders: 'Recordatorios de medicamento',
    pharmacyLocator: 'Encuentre una farmacia',
  },
  castlight: {
    return: 'haga clic aquí para volver a',
  },
  claims: {
    'you-paid': 'Pagó por última vez',
    'plan-paid': 'Su grupo pagó por última vez',
  },
  'claim-result': {
    'already-saving': '¿Por qué no ve ninguna opción de ahorro? Es posible que:',
    'already-saving__bullet-one':
      'El precio haya mbiado desde que se generó la alerta original, debido a un cambio en su plan de salud, el estado del deducible o el precio de mercado del medicamento, o',
    'already-saving__bullet-two': 'Ya está ahorrando todo lo que puede en este medicamento.',
    'quick-call':
      'Una llamada rápida a nuestro equipo de soporte de farmacia al {{phoneNumber}} le dará el precio más actualizado u otras terapias disponibles.',
    'want-see': '¿Aún desea ver los precios de su medicamento y otras terapias potenciales?',
    'let-know': 'Déjenos saber.',
  },
  'home-delivery-status-item': {
    'button-text': 'Solicitar entrega',
    'info-box-content':
      'Este medicamento está disponible para entrega. Ahórrese el viaje a la farmacia y deje que sus medicamentos le lleguen.',
  },
  'contact-prescriber': {
    title: 'Cambiar es simple',
    tabletSplit:
      'Nuestros farmacéuticos revisaron esta opción de medicamento y la consideran segura y eficaz. Simplemente divida a mano a lo largo de las líneas marcadas. Si no hay líneas marcadas, puede comprar un divisor de tabletas de bajo costo en su farmacia local.',
    text: 'Obtener una nueva receta para <strong>{{suggestedDrugText}}</strong> es más fácil de lo que piensa. ¡Incluso podemos enviar la solicitud a su prescriptor en su nombre! (Tenemos la información de contacto del prescriptor en su reclamo de prescripción.)',
    'mail-order-text':
      'Este medicamento está disponible para entrega a domicilio a través de <strong>{{pharmacy}}</strong>. Por favor llame al <a href="tel:{{number}}">{{number}}</a> para que <strong>{{pharmacy}}</strong> cambie su reveta a entrega a domicilio.',
    button: '¡Contactar a mi prescriptor!',
    option1: 'o',
    option2: 'use esta carta',
    option3: ' para guiar su propia conversación con su prescriptor.',
    step2:
      'Para realizar este cambio por su cuenta, utilice los cupones a continuación para asegurarse de obtener el precio correcto.',
    'step2-Pending':
      'Utilice los cupones a continuación para asegurarse de obtener el precio correcto.',
    'step2-Note':
      'NOTA: Con este cupón, la cantidad que pague no pagará su deducible a menos que envíe un reclamo a su compañía de seguro médico. Comuníquese con ellos para recibir instrucciones.',
    'order-online': 'Compra en línea',
    'request-delivery': 'Solicitar entrega',
    'home-delivery-footer-text':
      'Ahórrese el viaje a la farmacia y solicite que le envíen <strong>{{suggestedDrugText}}</strong>.',
  },
  chat: {
    'live-chat': 'Chat en vivo',
    'chat-with-us': 'Hable con nosotros',
  },
  'contact-prescriber-modal': {
    'start-header': 'Antes de enviar su solicitud',
    'start-message':
      'Ingrese su {{method}} para que podamos comunicarnos con usted sobre el estado de su solicitud',
    'start-phone-placeholder': 'Número de teléfono',
    'start-phone': 'teléfono',
    'start-email-placeholder': 'Dirección de correo electrónico',
    'start-email': 'correo electrónico',
    'start-placeholder': 'Ingrese su {{method}}',
    'start-transfer': 'Transferir mi receta',
    'start-contact': 'Contactar a mi prescriptor',
    'start-submit': 'Enviar {{method}}',
    'start-toggle': 'No gracias, contactarme a través de {{method}}',
    'start-error':
      'No pudimos enviar su solicitud en este momento. Comuníquese con nuestro Equipo de soporte de farmacia al 1-800-268-4476 si esto persiste.',
    'mobile-number': 'Número de teléfono celular',
    back: 'Volver al panel principal',
    processing: 'Procesando su solicitud',
    done: '¡Ya terminó! Lo tomaremos de aquí.',
    working: 'Estamos trabajando en su solicitud',
    'email-type': 'correo electrónico',
    'sms-type': 'teléfono',
    'reach-out':
      'Una persona real y viva se comunicará con usted al {{contactNumber}} {{cpResponseTime}} con una actualización del estado de su solicitud',
    cancel:
      '¿No quiso solicitar el cambio? <span class="contact-prescriber-modal__cancel-link" tabindex="0">Cancela la solicitud.</span>',
    disclaimer:
      'Al proporcionar mi dirección de correo electrónico y/o mi número de teléfono y hacer clic en enviar, consiento expresamente que Rx Savings, LLC d/b/a Rx Savings Solutions me envíe correos electrónicos y se comunique conmigo por teléfono con respecto al servicio solicitado de contacto con el prescriptor.',
    'cancel-before-submitting': '¿No es la farmacia correcta?',
    'cancel-before-submitting-link': 'Cancele la solicitud y encuentre la correcta.',
    'step-1': '1',
    'step-1-header': 'Revise los cambios que solicita.',
    'step-2': '2',
    'step-2-header': '¿Cómo desea que nos comuniquemos con usted?',
    switchingPrescription: 'Cambiar su receta a:',
    prescriptionApproval: 'Su prescriptor ha aprobado el cambio de su receta a:',
    sendingToPharmacy: 'Y enviando a esta farmacia:',
    TransferringToPharmacy: 'Transfiriendo a esta farmacia:',
    prescriberApproval: 'Una vez que su prescriptor lo apruebe, cambiaremos su receta a:',
    sendToPharmacy: 'Y lo enviaremos a esta farmacia:',
    sentToPharmacy: 'Y lo enviaremos a esta farmacia:',
    TransferToPharmacy: 'Y lo transferiremos a esta farmacia:',
    TransferredToPharmacy: 'Transferido a esta farmacia:',
    takeAction:
      'Si aún no ha recibido noticias nuestras o necesita cancelar o cambiar la solicitud, llámenos al:',
    additionalHelp: 'Si necesita ayuda adicional, llámenos al:',
    pharmacySupportAvailability:
      'Nuestro equipo de soporte de farmacia está disponible de 7 a.m. a 8 p.m. hora central de lunes a viernes.',
    prescriptionChangeRequestCanceled: 'Se canceló su solicitud de cambio de receta',
  },
  'contact-prescriber-button': {
    contactPharmacy: 'Comunicarme con mi farmacia',
    contact: 'Comunicarme con mi prescriptor',
    transfer: 'Transferir mi receta',
    'order-online': 'Compra en línea',
    getItDelivered: 'Recibelo en casa',
  },
  'coverage-alert': {
    please: 'Lea antes de continuar.',
  },
  'family-signup': {
    verified:
      'Debido a la edad de este miembro de la familia, las leyes de privacidad nos impiden mostrarle en qué medicamentos {{name}} puede ahorrar. Si tiene más de 18 años, puede registrarse para obtener una cuenta para utilizar esta función.',
    activate: '¿Deberíamos pedirles que activen su cuenta?',
    emailOnBehalf:
      'Podemos enviarles un correo electrónico en su nombre. Simplemente ingrese la dirección de correo electrónico a continuación.',
    emailRequired: 'Dirección de correo electrónico requerida',
    ccMe: 'Enviarme CC a este correo electrónico.',
    messageSent:
      'Hemos enviado un mensaje a <span data-private>{{firstName}}</span> para usted, y debería llegar en cualquier momento. Este dependiente puede responderle a usted directamente con cualquier pregunta o comunicarse con el equipo de soporte de farmacia de Rx Savings Solutions al {{phone}} o respondiendo al correo electrónico.',
    email: 'Dirección de correo electrónico',
    button: 'Enviar correo electrónico',
    reSend: 'Intentar nuevamente.',
    notGoingThrough: '¿El correo electrónico no ha llegado?',
  },
  'mail-order': {
    warning: 'Podría ahorrar una cantidad adicional si cambia a la entrega a domicilio.',
  },
  'medicine-cabinet': {
    'condition-updated': 'Se actualizó la afección de su medicamento',
    'problem-associating-with-condition':
      'Hubo un problema al asociar su medicamento con una afección. Por favor inténtalo de nuevo',
    title: 'Botiquín',
    'maintenance-medications': 'Medicamentos de uso crónico',
    'short-term-medications': 'Medicamentos de uso a corto plazo',
    medications: 'Medicamentos',
    uncategorized: 'Medicamentos sin categoría',
    'condition-management': {
      'choose-label': 'Elija una condición',
      confirm: 'Confirmar',
      cancel: 'Cancelar',
      'categorize-link': 'Categorizar esto por condición',
      'categorize-label': '¿Quiere organizar mejor sus medicamentos?',
      treats: 'Beneficios: {{condition}}',
      edit: 'editar',
    },
    'actions-list': {
      title: 'Estos son los medicamentos que lleno en los últimos 6 meses. Usted puede',
      'item-1': 'Hacer clic en cada uno para ver lo que pagó por su último llenado',
      'item-2': 'Establecer recordatorios para tomarcelos a tiempo',
      'item-3': 'Crear una lista para su próxima visita al médico',
      'item-4': 'Solicite que le envíen los medicamentos disponibles',
    },
    titleSubheader: 'Basado en reclamaciones de los últimos seis meses.',
  },
  'mobile-search': {
    'norm-label': 'Número de {{label}}',
    required: '*Requerido',
  },
  'ndc-list': {
    header: 'Los códigos nacionales de  medicamentos se consideran clones genéricos',
  },
  'no-med-alert': {
    paragraphOne:
      'Parece que actualmente no está tomando ningún medicamento recetado. Cuando tenga ahorros disponibles en medicamentos, verá sus alertas aquí.',
    paragraphTwo:
      'Cuando lue le receten un medicamento o simplemente desee buscar el precio de un medicamento, siempre puede buscarlo utilizando el nombre del medicamento.',
  },
  'other-suggestions': {
    options: 'Otros equivalentes terapéuticos para foobar.',
  },
  'password-modal': {
    label: 'Contraseña',
  },
  'pharmacy-hours': {
    title: 'Horario de la farmacia',
    closed: 'Cerrado',
    'no-hours': 'No hay horario de farmacia disponible',
    'open-24-hours': 'Abierto 24 horas',
    'open-closes-at': 'Abierto - Cierra a {{closes}}',
    'closed-opens-at': 'Cerrado - Abre a {{opens}}',
    'closed-today': 'Cerrado hoy',
  },
  'pharmacy-item': {
    'mail-order': 'Farmacia de entrega a domicilio',
    'is-mail-order': 'PEDIDO POR CORREO ',
    'is-out-of-network': 'Fuera de la red',
    'day-supply': 'Trate un suministro de 90 días por correo',
    directions: 'Instrucciones',
    call: 'Llame',
    'home-delivery-shipping': 'Envíos convenientes a su domicilio',
    'free-shipping': 'Envíos convenientes a su domicilio',
    'cost-per-quantity': 'Cant.: {{quantity}} ({{formattedCostPerUnit}}/cada)',
    'cost-per-quantity-split': 'Cant.: {{quantity}} ({{formattedCostPerUnit}}/cada)',
    'cost-per-day': 'Días: {{daySupply}} ({{formattedCostPerDay}}/día)',
  },
  'pharmacy-summary': {
    note: 'Este es su precio estimado en {{pharmacyName}}',
  },
  'pharmacy-list': {
    choose: 'Elegir una farmacia',
    'show-more': 'Mostrar más farmacias',
  },
  'pharmacy-picker-body': {
    choose: 'Elija su farmacia preferida',
    voucher: 'Presente su cupón a su farmacéutico cuando surta su receta.',
    'step2-PS': 'Utilice los cupones a continuación para asegurarse de obtener el precio correcto.',
    step2:
      'Paso 2: Utilice los cupones a continuación para asegurarse de obtener el precio correcto.',
    'step2-Note':
      'NOTA: Con este cupón, la cantidad que pague no pagará su deducible a menos que envíe un reclamo a su compañía de seguro médico. Comuníquese con ellos para recibir instrucciones.',
    discountTitle: 'Solo descuento, sin seguro',
    discountTitleText:
      'Los descuentos están disponibles exclusivamente a través de las farmacias participantes. El rango de los descuentos variará dependiendo del tipo de medicamento y farmacia elegidos. Este programa no realiza pagos directamente a las farmacias. Los miembros deben pagar todos los servicios de atención médica. Puede cancelar su registro en cualquier momento o presentar una queja comunicándose con nosotros al (800) -268-4476.',
    insurance: 'Presente su tarjeta de seguro a su farmacéutico cuando surta su receta.',
    nonPreferred:
      '¿Quiere transferir su receta a {{pharmacyName}}? ¡Podemos enviar la solicitud en su nombre!',
    'mail-order':
      'Asegúrese de tener su tarjeta de seguro a mano para que {{pharmacyName}} pueda confirmar su información.',
    'mail-order-with-claim':
      '¿Quiere transferir su receta a {{pharmacyName}}? ¡Podemos enviar la solicitud en su nombre!',
    preferredPharm: 'Presente su tarjeta de seguro a su farmacéutico cuando surta su receta.',
  },
  'phase-details': {
    'prices-reflect': 'Los precios anteriores reflejan que',
    what: '¿Qué significa esto?',
  },
  'phase-details-modal': {
    title: '¿Qué son las fases del plan?',
    description:
      'El monto que paga por los medicamentos recetados depende, en parte, de la fase en la que se encuentre su plan médico/de medicamentos recetados. A continuación encontrará una explicación de cada fase.',
    records: 'Nuestros registros muestran que',
    what: '¿Qué significan las fases?',
  },
  'phase-select': {
    reflect: 'Los precios reflejan que es',
  },
  'powered-footer': {
    title: 'Impulsado por',
  },
  'prescriber-modal': {
    dear: 'Estimado prescriptor,',
    'your-patient':
      'Su paciente, <span data-private>{{firstName}} {{lastName}}</span>, está interesado en reducir los costos de sus medicamentos recetados. El empleador o plan de salud de su paciente se ha asociado con Rx Savings Solutions para proporcionar ahorros en medicamentos recetados. Nuestro objetivo es trabajar con usted para sugerirle posibles opciones de ahorro que tengan sentido para su paciente.',
    'as-prescriber':
      'Como prescriptor, sabemos que puede haber razones por las que se ha seleccionado un medicamento en particular. Solicitamos su consideración al evaluar la ventaja de costo-beneficio de las alternativas menos costosas.',
    provided:
      'Le hemos proporcionado a su paciente algunas sugerencias sobre cómo lograr ahorros en medicamentos recetados. A continuación, encontrará la sugerencia que más le interesa.',
    'we-reccommend':
      'Le recomendamos a <span data-private>{{firstName}}</span> cambiar de {{currentDrug}} a {{suggestedDrug}}, una {{suggestionLabel}} sugerencia.',
    contact:
      'Agradecemos su comentario sobre nuestras recomendaciones de ahorro de costos. No dude en comunicarse con Rx Savings Solutions al 1-800-268-4476 entre las 7 a.m. y las 8 p.m. CT con preguntas. También puede enviarnos un correo electrónico a Pharmacist@rxsavingssolutions.com.',
    gratefully: 'Agradecidamente,  Rx Savings Solutions',
    button: 'Ver la carta al prescriptor',
  },
  'prescription-info': {
    title: 'Información de la receta',
    'drug-dosage': 'Medicamentos y dosis',
    quantity: 'Cantidad',
    cost: 'Precio con descuento: {{cost}}',
    generated: 'Este cupón se generó el {{date, "MM/DD/YYYY"}}',
  },
  'prescription-details': {
    'prescription-details': 'Detalles de la receta',
    'drug-details': 'Detalles del medicamento',
    medicine: 'Medicinas',
    'you-paid': 'Usted pagó',
    'plan-paid': 'El plan pagó',
    'contact-prescriber-button': 'Vea su solicitud',
    'savings-available': 'Ahorros disponibles',
    'rewards-available': 'Recompensas disponibles',
    'pharmacy-information': 'Información de la farmacia',
    'cost-breakdown': 'Desglose de los costos',
    'negotiated-price': 'Precio negociado:',
  },
  'prices-section': {
    'total-cost': 'Costo total',
    'per-day-cost': 'Costo por día',
    'check-other-options': 'Vea otras opciones',
    'try-these': 'Pruebe uno de estos para ahorrar',
    'earn-rewards': '¡Obtenga una tarjeta de prepago Rx Rewards Visa<sup>®</sup> de ${{amount}}!',
    'purchased-on': 'Comprado en: {{date}}',
    'save-most-suggested': 'Sugerido para ahorrar más',
    collapse: 'Colapso',
    expand: 'Ver opciones',
    'adjust-search': 'Ajuste su búsqueda',
    'comparison-options': 'Opciones de comparación',
    'you-paid': 'Usted pagó:&nbsp;',
    'you-save': 'Usted ahorro: {{percentage}}%',
    'you-save-up-to': 'Usted ahorro: {{percentage}}% o más',
    'group-saves': 'El grupo ahorro: {{percentage}}%',
    'group-saves-up-to': 'El grupo ahorro: {{percentage}}% o más',
    'group-paid': 'Su grupo pagó: {{cost}}',
    'plan-paid': 'El plan pagó: {{cost}}',
    'your-cost': 'Su costo',
    'as-low-as': 'Tan bajo como',
    'as-low-as-cost': '&nbsp;{{cost}}',
    'with-insurance': 'Su costo con el seguro',
    'with-coupon': 'con cupón',
    'read-more': 'Lea más',
    close: 'Cerrar',
    'dr-green': 'Dr. Jeff Green',
    'dr-green-title': 'Farmacéutico clínico',
    'dr-green-says': 'Dr. Green dice: {{note}}',
    'msg-from-pharmacist': 'Un mensaje de nuestro farmacéutico ...',
    'mobile-navigation': {
      'savings-results': 'Resultados de ahorro',
      'search-results': 'Resultados de la búsqueda',
      'pick-pharmacy': 'Elija una farmacia',
      'pharmacy-info': 'Información de la farmacia',
    },
    quantity: 'Cantidad:',
    retail: 'Minorista',
    'mail-order': 'Correo',
    cta: {
      'pricing-button': 'Compare los precios de la farmacia',
      'pricing-continue': 'Continuar',
      'see-details': 'Ver los detalles',
      'no-price': '<span>N/A</span>',
      'drug-search-retail-header':
        'Para obtener el mejor precio en esta opción, deberá elegir una farmacia.',
      'not-covered': 'No está cubierto por el seguro',
      'no-pricing-available':
        '<span>El precio no se encuentra disponible en este momento. Para recibir ayuda, llame al <strong><a href="tel:18002684476">1-800-268-4476</a></strong>.</span>',
      'mail-order-header': '{{productName}} está disponible en su farmacia de entrega a domicilio.',
      'mail-order-note':
        'Para obtener esta receta con entrega a domicilio, pagará más por adelantado, pero ahorrará dinero a largo plazo y tendrá que volver a surtirla con menos frecuencia.',
      'coupon-title':
        '¿Quiere utilizar un cupón para reducir el costo de {{productName}} en una de sus farmacias locales?',
      'coupon-note':
        'NOTA: Cuando utilice un cupón, la cantidad que pague no se aplicará al deducible de su seguro a menos que presente un reclamo a su proveedor de seguro médico. Comuníquese con ellos para determinar la forma correcta de presentar su reclamo.',
      'mfg-header':
        'Para aprovechar la tarjeta del fabricante de {{productName}}, deberá registrarse y descargar su tarjeta en el enlace a continuación.',
      'mfg-button': 'Ir a la tarjeta de fabricante',
      'mfg-notes':
        'NOTA: Tenga a mano los detalles de la tarjeta de ahorros, ya que deberá mostrárselos a su farmacéutico.',
      'retail-suggestion-title':
        '¿Quiere que hablemos con {{doctor}} sobre esta opción para usted?',
      'retail-suggestion-title-doctor-fallback':
        '¿Quiere que hablemos con su médico sobre esta opción para usted?',
      'rewards-header':
        'Esta opción califica para la tarjeta de prepago Visa Rx Rewards de ${{amount}}. Es posible que el monto que pague no cambie, pero el cambio reducirá el costo total de los medicamentos recetados de su plan.',
    },
    'pbm-max-title': '${{amount}} o menos',
    'see-details': 'Ver los detalles',
    'rewardable-header':
      'Esta opción califica para la tarjeta de prepago Visa® Rx Rewards de ${{amount}}.',
  },
  'product-details': {
    'you-pay': 'Usted pagó',
    cost: 'Costo',
    'pharmacy-picker': 'Selector de farmacia',
    'search-results': 'Resultados de la búsqueda',
    'alert-options': 'Opciones de alerta',
  },
  'product-info': {
    what: '¿Qué es {{product}}?',
    'no-details-header': 'No se encontró información sobre {{product}}',
    'no-details-body':
      'Lo sentimos, pero no pudimos encontrar ninguna información sobre {{product}} en este momento.',
  },
  profile: {
    header: 'Configuraciones de usuario',
    'user-header': 'Información de contacto y dirección de residencia',
    'user-mobile-contact': 'Información de contacto',
    'user-mobile-home': 'Dirección de residencia',
    'dependent-header': 'Dependientes',
    'password-header': 'Cambiar contraseña',
    'notification-header': 'Reciba notificaciones de ahorros a través de:',
    'user-info': {
      'mobile-label': 'Número de teléfono celular',
      'email-label': 'Dirección de correo electrónico',
      'street-label': 'Dirección física',
      'city-label': 'Ciudad',
      'state-label': 'Estado',
      'zip-label': 'Código postal',
      'password-label': 'Contraseña',
      'update-button': 'Actualizar su información',
      'contact-button': 'Actualizar la información de contacto',
      'address-button': 'Actualizar dirección',
    },
    'password-update': {
      'update-button': 'Actualizar contraseña',
      'cancel-button': 'Cancelar actualización',
      'rules-header': 'Su contraseña debe tener cada uno de los siguientes elementos:',
      'lowercase-rule': 'Un carácter en minúscula',
      'uppercase-rule': 'Un carácter en mayúscula',
      'special-char-rule': 'Un número o carácter especial',
      'eight-char-rule': 'Debe contener al menos 8 caracteres',
    },
    'lightswitch-email': 'Correo electrónico:',
    'lightswitch-sms': 'Mensaje de texto/SMS:',
    'success-messages': {
      email: 'Su correo electrónico ha sido actualizado.',
      mobile: 'Su número de celular ha sido actualizado.',
      password: 'Su contraseña ha sido actualizada.',
      address: 'Su dirección ha sido actualizada',
    },
    privacy: {
      currentlyAllow:
        'Actualmente, usted permite que <span data-private>{{name}}</span> vea su perfil.',
      withdraw:
        'Si retira su permiso, <span data-private>{{name}}</span> ya no podrá ver su perfil ni sus medicamentos.',
      'withdraw-permission': 'Retirar el permiso',
      close: 'Cerrar',
      wouldYouLike: '¿Le gustaría permitir que <span data-private>{{name}}</span> vea su perfil?',
      withYourPermission:
        'Con su permiso, <span data-private>{{name}}</span> puede ver qué medicamentos toma ahora o en el futuro, y si hay opciones más económicas disponibles.',
      readAndAgree: 'He leído y estoy de acuerdo con los',
      terms: 'términos y condiciones.',
      seeYourMeds: 'vea su perfil',
      mustAgree:
        'Debe aceptar los términos y condiciones antes de poder otorgar permiso para ver su perfil.',
      shareSuccess:
        'Su configuración se ha actualizado. <span data-private>{{name}}</span> ahora puede ver su perfil.',
      revokeSuccess:
        'Su configuración se ha actualizado. Ahora <span data-private>{{name}}</span> no puede ver su perfil.',
      allowFamily: 'Permitir que estos miembros de la familia vean mi perfil',
      disclaimer:
        'Advertencia: los dependientes menores no pueden ver su perfil y medicamentos porque los menores no pueden registrarse para obtener una cuenta.',
    },
  },
  reminders: {
    'no-email': 'No tiene un correo electrónico registrado. Por favor agregue uno en su',
    'no-phone': 'No tiene un número de teléfono celular registrado. Por favor agregue uno en su',
    'home-title': 'Recordatorios de medicamentos',
    am: 'a.m.',
    pm: 'p.m.',
    text: 'SMS/Texto',
    push_notifications: 'Notificaciones emergentes',
    email: 'Correo electrónico',
    time: 'Tiempo',
    'sms-title': 'Texto',
    'email-title': 'Correo electrónico',
    'reminder-time': 'Tiempo del recordatorio',
    'when-to-remind': '¿Cuándo le gustaría recibir el recordatorio de su medicamento?',
    morning: 'Mañana',
    afternoon: 'Tarde',
    evening: 'Al final de la tarde',
    night: 'Noche',
    'repeats-daily': 'Repetir todos los días',
    medications: 'Medicamentos',
    'which-medications':
      'Elija los medicamentos para los que le gustaría recibir un recordatorio. (Seleccione todos los que correspondan)',
    'communication-preferences': 'Preferencias de comunicación',
    'communication-preference-header':
      'Seleccione su preferencia de comunicación para sus recordatorios',
    'no-reminders': 'Aún no ha creado ningún recordatorio. ¡Establezca uno a continuación!',
    'current-reminders-header': 'Sus recordatorios actuales',
    welcome:
      'Bienvenido a los recordatorios de medicamentos, un recurso útil para mantenerlo al día con sus recetas. Solo configúrelo y olvídelo, ¡se lo recordaremos!',
    weekdays: {
      sunday: 'D',
      monday: 'L',
      tuesday: 'Ma',
      wednesday: 'Mi',
      thursday: 'J',
      friday: 'V',
      saturday: 'S',
    },
    'weekdays-long': {
      sunday: 'Dom',
      monday: 'Lun',
      tuesday: 'Mar',
      wednesday: 'Mié',
      thursday: 'Jue',
      friday: 'Vie',
      saturday: 'Sab',
    },
    daily: 'Diario',
    'notification-settings': 'Configuración de las notificaciones',
    'next-reminder-set': 'Próximo recordatorio establecido para {{next}}',
    'next-reminder-today': 'hoy a las {{time}}',
    'no-meds':
      'Actualmente no tiene recetas en nuestro sistema. La adherencia es fundamental para muchos medicamentos, así que recuerde esta característica cada vez que le receten un medicamento que deba tomarse en un horario específico.',
    'no-meds-header': 'No necesita recordatorios',
    edit: 'Editar',
    save: 'Guardar',
    add: 'Agregar',
    'days-error': 'Debe seleccionar al menos un día',
    'medication-error': 'Debe seleccionar al menos un medicamento',
    'add-another': 'Crear recordatorios',
    'create-reminder': 'Establecer hora de recordatorio',
    'update-reminder': 'Actualizar la hora del recordatorio',
    'set-medications': 'Establecer medicamentos',
    'update-medications': 'Guardar los cambios',
    cancel: 'Cancelar',
    'set-preferences': 'Establecer preferencias',
    on: 'Encender',
    off: 'Activar',
    delete: 'Desactivar',
    'update-sms': 'actualizar número',
    'set-sms': 'página de perfil',
    'update-email': 'actualizar correo electrónico',
    'set-email': 'página de perfil',
    'edit-reminder': 'Editar',
    'duplicate-error': 'Ya ha creado una alerta con los mismos horarios, fechas y medicamentos',
    'email-error': 'Ingrese una dirección de correo electrónico válida',
    'sms-error': 'Ingrese un número de teléfono válido',
    'communication-preferences-updated':
      'Se actualizaron sus preferencias de comunicación. Para ajustar su configuración, haga clic en el enlace de preferencias de comunicación.',
    'reminders-list-updated-successfully':
      'Su lista de recordatorios se ha actualizado correctamente',
    'communication-preferences-sms-disclaimer':
      'Al habilitar el SMS, está dando su consentimiento para recibir alertas móviles de Rx Savings Solutions. Se pueden aplicar tarifas por mensajes y datos. Envíe el mensaje de texto STOP para cancelar, envíe HELP para obtener ayuda.',
    'report-card': {
      'your-report-card': 'Su reporte de recordatorios',
      month: 'Este mes',
      all: 'Todo el tiempo',
      community: 'Comunidad',
      'this-week': 'Esta semana',
      oops: 'Parece que omitió algunos medicamentos el {{day}}, {{date}}.',
      success: '¡Buen trabajo! Tomó su dosis {{plural}} el {{day}}, {{date}}',
      'oops-message': '¿Olvidó confirmar que los tomó? Márquelos a continuación.',
      na: 'N/A',
      update: 'actualizando',
      fetch: 'extrayendo',
      'error-header': '¡Somos nosotros, no usted!',
      error:
        'Perdón. Hubo un problema {{task}} sus registros. Actualice la página y vuelva a intentarlo. Si eso no funciona, llame al {{number}} y una persona en vivo puede ayudarlo.',
    },
  },
  'Remove-Alert-Survey': {
    banner: 'Ya no verá ahorros para {{productName}}.',
    'question-header': '¿Por qué no quiere ver más esta alerta?',
    'question-item-one': 'Demasiados correos electrónicos',
    'question-item-two': 'Irrelevante',
    'question-item-three': 'No quiero cambiar mi receta',
    'question-item-four': 'Otro',
    'thank-you':
      '¡Gracias por sus comentarios! Si desea verificar los ahorros en {{productName}} en el futuro, puede realizar una búsqueda arriba o llamar a nuestro equipo de soporte de farmacia al 1-800-268-4476.',
  },
  'search-bar': {
    search: 'Buscar',
    'search-again': 'Buscar nuevamente',
    clear: 'Cancelar',
    'no-result': 'No hay resultados disponibles en este momento.',
    'try-different': 'Pruebe con una escritura diferente o busque un medicamento diferente.',
  },
  'pill-toggle': {
    left: 'Costo total',
    right: 'Costo por día',
  },
  'suggestion-item': {
    from: 'Tan bajo como {{price}} por volver a surtir',
    save: 'Ahorre {{price}} por surtido',
    saveEmployer: 'Guarde su grupo {{price}} por surtido',
    requestFrom: 'Solicite estos medicamentos por tan solo {{price}} por surtido',
    requestSave: 'Solicite estos medicamentos y ahorre {{price}} por surtido',
  },
  'suggestion-item-combo-split': {
    header: 'Solicite estos medicamentos',
    'as-low-as': 'Tan bajo como {{price}} para todo',
  },
  'suggestion-list-wrapper': {
    disclaimer:
      'Estos precios son estimaciones personalizadas y pueden no reflejar el precio total real. Las estimaciones se basan en su plan médico/de medicamentos recetados al día de hoy. Sin embargo, el costo real puede diferir debido a los descuentos o cargos que pueda aplicar una farmacia en particular. No debe depender exclusivamente de esta estimación de precio o de cualquier estimación de precio únicamente para tomar sus decisiones de compra de atención médica.',
    pharmacist_disclaimer:
      'Cuando Rx Savings Solutions sugiere medicamentos de venta libre con el propósito de ahorrar, es solo con fines informativos y no pretende sustituir el asesoramiento médico/farmacéutico profesional. Rx Savings Solutions no brinda asesoramiento médico/farmacéutico profesional, sino que brinda a los consumidores información sobre ahorros de costos. No debe confiar en Rx Savings Solutions como alternativa al consejo médico de un prescriptor calificado. La confianza en cualquier sugerencia es bajo su propio riesgo y Rx Savings Solutions no será responsable de ninguna pérdida y/o daño resultante. Rx Savings Solutions le recomienda encarecidamente que consulte con su médico antes de suspender el uso de un medicamento recetado y/o antes de utilizar un sustituto de venta libre. Si no obtiene asesoramiento médico/farmacéutico profesional antes de utilizar un medicamento de venta libre, revise el contenido de la "Información sobre el medicamento" del medicamento antes de ingerirlo. Rx Savings Solutions le recomienda encarecidamente que visite el sitio web de la Administración de Alimentos y Medicamentos para "Comprar y utilizar medicamentos de manera segura" antes de utilizar cualquier medicamento de venta libre. Rx Savings Solutions no respalda ningún medicamento de venta libre específico mencionado.',
    pharmacist_note_disclaimer:
      'Las notas del farmacéutico contenidas en este documento son solo para fines informativos. Los farmacéuticos clínicos de Rx Savings Solutions ™ no brindan ningún consejo clínico o médico y la información proporcionada no debe interpretarse ni utilizarse como tal. Nada de lo contenido en las notas del farmacéutico tiene la intención de crear una relación farmacéutico-paciente o de reemplazar los servicios de un farmacéutico autorizado. No debe depender únicamente de lo contenido en las Notas del farmacéutico, y debe consultar a un médico con licencia en todos los asuntos relacionados con su salud. Mediante el uso de esta información, usted acepta que no tomará ninguna decisión relacionada con la salud o médica basada en todo o en parte en cualquier contenido de las notas del farmacéutico.',
  },
  'claims-suggestion-list-wrapper': {
    unavailable: '¿Sin ahorros? Podemos explicarlo.',
    contact:
      'Ocasionalmente, un cambio en su plan de salud, el estado del deducible o el precio de los medicamentos no genera oportunidades de ahorro. Pedimos disculpas. Nuestro equipo de apoyo de farmacia está disponible de lunes a viernes, de 7 a.m. a 8 p.m. CT para encontrar el precio más reciente y más bajo para usted: 1-800-268-4476 o support@rxsavingssolutions.com.',
  },
  'search-result-modal': {
    adjust: 'Ajuste su búsqueda',
  },
  voucher: {
    'customer-call': 'Llame a Rx Savings Solutions al',
  },
  'voucher-header': {
    print: 'Imprimir',
    email: 'Correo electrónico',
    text: 'Texto',
  },
  'voucher-info-list': {
    generated: 'Este cupón se generó el {{date}}',
    'helpful-note': 'Dele esto a farmacéutico.',
    'price-based': 'Precio basado en la NDC: {{ndc}}',
    'coupon-question': '¿Tiene alguna pregunta sobre este cupón?',
  },
  'voucher-list': {
    'singular-title': 'Cupón de ahorro',
    'plural-title': 'Cupones de ahorro',
  },
  'voucher-modal': {
    confirm: 'Confirme el {{title}} al que desea enviar el cupón.',
    success: 'Su cupón de ahorro se ha enviado a la {{alert}} anterior.',
    sendIt: '¡Mándalo!',
  },
  questions: {
    header: '¿Podemos ayudarle?',
    text: 'Nuestro equipo de soporte de farmacia está disponible de 7 a.m. a 8 p.m. hora central de lunes a viernes en:',
  },
  'recent-searches': {
    header: 'Búsquedas recientes',
    search: 'Busque un medicamento arriba para encontrar ahorros potenciales.',
  },
  search: {
    placeholder: 'Búsqueda de un medicamento',
    open: 'Búsqueda de un medicamento',
    noResults:
      'No hay resultados disponibles en este momento. Pruebe con una escritura diferente o busque un medicamento diferente.',
  },
  'search-field': {
    error: '*Requisito',
    'norm-label': 'Número de {{item}}',
    dosage: 'Dosis',
    zip: 'Código postal',
    form: 'Formulario',
    submit: 'Búsqueda',
    cancel: 'Cancelar',
  },
  'contact-modal': {
    return: 'Volver a {{location}}',
    markup:
      '"¿Tiene alguna pregunta o necesita ayuda con sus medicamentos? Llame a nuestros técnicos farmacéuticos certificados locales al:',
    help: '¿Cómo podemos ayudarle?',
    label: 'O puede enviarnos un mensaje a continuación',
    submit: 'Envíe su mensaje',
    success: '¡Gracias por contactarnos! Nos pondremos en contacto con usted pronto.',
    fail: 'Ingrese un mensaje para nuestro equipo de soporte de farmacia.',
  },
  'disappearing-alert-modal': {
    title: '¡Rayos! Esta oportunidad de ahorro ya no está disponible.',
    explanation:
      '¿Qué ha ocurrido? Ya sea que el precio del medicamento haya cambiado, que haya entrado en un nuevo "año de plan" del seguro médico o que esté en una fase de deducibilidad diferente.',
    reminder:
      'Recuerde que puede encontrar una opción más económica para cualquier medicamento, utilizando la función de búsqueda en la parte superior de la pantalla.',
    close: 'Cerrar',
    'icon-alt': 'Icono de lupa',
  },
  'account-members': {
    'your-meds': 'Sus medicamentos',
    'name-meds': "{{name}}'s medicamentos",
    'member-select': 'Medicamentos para:',
    alerts: 'Alertas de ahorro',
    'over-eighteen':
      'Debido a la edad de este dependiente, las leyes de privacidad nos impiden mostrarle en qué medicamentos puede ahorrar <span data-private>{{name}}</span>. Si tiene más de 18 años, puede registrarse para obtener una cuenta para utilizar esta función.',
    'no-alerts-or-claims':
      'Parece que no ha tomado ningún medicamento en los últimos 6 meses. Los medicamentos para sus dependientes menores de 18 años se pueden revisar haciendo clic en las pestañas de arriba.',
    'under-eighteen':
      'Debido a la edad de este dependiente, las leyes de privacidad nos impiden mostrarle sus medicamentos o alertas de ahorro.',
  },
  'savings-alert': {
    button: 'Ver todas las opciones de ahorro',
    buttonMobile: 'Ahorre {{amount}} por surtido',
    'buttonMobile-up-to': 'Ahorre {{amount}} o más por surtido',
    'no-savings-button': 'Ver opciones',
    foundSavings: '¡Ahorre hasta {{amount}} por surtido!',
    'foundSavings-up-to': '¡Ahorre {{amount}} o más por surtido!',
    foundChildSavings: '¡Encontramos ahorros para <span data-private>{{name}}</span>!',
    paid: 'Ahorre {{amount}} por surtido',
    alreadySaving: 'La última vez pagó {{amount}} el {{date}}',
    savingsSuggestionHeader: 'Elija una sugerencia de ahorro.',
    saveMore: 'Pruebe uno de estos para ahorrar más',
    unsavingsPaid: 'Ahorro total {{amount}} por surtido',
    unsavingsButton: 'Ver todas las opciones de ahorro',
    unsavingsButtonMobile: 'Guarde su grupo {{amount}} por surtido',
    unsavingsBodyHeader: '¡Gane una tarjeta de prepago Visa<sup>®</sup> Rx Rewards de ${{amount}}!',
    childUnsavingsBodyHeader:
      '¡<span data-private>{{name}}</span> puede ganar una tarjeta de prepago Visa<sup>®</sup> Rx Rewards de ${{amount}}!',
    unsavingsBodyFooter:
      'Por cambiar a una sugerencia de menor costo, recibirá una tarjeta de prepago Visa<sup>®</sup> Rx Rewards de ${{amount}}. Úselo en farmacias, tiendas de comestibles y almacenes para ayudar con los gastos médicos fuera de su bolsillo.',
    childUnsavingsBodyFooter:
      'Por cambiar a una sugerencia de menor costo, <span data-private>{{name}}</span> recibirá una tarjeta de prepago Rx Rewards Visa<sup>®</sup> de ${{amount}}. Úselo en farmacias, tiendas de comestibles y almacenes para ayudar con los gastos médicos fuera de su bolsillo.',
    unsavingsIconAltText: 'frasco de medicamento recetado y una tarjeta de regalo de $100',
    'unsavingsButtonMobile-up-to': 'Ahórrele a su grupo {{amount}} o más por surtido',
    unsavingsSuggestionHeader: 'Elija una sugerencia de ahorro.',
    unsavingsSuggestionBody:
      'Es posible que sus ahorros fuera de bolsillo sean limitados en este momento. Elegir una alternativa de menor costo ahora puede ahorrarle dinero en el futuro y ayudar a evitar que sus primas de salud aumenten.',
    bestSavingsSuggestionHeader: '¡Ahorre hasta {{amount}} por surtido!',
    'bestSavingsSuggestionHeader-up-to': '¡Ahorre {{amount}} o más por surtido!',
    bestSavingsSuggestionButtonText: 'Ver la mejor opción de ahorro',
    bestSavingsSuggestionLinkTextDesktop: 'Ver todas las opciones de ahorro',
    bestSavingsSuggestionLinkTextMobile: 'Mostrar todas las opciones de ahorro',
  },
  'mail-order-alert': {
    header: 'Recíbalo y ahorra {{amount}} en total.',
    button: 'Ver la opción de ahorro',
    details: {
      header: 'Cambie a un suministro de 90 días para ahorrar',
      'header-same-day': 'Cambie a entrega a domicilio para ahorrar',
      'mobile-header': 'Pruebe la entrega a domicilio para ahorrar',
      'footer-no-cp':
        'Su siguiente paso: para obtener esta receta en su farmacia de entrega a domicilio, llame a {{pharmacyName}} al {{number}}.',
      'footer-for-cp': '¿Podemos transferir esta receta a domicilio para usted?',
    },
    quantity: 'Cantidad',
    'current-retail': 'Farmacia minorista actual',
    'home-delivery': 'Farmacia de entrega a domicilio',
    'or-less': ' o menos',
  },
  'available-dependent-alert': {
    save: 'Ahorre {{amount}} por surtido',
    'save-up-to': 'Ahorre {{amount}} o más por surtido',
  },
  'refill-reminder': {
    noSavings: {
      header: 'Casi es momento de surtir su medicamento.',
      body: 'Según nuestros datos de reclamaciones, la última vez que surtió {{drug}} fue el {{date}}. Esto significa que debe volver a surtirlo.',
    },
    memberSavings: {
      header: '¡Puede ahorrar en su próximo surtido!',
      body: 'Su receta de {{drug}} debe surtirse Y puede ahorrar dinero en su receta.',
    },
    clientSavings: {
      header: '¡Ayude a su grupo a ahorrar en su próxima llenada!',
      body: 'Su receta de {{drug}} deber surtirse. Probar una opción más económica le ayudará a todos los miembros de su grupo.',
      footer:
        '¿Quiere ser el mejor jugador de equipo? Si actúa de acuerdo con esta alerta de ahorro, ayudará a mantener bajas las primas de salud para usted y sus compañeros de trabajo.',
    },
  },
  'alert-list': {
    'empty-state': {
      'header-primary': '¡Dónde estón mis ahorros?',
      'header-dependant': '¿Dónde estón los ahorros de {{dependant}}?',
      'body-primary': {
        'line-1':
          'Es posible que ya esté ahorrando todo lo que pueda. Es posible que le hayamos enviado un mensaje de ahorro, pero no lo ve aquí porque ya no está activo. Esto puede suceder cuando hay cambios en los precios de los medicamentos, el a´no del plan o el deducible. Para obtener más información, por favor llame a nuestro equipo de soporte de farmacia al <a href="tel:18002684476">1-800-268-4476.</a>',
        'line-2':
          'Siempre puede encontrar el precio más bajo para cualquier medicamento utilizando la función de Buscar medicamento en la parte superior de la pantalla. Para obtener una lista de sus medicamentos activos, visite su gabinete de medicamentos ',
      },
      'body-dependant': {
        'line-1':
          'Es posible que {{dependant}} ya esté ahorrando todo lo que puede. Es posible que le hayamos enviado a su familia un mensaje de ahorro, pero no lo ve aquí porque ya no está activo. Esto puede suceder cuando hay cambios en los precios de los medicamentos, el a´no del plan o el deducible. Para obtener más información, por favor llame a nuestro equipo de soporte de farmacia al <a href="tel:18002684476">1-800-268-4476.</a>',
        'line-2':
          'Siempre puede encontrar el precio más bajo para cualquier medicamento utilizando la función de Buscar medicamento en la parte superior de la pantalla. Para obtener una lista de los medicamentos activos de {{dependant}}, visite el gabinete de medicamentos de {{dependant}} ',
      },
      'link-text': 'gabinete de medina.',
      'icon-alt-text': 'Icono de lista de ahorros vacía',
    },
    congrats: '¿Dónde están mis ahorros?',
    congratsChild: '¿Dónde están los ahorros de {{child}}?',
    generated: 'Generado el: {{date}}',
    'no-alerts': 'No tiene alertas en este momento.',
    disclaimer:
      'Los montos de ahorro son precisos en el momento en que se genera cada alerta y pueden aumentar o disminuir con el tiempo debido a cambios en su plan de salud, el estado del deducible o el precio de mercado del medicamento.',
    loader:
      'Estamos revisando las oportunidades de ahorro para usted (los resultados pueden demorar un par de segundos)',
    heading: 'Alerta de ahorro',
    'no-alerts-1':
      'Parece que actualmente no está tomando ningún medicamento recetado. Cuando tenga ahorros disponibles en medicamentos, verá sus alertas aquí.',
    'no-alerts-2':
      'Cuando le receten un medicamento o simplemente desee buscar el precio de un medicamento, siempre puede buscar usando el nombre del medicamento.',
    'cabinet-message':
      'Si tiene una alerta de ahorro, la encontrará aquí. Siempre puede consultar sugerencias de ahorro y precios actualizadas (los precios de los medicamentos cambian con frecuencia) mediante la función "Buscar un medicamento" en la parte superior de la pantalla. Para conocer todos sus medicamentos activos, visite su',
    'cabinet-messageChild':
      'Si {{child}} tiene una alerta de ahorro, la encontrará aquí. Para conocer todos sus medicamentos, visite {{child}}',
    'cabinet-link': 'botiquín',
    'cabinet-linkChild': 'botiquín',
  },
  'ie10-error': {
    markup:
      'Navegador desactualizado: el navegador que está utilizando no es totalmente compatible con Rx Savings Solutions. Haga clic aquí para actualizar su navegador.',
  },
  'manufacturer-card': {
    header: 'Tarjeta del fabricante de {{drug}}',
    description:
      'Para aprovechar la tarjeta del fabricante de {{drug}} {{form}}, deberá registrarse y descargar su tarjeta en el enlace a continuación.',
    button: 'Ir a la tarjeta de fabricante',
    footer:
      'Tenga a mano los detalles de la tarjeta de ahorro, ya que deberá mostrárselos a su farmacéutico.',
  },
  'search-results': {
    'product-header': 'Resultados de búsqueda para',
    'search-button': 'Ajuste su búsqueda',
    'return-to-text': 'Volver a resultados de ahorro',
    'no-therapy': '¿Por qué es esta la única opción de terapia en la lista?',
    'no-therapy__explaination':
      'Esta es actualmente la opción de terapia de menor costo disponible.',
    'quick-call':
      'Una llamada rápida a nuestro equipo de asistencia de farmacia al {{contactNumber}} le proporcionará el precio más actualizado u otras terapias disponibles.',
    'see-pricing': '¿Aún desea ver los precios de otras terapias potenciales?',
    'let-know': 'Infórmenos',
  },
  'suggestion-wrapper': {
    TE: 'Necesitará una nueva receta para el equivalente terapéutico, {{suggestedDrug}}. Use la carta en el enlace a continuación para ayudar a guiar su conversación con su prescriptor.',
    'TE-Coupon': 'Paso 1: necesitará una nueva receta para {{suggestedDrug}}. ',
    TA: 'Necesitará una nueva receta para el equivalente terapéutico, {{fsuggestedDrug}}. Use la carta en el enlace a continuación para ayudar a guiar su conversación con su prescriptor.',
    'TA-Coupon':
      'Paso 1: necesitará una nueva receta para el equivalente terapéutico, {{suggestedDrug}}. ',
    GS: {
      details:
        'Para tomar un genérico de {{currentDrug}}, hable con su farmacéutico sobre la posibilidad de tomar un genérico.',
      detailsWithClaim:
        'Obtener una nueva receta para el genérico de {{currentDrug}} es más fácil de lo que cree. ¡Incluso podemos enviar la solicitud en su nombre! O, para hacer el cambio usted mismo, simplemente pídale a su farmacéutico el genérico.',
    },
    'GS-Coupon': {
      details:
        'Paso 1: para tomar un genérico de {{currentDrug}}, hable con su farmacéutico sobre la posibilidad de tomar un genérico.',
      detailsWithClaim:
        'Obtener una nueva receta para el genérico de {{currentDrug}} es más fácil de lo que cree. ¡Incluso podemos enviar la solicitud en su nombre! O, para hacer el cambio usted mismo, simplemente pídale a su farmacéutico el genérico.',
    },
    GC: {
      details:
        'Para tomar el clon genérico de {{currentDrug}}, hable con su farmacéutico sobre la posibilidad de tomar el clon genérico. Asegúrese de mostrarles la lista de NDC (códigos nacionales de medicamentos) a continuación que se consideran clones genéricos. Es importante que obtenga uno de estos NDC si desea recibir los mismos ingredientes activos e inactivos que el producto de marca.',
      'ndc-text': 'Los códigos nacionales de medicamentos se consideran clones genéricos',
      detailsWithClaim:
        'Obtener una nueva receta para el clon genérico de {{currentDrug}} es más fácil de lo que cree. ¡Incluso podemos enviar la solicitud en su nombre! O, para hacer el cambio usted mismo, simplemente dé a su farmacia uno de los NDC (códigos nacionales de medicamentos) a continuación para asegurarse de obtener los mismos ingredientes activos e inactivos que el medicamento de marca.',
    },
    'GC-Coupon': {
      details:
        'Paso 1: para tomar el clon genérico de {{currentDrug}}, hable con su farmacéutico sobre la posibilidad de tomar el clon genérico. Asegúrese de mostrarles la lista de NDC (códigos nacionales de medicamentos) a continuación que se consideran clones genéricos. Es importante que obtenga uno de estos NDC si desea recibir los mismos ingredientes activos e inactivos que el producto de marca.',
      'ndc-text': 'Los códigos nacionales de medicamentos se consideran clones genéricos',
      detailsWithClaim:
        'Obtener una nueva receta para el clon genérico de {{currentDrug}} es más fácil de lo que cree. ¡Incluso podemos enviar la solicitud en su nombre! O, para hacer el cambio usted mismo, simplemente dé a su farmacia uno de los NDC (códigos nacionales de medicamentos) a continuación para asegurarse de obtener los mismos ingredientes activos e inactivos que el medicamento de marca.',
    },
    DF: 'Necesitará una nueva receta para la forma {{suggestedDrug}} a {{currentDrug}}. Use la carta en el enlace a continuación para ayudar a guiar su conversación con su prescriptor.',
    'DF-Coupon':
      'Paso 1: Necesitará una nueva receta para {{suggestedDrug}} en la forma de {{currentDrug}}.',
    CS: 'Necesitará una nueva receta para tomar cada ingrediente activo individualmente, {{suggestedDrug}}. Use la carta en el enlace a continuación para ayudar a guiar su conversación con su prescriptor.',
    'CS-Coupon':
      'Paso 1: Necesitará una nueva receta para tomar cada ingrediente activo individualmente, {{suggestedDrug}}.',
    TS: '<p class="contact-prescriber__tablet-split">Nuestros farmacéuticos revisaron esta opción de medicamento y la consideran segura y eficaz. Simplemente divida a mano a lo largo de las líneas marcadas. Si no hay líneas marcadas, puede comprar un divisor de tabletas de bajo costo en su farmacia local.</p> Necesitará una nueva receta para {{suggestedDrug}} con nuevas instrucciones para tomar media tableta (administrando la misma dosis a su cuerpo). Use la carta en el enlace a continuación para ayudar a guiar su conversación con su prescriptor.',
    'TS-Coupon':
      '<p class="contact-prescriber__tablet-split">Nuestros farmacéuticos revisaron esta opción de medicamento y la consideran segura y eficaz. Simplemente divida a mano a lo largo de las líneas marcadas. Si no hay líneas marcadas, puede comprar un divisor de tabletas de bajo costo en su farmacia local.</p> <strong>Paso 1: </strong> Necesitará una nueva receta para {{suggestedDrug}} con nuevas instrucciones para tomar media tableta (administrando la misma dosis a su cuerpo).&nbsp;',
    PS: 'A veces, las farmacias pueden variar en costo. Elija una opción de farmacia de la lista de la izquierda que mejor equilibre sus necesidades de costo y atención. Presente el cupón a continuación a su farmacéutico cuando llene la receta.',
    DS: 'Necesitará una nueva receta para obtener {{suggestedDrug}}, que puede tomar en mayor cantidad para lograr la misma dosis y ahorrar dinero. Simplemente haga clic en el enlace a continuación para obtener una carta para enviar o utilizar cuando hable con su prescriptor.',
    CE: 'Necesitará una nueva receta para tomar {{suggestedDrug}} individualmente y ahorrar dinero. Simplemente haga clic en el enlace a continuación para obtener una carta para enviar o utilizar cuando hable con su prescriptor.',
    'DS-Coupon':
      'Paso 1: Necesitará una nueva receta para obtener {{suggestedDrug}}, que puede tomar en mayor cantidad para lograr la misma dosis y ahorrar dinero. Simplemente haga clic en el enlace a continuación para obtener una carta para enviar o utilizar cuando hable con su prescriptor.',
    'CE-Coupon':
      'Paso 1: Necesitará una nueva receta para tomar {{suggestedDrug}} individualmente y ahorrar dinero. Simplemente haga clic en el enlace a continuación para obtener una carta para enviar o utilizar cuando hable con su prescriptor.',
    'suggestion-Step1': 'Utilizar esta carta',
    'suggestion-Step2': 'para ayudar a guiar su conversación con su médico.',
  },
  'suggestion-details': {
    testing: {
      title: '¿Qué es un TESTING PLS IGNOAR?',
      text: 'Esta es una prueba del sistema de transmisión de emergencia! 1!!1!1!OMG!11!1!111!',
    },
    TA: {
      title: '¿Qué es un equivalente terapéutico?',
      label: 'Equivalente terapéutico',
      text: 'Cuando los médicos recetan un medicamento, eligen entre varias opciones. A veces, un medicamento es claramente lo mejor para usted. Otras veces, las opciones siguientes funcionarían igualmente bien. Cada uno contiene diferentes ingredientes activos, pero todos tienen el mismo efecto cuando se toman en dosis equivalentes. Hable con su médico; necesitará una receta NUEVA para cambiar a uno de estos.',
      'results-page-text':
        'Hay una o más alternativas disponibles para tratar la misma condición que trata {{productName}}. Para aprovechar esta opción, hable con su prescriptor sobre la posibilidad de cambiar a uno de los medicamentos a continuación.',
      'savings-option-tag': 'Pruebe con un medicamento diferente que trate la misma afección.',
    },
    TE: {
      title: '¿Qué es un equivalente terapéutico?',
      label: 'Equivalente terapéutico',
      text: 'Cuando los prescriptores recetan un medicamento, eligen entre varias opciones. A veces, un medicamento es claramente lo mejor para usted. Otras veces, las opciones siguientes funcionarían igualmente bien. Cada uno contiene diferentes ingredientes activos, pero todos tienen el mismo efecto cuando se toman en dosis equivalentes. Hable con su médico: necesitará una receta NUEVA para cambiar a uno de estos.',
      'results-page-text':
        'Hay una o más alternativas disponibles para tratar la misma afección que trata {{productName}}. Para aprovechar esta opción, hable con su médico sobre la posibilidad de cambiar a uno de los medicamentos a continuación.',
      'savings-option-tag': 'Pruebe con un medicamento diferente que trate la misma afección.',
    },
    CS: {
      title: '¿Qué es un división combinada?',
      label: 'División combinada',
      text: 'Este medicamento es en realidad una combinación de dos o más ingredientes activos. En su lugar, tómelo como ingredientes individuales ("píldoras" separadas) y podrá ahorrar dinero. Necesitará NUEVAS recetas de su médico para esta opción.',
      'results-page-text':
        '{{productName}} es una combinación de varios ingredientes activos que puede tomar individualmente. Hable con su prescriptor sobre la posibilidad de tomarlos por separado para ahorrar dinero.',
      'savings-option-tag': 'Intente tomar los ingredientes activos individualmente.',
    },
    GC: {
      title: '¿Qué es un clon genérico?',
      label: 'Clon genérico',
      text: 'Un clon genérico contiene los mismos ingredientes activos e inactivos que el medicamento de marca que le recetó su médico, y puede esperar el mismo efecto que el medicamento de marca. Es importante pedirle a su farmacéutico el clon genérico por su código nacional de medicamentos (NDC). Puede encontrar el NDC en la etiqueta de su receta. Siempre muestre a su farmacéutico el NDC genérico específico del clon cuando llene su receta. Por lo general, no necesita una nueva receta.',
      'results-page-text':
        'Los productos de marca cuestan más, incluidos los medicamentos. Este genérico es el mismo en todos los aspectos que {{productName}} y tiene exactamente el mismo efecto cuando se toma en la misma dosis.',
      'savings-option-tag':
        'Intente tomar el genérico de este medicamento que sea el mismo que el producto de marca en todos los sentidos.',
    },
    GS: {
      title: '¿Qué es un sustituto genérico?',
      label: 'Sustituto genérico',
      text: 'Los productos de marca cuestan más, incluidos los medicamentos. Las sustitutos genéricos contienen el mismo ingrediente activo que el medicamento de marca que le recetó su médico y tienen el mismo efecto cuando se toman en la misma dosis. Simplemente pregúntele a su farmacéutico por el genérico. Por lo general, no necesita una nueva receta.',
      'results-page-text':
        'Los productos de marca cuestan más, incluidos los medicamentos. Este genérico contiene el mismo ingrediente activo que {{productName}} y tiene el mismo efecto cuando se toma en la misma dosis.',
      'savings-option-tag': 'Trate de tomar el genérico de este medicamento.',
    },
    DF: {
      title: '¿Qué es un cambio en la forma de la dosis?',
      label: 'Cambio en la forma de la dosis',
      text: 'El medicamento recetado está disponible en múltiples formas de dosis (como cápsulas o tabletas). Al cambiar de tomar la forma de la dosis prescrita a una a continuación, podría ahorrar, sin dejar de recibir la misma dosis y el mismo ingrediente activo que el recetado. Necesitará una NUEVA receta de su médico para esta opción.',
      'results-page-text':
        'Al cambiar de una forma de dosis a otra (por ejemplo, de tableta a cápsula), recibirá la misma dosis del ingrediente activo recetado, al tiempo que reduce su costo. Para aprovechar esta opción, hable con su prescriptor sobre la posibilidad de tomar el medicamento a continuación.',
      'savings-option-tag': 'Intente tomar este medicamento en una forma diferente.',
    },
    MC: {
      title: '¿Qué es la tarjeta de fabricante?',
      label: 'Tarjeta del fabricante',
      text: 'El fabricante de este medicamento hace que sea más fácil para los pacientes pagarlo al reducir los gastos de su propio bolsillo. Pueden aplicarse algunas restricciones. Consulte la tarjeta para obtener más detalles. No necesitará una nueva receta para esta opción.',
      'results-page-text':
        'El fabricante de este medicamento hace que sea más fácil para los pacientes pagarlo al reducir los gastos de su propio bolsillo. Pueden aplicarse algunas restricciones. Consulte la tarjeta para obtener más detalles. No necesitará una nueva receta para esta opción.',
      'savings-option-tag': 'Prueba una tarjeta del fabricante para {{productName}}.',
    },
    TS: {
      title: '¿Qué es una sugerencia de división de tabletas?',
      label: 'División de tabletas',
      text: 'La mayoría de las tabletas cuestan lo mismo, independientemente de la concentración. Para ahorrarle dinero, su médico puede recetarle tabletas que tengan el doble de la dosis que necesita y pedirle que las parta por la mitad. Hemos verificado que este medicamento es seguro y apropiado para dividir tabletas. Necesitará una NUEVA receta para las tabletas de dosis más altas para aprovechar esta opción.',
      'results-page-text':
        'Dividir una dosis más alta de {{productName}} a la mitad puede ahorrarle hasta un 50% por surtido. Consulte con su prescriptor si es adecuado para usted dividir una dosis más alta a la mitad. Su prescriptor ajustará su prescripción para la nueva dosis e instrucciones.',
      'savings-option-tag':
        'Intente dividir una tableta de dosis más alta por la mitad (logra la misma dosis).',
    },
    PS: {
      title: '¿Qué es una sugerencia de compra de farmacia?',
      label: 'Compras de farmacia',
      text: 'El precio de este medicamento varía de una farmacia a farmacia. Para encontrar precios mejores que los que está pagando actualmente, seleccione esta opción. Es posible que deba transferir su receta de una farmacia a otra.',
      'results-page-text':
        'El precio de {{productName}} puede variar de una farmacia a farmacia. Para evaluar los precios en las farmacias cercanas, seleccione esta opción. Es posible que deba transferir su receta de una farmacia a otra.',
      'savings-option-tag': 'Ver ahorros en estas farmacias.',
    },
    CE: {
      title: '¿Qué es una sugerencia alternativa terapéutica combinada-dividida?',
      label: 'Alternativa terapéutica combinada-dividida',
      text: 'Tomar estos medicamentos individualmente funcionará de la misma manera que una dosis única de {{productName}}.',
      'results-page-text':
        'Tomar estos medicamentos individualmente funcionará de la misma manera que una dosis única de {{productName}}.',
      'savings-option-tag':
        'Intente tomar una combinación de medicamentos que funcionen de la misma manera.',
    },
    DS: {
      title: '¿Qué es una sugerencia de multiplicación de dosis?',
      label: 'Multiplicación de dosis',
      text: 'Estos medicamentos se pueden tomar en cantidades mayores de una dosis menor para lograr el mismo efecto que {{productName}}.',
      'results-page-text':
        'Estos medicamentos se pueden tomar en cantidades mayores de una dosis menor para lograr el mismo efecto que {{productName}}.',
      'savings-option-tag': 'Intente tomar varias dosis más bajas del mismo medicamento.',
    },
    MO: {
      'savings-option-tag':
        'Cambie a un suministro de 90 días de su farmacia de entrega a domicilio.',
    },
  },
  health_plan_info: [
    null,
    {
      label: 'está en su fase deducible',
      title: 'Fase deducible',
      content:
        'La fase de deducible significa que aún no ha alcanzado su deducible para el año del plan. Un deducible es una cantidad específica de dinero que debe pagar antes de que su plan médico/de recetas comience a ayudarlo a pagar una receta. Hasta que pague al menos esta cantidad (deducible), será responsable del 100% del costo de su receta, excepto en el caso de recetas en las que no se aplique el deducible.',
    },
    {
      label: 'está en su fase de costos compartidos',
      title: 'Fase de costos compartidos',
      content:
        'La fase de costo compartido es un período en el que tanto usted como su plan médico/de medicamentos recetados comparten el costo de sus medicamentos recetados. Pagará menos de lo que pagó en la fase de deducible porque su plan médico/de medicamentos recetados está ayudando a reducir sus costos.',
    },
    {
      label: 'se ha alcanzado el gasto máximo de su propio bolsillo',
      title: 'Gasto de su propio bolsillo alcanzado',
      content:
        'Una vez que haya gastado la cantidad máxima de gastos de su propio bolsillo según lo define su plan médico/de medicamentos recetados, su plan pagará la mayor parte, si no la totalidad, del costo de sus medicamentos recetados.',
    },
    {
      label: 'está en el período de deducible de la parte D de Medicare.',
      title: 'Periodo de deducible',
      content:
        'Hasta que alcance su deducible de la parte D, pagará el precio total negociado por sus medicamentos recetados cubiertos. Una vez que haya alcanzado el deducible, el plan comenzará a cubrir el costo de sus medicamentos. Si bien los deducibles pueden variar de un plan a otro, el deducible de ningún plan puede ser superior a $505 en 2023 y algunos planes no tienen deducible.',
    },
    {
      label: 'se encuentra en el período de cobertura inicial de la Parte D de Medicare.',
      title: 'Período de cobertura inicial',
      content:
        'Una vez que alcance su deducible, su plan lo ayudará a pagar sus medicamentos recetados cubiertos. Su plan pagará parte del costo y usted pagará un copago o coseguro. El tiempo que permanezca en el período de cobertura inicial depende de los costos de sus medicamentos y de la estructura de beneficios de su plan. Para la mayoría de los planes en 2023, el período de cobertura inicial finaliza después de que haya acumulado $4,660 en costos totales de medicamentos.\n \n Nota: Los costos totales de medicamentos incluyen la cantidad que usted y su plan han pagado por sus medicamentos cubiertos.',
    },
    {
      label: 'está en el período sin cobertura de la Parte D de Medicare.',
      title: 'Período sin cobertura',
      content:
        'Una vez que los costos totales de sus medicamentos alcanzan una cierta cantidad ( 4,660 para la mayoría de los planes), ingresa al período sin cobertura, también conocido como el agujero de dona. El agujero de dona se cerró para todos los medicamentos en 2020, lo que significa que cuando ingrese al período sin cobertura, será responsable del 25% del costo de sus medicamentos. En el pasado, usted era responsable de un porcentaje más alto del costo de sus medicamentos. Aunque el período sin cobertura se ha cerrado, es posible que aún vea una diferencia en el costo entre el período de cobertura inicial y el período sin cobertura. Por ejemplo, si el costo total de un medicamento es de $ 100 y usted paga el copago de $ 20 de su plan durante el período de cobertura inicial, será responsable de pagar $ 25 (25% de $ 100) durante el período sin cobertura.',
    },
    {
      label: 'está en el período de cobertura catastrófica de la parte D de Medicare.',
      title: 'Período de cobertura catastrófica',
      content:
        'En todos los planes de la parte D, después de haber pagado $ 7,400 en 2023 en gastos de su propio bolsillo por medicamentos cubiertos (esta cantidad es solo la cantidad que pagó, no los costos totales de medicamentos que usted y su plan pagaron), alcanza la cobertura catastrófica. Durante este período, paga copagos o coseguro significativamente más bajos por sus medicamentos cubiertos durante el resto del año. Durante la cobertura catastrófica, pagará el 5% del costo de cada uno de sus medicamentos, o $4.15 por los genéricos y $10.35 por los de marca (el que sea mayor).',
    },
  ],
  'pharmacy-locator': {
    'home-title': 'Encontrar una farmacia',
    'option-select': {
      title: '¿Necesita encontrar una farmacia?',
      subheading: 'Buscar por:',
      address: {
        title: 'Su dirección',
      },
      location: {
        title: 'Su ubicación',
      },
      zip: {
        title: 'Código postal',
      },
      button: 'Buscar por farmacia minorista',
    },
  },
  footer: {
    leftText: {
      header: '¿Podemos ayudarle?',
      body:
        'Nuestro equipo está compuesto de expertos en farmacia y beneficios de seguros. Llame al ' +
        '<a href="tel:{{number}}">{{number}}</a> ' +
        '(TTY: ' +
        '<a href="tel:18008778973">1-800-877-8973</a>' +
        ') ' +
        'para obtener asistencia personal en vivo (Lunes-Viernes, 7 a.m.-8 p.m. CT), o ' +
        '<a style="text-decoration: underline; cursor: pointer;"><span data-modal-id="contactmodal" tabindex="0" >envíenos un mensaje</span></a> ' +
        'y nos comunicaremos con usted pronto.',
      feedback: '¿Tiene comentarios para nosotros?',
      tellUs: '¡Díganos qué podemos hacer mejor!',
    },
    rightText: {
      header: 'Descargue nuestra aplicación móvil',
      textLink: 'O envíame un mensaje de texto con un enlace a',
      download: 'descargar en mi teléfono',
    },
    whitelabel: '',
    chatIconAltText: 'signo de interrogación y burbujas de chat',
    poweredByLogoAltText: 'alimentado por adjudilite',
  },
  'load-events': {
    headings: {
      'savings-heading': '¡Estamos recopilando los resultados de sus ahorros!',
      'savings-subheading': '(Estarán listos en solo un par de segundos).',
    },
    events: {
      suggestions_called: 'Encontrar sugerencias de medicamentos',
      pharmacies_called: 'Obtener precios de farmacias cercanas',
      pricing_called: 'Comunicarse con su plan de salud para obtener beneficios de las farmacia',
    },
  },
  productInfoItem: {
    disclaimer:
      'Aviso: esta información sobre medicamentos es solo un recurso de información y puede provenir de una fuente de terceros fuera de Rx Savings Solutions. La información de ninguna manera constituye un consejo u orientación médica o de farmacia y una persona debe comunicarse con su médico u otro prescriptor antes de tomar o realizar cambios en cualquier medicamento. Además, una persona debe pedirle a su prescriptor o farmacéutico información relacionada con la seguridad, efectividad y posibles efectos secundarios de cualquier medicamento y no debe depender únicamente de esta información para determinar la seguridad y/o efectividad de cualquier medicamento o combinación de medicamentos. No hay garantía de la exactitud de la información de este medicamento o de que sea la información más actualizada con respecto a este medicamento. Rx Savings Solutions no respalda ningún medicamento y no hace recomendaciones con respecto a ningún tratamiento, medicamento o terapia basada en esta información. Rx Savings Solutions está exenta de cualquier responsabilidad relacionada con cualquier reclamo relacionado con el suministro de esta información sobre medicamentos.',
  },
  SMSOptIn: {
    header: 'No se pierda los ahorros',
    paragraphOne:
      '¡Reciba notificaciones de ahorros de medicamentos recetados enviadas directamente a su teléfono!',
    paragraphTwo:
      'Regístrese hoy y le notificaremos tan pronto como encontremos oportunidades de ahorro en los medicamentos que usted o sus dependientes están tomando.',
    paragraphThree:
      'Ingrese (o confirme) su número de teléfono celular a continuación para recibir notificaciones de ahorros por mensaje de texto.',
    disclaimer:
      'Al hacer clic en Registrarse, está dando su consentimiento para recibir alertas móviles de Rx Savings Solutions. Hasta 4 mensajes por miembro por mes. Se pueden aplicar tarifas por mensajes y datos. Envíe el mensaje de texto STOP para cancelar, envíe HELP para obtener ayuda.',
  },
  HistoricalReport: {
    BreadcrumbTextForMedicineCabinet: 'botiquín de medicamentos',
    BreadcrumbTextForViewReport: 'Ver el informe',
    ViewReport: 'Ver el informe',
    MedListDescription:
      'El informe de la lista de medicamentos para una visita al médico le dará una lista completa de los medicamentos que está tomando, de modo que cuando un médico (o enfermero) le pregunte qué está tomando, podrá mostrárselos. También incluimos sugerencias de ahorro en caso de que quiera preguntarle a su médico sobre ellos en la visita.',
    MedSpendingDescription:
      'El informe de gastos de medicamentos le brindará información detallada sobre los gastos de cada medicamento que esté tomando o haya tomado durante cualquier período de tiempo en el que sus reclamaciones estén disponibles.',
    ChooseReport: {
      title: 'Elija el tipo de informe que desee',
      optionOne: 'Lista de medicamentos para la visita al médico',
      optionTwo: 'Gasto en medicamentos',
      buttonOne: 'Siguiente paso',
      buttonTwo: 'Siguiente paso',
    },
    MedicationList: {
      title: 'Personalice su lista de medicamentos',
      optionOne: 'Solo mis medicamentos actuales',
      optionTwo: 'Historial de medicación personalizado',
      buttonOne: 'Ver su informe',
      buttonTwo: 'Siguiente paso',
    },
    DocMedHistory: {
      title: 'Elija el rango de fechas de su historial de medicamentos',
      optionOne: 'Desde el primer reclamo disponible',
      optionTwo: 'Intervalo de fechas personalizado',
      buttonOne: 'Ver su informe',
      buttonTwo: 'Ver su informe',
    },
    MedSpending: {
      title: 'Personalizar su informe de gastos de medicamentos',
      optionOne: 'Solo mostrar mis últimos surtidos',
      optionTwo: 'Mostrar gasto a lo largo del tiempo',
      buttonOne: 'Ver su informe',
      buttonTwo: 'Siguiente paso',
    },
    MedHistory: {
      title: 'Elija su rango de fechas de gasto de medicamentos',
      optionOne: 'Desde el primer reclamo disponible',
      optionTwo: 'Año fiscal {{year}}',
      optionThree: 'Intervalo de fechas personalizado',
      buttonOne: 'Ver su informe',
      buttonTwo: 'Ver su informe',
      buttonThree: 'Ver su informe',
    },
  },
  profileSettings: {
    edit: 'editar',
    change: 'Cambio',
    mobileNumberTitle: 'Número de teléfono celular',
    emailAddressTitle: 'Dirección de correo electrónico',
    addressTitle: 'Dirección',
    streetTitle: 'Calle',
    cityTitle: 'Ciudad',
    stateTitle: 'Estado',
    zipTitle: 'Código postal',
    passwordTitle: 'Contraseña',
    choosePassword: 'Elegir nueva contraseña',
    requirementsTitle: 'Su contraseña debe tener cada uno de los siguientes:',
    requirementsSpecial: 'Como mínimo, 1 número, carácter especial o espacio',
    requirementsCharacterLimit: 'Como mínimo, 8 caracteres',
    requirementsCharacters: 'Como mínimo, 1 letra mayúscula y 1 letra minúscula',
    toggleFieldEmail: 'Correo electrónico',
    toggleFieldSMS: 'SMS',
    toggleFieldPush: 'Notificaciones emergentes',
    userSettings: 'Configuraciones de usuario',
    'sms-number-modal': {
      instructions:
        'Ingrese su número de teléfono celular para recibir notificaciones por sms/mensaje de texto.',
      cancel: 'Cancelar',
      disclaimer:
        'Al habilitar SMS, está dando su consentimiento para recibir alertas de Rx Savings Solutions a su celular. Se pueden aplicar tarifas por mensajes y datos. Envíe el mensaje de texto STOP para cancelar, envíe HELP para obtener ayuda.',
    },
    preferredPharmacy: 'Se ha actualizado su farmacia minorista principal.',
    communicationSettings: {
      heading: 'Configuración de recordatorio médico',
      optionEmail: 'Correo electrónico',
      optionSMS: 'Texto/SMS',
      optionPush: 'Notificaciones emergentes',
      manage: '¿Necesitas administrar sus recordatorios?',
      successMessage: 'Se actualizaron sus preferencias de comunicación.',
      click: 'Haga clic aquí',
      notSet: 'Aún no ha creado ningún recordatorio. Para configurar uno,',
      pushNotificationPrompt: 'Para recibir notificaciones emergentes, debe',
      pushNotificationPromptLink: 'descargar nuestra aplicación.',
    },
    loginHeader: 'Información para ingresar',
    contactHeader: 'Información de contacto',
    primaryPharmacyHeader: 'Su farmacia minorista principal',
    recentPharmaciesHeader: 'O elija una farmacia minorista de entre sus reclamos',
    searchUsingAddressOrZip: 'Buscar una farmacia minorista',
    noPharmaciesAddress: 'No hay farmacias disponibles en esta dirección.',
    noPharmaciesZipcode: 'No hay farmacias disponibles en este código postal.',
    invalidAddress: 'Ingrese una dirección válida',
    invalidZipCode: 'Ingrese un código postal válido',
    emailMustBeValid: 'El correo electrónico debe ser una dirección de correo electrónico válida.',
    mobileNumberInvalid: 'The mobile number format is invalid.',
    allFieldsRequired: 'All fields are required.',
  },
  reminderAcknowledgement: {
    morning: '¡Buenos días!',
    afternoon: '¡Buenas tardes!',
    evening: '¡Buenas tardes!',
    night: '¡Buenas noches!',
    thanks: 'Gracias por tomar sus medicamentos',
    dashboard:
      'No olvide revisar su tablero. Es posible que le esté esperando una nueva oportunidad de ahorro.',
    itsTime: 'Es hora de tomar su medicamento {{plural}}',
    taken: 'Tomé mi medicamento {{plural}}',
    toDashboard: 'Llevarme a mi panel principal',
    needUpdate: '¿Necesita actualizar sus recordatorios?',
    toReminders: 'Ir a los recordatorios',
    error: 'Hubo un problema al reconocer sus medicamentos. Inténtelo nuevamente.',
    alreadyAcknowledged: 'Ya reconoció que tomó sus medicamentos durante {{time}} el {{date}}.',
    email: 'Correo electrónico',
    sms: 'mensajes de texto',
    push: 'Notificaciones emergentes',
    expiredMessage: 'Este recordatorio ha caducado. Compruebe si hay un nuevo recordatorio.',
    errorMessage:
      '¡Ups! No podemos encontrar este recordatorio. Intente actualizar la página. Si este problema persiste, comuníquese con nuestro equipo de soporte de farmacia al 1-800-268-4476.',
  },
  smsToggleModal: {
    header: 'Ingrese su número de teléfono celular para recibir notificaciones por sms/texto.',
    disclaimer:
      'Al habilitar los SMS, está dando su consentimiento para recibir alertas Rx Savings Solutions a su celular. Hasta 3 mensajes por mes. Se pueden aplicar tarifas por mensajes y datos. Envíe el mensaje de texto STOP para cancelar, envíe HELP para obtener ayuda.',
    mobileNumberHeader: 'Número de teléfono celular',
    errorLabel: 'Por favor ingrese un número de teléfono válido.',
    Save: 'Ahorrar',
    Cancel: 'Cancelar',
  },
  smsOptInModal: {
    signUp: 'Regístrese',
    noThanks: 'No, Gracias',
  },
  'contact-prescriber-started': {
    header: '¡Su solicitud está actualmente en curso!',
    body: 'Estamos en el proceso de realizar este cambio de receta con su proveedor y farmacia. Si no ha sido notificado del estado de su solicitud, o necesita cancelarla o cambiarla de alguna manera, llame al 1-800-268-4476.',
  },
  MailOrderRefillModal: {
    almostTime: 'Casi es momento de volver a surtir su medicamento.',
    lastRefilled:
      'Según nuestros datos de reclamaciones, la última vez que surtió su medicamento fue el {{date}}. Esto significa que debe volver a surtirlo.',
    refillInfo: 'Utilice la siguiente información para volver a surtir su receta.',
    pharmacyDetails: 'Detalles de la farmacia',
    mailOrder: 'Farmacia de entrega a domicilio',
    refilled: 'He resurtido mi receta',
  },
  reportModal: {
    rightText: {
      header: '¿Podemos ayudarle?',
      body: 'Nuestro equipo de asistencia de farmacia está disponible en: {{number}}',
    },
    preReport: 'Obteniendo sus datos',
  },
  doctorVisitReport: {
    BreadcrumbText: 'Informe de visita al médico',
    title: 'Traer la siguiente información a su próxima visita al médico',
    noMeds: 'No se encontraron reclamos históricos',
    DateRange: 'Recetas surtidas desde: {{from}} a {{to}}',
    LoadingStateLabel: 'Espere mientras recopilamos sus datos y generamos el informe',
    PastMedsTableHeaders: {
      header: 'Medicamentos de más de un año',
      drugName: 'Nombre del medicamento',
      dose: 'Dosis',
      quantity: 'Cantidad',
      form: 'Formulario',
      dates: 'Fecha(s) de surtido',
    },
    CurrentMedsTableHeaders: {
      header: 'Medicamentos actuales',
      drugName: 'Medicamento',
      Adherent: '% adherente',
    },
    waitState: {
      heading: 'Informe de visita al médico',
      'sub-heading': '(Estará listo en solo un par de segundos).',
    },
    savingsHeader: 'Oportunidades de ahorro para los surtidos más recientes',
    savingsText:
      'A continuación, se muestran oportunidades para que <span data-private>{{firstName}} {{lastName}}</span> ahorre en medicamentos. Como prescriptor de <span data-private>{{firstName}}</span>, analice estas opciones con <span data-private>{{firstName}}</span> para determinar si un cambio sería beneficioso.',
    savingsFrom: 'Gastos y ahorros a partir del {{date}}',
    savingsPaid: 'Pagó {{amount}}',
    paidOn: 'el {{date}}',
    savingsPerFill: 'Ahorre {{amount}} por surtido',
    errorMessage:
      'Lo sentimos, hubo un problema al crear su informe. Intente actualizar la página. Si este problema persiste, comuníquese con nuestro equipo de soporte de farmacia al 1-800-268-4476.',
  },
  spendingReport: {
    BreadcrumbText: 'Reporte de gastos',
    title: 'Informe de gastos médicos para',
    DateRange: 'Gasto total desde: {{from}} hasta {{to}}',
    noMeds: 'No se encontraron reclamos históricos',
    LoadingStateLabel: 'Espere mientras recopilamos sus datos y generamos el informe',
    MedsHeaders: {
      'short-term': 'Medicamentos de uso a corto plazo',
      maintenance: 'Medicamentos de uso crónico',
    },
    waitState: {
      heading: 'Reporte de gastos',
      'sub-heading': '(Estará listo en solo un par de segundos).',
    },
    medList: {
      filledDate: 'Receta llena:',
      pharmacyHeader: 'Información de farmacia',
      costHeader: 'Desglose de los costos',
      retail: 'Precio del minorista:',
      planPay: 'El plan paga:',
      yourPay: 'Usted paga',
    },
    errorMessage:
      'Lo sentimos, hubo un problema al crear su informe. Intente actualizar la página. Si este problema persiste, comuníquese con nuestro Equipo de soporte de farmacia al 1-800-268-4476.',
  },
  'prescriber-letter-modal': {
    rightText: {
      header: '¿Podemos ayudarle?',
      body: 'Nuestro equipo de asistencia de farmacia está disponible en: {{number}}',
    },
    salutation: 'Estimado prescriptor,',
    paragraph1:
      'Su paciente, <span data-private>{{firstName}} {{lastName}}</span>, está interesado en reducir los costos de sus medicamentos recetados.\nEl empleador o plan de salud de su paciente se ha asociado con Rx Savings Solutions para proporcionar\nahorros en medicamentos recetados. Nuestro objetivo es trabajar con usted para sugerirle opciones de ahorros potenciales que tengan sentido para su paciente .',
    paragraph2:
      'Como prescriptor, nos damos cuenta de que puede haber razones por las que se ha seleccionado un medicamento en particular.\nSolicitamos su consideración al evaluar la ventaja de costo-beneficio de las alternativas menos costosas.',
    paragraph3:
      'Le hemos proporcionado a su paciente algunas sugerencias sobre cómo conseguir ahorros en sus medicamentos recetados. \nA continuación, encontrará la sugerencia que más les interesa.',
    paragraph4:
      'Recomendamos que <span data-private>{{firstName}}</span> cambie de {{product}} {{Strength}} {{form}} a {{suggestedProducts}}, una sugerencia de {{suggestionType}}.',
    paragraph5:
      'Si aprueba esta solicitud, <span data-private>{{firstName}}</span> comenzará a ahorrar $ {{Savings}} en cada surtido.',
    paragraph6:
      'Agradecemos su revisión de nuestras recomendaciones de ahorro de costos. No dude en comunicarse con\nRx Savings Solutions al {{phoneNumber}} si tiene preguntas. También puede enviarnos un correo electrónico a {{emailAddress}} .',
  },
  'family-permission': {
    'prescription-details': 'Ver los detalles de su receta.',
    'contact-prescriber': {
      how: '¿Cómo puede <span data-private>{{name}}</span> obtener estos ahorros?',
      'how-reward':
        'Cómo hacer el cambio y recibir la tarjeta prepaga Rx Rewards Visa<sup>®</sup> con ${{amount}}?',
      fromPharmacy:
        'Debido a que <span data-private>{{name}}</span> es mayor de 18 años, deberá solicitar {{drug}} en la farmacia.',
      requestPrescription:
        'Debido a que <span data-private>{{name}}</span> es mayor de 18 años, deberá solicitar una receta para {{drug}}:',
      toCompleteReward:
        'Para completar el cambio y recibir la tarjeta prepaga Visa<sup>®</sup> con ${{amount}}, pídale a <span data-private>{{name}}</span> que inicie sesión en nuestro portal de internet.',
      pleaseTell:
        'Dígale a <span data-private>{{name}}</span> que inicie sesión, haga clic en alerta de ahorro para {{drug}} y haga clic en el botón "contactar a mi prescriptor". Nuestro equipo de soporte de farmacia se encargará de todo.',
      downloadLetter:
        '<span data-private>{{name}}</span> puede descargar una carta para enviarla al médico que le prescribe. El enlace a la carta se puede encontrar junto al botón "contactar a mi médico prescriptor".',
      simplyCall:
        '<span data-private>{{name}}</span> simplemente puede llamar al médico que le prescribe y hacer la solicitud por teléfono.',
      requestLetter:
        'Dígale a <span data-private>{{name}}</span> que inicie sesión en Rx Savings Solutions y haga clic en alerta de ahorro para {{drug}}. <span data-private>{{name}}</span> puede hacer clic y descargar la carta de solicitud y enviársela al médico. Toda la información necesaria se incluye automáticamente en la carta.',
      or: 'o',
    },
    accept: 'Acepto',
    decline: 'Rechazar',
    'reactive-grant': {
      header: 'A <span data-private>{{name}}</span> le gustaría ver su perfil.',
      body: 'Con su permiso, <span data-private>{{name}}</span> puede ver qué medicamentos toma ahora o en el futuro, y si hay opciones más económicas disponibles.',
    },
    'proactive-grant': {
      header: '¿Le gustaría permitir que <span data-private>{{name}}</span> vea su perfil?',
      body: 'Con su permiso, <span data-private>{{name}}</span> puede ver qué medicamentos toma ahora o en el futuro, y si hay opciones más económicas disponibles.',
    },
    'accept-button': 'Permitir que <span data-private>{{name}}</span> vea mi perfil',
    'ignore-button': 'Ignorar',
    terms: 'He leído y acepto ',
    termsTwo: 'los términos y condiciones',
    modalTerms:
      'Antes de poder compartir su perfil y medicamentos con <span data-private>{{name}}</span>, debe aceptar los términos y condiciones.',
    shareWithPrimaryModal:
      'Al hacer clic en "aceptar", está dando permiso para que Rx Savings Solutions divulgue la información de su receta al padre/madre/tutor responsable del pago de su atención como titular principal de la póliza. Tenga en cuenta que ciertas recetas delicadas no se compartirán con la persona designada en función de su plan de salud o proveedor en particular. Además, acepta que ha leído y comprendido lo siguiente: Este consentimiento será válido hasta que deje de figurar como dependiente en la póliza de seguro médico del padre/madre/tutor. Solo el titular de la póliza principal de su póliza de seguro tendrá acceso a la información de su receta. Este acceso se otorgará únicamente en la capacidad del padre/madre/tutor como titular principal de la póliza. Rx Savings Solutions proporcionará la cantidad mínima necesaria de información sobre sus recetas al padre/madre/tutor en su calidad de titular principal de la póliza. Tiene derecho a objetar o retirar su consentimiento en cualquier momento enviando un correo electrónico al agente de privacidad de Rx Savings Solution a Privacy@rxsavingssolutions.com. No se requiere el consentimiento para compartir su información con una persona designada para inscribirse en los servicios de Rx Savings Solutions.',
    shareWithDependentModal:
      'Al hacer clic en "aceptar", está dando permiso para que Rx Savings Solutions divulgue la información de su receta con fines de notificación limitada a la persona que designe como participante de su atención. Tenga en cuenta que ciertas recetas delicadas no se compartirán con la persona designada en función de su plan de salud o proveedor en particular. Además, acepta que ha leído y comprendido lo siguiente: (1) Este consentimiento será válido hasta que lo retire o se oponga a que la persona designada tenga acceso a la información de su receta; (2) este acceso se le otorga a la persona designada porque lo mejor para usted es que la persona designada tenga acceso; y (3) Rx Savings Solutions le ha brindado la oportunidad de aceptar u objetar el uso o divulgación de la información de su receta. Tiene derecho a objetar o retirar su consentimiento en cualquier momento, ya sea a través del portal de miembros o enviando un correo electrónico al agente de privacidad de Rx Savings Solution a Privacy@rxsavingssolutions.com. No se requiere el consentimiento para compartir su información con una persona designada de inscribirse en los servicios de Rx Savings Solutions.',
    sharing: {
      confirmation:
        'Se ha enviado un correo electrónico solicitando el permiso de <span data-private>{{name}}</span>.',
      confirmationQuestion:
        '¿Quieres compartir tu perfil y la información de tus medicamentos con <span data-private>{{name}}?</span>?',
      confirmationButtonYes: 'Sí',
      confirmationButtonNo: 'No',
      header:
        '¿También le gustaría compartir su perfil y medicamentos con <span data-private>{{name}}</span>?',
      p1: '¡Excelente! Le enviaremos un mensaje a <span data-private>{{name}}</span>, preguntándole si está bien que usted puede ver su perfil.',
      readTerms: 'He leído y estoy de acuerdo con',
      conditions: 'los términos y condiciones.',
      success:
        '¡Éxito! Enviamos un mensaje a <span data-private>{{name}}</span> en nombre suyo, preguntándote si usted puede ver su perfil.',
      successAlso:
        '<span data-private>{{name}}</span> también podrá ver su perfil. Siempre puede cambiar esto en su',
      privacySettings: 'configuración de privacidad.',
      askSuccess:
        '¡Éxito! Enviamos un mensaje a <span data-private>{{name}}</span> en nombre suyo, preguntándote si está bien que usted puede ver su perfil.',
      dueToPrivacy:
        'Debido a las leyes de privacidad, no podemos mostrarte el perfil de <span data-private>{{name}}</span> sin permiso.',
      request:
        '¿Le gustaría solicitar permiso a este miembro de la familia para ver su perfil y medicamento(s)?',
      button: 'Mandar Pedido',
    },
    alert: {
      mustAgree:
        'Antes de poder compartir su perfil con {{name}}, debe aceptar los términos y condiciones.',
      success:
        '¡Éxito! <span data-private>{{name}}</span> ahora puede ver su perfil. Siempre puede cambiar esto en su',
      privacySettings: 'configuración de privacidad.',
    },
  },
  videos: {
    downloadAppText: 'Está listo para',
    downloadAppButton: 'descargar nuestra aplicación.',
    alerts: {
      title: '¿Toma medicamentos regularmente? ¡Lo tenemos cubierto!',
      description: 'Observe cómo los ahorros lo encuentran a usted, no al revés.',
    },
    noAlerts: {
      title: 'En la próxima visita al médico, ¡traiga a un amigo!',
      description: 'Vea cómo puede ahorrar dinero antes de llegar a la farmacia.',
    },
  },
  'contact-prescriber-alert': {
    seeMore: 'Ver más detalles',
    active: {
      header: '¡Su cambio de receta para {{product}} está en proceso!',
      dependentHeader: '¡El cambio de prescripción de {{name}} para {{product}} está en proceso!',
      body: 'Estamos trabajando con su prescriptor y/o farmacia para realizar el cambio.',
    },
    complete: {
      header: 'Se completó el cambio de su receta para {{product}}.',
      dependentHeader: 'Se completó el cambio de receta de {{name}} para {{product}}.',
      body: 'Consulte con su farmacia. Podría estar listo para ser recogido o entregado.',
    },
  },
  pharmacySections: {
    primaryPharmacyHeader: 'Su farmacia primaria',
    preferredPharmaciesHeader: 'Plan de farmacias preferidas',
    nearbyPharmaciesHeader: 'Farmacias cerca de usted',
    mailOrderPharmaciesHeader: 'Farmacias con entregas a domicilio',
  },
  'express-alert': {
    'navigation-header': 'La mejor forma de ahorrar',
    'comparisonView-navigation-header': '¡Aquí le mostramos cómo cambiar!',
    'rewardable-header': 'Obtenga una tarjeta de prepago Visa® Rx Rewards de ${{amount}}',
    'reward-description': 'Además, gane una tarjeta de prepago Visa Rx Rewards de ${{amount}}',
    header: '¡Ahorrar {{amount}} es fácil!',
    'header-up-to': '¡Ahorrar {{amount}} o más es fácil!',
    clientSavingsHeader: '¡Ahorrarle a su grupo {{amount}} es fácil!',
    'clientSavingsHeader-up-to': '¡Ahorrarle a su grupo {{amount}} o más es fácil!',
    subHeader: 'Esto es lo que haremos por usted.',
    'footer-button': 'Empecemos',
    'footer-link': 'Mostrarme otras opciones',
    'comparisonView-footer-link': 'Comparar precios de farmacia',
    'or-less': ' o menos',
    'step-2': 'Surtir la receta médica por:',
    'change-pharmacy': 'Cambiar de farmacia',
    'rewardable-step-2':
      'Recibirá una tarjeta de prepago Visa Rx Rewards de ${{amount}} después de que se surta la nueva receta y se procese el reclamo.',
    'medication-switch': {
      to: 'para',
      TA: {
        'switching-header': 'Obtendremos la aprobación de {{doctor}} para cambiarlo de:',
        'switching-disclaimer-text':
          'Nuestros farmacéuticos creen que este medicamento puede funcionar de la misma manera en el tratamiento de su afección.',
        'switching-disclaimer-link': 'Darme más información.',
      },
      DF: {
        'switching-header':
          'Hablaremos con {{doctor}} sobre cómo cambiar su {{primaryMedication}} de un:',
        'switching-disclaimer-text':
          'Al cambiar de un {{medicineFrom}} a un {{medicineTo}}, recibirá el mismo ingrediente activo recetado. ¿Necesita más información?',
        'switching-disclaimer-link': 'Haga clic aquí',
      },
      GC: {
        'switching-header': 'Hablaremos con {{doctor}} sobre cómo cambiarlo de:',
        'switching-disclaimer-text':
          'Este genérico tiene exactamente los mismos ingredientes activos e inactivos que la marca {{medicineFrom}}, y tiene exactamente el mismo efecto cuando se toma en la misma dosis.',
        'switching-disclaimer-link': 'Darme más información.',
      },
      GS: {
        'switching-header': 'Hablaremos con {{doctor}} sobre cómo cambiarlo de:',
        'switching-disclaimer-text':
          'Este genérico contiene el mismo ingrediente activo que {{medicineFrom}} y tiene el mismo efecto cuando se toma en la misma dosis.',
        'switching-disclaimer-link': 'Darme más información.',
      },
      TS: {
        'switching-header':
          'Hablaremos con {{doctor}} sobre una receta para una dosis más alta de {{primaryMedication}} que pueda dividir.',
        'switching-disclaimer-text':
          'Dividir a la mitad una dosis más alta de {{medicineFrom}} puede ahorrarle por surtido. {{doctor}} ajustará su receta y las instrucciones para la nueva dosis.',
        'switching-disclaimer-link': 'Darme más información.',
      },
    },
    'contact-prescriber': {
      'contact-info': {
        'navigation-header': 'Confirmar la información de contacto',
        header: 'Háganos saber cuál es la mejor manera de comunicarnos con usted.',
        'confirm-number':
          'Para realizar el cambio, ingrese (o confirme) su número de teléfono móvil para que podamos enviarle un mensaje de texto sobre el estado de su solicitud.',
        'mobile-button-text': 'Enviar número de teléfono',
        'email-button-text': 'Envíeme un correo electrónico en su lugar',
        'more-info': 'Usaremos el correo electrónico de su cuenta.',
        consent:
          'Al proporcionar mi dirección de correo electrónico y/o mi número de teléfono y hacer clic en enviar, consiento expresamente que Rx Savings, LLC d/b/a Rx Savings Solutions me envíe correos electrónicos y se comunique conmigo por teléfono con respecto al servicio de contacto del prescriptor solicitado.',
        'rewardable-consent-1':
          'Al proporcionar mi dirección de correo electrónico y/o mi número de teléfono y hacer clic en enviar, acepta recibir la tarjeta de prepago Visa® PayForward Rx Savings Solutions sin costo para usted, precargada con su recompensa de incentivo, cuyo uso está sujeto al Acuerdo del titular de la tarjeta , Termino del servicio y política de privacidad.',
        'rewardable-consent-2':
          'La tarjeta de prepago Visa PayForward® Rx Savings Solutions es emitida por Stride Bank, N.A., miembro de la FDIC, de conformidad con una licencia de Visa USA Inc. PayForward es una empresa independiente que administra el programa de recompensas para miembros en nombre de Rx Savings Solutions.',
        'footer-cancel-link': 'Cancelar solicitud',
        'email-info-bubble': 'Usaremos el correo electrónico de su cuenta.',
      },
      'rx-home-delivery': {
        'confirm-header': 'confirmar entrega a domicilio',
      },
      confirmation: {
        'navigation-header': 'Enviar solicitud',
        header: '¡Nosotros seguiremos desde aquí!',
        'doctor-request': 'Le pedimos a {{doctor}} que cambie su receta a:',
        'sending-to': 'Y enviándolo para ser surtido a:',
        'sending-to-MO': 'Y enviándolo a su farmacia de entrega a domicilio:',
        'support-text':
          'Recibirá actualizaciones de solicitudes a través de {{contactType}} en <span data-private>{{value}}</span>. Si nuestro equipo o su médico tienen alguna pregunta sobre el cambio, nuestro equipo de asistencia de farmacia se comunicará con usted a través de {{contactType}}.',
        'back-to-dashboard-button': 'Volver al panel principal',
        'footer-text': '¿No quiso hacer este cambio?',
        'footer-cancel-link': 'Cancelar solicitud',
        'rewards-1':
          'Recibirá una tarjeta de prepago Visa® Rx Rewards de ${{amount}} después de que se surta la nueva receta y se procese el reclamo.',
        'rewards-2': 'Gana una tarjeta de prepago Visa® Rx Rewards de ${{amount}}',
      },
    },
    productDetails: {
      BreadcrumbTextForDrugDetail: 'Detalle del medicamento',
      'navigation-header': 'Por qué puede confiar en esta opción',
      jeff: {
        header: 'El Dr. Green dice:',
        details: {
          designation: 'Director de farmacia',
          degree: 'Dr. Jeff Green, PharmD',
          summary:
            'Jeff tiene más de 13 años de experiencia en farmacia minorista y experiencia en beneficios de farmacia con Walgreens y Aetna.',
        },
        DF: 'Lo crea o no, el mismo medicamento puede tener un precio muy diferente según la forma (cápsula, tableta, líquido, etc.). Siempre les digo a mis amigos y familiares que los revisen todos. En este caso, ahorrará dinero cambiando a una forma diferente. Simplemente haga clic en el botón a continuación y le pediremos a su médico que le recete la opción de menor costo.',
        TS: 'Revisé esta sugerencia y considero que es seguro y efectivo dividir estas tabletas por la mitad, incluso si no están precalificadas para dividirlas fácilmente. Las herramientas para dividir tabletas son económicas y se pueden encontrar en farmacias en todas partes. Esta es una manera fácil de ahorrar dinero, porque obtiene la misma cantidad de dosis a la mitad del precio. Simplemente haga clic en el botón de abajo y le pediremos a su médico que le recete la dosis más grande.',
      },
      stone: {
        header: 'Dr. Stone dice:',
        details: {
          designation: 'Gerente clínico de la farmacia',
          degree: 'Dr. Nikki Stone, PharmD',
          summary:
            'Nikki tiene más de 15 años de experiencia en farmacia clínica en el sector minorista para Walgreens, CVS y Publix Supermarkets.',
        },
        TA: 'He revisado su sugerencia de ahorro y creo que esta es una gran alternativa, algo que recomendaría a mi propia familia. Este medicamento le permitirá ahorrar dinero y puede ser igualmente eficaz. Simplemente haga clic en el botón de abajo y nos pondremos en contacto con su médico para asegurarnos de que sea adecuado para usted.',
        GC: '¡Esta sugerencia es fácil! Puede obtener exactamente el mismo medicamento, solo que sin la marca y un precio más alto. Definitivamente le diría a un amigo o familiar que haga el cambio. Simplemente haga clic en el botón a continuación y le pediremos a su médico que le recete el genérico de menor costo.',
      },
      matt: {
        header: 'Dr. Jarvis dice:',
        details: {
          designation: 'Director de farmacia',
          degree: 'Dr. Matt Jarvis, PharmD',
          summary:
            'Dr. Jarvis desempeñó una variedad de funciones con Walgreens como Gerente de Farmacia, Gerente de Farmacia Especializada y Supervisor de Atención Médica.',
        },
        GS: 'Revisé su sugerencia de ahorro para este genérico y creo que es una decisión inteligente. Ahorrará dinero y obtendrá el mismo ingrediente activo que su medicamento actual. Si un amigo o familiar me preguntara, yo diría “¡Definitivamente!” Simplemente haga clic en el botón de abajo y le pediremos a su médico que le recete el genérico de menor costo.',
      },
      'switch-text': '¿Listo para hacer el cambio?',
      'switch-button': 'Comencemos',
      'comparison-header': 'Compare los medicamentos',
      'tablet-split-header': 'Acerca de {{medication}}',
      'comparison-footer':
        'Aviso: esta información sobre medicamentos es solo un recurso de información y puede provenir de una fuente de terceros fuera de Rx Savings Solutions. La información de ninguna manera constituye un consejo u orientación médica o de farmacia y una persona debe comunicarse con su médico u otro prescriptor antes de tomar o realizar cambios en cualquier medicamento. Además, una persona debe pedirle a su prescriptor o farmacéutico la información relacionada con la seguridad, efectividad y posibles efectos secundarios de cualquier medicamento y no debe depender únicamente de esta información para determinar la seguridad y/o efectividad de cualquier medicamento o combinación de medicamentos. No hay garantía de la exactitud de la información de este medicamento o de que sea la información más actualizada con respecto a este medicamento. Rx Savings Solutions no respalda ningún medicamento y no hace recomendaciones con respecto a ningún tratamiento, medicamento o terapia basada en esta información. Rx Savings Solutions se exime de cualquier responsabilidad relacionada con cualquier reclamo relacionado con el suministro de esta información sobre medicamentos.',
      old: 'VIEJO',
      new: 'NUEVO',
      'side-effects': 'Efectos secundarios',
      Interactions: 'Interacciones',
      Details: 'Detalles',
    },
    pharmacyList: {
      header: 'Seleccione una farmacia',
      'primary-pharmacy': 'Farmacia primaria',
      'best-savings': 'Mejores ahorros',
      confirm: 'Confirmar',
      quantity: 'Cant.: {{cantidad}} ({{costPerUnit}}/cada)',
      days: 'Días: {{daySupply}} ({{costPerDay}}/día)',
      limitedPharmaciesHeader: '¿Por qué no encuentro la farmacia que busco?',
      limitedPharmaciesBody:
        'Esta lista muestra los precios más bajos en las farmacias de la red que están cerca del código postal de su farmacia principal ({{zip}}). Para modificarlo, ',
      limitedPharmaciesBodyNoZip:
        'Esta lista muestra los precios más bajos en las farmacias de la red que están cerca del código postal de su farmacia principal. Para modificarlo, ',
      limitedPharmaciesLink: 'cambie de farmacia principal',
    },
  },
  'primary-pharmacy-alerts': {
    confirm: {
      header: '¿Es esta su farmacia actual?',
      body: 'Notamos que ha surtido recetas en:',
      buttonText: 'Confirmar farmacia primaria',
      footerText: '¿No es su farmacia?',
      footerTextLink: 'Cámbiela en su configuración.',
    },
    confirmed: {
      header: 'Gracias por confirmar su farmacia primaria.',
      body: 'Para cambiarlo, vaya a su',
      bodyLink: 'configuración.',
    },
    update: {
      header: '¿Es esta su nueva farmacia?',
      body: 'Notamos que lleno una receta en:',
      buttonText: 'Actualizar farmacia primaria',
      footerTextLink: 'Ignorar esta farmacia',
    },
    updated: {
      header: '¡Genial! Hemos actualizado su farmacia primaria.',
      body: 'Siempre puede cambiarlo en la',
      bodyLink: 'configuración.',
    },
    ignored: {
      header: '¡Gracias! La ubicación de su farmacia primaria permanecerá:',
      footerText: '¿Aún no es su farmacia primaria?',
      footerTextLink: 'Cámbiela en su configuración.',
    },
  },
  logoutPage: {
    logoAltText: 'logo',
    iconAltText: 'Icono que indica hacer clic en el cursor',
    header: 'Su sesión fue cerrada',
    message:
      'Regrese a su cuenta de Rx Savings Solutions en cualquier momento para saber cómo ahorrar en sus recetas.',
    ssoButton: 'Regresarme a {{page}}',
    ssoSignInAgain: 'O puede <a href="{{url}}">ingresar</a> nuevamente',
    needHelp: '¿Necesita ayuda?',
    contactSupport: 'Póngase en contacto con nuestro equipo de soporte de farmacia',
    phoneNumber:
      '<a href="tel:18002684476">1-800-268-4476</a> (TTY: <a href="tel:18008778973">1-800-877-8973</a>)',
    hours: 'Lunes a viernes de 7 a.m. a 8 p.m. central, o envíe un correo electrónico a',
    email: '<a href="mailto:support@rxsavingssolutions.com">support@rxsavingssolutions.com</a>',
    signInAgain: 'Iniciar sesión',
    failure: 'Hubo un problema al cerrar la sesión. Inténtelo nuevamente.',
  },
  'rx-rewards-ppp': {
    and: 'y',
    header:
      'Sí, envíenme una tarjeta de prepago Rx Rewards Visa<sup>®</sup> de ${{amount}} una vez que se llene la nueva receta.',
    terms:
      'Al hacer clic en "{{button}}," doy mi consentimiento para recibir la tarjeta de prepago Visa<sup>®</sup> PayForward® Rx Savings Solutions para mí sin costo para mí, precargada con mi recompensa de incentivo, cuyo uso está sujeto al',
    'agreement-link': 'Acuerdo del titular de la tarjeta',
    'tos-link': 'Términos de servicio',
    'privacy-link': 'Política de privacidad',
    disclaimer:
      'La tarjeta prepaga PayForward<sup>®</sup> Rx Savings Solutions Visa<sup>®</sup> es emitida por Stride Bank, N.A., miembro de la FDIC, de conformidad con una licencia de Visa USA Inc. PayForward es una empresa independiente que administra el programa de recompensas para miembros en nombre de Rx Savings Solutions.',
  },
  'pbm-max-info': {
    'or-more': 'o más',
    'or-less': ' o menos',
    header: '¿Por qué no contamos con un precio exacto para este medicamento?',
    body: 'El precio de los medicamentos cambia con frecuencia, por lo que realizamos un cálculo estimado en base en la información más reciente disponible. Su plan puede contribuir en el costo de este medicamento. Sin embargo, mostraremos el costo total del medicamento cuando no estemos seguros de la repartición de costos, o en caso de que haya alcanzado el deducible o el gasto de bolsillo máximo. No debe gastar más de esta estimación e incluso, existe la posibilidad de que gaste menos.',
  },
  'gpi-suppression': {
    disclaimer:
      'Aviso: según su plan de salud o proveedor en particular, ciertas inquietudes sobre la privacidad pueden requerir que restrinjamos la divulgación de la información de prescripción completa de su dependiente relacionada con la clase de medicamento involucrado. Sin embargo, su dependiente puede acceder a un perfil completo de ahorros para recetas al iniciar sesión en su propia cuenta, si tiene acceso para hacerlo.',
  },
  tags: {
    'primary-pharmacy': 'Farmacia primaria',
    'best-savings': 'Mejores ahorros',
    'home-delivery': 'Entrega a domicilio',
    'three-month-supply': 'Suministro de 3 meses',
  },
  'home-delivery': {
    convenience: {
      from: 'Envío de su receta para:',
      to: 'A esta farmacia',
    },
    'rx-home-delivery': 'ENTREGA A DOMICILIO DE RX',
    'rx-home-delivery-lc': 'Entrega a domicilio de RX',
    'behavior-change-view': {
      convenience: 'Solicite que se lo envíen',
      heading: 'Cambie a una entrega a domicilio conveniente',
      'transfer-prescription-instructions': 'Transfiera su receta de {{drugWithStrength}} de:',
      to: 'para',
      'rx-home-delivery-card': {
        name: 'ENTREGA A DOMICILIO DE RX',
        'shipping-price': 'INCLUYE ENVÍO GRATIS',
      },
      'confirm-step-instructions': 'Confirme su información de contacto y se le enviará la receta.',
      'order-online': 'Compra en línea',
      continue: 'Continuar',
    },
    'contact-prescriber': {
      'complete-switch':
        'Para completar su cambio, será redirigido a Rx Home Delivery para confirmar su información.',
      'confirm-mobile':
        'Ingrese o confirme su número de teléfono celular para que nuestro equipo de soporte de farmacia pueda comunicarse con usted si surge alguna pregunta.',
      'mobile-button-text': 'Confirmar y continuar',
      'new-tab-disclaimer':
        'Nota: El sitio web de Rx Home Delivery se abrirá en una nueva pestaña. Si no lo ve, verifique su bloqueador de ventanas emergentes y asegúrese de que esté desactivado.',
    },
    status: {
      qualifies: 'Cambiar a Rx Home Delivery',
      'switch-submitted': 'Solicitud de cambio enviada',
      'switch-completed': 'Cambiar a completado',
    },
    'external-link-view': {
      header: 'solo un ultimo paso!',
      complete: 'complete este proceso confirmando su información con rx home delivery',
      button: 'ir a rx home delivery',
      done: 'terminado',
      footer:
        'el sitio web de rx home delivery se abrirá en una nueva pagina. Si no lo ve, verifique que su bloqueador de ventanas emergentes esté deshabilitado, o llame a nuestro equipo de soporte al <span class="no-wrap">1-800-268-4476</span> para completar su solicitud',
    },
    confirmation: {
      payment: '¿Aún necesita completar su pedido?',
      'home-delivery': 'Visite Rx Home Delivery',
      'footer-cancel-text':
        'Para cancelar este cambio, comuníquese con nuestro equipo de soporte de farmacia:',
      'contact-info': '1-800-268-4476 de 7 a.m.—8 p.m. CT, de lunes a viernes',
      'free-shipping': 'Incluye envío gratis',
    },
  },
  'telephone-input': {
    'default-error-message': 'Por favor ingrese un número de teléfono válido.',
  },
  'dashboard-value-message-banner': {
    primary:
      'Aproveche las formas sencillas y aprobadas por médicos de pagar menos por sus medicamentos. Cómo funciona:',
    'review-savings': {
      'icon-alt': 'Revise su icono de ahorros',
      header: 'Revise sus ahorros.',
      body: 'Mostramos sus mejores oportunidades para ahorrar.',
    },
    'click-to-switch': {
      'icon-alt': 'Haga clic para cambiar el icono',
      header: 'Haga clic para cambiar.',
      body: 'Manejamos aprobaciones y transferencias con su médico.',
    },
    relax: {
      'icon-alt': 'Icono de relajación',
      header: 'Relajarse.',
      body: 'Observamos los precios las 24 horas del día, los 7 días de la semana y le notificamos cuando algo cambia.',
    },
    'save-up-to': 'Ahorrar hasta',
    'per-year': 'por año',
    'savings-button': 'Ahorrar ahora',
  },
  RemoveAlert: {
    TurnOffAlert: 'Desactivar alerta',
  },
  MedicineCabinetDashboard: {
    NoMedicationsLabel: 'No tiene medicamentos para mostrar en este momento.',
    View: 'Ver',
    ViewAndPrintReport: 'Ver e imprimir informe',
  },
  MedicineListItem: {
    SetReminder: 'Establecer recordatorio',
  },
  BackNavigation: {
    Back: 'Regresar',
  },
  LocatorOptionZipcode: {
    PharmaciesNear: 'Farmacias cercanas',
  },
  Locator: {
    BreadcrumbTextForFindAPharmacy: 'Encuentre una farmacia',
    BreadcrumbTextForSearchResults: 'Resultados de la búsqueda',
    PharmacyResultsFor: 'Resultados de farmacia para:',
    BackToResults: 'Volver a Resultados',
    InNetworkPharmacy: 'Farmacia dentro de la red',
    OutOfNetworkPharmacy: 'Farmacia fuera de la red',
    ShowingPage: 'Mostrando página {{page}} de {{totalPages}}',
  },
  Profile: {
    BreadcrumbText: 'Ajustes del usuario',
    Account: 'Cuenta',
    Notifications: 'Notificaciones',
    Privacy: 'Privacidad',
    RxSSHomeDelivery: 'Entrega',
    CommunicationPreferences: {
      SavingNotificationsHeader: 'Notificación de ahorros',
    },
  },
  PasswordTab: {
    Show: 'Mostrar',
    Hide: 'Esconder',
    PasswordDoesNotMeetRequirements:
      'Cumpla con los siguientes requisitos para crear la contraseña.',
    PleaseEnterAPassword: 'Ingrese una contraseña.',
    Cancel: 'Cancelar',
    Save: 'Ahorrar',
  },
  ContactTab: {
    Edit: 'editar',
    Add: 'agregar',
    Cancel: 'Cancelar',
    Save: 'Ahorrar',
    ChangePrimaryRetailPharmacy: 'Cambiar la farmacia minorista principal',
  },
  AdjustSearch: {
    TotalCost: 'Costo total',
    PerDayCost: 'Costo por día',
  },
  PrescriptionDetails: {
    PrescriptionFilledDate: 'Receta surtida: {{fillDate}}',
  },
  BreadcrumbStore: {
    Dashboard: 'Tablero',
  },
  ReportBody: {
    WhatsTheDifference: '¿Cual es la diferencia?',
  },
  DateSelector: {
    StartDate: 'Fecha de inicio',
    Month: 'Mes',
    Year: 'Año',
    EndDate: 'Fecha final',
    Months: {
      January: 'Enero',
      February: 'Febrero',
      March: 'Marzo',
      April: 'Abril',
      May: 'Mayo',
      June: 'Junio',
      July: 'Julio',
      August: 'Agosto',
      September: 'Septiembre',
      October: 'Octubre',
      November: 'Noviembre',
      December: 'Diciembre',
    },
  },
  CoverageAlert: {
    ClinicalPharmacist: 'Farmacéutico clínico',
    DoctorSays: 'Dr. Green dice:',
  },
  RxHomeDeliveryPharmacyInformation: {
    DeliveryImageAlt: 'Entrega a domicilio de medicamentos recetados',
    GetItDeliveredWith: 'Recíbalo a domicilio con',
    ShipsToYou: 'Envíos a su domicilio',
    IncludesFreeShipping: 'Solicite que se lo envíen',
  },
  HomeDeliveryRequest: {
    RxssLogo: {
      LogoAltText: 'Logotipo de Rx Savings Solutions',
    },
    InsuranceSection: {
      InsuranceCardIntro:
        'Ingrese los detalles de su seguro para transferir su receta. Puede encontrar esta información en su tarjeta del seguro médico o de beneficios de medicamentos recetados.',
    },
    OrderSummarySection: {
      ProcessingFeeItem: {
        Title: 'Cuota de tramitación',
        TooltipIconAltText: 'Icono de información',
        TooltipTitle: '¿A qué se refiere esto?',
        TooltipContent:
          'Incluimos una tarifa de tramitación por realizar un pago en línea para su pedido.',
      },
    },
    SuccessScreen: {
      BodyText:
        'Su solicitud de entrega ha sido enviada. Si se aprueba la transferencia de la receta, le enviaremos una factura con el precio final antes del envío de medicamentos.',
    },
  },
  GenericError: {
    Unavailable: '¡Ups! Ha encontrado una situación poco común aquí.',
    Contact:
      'De vez en cuando, los datos de precios actuales no nos llegan a tiempo. Pedimos disculpas por las molestias. Nuestro equipo de apoyo de farmacia está disponible de lunes a viernes, de 7 a.m. a 8 p.m. CT para encontrar el precio más reciente y más bajo para usted: 1-800-268-4476 o support@rxsavingssolutions.com.',
  },
  MobileAppDeprecationNotice: {
    Header: 'Tenemos una nueva aplicación.',
    Retired: 'Esta versión de nuestra aplicación por ahora ya se ha retirado y no se actualizará.',
    Download:
      'Favor de descargar la nueva aplicación RxSS para tener acceso a todas nuestras nuevas funciones.',
    DownloadButton: 'Descarga La Nueva Aplicación',
    LaterLink: 'Después',
  },
  PageNotFound: {
    Header: '<strong>¿Qué? ¿Qué diablos significa 404?</strong>',
    BodyText:
      'Definitivamente no es la página que está buscando. Podría ser una dirección incorrecta o un enlace desactualizado. No se preocupe: simplemente regrese a la pantalla principal.',
    ButtonText: 'Ir a la pantalla principal',
    GifAltText: 'Error 404 con lupa',
  },
  pageHeader: {
    homeNav: 'Panel principal',
    backNav: 'Volver',
  },
  drugDetails: {
    memberSpendPerDay: '{{memberSpendPerDay}}/día',
    costPerDayLabel: '({{costPerDay}}/día)',
    daySupplyLabel: 'Suministro para {{daySupply}} días',
    planSpendLabel: 'El plan paga:',
    memberSpendLabel: 'Usted paga:',
    quantityAbbreviation: 'Cant: {{quantity}}',
    sideEffectsLabel: 'Efectos secundarios',
    interactionsLabel: 'Interacciones',
  },
  universalSavings: {
    tags: {
      currentMedication: 'Medicamentos que toma actualmente',
      highestSavingsOption: 'Opciones de mayor ahorro',
      shippingTag: 'Simple y conveniente',
    },
    linkCopy: {
      helpMeSwitch: 'Ayuda para hacer el cambio',
      compareMedication: 'Comparar medicamentos',
      seeAllSavings: 'Ver todas las opciones de ahorro',
      seeAllOptions: 'Ver todas las opciones',
      howDoesRxssWork: '¿Cómo funciona RxSS?',
      whyDidWeSuggestThis: '¿Por qué hacemos esta sugerencia?',
      confirm: 'Confirmar',
      confirmPharmacy: 'Confirmar farmacia',
      confirmYourChoice: 'Confirmar su elección',
      confirmSelection: 'Confirmar selección',
      showMorePharmacies: 'Mostrar más farmacias',
    },
    pbmMaxCostCopy: {
      min: 'A tan solo {{savings}}',
      max: '{{savings}} o menos',
    },
    comparePage: {
      header: 'Comparar medicamentos',
      intro: {
        TA: {
          forSelf: {
            withDoctorName:
              'Nuestros farmacéuticos clínicos han sugerido este medicamento. Si quiere <strong>ahorrar {{savings}} por surtido,</strong> nos comunicaremos con su médico/a, {{doctorName}}, para autorizar este cambio.',
            withoutDoctorName:
              'Nuestros farmacéuticos clínicos han sugerido este medicamento. Si quiere <strong>ahorrar {{savings}} por surtido,</strong> nos comunicaremos con su médico/a para autorizar este cambio.',
          },
          forFamily: {
            withDoctorName:
              'Nuestros farmacéuticos clínicos han sugerido este medicamento. Si quiere <strong>ahorrar {{savings}} por surtido,</strong> nos comunicaremos con el/la médico/a de <span data-private>{{memberName}}</span>, {{doctorName}}, para autorizar este cambio.',
            withoutDoctorName:
              'Nuestros farmacéuticos clínicos han sugerido este medicamento. Si quiere <strong>ahorrar {{savings}} por surtido,</strong> nos comunicaremos con el/la médico/a de <span data-private>{{memberName}}</span> para autorizar este cambio.',
          },
        },
        DF: {
          forSelf: {
            withDoctorName:
              'Nuestros farmacéuticos clínicos han sugerido este medicamento. Si quiere <strong>ahorrar {{savings}} por surtido,</strong> nos comunicaremos con su médico/a, {{doctorName}}, para autorizar este cambio.',
            withoutDoctorName:
              'Nuestros farmacéuticos clínicos han sugerido este medicamento. Si quiere <strong>ahorrar {{savings}} por surtido,</strong> nos comunicaremos con su médico/a para autorizar este cambio.',
          },
          forFamily: {
            withDoctorName:
              'Nuestros farmacéuticos clínicos han sugerido este medicamento. Si quiere <strong>ahorrar {{savings}} por surtido,</strong> nos comunicaremos con el/la médico/a de <span data-private>{{memberName}}</span>, {{doctorName}}, para autorizar este cambio.',
            withoutDoctorName:
              'Nuestros farmacéuticos clínicos han sugerido este medicamento. Si quiere <strong>ahorrar {{savings}} por surtido,</strong> nos comunicaremos con el/la médico/a de <span data-private>{{memberName}}</span> para autorizar este cambio.',
          },
        },
        TS: {
          forSelf: {
            withDoctorName:
              'Nuestros farmacéuticos clínicos han sugerido este medicamento. Si quiere <strong>ahorrar {{savings}} por surtido,</strong> nos comunicaremos con su médico/a, {{doctorName}}, para autorizar este cambio.',
            withoutDoctorName:
              'Nuestros farmacéuticos clínicos han sugerido este medicamento. Si quiere <strong>ahorrar {{savings}} por surtido,</strong> nos comunicaremos con su médico/a para autorizar este cambio.',
          },
          forFamily: {
            withDoctorName:
              'Nuestros farmacéuticos clínicos han sugerido este medicamento. Si quiere <strong>ahorrar {{savings}} por surtido,</strong> nos comunicaremos con el/la médico/a de <span data-private>{{memberName}}</span>, {{doctorName}}, para autorizar este cambio.',
            withoutDoctorName:
              'Nuestros farmacéuticos clínicos han sugerido este medicamento. Si quiere <strong>ahorrar {{savings}} por surtido,</strong> nos comunicaremos con el/la médico/a de <span data-private>{{memberName}}</span> para autorizar este cambio.',
          },
        },
        GS: {
          forSelf:
            'Nuestros farmacéuticos clínicos han sugerido este medicamento. Si quiere <strong>ahorrar {{savings}} en su próximo surtido,</strong> lo ayudaremos a hacer el cambio.',
          forFamily:
            'Nuestros farmacéuticos clínicos han sugerido este medicamento. Si quiere <strong>ahorrar {{savings}} en su próximo surtido,</strong> lo ayudaremos a hacer el cambio.',
        },
        GC: {
          forSelf:
            'Nuestros farmacéuticos clínicos han sugerido este medicamento. Si quiere <strong>ahorrar {{savings}} en su próximo surtido,</strong> lo ayudaremos a hacer el cambio.',
          forFamily:
            'Nuestros farmacéuticos clínicos han sugerido este medicamento. Si quiere <strong>ahorrar {{savings}} en su próximo surtido,</strong> lo ayudaremos a hacer el cambio.',
        },
      },
      disclaimer:
        'Aviso legal: la información sobre estas drogas es solo un recurso informativo y puede provenir de una fuente tercerizada externa a Rx Savings Solutions. La información de ninguna manera constituye un consejo médico y debe comunicarse con un/a médico/a antes de tomar o cambiar cualquier medicamento. No hay garantías sobre la exactitud o actualidad de la información relacionada con esta droga. Rx Savings Solutions no respalda ninguna droga ni hace recomendaciones sobre tratamientos en virtud de esta información. Rx Savings Solutions no se hace responsable de ningún reclamo relacionado con la provisión de la información de esta droga.',
    },
    suggestionPage: {
      header: 'Cómo ahorrar al máximo',
      intro: {
        TA: {
          forSelf: {
            withDoctorName: `¡Puede <strong>ahorrar {{savings}} por surtido!</strong> Solo <strong>elija otro medicamento para tratar su afección.</strong> Hablaremos con su médico/a, {{doctorName}}, para autorizar este cambio.`,
            withoutDoctorName: `¡Puede <strong>ahorrar {{savings}} por surtido!</strong> Solo <strong>elija otro medicamento para tratar su afección.</strong> Hablaremos con su médico/a para autorizar este cambio.`,
          },
          forFamily: {
            withDoctorName:
              '¡Puede <strong>ahorrar {{savings}} por surtido!</strong> <span data-private>{{memberName}}</span>  puede <strong>tomar otro medicamento para tratar esta afección.</strong> Hablaremos con el/la médico/a de <span data-private>{{memberName}}</span> , {{doctorName}}, para autorizar este cambio.',
            withoutDoctorName:
              '¡Puede <strong>ahorrar {{savings}} por surtido!</strong> <span data-private>{{memberName}}</span>  puede <strong>tomar otro medicamento para tratar esta afección.</strong> Hablaremos con el/la médico/a de <span data-private>{{memberName}}</span>  para autorizar este cambio.',
          },
        },
        DF: {
          forSelf: {
            withDoctorName:
              '¡Puede <strong>ahorrar {{savings}} por surtido!</strong> Solo <strong>debe tomar este medicamento en un formato diferente.</strong> Los ingredientes activos de su medicamento actual no cambian, solo cambia el formato. Hablaremos con su médico/a, {{doctorName}}, para autorizar este cambio.',
            withoutDoctorName:
              '¡Puede <strong>ahorrar {{savings}} por surtido!</strong> Solo <strong>debe tomar este medicamento en un formato diferente.</strong> Los ingredientes activos de su medicamento actual no cambian, solo cambia el formato. Hablaremos con su médico/a para autorizar este cambio.',
          },
          forFamily: {
            withDoctorName:
              '¡Puede <strong>ahorrar {{savings}} por surtido!</strong> <span data-private>{{memberName}}</span>  puede <strong>tomar este medicamento en un formato diferente.</strong> Los ingredientes activos del medicamento actual no cambian, solo cambia el formato. Hablaremos con el/la médico/a de <span data-private>{{memberName}}</span>, {{doctorName}}, para autorizar este cambio.',
            withoutDoctorName:
              '¡Puede <strong>ahorrar {{savings}} por surtido!</strong> <span data-private>{{memberName}}</span>  puede <strong>tomar este medicamento en un formato diferente.</strong> Los ingredientes activos del medicamento actual no cambian, solo cambia el formato. Hablaremos con el/la médico/a de <span data-private>{{memberName}}</span> para autorizar este cambio.',
          },
        },
        TS: {
          forSelf: {
            withDoctorName:
              '¡Puede <strong>ahorrar {{savings}} por surtido!</strong> Solo <strong>debe dividir una tableta de una dosis mayor en dos para tomar la misma dosis que toma actualmente.</strong> Hablaremos con su médico/a, {{doctorName}}, para autorizar este cambio.',
            withoutDoctorName:
              '¡Puede <strong>ahorrar {{savings}} por surtido!</strong> Solo <strong>debe dividir una tableta de una dosis mayor en dos para tomar la misma dosis que toma actualmente.</strong> Hablaremos con su médico/a para autorizar este cambio.',
          },
          forFamily: {
            withDoctorName:
              '¡Puede <strong>ahorrar {{savings}} por surtido!</strong> Solo <strong>debe dividir una tableta de una dosis mayor en dos para obtener la misma dosis que <span data-private>{{memberName}}</span> toma actualmente.</strong> Hablaremos con el/la médico/a de <span data-private>{{memberName}}</span>, {{doctorName}}, para autorizar este cambio.',
            withoutDoctorName:
              '¡Puede <strong>ahorrar {{savings}} por surtido!</strong> Solo <strong>debe dividir una tableta de una dosis mayor en dos para obtener la misma dosis que <span data-private>{{memberName}}</span> toma actualmente.</strong> Hablaremos con el/la médico/a de <span data-private>{{memberName}}</span> para autorizar este cambio.',
          },
        },
        GS: {
          forSelf:
            '¡Puede <strong>ahorrar {{savings}} por surtido!</strong> Solo <strong>debe tomar una versión genérica de su medicamento actual.</strong> Incluso podemos ayudarle a hacer el cambio.',
          forFamily:
            '¡Puede <strong>ahorrar {{savings}} por surtido!</strong> <span data-private>{{memberName}}</span>  puede simplemente <strong>tomar una versión genérica de su medicamento actual.</strong> Incluso podemos ayudarle a hacer el cambio.',
        },
        GC: {
          forSelf:
            '¡Puede <strong>ahorrar {{savings}} por surtido!</strong> Simplemente <strong>tome una versión genérica que sea idéntica a su medicamento actual.</strong> Incluso podemos ayudarle a hacer el cambio.',
          forFamily:
            '¡Puede <strong>ahorrar {{savings}} por surtido!</strong> <span data-private>{{memberName}}</span>  puede simplemente <strong>tomar una versión genérica que sea idéntica a su medicamento actual.</strong> Incluso podemos ayudarle a hacer el cambio.',
        },
      },
      tags: {
        current: 'Medicamento que toma actualmente',
        highest: 'Opción de mayor ahorro',
      },
      supportCopy:
        'Nuestro farmacéutico clínico seleccionó este medicamento. <a>¿Por qué lo sugerimos?</a>',
      linkCopy: {
        helpMeSwitch: 'Ayuda para hacer el cambio',
        compareMedication: 'Comparar medicamentos',
        seeAllSavings: 'Ver todas las opciones de ahorro',
        howDoesRxssWork: '¿Cómo funciona RxSS?',
      },
      disclaimer:
        'Estos precios son cálculos personalizados y podrían no reflejar el precio total real. Los cálculos se basan en el plan de su medicamento recetado al día de la fecha. El costo real podría cambiar debido a los descuentos o cargos que cualquier farmacia en particular podría aplicar. No base su decisión de compra solo en estos cálculos.',
      medIcons: {
        altText: {
          pillBottleWithTabletsInside: 'Frasco lleno de tabletas',
          prescriptionBottle: 'Frasco de medicamentos recetados',
          medicineBottle: 'MFrasco de medicamentos',
          pillBottle: 'Frasco de pastillas',
          tablet: 'Tableta',
          tabletSplitInTwo: 'Tableta dividida en dos',
          openCapsuleSprinklingContents: 'Open capsule sprinkling contents',
          capsuleAndTablet: 'Cápsula y tableta',
        },
      },
    },
    fulfillmentPage: {
      header: {
        default: '¿Cómo quiere surtir esta receta?',
        shippingOnly: '¿Cómo desea que le entreguen estos medicamentos recetados?',
      },
      pickUpCardLbl: 'Retiro',
      shippingCardLbl: 'Envío',
      intro: {
        drugChange: {
          forSelf: {
            withDoctorName:
              'Hablaremos con {{doctorName}} sobre el cambio. Confirme cómo quiere surtir esta receta abajo.',
            withoutDoctorName:
              'Hablaremos con su médico/a sobre el cambio. Confirme cómo quiere surtir esta receta abajo.',
          },
          forFamily: {
            withDoctorName:
              'Hablaremos con {{doctorName}} sobre el cambio de <span data-private>{{memberName}}</span>. Confirme cómo quiere surtir esta receta abajo.',
            withoutDoctorName:
              'Hablaremos con el/la médico/a sobre el cambio de <span data-private>{{memberName}}</span>. Confirme cómo quiere surtir esta receta abajo.',
          },
        },
        requestDeliveryCopy:
          'Reciba sus medicamentos recetados en su hogar eligiendo una de las siguientes opciones.',
        genericChange: 'Confirme cómo quiere surtir esta receta abajo.',
      },
      confirmBtn: 'Confirmar',
      changePharmacyBtn: 'Cambiar de farmacia',
      disclaimer:
        'Estos precios son cálculos personalizados y podrían no reflejar el precio total real. Los cálculos se basan en el plan de su medicamento recetado al día de la fecha. El costo real podría cambiar debido a los descuentos o cargos que cualquier farmacia en particular podría aplicar. No base su decisión de compra solo en estos cálculos.',
      pharmaciesListLbl: 'Farmacias cercanas:',
      shippingCopy: {
        shippingOptionsLbl: 'Opciones de envío:',
        cardLabel: 'Envíos a su domicilio',
        pharmacies: {
          base: 'Envíos convenientes a su domicilio',
          withInsurance: 'Costo con seguro',
        },
        medicaidNote:
          'La opción de costo sin seguro no tiene relación con su seguro, y en algunos casos no se puede usar junto con otros programas federales o financiados por el estado, como Medicare o Medicaid.',
        shippingFeesNote:
          'Es posible que se apliquen cargos administrativos y de envío adicionales.',
      },
      toolTip: {
        cash: 'Esta opción no utiliza su seguro de salud, por lo que el monto que paga no contará para su deducible. Dependiendo de su plan, puede presentar una reclamación por separado a su compañía de seguros de salud.',
        insurance:
          'Esta opción usa su seguro de salud, por lo que el monto que paga se aplicará a su deducible.',
      },
    },
    pharmacistNote: {
      stone: {
        name: 'Dra. Nikki Stone, PharmD',
        jobDetails: 'Farmacéutica autorizada desde 2004',
        imageAltText: 'Dra. Nikki Stone',
      },
      green: {
        name: 'Dr. Jeff Green, PharmD',
        jobDetails: 'Farmacéutica autorizada desde 2004',
        imageAltText: 'Dr. Jeff Green',
      },
      note: {
        TA: {
          forSelf: `"{{drugName}} es una alternativa común para su tratamiento actual y es segura y efectiva".`,
          forFamily: `"{{drugName}} es una alternativa común para el tratamiento actual de <span data-private>{{memberName}}</span>'s y es segura y efectiva".`,
        },
        DF: {
          forSelf: `"{{drugName}} es solo un formato diferente con los ingredientes activos de su medicamento actual y es seguro y efectivo para la mayoría de los pacientes".`,
          forFamily: `"{{drugName}} es solo un formato diferente con los ingredientes activos del medicamento actual de <span data-private>{{memberName}}</span>'s y es seguro y efectivo para la mayoría de los pacientes".`,
        },
        TS: {
          forSelf: `"Al dividir una tableta de una dosis mayor a la mitad, puede obtener la misma dosis que toma actualmente y ahorrar dinero".`,
          forFamily: `"Al dividir una tableta de una dosis mayor a la mitad, <span data-private>{{memberName}}</span>'s puede obtener la misma dosis que toma actualmente y ahorrar dinero".`,
        },
        GS: {
          forSelf: `"Esta es una versión genérica de su medicamento actual, que usa los mismos ingredientes activos".`,
          forFamily: `"Esta es una versión genérica del medicamento actual de <span data-private>{{memberName}}</span>, que usa los mismos ingredientes activos".`,
        },
        GC: {
          forSelf: `"Esta es una opción genérica idéntica a su medicamento actual".`,
          forFamily: `"Esta es una opción genérica idéntica al medicamento actual de <span data-private>{{memberName}}</span>".`,
        },
      },
    },
  },
  logoAltText: 'Logotipo de RxSS',
  tooltipIconAltText: 'Información',
  costWithoutInsurance: 'Costo sin seguro',
};
