/* @flow */
import Model from '@data/models/Model';
import { computed, observable } from 'mobx';
import type { BasePharmacy } from '@features/savings/types/Pharmacy';

class BaseMember extends Model {
  authenticatedMemberId: string;

  @observable accumulator: {||};

  @observable member_mobile_platforms: Array<any>;

  /**
   * @deprecated Deprecated: Use primary_retail_pharmacy instead. See PORTAL-1920 for details
   * {@link https://rxsavings.atlassian.net/browse/PORTAL-1920}
   * @type {Object}
   */
  @observable preferred_retail_pharmacy: {||};

  @observable primary_retail_pharmacy: ?BasePharmacy;

  @observable first_name: string;

  @observable last_name: string;

  @observable date_of_birth: string;

  @observable phone_number: string;

  @observable mobile_number: ?string;

  @observable street_address: string;

  @observable secondary_address: string;

  @observable city: string;

  @observable state: string;

  /**
   * Boolean integer 1 or 0.
   * Consider coercing into a boolean in the future.
   * @type {number}
   */
  @observable seen_sms_modal: number;

  /**
   * Boolean integer 1 or 0.
   * Consider coercing into a boolean in the future.
   * @type {number}
   */
  @observable sms_welcome_sent: number;

  @observable age: number;

  @observable notification_preferences: Array<{||}>;

  @observable accessible: boolean;

  @observable can_be_invited: boolean;

  @observable registered_since: string;

  @observable email: string;

  @observable zip: string;

  @observable latitude: number;

  @observable longitude: number;

  @observable address: {||};

  @observable alerts: Array<{||}>;

  @observable pharmacies: Array<BasePharmacy>;

  @observable health_plan_info: {||};

  @observable subgroup: {
    preferred_pharmacy_id: string | null,
  };

  @observable is_primary_account_holder: boolean;

  @observable permissions: {
    access_phi: boolean,
    access_non_phi: boolean,
    make_healthcare_decisions: boolean,
    admin_logged_in: boolean,
  };

  @observable family: Array<{||}>;

  constructor({ json, authenticatedMemberId }: { json: {||}, authenticatedMemberId: string }) {
    super();
    this.id = json.id;
    this.authenticatedMemberId = authenticatedMemberId;
    this.updateFromJson(json);
  }

  updateFromJson(json: {||}) {
    // Assign all json keys to 'this' properties
    Object.assign(this, json);
  }

  @computed
  get isAuthenticatedMember(): boolean {
    return this.id === this.authenticatedMemberId;
  }

  @computed
  get primaryPharmacyNcpdp() {
    try {
      return this.primary_retail_pharmacy.ncpdp;
    } catch (error) {
      Rollbar.error(error);
      return undefined;
    }
  }

  @computed
  get planPreferredPharmacyId(): ?number {
    try {
      return Number(this.subgroup.preferred_pharmacy_id);
    } catch (error) {
      Rollbar.error(error);
      return undefined;
    }
  }
}

export default BaseMember;
