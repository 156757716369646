import { CookieStorage } from 'cookie-storage';
import { getDomainFromUrl } from '@utils/Functions';
import SessionManager from '../session-manager';

let domain;
try {
  domain = '.' + getDomainFromUrl(window.api_url || global.api_url);
} catch (e) {
  Rollbar.log('error', e);
}

const cookieStorage = new CookieStorage({
  path: '/',
  domain,
  secure: false,
});

/**
 * Helper class to manage browser Cookie storage
 * @type {SessionManager}
 */
const CookieStorageManager = new SessionManager(cookieStorage);

export { CookieStorageManager };
