/* @flow */

import PropTypes from 'prop-types';
import type { ArrayPricesWithIds } from '@features/savings/types/Price';

/**
 * Type definition for base product response from various APIs, including:
 *  claim.product
 *  suggestions.suggested_products
 */
export type BaseProduct = {
  id: number,
  primary_name: string,
  secondary_name: string,
  form: string,
  form_plural: string,
  strength: string,
  image_url: string,
  medication_details: {
    side_effects: string,
    details: string,
    interactions: string,
  },
};

/**
 * Base product that also has a prices object
 */
export type PricedProduct = BaseProduct & ArrayPricesWithIds;

/**
 * Array of products with prices and drug info
 */
export type SuggestedProducts = Array<PricedProduct>;

/**
 * Used in place of PricedProduct sometimes, but has a completely different data shape so be careful with type checks!
 */
export type Ndcs = {
  id: number,
  pbm_id: null,
  contact_prescriber_id: number,
  ndc: string,
  quantity: number,
  quantity_with_unit: string,
  member_cost: number,
  client_cost: number,
  package: {
    details: {
      id: number,
      ndc: string,
      imprint_front: string,
      imprint_back: null,
      color: string,
      shape: string,
      manufacturer: null,
      image_url: string,
    },
    product: BaseProduct,
    id: number,
    ndc: string,
    description: string,
    unit: string,
    size: string,
    mdq: 1,
    inner_count: null,
    clone: 0,
    otc: 0,
  },
};

/**
 * Used in place of SuggestedProduct sometimes, but has a completely different data shape so be careful with type checks!
 */
export type SuggestedNdcs = Array<Ndcs>;

/**
 * Derived product details with pricing for quick access.
 * Not representative of original API response.
 */
export type ComputedPriceProduct = {
  quantity: number,
  day_supply: number,
  cost_per_refill: number,
  cost_per_day: number,
  primary_name: string,
  secondary_name: string,
  form: string,
  strength: string,
  attributes: { plan_pay: ?number },
};

/**
 * Derived product details with pricing for quick access.
 * Not representative of original API response.
 */
export const ComputedPriceProductPropType = PropTypes.shape({
  quantity: PropTypes.number.isRequired,
  day_supply: PropTypes.number.isRequired,
  cost_per_refill: PropTypes.number.isRequired,
  cost_per_day: PropTypes.number.isRequired,
  primary_name: PropTypes.string.isRequired,
  secondary_name: PropTypes.string.isRequired,
  form: PropTypes.string.isRequired,
  strength: PropTypes.string.isRequired,
});
