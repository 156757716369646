import { Factory } from 'miragejs';

/**
 * Formats the given suggestion model as a Super Alert on an opportunity
 * - Currently only called indirectly through the opportunity factory's `withSuperAlert` trait
 */
export const expressSuggestionFactory = Factory.extend({
  display_info() {
    return this.suggestion.express_display_info;
  },
});
