import merge from 'lodash.merge';

/**
 * Function for merging different translation files into a single translation object the application can use.
 *
 * @param {Object} rxssTranslations - The default translations to use
 * @param {Object} customTranslations - Client-specific translations used to override the default translations.
 *
 * @return {Object} - An object with a "translation" property that deeply merges the two translations provided.
 */
export default function mergeTranslations(rxssTranslations, customTranslations) {
  const updatedTranslations = {};

  merge(updatedTranslations, rxssTranslations, customTranslations);
  return {
    translation: updatedTranslations,
  };
}
