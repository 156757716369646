import BaseAlert from '../BaseAlert/BaseAlert';
import AlertTypes from '../AlertTypes';

class PrimaryRetailAlert extends BaseAlert {
  constructor(info) {
    super(info);
    const { alertInfo, navigateToUser, navigate, exclude } = info;
    this.modelName = AlertTypes.PRIMARY_RETAIL;
    this.navigateToUser = navigateToUser;
    this.navigate = navigate;
    this.exclude = exclude;
    this.showRemoveAlertBar = false;
    this.icon = 'primary-pharmacy';
    this._headerText = this.translate(
      `primary-pharmacy-alerts.${alertInfo.alert_type.replace('primary-retail-', '')}.header`,
    );
    this.hasSavings = ![
      'primary-retail-confirmed',
      'primary-retail-updated',
      'primary-retail-ignored',
    ].includes(alertInfo.alert_type);
    switch (alertInfo.alert_type) {
      case 'primary-retail-confirm':
        this._mobileButtonText = this.translate('primary-pharmacy-alerts.confirm.buttonText');
        this._buttonAreaText = '';
        this.buttonText = this.translate('primary-pharmacy-alerts.confirm.buttonText');
        this._footerText = this.translate('primary-pharmacy-alerts.confirm.footerText');
        this.footerTextLink = this.translate('primary-pharmacy-alerts.confirm.footerTextLink');
        this.bodyLink = '';
        this.bodyText = this.translate('primary-pharmacy-alerts.confirm.body');
        break;
      case 'primary-retail-confirmed':
        this.bodyLink = this.translate('primary-pharmacy-alerts.confirmed.bodyLink');
        this.footerTextLink = '';
        this.bodyText = this.translate('primary-pharmacy-alerts.confirmed.body');
        break;
      case 'primary-retail-update':
        this._mobileButtonText = this.translate('primary-pharmacy-alerts.update.buttonText');
        this._buttonAreaText = '';
        this.buttonText = this.translate('primary-pharmacy-alerts.update.buttonText');
        this.footerTextLink = this.translate('primary-pharmacy-alerts.update.footerTextLink');
        this.bodyLink = '';
        this.bodyText = this.translate('primary-pharmacy-alerts.update.body');
        break;
      case 'primary-retail-updated':
        this.footerTextLink = '';
        this.bodyLink = this.translate('primary-pharmacy-alerts.updated.bodyLink');
        this.bodyText = this.translate('primary-pharmacy-alerts.updated.body');
        break;
      case 'primary-retail-ignored':
        this._footerText = this.translate('primary-pharmacy-alerts.ignored.footerText');
        this.footerTextLink = this.translate('primary-pharmacy-alerts.ignored.footerTextLink');
        this.bodyLink = '';
        break;
    }
    this.footerLinkAction = this.footerLinkAction.bind(this);
    this.bodyLinkAction = this.bodyLinkAction.bind(this);
  }

  get headerText() {
    return this._headerText;
  }

  get footerText() {
    return this._footerText;
  }

  get mobileButtonText() {
    return this._mobileButtonText;
  }

  get buttonAreaText() {
    return this._buttonAreaText;
  }

  get pharmacyInfo() {
    switch (this.alertType) {
      case 'primary-retail-ignored':
        return this.activeMember.preferred_retail_pharmacy;
      case 'primary-retail-updated':
        return false;
      default:
        return this.alertObject.pharmacy;
    }
  }

  footerLinkAction(ncpdp) {
    if (
      this.alertType === 'primary-retail-confirm' ||
      this.alertType === 'primary-retail-ignored'
    ) {
      this.router.history.push(
        this.navigateToUser({
          id: this.id,
          alert_type: this.alertObject.alert_type,
        }),
      );
    } else if (this.alertType === 'primary-retail-update') {
      this.exclude(ncpdp, this.id).then(() => {
        this.alertType = 'primary-retail-ignored';
        this.hasSavings = false;
        this._footerText = this.translate('primary-pharmacy-alerts.ignored.footerText');
        this.footerTextLink = this.translate('primary-pharmacy-alerts.ignored.footerTextLink');
        this.bodyLink = '';
      });
    }
  }

  onClickAction() {
    this.navigate(this.alertObject);
  }

  bodyLinkAction() {
    if (
      this.alertType === 'primary-retail-confirmed' ||
      this.alertType === 'primary-retail-updated'
    ) {
      this.router.history.push(
        this.navigateToUser({
          id: this.id,
          alert_type: this.alertType,
        }),
      );
    }
  }
}

export default PrimaryRetailAlert;
