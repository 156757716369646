import moment from 'moment';

const formatTime = (time) => {
  if (!time) {
    return false;
  }
  const timeObj = moment('2000-01-01 ' + time.slice(0, 2));
  return `${timeObj.format('h:00')} ${timeObj
    .format('a')
    .split('')
    .map((letter) => `${letter}.`)
    .join('')}`;
};

const parseTimeString = (timeString) => {
  if (!timeString) {
    return false;
  }
  return Math.floor(parseInt(timeString) / 100);
};

/**
 * Gets a given pharmacy's hours for rendering from their provided hours.
 *
 * @param {Object} pharmaciesOpenHours - pharmacy's provided hours
 * @param {Array} pharmaciesOpenHours.periods - pharmacy's specific hours during the week
 */
const getPharmacyHours = (pharmaciesOpenHours) => {
  if (!pharmaciesOpenHours || !pharmaciesOpenHours.periods) {
    return {
      key: 'no-hours',
      data: null,
    };
  }

  const { periods } = pharmaciesOpenHours;
  const now = moment();
  const currentDayIndex = now.weekday();
  const currentTime = parseTimeString(now.format('HH00'));
  const today = periods[currentDayIndex];
  const tomorrow = periods[currentDayIndex + 1 === periods.length ? 0 : currentDayIndex + 1];
  const openTime = today.open && parseTimeString(today.open.time);
  const closeTime = today.close && parseTimeString(today.close.time);
  const displayOpenTime =
    (currentTime >= openTime || openTime === false) &&
    (currentTime > closeTime || closeTime === false)
      ? formatTime(tomorrow.open.time)
      : formatTime(today.open.time);

  if (openTime === 0 && closeTime === 0) {
    return {
      key: 'open-24-hours',
      data: null,
    };
  }
  if (currentTime >= openTime && (closeTime === 0 || currentTime < closeTime)) {
    return {
      key: 'open-closes-at',
      data: { closes: formatTime(today.close.time) },
    };
  }

  if (openTime !== false && displayOpenTime) {
    return {
      key: 'closed-opens-at',
      data: { opens: displayOpenTime },
    };
  }
  return {
    key: 'closed-today',
    data: null,
  };
};

export default getPharmacyHours;
