import BaseAlert from '../BaseAlert/BaseAlert';
import AlertTypes from '../AlertTypes';

class MessageAlert extends BaseAlert {
  constructor(info) {
    super(info);
    const { alertInfo } = info;
    this.modelName = AlertTypes.MESSAGE;
    this.icon = alertInfo.icon;
    this.showRemoveAlertBar = false;
    this._headerText = alertInfo.header_text;
    this.hasSavings = false;
    this.alertId = alertInfo.id;
    this.elaboratedMessage = alertInfo.body_text;
    this.alertLinkText = alertInfo.link_text;
    this.alertLinkUrl = alertInfo.link_url;
    this.breadCrumbText = alertInfo.breadcrumb_text;
    this.bodyText = alertInfo.short_body_text;
  }

  get headerText() {
    return this._headerText;
  }
}

export default MessageAlert;
