/**
 * Helper for pendo.track
 * Takes the event type and an optional payload object or key value pairs to pass to pendo
 *
 * @param {string} eventType The type of Pendo Event that is being tracked
 * @param {object} payload An object of key value pairs to pass in the Pendo event
 */
export default function pendoTrack(eventType, payload) {
  try {
    window.pendo.track(eventType, payload);
  } catch (e) {
    Rollbar.warn(`Pendo Track Event ${eventType} failed: ${e}`);
  }
}
