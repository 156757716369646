export default {
  questions: {
    header: '',
    text: 'Our Member Service Associates are standing by to take your calls 8 a.m. – 6 p.m. ET Monday through Friday, toll free:',
    number: '(855) 624-6463',
  },
  'suggestion-list-wrapper': {
    disclaimer:
      'These prices are personalized estimates and may not reflect the actual total price paid at the pharmacy. The estimates are based upon your prescription drug plan as of today. However, the actual cost may differ because of discounts or charges that a particular pharmacy may apply. You should not rely exclusively on this price estimate or any price estimate alone to make your healthcare purchasing decisions.',
  },
  footer: {
    leftText: {
      body: `
          Our Member Service Associates are standing by to take your calls 8 a.m. – 6 p.m. ET Monday through Friday, toll free: {{number}}
        `,
    },
  },
  'family-signup': {
    verified:
      'Due to the age of this dependent, privacy laws prevent us from showing you their medications or savings alerts. To access this feature, your dependent needs to log in to their own account.',
    activate: 'Should we ask them to activate their account?',
    emailOnBehalf:
      'We can send them an email on your behalf. Just enter their email address below.',
    emailRequired: 'Email Address Required',
    ccMe: 'Please CC me on this email.',
    messageSent:
      'We’ve sent a message to {{firstName}} for you, and it should arrive any time now. This dependent can reply directly to you with any questions or contact a Member Services representative at {{phone}}.',
    email: "Dependent's Email Address",
    button: 'Send Email',
  },
};
