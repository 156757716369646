/* @flow */

import { action, observable } from 'mobx';
import axios from '@utils/axios/axios';

class FamilyPermissionsStore {
  @observable userStates = {};

  @action updateUserState(user_id, state_obj) {
    this.userStates[user_id] = state_obj;
    this.userStates = { ...this.userStates };
  }

  // request permission to view requestedMembers alerts/medications
  @action.bound
  async requestPermission(currentMemberId, requestedMemberId) {
    const request = await axios.post(`/v2/${currentMemberId}/family/${requestedMemberId}/request`);
    return request;
  }

  // grant permission to grantedMember to view current members alert/medications
  @action.bound
  async grantPermission(currentMemberId, grantedMemberId) {
    const request = await axios.post(`/v2/${currentMemberId}/family/${grantedMemberId}/grant`);
    return request;
  }

  // revoke permission for revokedMember to see current members alerts/medications
  @action.bound
  async revokePermission(currentMemberId, revokedMemberId) {
    const request = await axios.post(`/v2/${currentMemberId}/family/${revokedMemberId}/revoke`);
    return request;
  }
}

const familyPermissionsStore = new FamilyPermissionsStore();

export default familyPermissionsStore;
export { FamilyPermissionsStore };
