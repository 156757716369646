/* @flow */

import { action, computed, observable } from 'mobx';
import { alertApi } from '@data/api';
import BaseModel from '@data/models/AlertItemTypes/BaseAlert/BaseAlert';
import { AlertTypes } from '@data/models/AlertItemTypes';
import { redirectType } from '@features/savings/utils/savingsDeepLinkRedirectConstants';
import { MemberStore } from '@stores_old/memberStore/memberStore';
import { alertLoadingStates } from './alertLoadingStates';
import { alertTypes } from './alertTypes';

export class AlertListStore {
  memberStore: MemberStore;

  childStores: Object;

  promiseCache: Object;

  cancel: Function;

  @observable state: string;

  @observable claims: Array<Object>;

  @observable alerts: Object;

  @observable currentAlertId: ?number;

  constructor({ memberStore, childStores }: { memberStore: MemberStore, childStores: Object }) {
    this.memberStore = memberStore;
    this.promiseCache = {};
    this.claims = [];
    this.alerts = [];
    this.cancel = null;
    this.childStores = childStores;
    this.state = alertLoadingStates.UNLOADED;
    this.currentAlertId = null;
  }

  @computed
  get memberId(): string {
    return (this.memberStore.activeMember || {}).id;
  }

  @computed
  get loadingState(): string {
    return this.state;
  }

  @computed
  get alertItems() {
    return this.alerts || [];
  }

  @computed
  get currentAlert() {
    return this.alerts.collectionName !== 'Alerts' ? {} : this.alerts.getById(this.currentAlertId);
  }

  /**
   * Returns the reason that a saving alert deep link had to redirect
   *
   * @returns {string}
   */
  @computed
  get savingsRedirectFailureReason() {
    if (
      this.filteredSavingsAlerts.length === 1 &&
      this.filteredSavingsAlerts[0].modelName !== AlertTypes.EXPRESS
    ) {
      return redirectType.NOT_SUPER_ALERT;
    } else if (this.filteredSavingsAlerts.length > 1) {
      return redirectType.MULTIPLE_SAVINGS;
    } else if (this.filteredSavingsAlerts.length < 1) {
      return redirectType.NO_SAVINGS;
    } else {
      return '';
    }
  }

  /**
   * Determines if a contact prescriber has been abandoned by filtering viewed savings alerts that do NOT have an associated contact prescriber
   * @returns {boolean}
   */
  @computed
  get hasContactPrescriberBeenAbandoned() {
    const abandonedAlerts = this.alertItems.filter((alert) => {
      return (
        alert.viewed_at &&
        this.doesAlertHaveSavings(alert) &&
        !(
          // contact_prescriber_temp is for the mobx store to hold until cp is submitted to the api
          (
            alert.alertObject.medication_claim?.contact_prescriber_temp ||
            alert.alertObject.medication_claim?.contact_prescriber
          )
        )
      );
    });

    return abandonedAlerts.length > 0;
  }

  /**
   * Returns true if the alert is a savings alert, else false
   * @param {BaseAlert} alert
   * @returns {boolean}
   */
  doesAlertHaveSavings(alert) {
    return (
      alert.alertObject.alert_type === alertTypes.SAVINGS ||
      alert.alertObject.alert_type === alertTypes.MAILORDER
    );
  }

  /**
   * Returns a super alert if one and only one savings alert that is a super alert exists for the active member, else returns null, indicating the member has 0 or > 1 savings alerts
   */
  @computed
  get singleSavingsSuperAlert() {
    return this.filteredSavingsAlerts.length == 1 &&
      this.filteredSavingsAlerts[0].modelName === AlertTypes.EXPRESS
      ? this.filteredSavingsAlerts[0]
      : null;
  }

  /**
   * Returns all savings alerts
   * @returns {*}
   */
  @computed
  get filteredSavingsAlerts() {
    return this.alertItems.filter((alert) => {
      return this.doesAlertHaveSavings(alert);
    });
  }

  /**
   * This is a sorting function for alerts that will sort non-viewed alerts before viewed alerts, and
   * then sort by savings within those groupings, with higher savings amounts being higher in the list
   *
   * @param {BaseModel} a
   * @param {BaseModel} b
   * @returns {number}
   */
  static sortBySavings(a: BaseModel, b: BaseModel) {
    if (a.viewed_at === b.viewed_at) {
      const aSavings = a.alertObject.express_suggestion
        ? a.alertObject.express_suggestion.display_info.savings
        : a.alertObject.refill_savings;
      const bSavings = b.alertObject.express_suggestion
        ? b.alertObject.express_suggestion.display_info.savings
        : b.alertObject.refill_savings;
      return bSavings - aSavings;
    }
    return a.viewed_at === null ? -1 : 1;
  }

  @computed
  get hasClaims(): boolean {
    return !!this.claims && !!this.claims.length;
  }

  /**
   *
   * Determines whether the current member's alerts have any savings. This method is being deprecated because the hasSavings property has been determined to
   * be an attribute that indicates user actions on the AlertList for a member.
   * @deprecated
   * @returns { boolean }
   */
  @computed
  get hasAlertsWithSavings(): boolean {
    return !!this.alertItems.find((alert) => alert.hasSavings);
  }

  /**
   *
   * Determines whether the current member's alerts have any savings
   *
   * @returns { boolean }
   */
  @computed
  get activeMemberHasAlertsWithSavings(): boolean {
    return this.filteredSavingsAlerts.length > 0;
  }

  /**
   *
   * Sorts the raw list of the current member's alerts
   *
   * @returns { Array }
   */
  @computed
  get sortedAlertList(): Array<Object> {
    const {
      messages,
      rewardableOpportunities,
      savingsOpportunities,
      contactPrescriberRequests,
      permissionRequests,
      requests,
    } = this.alertItems.reduce(
      (acc, alertItem) => {
        if (alertItem.modelName === 'MessageAlert') {
          acc.messages.push(alertItem);
        } else if (alertItem.modelName === 'RewardableAlert') {
          acc.rewardableOpportunities.push(alertItem);
        } else if (alertItem.hasSavings && alertItem.modelName !== 'PrimaryRetailAlert') {
          acc.savingsOpportunities.push(alertItem);
        } else if (alertItem.modelName === 'ContactPrescriberAlert') {
          acc.contactPrescriberRequests.push(alertItem);
        } else if (alertItem.modelName === 'PermissionRequestAlert') {
          acc.permissionRequests.push(alertItem);
        } else {
          acc.requests.push(alertItem);
        }
        return acc;
      },
      {
        messages: [],
        rewardableOpportunities: [],
        savingsOpportunities: [],
        contactPrescriberRequests: [],
        permissionRequests: [],
        requests: [],
      },
    );
    return [
      ...messages,
      ...rewardableOpportunities.sort(AlertListStore.sortBySavings),
      ...savingsOpportunities.sort(AlertListStore.sortBySavings),
      ...contactPrescriberRequests,
      ...permissionRequests,
      ...requests,
    ];
  }

  /**
   * Determines whether the AlertListEmptyState should show.
   *
   * @returns { boolean }
   */
  @computed
  get alertListEmptyState(): boolean {
    if (this.sortedAlertList.length === 0) {
      return true;
    } else if (this.alertItems.find((alert) => alert.modelName === 'ContactPrescriberAlert')) {
      return false;
    } else if (
      this.alertItems.find(
        (alert) =>
          alert.hasSavings &&
          !(alert.modelName === 'PrimaryRetailAlert' || alert.showInviteDependentButtonArea),
      )
    ) {
      return false;
    } else return true;
  }

  /**
   * This sets the value that the loadingState will return
   *
   * @param {string} newState
   * @returns void
   */
  @action.bound
  setState(newState: string): void {
    this.state = newState;
  }

  /**
   * Updates the active member and fetches their alerts if they haven't been set already;
   *
   * @param {Object} newMember
   * @returns void
   */
  @action.bound
  async setActiveMember(newMember: Object): void {
    const { id: newMemberId } = newMember;

    if (this.memberId !== newMemberId || this.loadingState === alertLoadingStates.UNLOADED) {
      this.memberStore.setActiveMember(newMember);
      typeof this.cancel === 'function' && this.cancel();

      if (this.promiseCache[newMemberId]) {
        return this.promiseCache[newMemberId].then((alertResponse) => {
          this.alerts = alertResponse.alerts;
          this.claims = alertResponse.claims;
          this.setState(alertLoadingStates.LOADED);
        });
      } else {
        this.setState(alertLoadingStates.LOADING);
        const endpoint =
          this.memberStore.currentMember.id === newMemberId ? 'combined-alerts' : 'opportunities';
        const [alertPromise, cancel] = alertApi.loadMember(newMember, endpoint, this.childStores);
        this.cancel = cancel;
        this.promiseCache[newMemberId] = alertPromise;

        return alertPromise
          .then((alertResponse) => {
            this.alerts = alertResponse.alerts;
            this.claims = alertResponse.claims;
            this.cancel = null;
            this.setState(alertLoadingStates.LOADED);
          })
          .catch((err) => {
            this.setState(alertLoadingStates.loaded);
          });
      }
    }
  }

  /**
   *
   * This clears out the promise cache when such behavior is required.
   * @returns void
   */
  @action.bound
  resetPromiseCache() {
    this.promiseCache = {};
  }

  /**
   * This sets the alert id used for computing the current alert.
   *
   * @param {string} newId
   * @returns void
   */
  @action.bound
  setCurrentAlertId(newId: number): void {
    this.currentAlertId = newId;
  }

  /**
   * This resets the current alert id back to null, the default value
   *
   * @returns void
   */
  @action.bound
  resetCurrentAlertId(): void {
    this.currentAlertId = null;
  }

  /**
   * This resets the current alert id back to null, the promise cache to empty object, the state to UNLOADED
   *
   * @returns void
   */
  @action.bound
  resetAlerts(): void {
    this.alerts.clear();
    this.resetPromiseCache();
    this.setState(alertLoadingStates.UNLOADED);
  }

  /**
   * This is for loading a single alert when needed. It isn't supposed to save this to any caching, or
   * prevent the alertListStore from loading the full alerts when setActiveMember is called, however.
   *
   * @param {Object} info - a collection of the alert id and an optional contact prescriber enabled boolean
   * @param {string} argument.alertId - The id of the alert to be loaded
   * @param {boolean} argument.isContactPrescriberEnabled - since we're not getting a full alert response
   *   the isContactPrescriberEnabled function needs to be given manually. By default it will be false.
   * @returns {Promise}
   */
  loadSingleAlert({
    alertId,
    isContactPrescriberEnabled = false,
  }: {
    alertId: string | number,
    isContactPrescriberEnabled: boolean,
  }): Promise<void> {
    this.setState(alertLoadingStates.LOADING);
    return alertApi
      .getSingleAlert(
        alertId,
        this.memberStore.activeMember,
        this.childStores,
        isContactPrescriberEnabled,
      )
      .then((alertResponse) => {
        this.setState(alertLoadingStates.UNLOADED);
        this.setCurrentAlertId(parseInt(alertId));
        this.alerts = alertResponse.alerts;
        this.claims = alertResponse.claims;
      })
      .catch(() => {
        this.setState(alertLoadingStates.UNLOADED);
      });
  }
}
