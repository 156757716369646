/**
 * Enum to store the client configuration keys that are used throughout the application.
 */
export const clientConfigurationKeys = {
  /**
   * A 1-indexed based position where Rx Home Delivery results should appear in the pharmacy picker list in Portal. 1
   * puts it first, 2 puts it 2nd, etc.
   */
  PHARMACY_LIST_RX_HOME_DELIVERY_POSITION: 'pharmacy_list_rx_home_delivery_position',
};
