/**
 * Returns the base domain (domain name + tld) of a given URL string
 * Removes protocol, subdomain, port, path, and query parameters
 *
 * @param {String} urlString - the url string to pull the domain from
 */
function getDomainFromUrl(urlString) {
  const urlWithProtocol = urlString.match(/\w+:\/\//) ? urlString : 'https://' + urlString;
  const { hostname } = new URL(urlWithProtocol);
  return hostname.split('.').slice(-2).join('.');
}

export default getDomainFromUrl;
