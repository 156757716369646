export default Object.freeze({
  CONTACT_PRESCRIBER: 'ContactPrescriberAlert',
  EXPRESS: 'ExpressAlert',
  MAIL_ORDER: 'MailOrderAlert',
  MEMBER: 'MemberAlert',
  MESSAGE: 'MessageAlert',
  PERMISSION_REQUEST: 'PermissionRequestAlert',
  PRIMARY_RETAIL: 'PrimaryRetailAlert',
  REFILL: 'RefillAlert',
  REWARDABLE: 'RewardableAlert',
});
