/* @flow */
import { observable, action, computed } from 'mobx';
import { tz } from 'moment-timezone';
import axios from '@utils/axios/axios';

/**
 * Stores state for all the reminders used in the following components:
 *    ReminderContactPreferences
 *    ReminderListItem (ReminderWizard)
 *    RemindersWeekReview
 *    RemindersReportCard
 *    RemindersCharts
 *    ReminderListItem
 *    Reminders
 *    ReminderAcknowledgement
 *    CommunicationSettings
 *    MedicineListItem
 * @memberof Stores
 */
class RemindersStore {
  @observable message;

  @observable _reminderMedications = [];

  @observable _reminders = [];

  @observable _currentReminder = {};

  @observable _blankReminder = {};

  @observable communication_preference = '';

  @observable remindersLoading = false;

  @observable messageVisible = false;

  @observable messageInterval = null;

  @observable preSelectedProduct = null;

  messageTimeout = 5000;

  generateBlankReminder() {
    return {
      active_weekdays: [
        'sunday',
        'monday',
        'tuesday',
        'wednesday',
        'thursday',
        'friday',
        'saturday',
      ],
      send_time: '',
      timezone: tz.guess(),
      products: [],
    };
  }

  validateResponse(response) {
    const validResponse = response;

    validResponse.medication_reminders = response.medication_reminders.map((reminder) => {
      reminder.enabled = reminder.enabled === true || reminder.enabled === 1;
      return reminder;
    });
    return validResponse;
  }

  @computed get currentReminder() {
    return this._currentReminder;
  }

  @action fetchReminders() {
    this.remindersLoading = true;
    return axios
      .setIsVerbose(false)
      .get(`/v2/${this.memberStore.currentMember.id}/medication-reminders`)
      .then(this.setRemindersFromResponse)
      .catch((err) => {
        Rollbar.warn(err);
      });
  }

  @action setCurrentReminder(newReminder) {
    this._currentReminder = newReminder;
  }

  @action.bound setRemindersFromResponse(data) {
    const response = this.validateResponse(data);
    this._reminders = response.medication_reminders;
    this._reminderMedications = response.products;
    this.communication_preference =
      typeof response.communication_preference === 'string'
        ? response.communication_preference.toLowerCase()
        : '';
    this.remindersLoading = false;
    this.medicineCabinetStore.loadCabinet();
  }

  @action refresh() {
    return axios
      .setIsVerbose(false)
      .get(`/v2/${this.memberStore.currentMember.id}/medication-reminders`)
      .then(this.setRemindersFromResponse)
      .catch((err) => {
        Rollbar.warn(err);
      });
  }

  @action setPreSelectedProduct(newValue) {
    this.preSelectedProduct = newValue;
  }

  @action createReminder(reminder) {
    axios
      .setIsVerbose(false)
      .post(`/v2/${this.memberStore.currentMember.id}/medication-reminders`, reminder)
      .then((response) => {
        this.setRemindersFromResponse(response);
        this.showMessage();
      })
      .catch((err) => {
        Rollbar.warn(err);
      });
  }

  @action updateReminder(reminder) {
    axios
      .setIsVerbose(false)
      .put(`/v2/${this.memberStore.currentMember.id}/medication-reminders/${reminder.id}`, reminder)
      .then(this.replaceResponseReminder)
      .catch((err) => {
        Rollbar.warn(err);
      });
  }

  @action toggleReminder(id) {
    axios
      .setIsVerbose(false)
      .put(`/v2/${this.memberStore.currentMember.id}/medication-reminders/${id}/toggle`)
      .then(this.replaceResponseReminder)
      .catch((err) => {
        Rollbar.warn(err);
      });
  }

  @action removeReminder(id) {
    axios
      .delete(`/v2/${this.memberStore.currentMember.id}/medication-reminders/${id}`)
      .then(() => {
        this.removeResponseReminder(id);
      })
      .catch((err) => {
        Rollbar.warn(err);
      });
  }

  @action.bound replaceResponseReminder({ medication_reminder: medicationReminder }) {
    this._reminders = this._reminders.map((reminder) => {
      return reminder.id === medicationReminder.id ? medicationReminder : reminder;
    });
    this.showMessage();
    this.medicineCabinetStore.loadCabinet();
  }

  @action addResponseReminder(response, duplicateError) {
    const newReminder = response.data.medication_reminder;
    newReminder.enabled =
      response.data.medication_reminder.enabled === true ||
      response.data.medication_reminder.enabled === 1;
    if (
      this._reminders.filter((reminder) => {
        return reminder.id === newReminder.id;
      }).length > 0
    ) {
      duplicateError();
    } else {
      this._reminders.push(newReminder);
    }
    this.showMessage();
    this.medicineCabinetStore.loadCabinet();
  }

  @action removeResponseReminder(id) {
    this._reminders = this._reminders.filter((reminder) => reminder.id !== id);
    this.showMessage();
    this.medicineCabinetStore.loadCabinet();
    this.refresh();
  }

  @action setBlankReminder() {
    this._blankReminder = this.generateBlankReminder();
  }

  @action updateBlankReminder(updatedReminder) {
    this._blankReminder = updatedReminder;
  }

  @computed get reminderMedications() {
    return this._reminderMedications.sort((a, b) => {
      let returnVal = 0;
      if (this.preSelectedProduct) {
        if (this.preSelectedProduct === a.id) {
          returnVal = -1;
        } else if (this.preSelectedProduct === b.id) {
          returnVal = 1;
        } else {
          returnVal = a.name.name.localeCompare(b.name.name);
        }
      } else {
        returnVal = a.name.name.localeCompare(b.name.name);
      }
      return returnVal;
    });
  }

  @computed get reminders() {
    return this._reminders.sort((a, b) => {
      return parseInt(a.send_time.split(':')[0]) - parseInt(b.send_time.split(':')[0]);
    });
  }

  @action showMessage() {
    this.messageVisible = true;
    window.clearInterval(this.messageInterval);
    this.messageInterval = window.setTimeout(() => {
      this.hideMessage();
    }, this.messageTimeout);
  }

  @action hideMessage() {
    this.messageVisible = false;
  }

  @action updateCommunicationPreferences(newVal) {
    return axios
      .put('/members', {
        id: this.memberStore.currentMember.id,
        reminder_preference: newVal,
      })
      .then((resp) => {
        this.refresh();
        return resp;
      })
      .catch((err) => {
        Rollbar.warn(err);
        return err;
      });
  }

  constructor(memberStore: Object, medicineCabinetStore: Object) {
    this.memberStore = memberStore;
    this.medicineCabinetStore = medicineCabinetStore;
    this.setCurrentReminder(this.generateBlankReminder());
    this.setBlankReminder();
  }
}

const remindersStore = new RemindersStore();

export default remindersStore;
export { RemindersStore };
