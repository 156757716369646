export default {
  footer: {
    whitelabel: `
    Rx Savings Solutions (RxSS) es una compañía independiente que tiene contrato directo con Prime para proporcionar consultas de recetas. RxSS no ofrece otros productos o servicios del plan médico y solo es responsable de los productos y servicios que provee. No avala, representa ni garantiza proveedores externos. Los afiliados deben comunicarse directamente con el proveedor si tienen preguntas acerca de los productos o servicios ofrecidos por terceros.
    <br />
    <br />
    Prime Therapeutics LLC es una compañía de administración de beneficios de farmacia separada contratada por Blue Cross and Blue Shield of Texas (BCBSTX) para proporcionar servicios de administración de beneficios de farmacia y otros servicios relacionados. BCBSTX, así como varios planes Blue Cross y Blue Shield independientes, tiene participación patrimonial en Prime Therapeutics.
    <br />
    <br />
    BCBSTX no avala, representa o garantiza proveedores externos ni los productos y servicios ofrecidos que estos ofrecen.
    <br />
    <br />
    Blue Cross and Blue Shield of Texas, una División de Health Care Service Corporation, una Compañía de Reserva Legal Mutua, una licenciataria independiente de Blue Cross and Blue Shield Association
    `,
  },
  'prices-section': {
    'earn-rewards': '¡Gane una recompensa de ${{amount}} en efectivo con Member Rewards!',
    'rewardable-header':
      'Esta opción califica para la recompensa de ${{amount}} en efectivo de Member Rewards.',
  },
  'savings-alert': {
    unsavingsBodyHeader: '¡Gane una recompensa de ${{amount}} en efectivo con Member Rewards!',
    childUnsavingsBodyHeader:
      '¡<span data-private>{{name}}</span> puede ganar una recompensa de ${{amount}} en efectivo con Member Rewards!',
    unsavingsBodyFooter:
      'Reciba una recompensa de ${{amount}} en efectivo por medio de un cheque al cambiar a una alternativa de menor costo (solo con la primera renovación de la receta). El programa Member Rewards es parte de los beneficios de su plan de salud y es administrado por Sapphire Digital.',
    childUnsavingsBodyFooter:
      'Por cambiar a una alternativa de menor costo (solo para la primera renovación de la receta), <span data-private>{{name}}</span> puede recibir una recompensa de ${{amount}} en efectivo mediante un cheque. El programa Member Rewards es parte de los beneficios de su plan de salud y es administrado por Sapphire Digital.',
    unsavingsIconAltText: 'icono de cheque de la recompensa',
  },
  'rx-rewards-ppp': {
    and: '',
    comma: '',
    period: '',
    header:
      '<strong>Sí, ¡envíeme una recompensa de ${{amount}} por cambiar al nuevo medicamento recetado elegible para la recompensa!</strong>',
    terms:
      'Al seleccionar "{{button}}," acepto participar en el programa Member Rewards* ofrecido como parte de los beneficios de mi plan de salud y entiendo que recibiré una recompensa de ${{amount}} en efectivo por medio de un cheque enviado directamente a mi casa por el administrador del programa Sapphire Digital después de que se pague y verifique el reclamo.',
    'agreement-link': '',
    'tos-link': '',
    'privacy-link': '',
    disclaimer:
      '*Los montos de recompensa en efectivo recibidos a través de Member Rewards pueden estar sujetos a impuestos. Las opciones elegibles para la recompensa y los montos de la recompensa están sujetos a cambios.',
  },
  'family-permission': {
    'contact-prescriber': {
      'how-reward':
        '<strong>Cómo hacer el cambio para recibir una recompensa en efectivo de ${{amount}}</strong>',
      toCompleteReward:
        'Para completar el cambio al nuevo medicamento recetado elegible para recompensa y recibir la recompensa de ${{amount}} en efectivo, pídale a <span data-private>{{name}}</span> que inicie sesión en nuestro portal de internet.',
    },
  },
};
