/* @flow */

import { observable, action, computed } from 'mobx';
import axios from '@utils/axios/axios';
import { MemberStore } from '@stores_old/memberStore/memberStore';
import { GlobalModalStore } from '@stores_old/globalModalStore/globalModalStore';
import { UserMessageStore } from '@stores_old/userMessageStore/userMessageStore';
import { LocatorPageStore } from '@stores_old/locatorPageStore/locatorPageStore';

class ProfileStore {
  constructor(
    memberStore: MemberStore,
    globalModalStore: GlobalModalStore,
    userMessageStore: UserMessageStore,
    locatorPageStore: LocatorPageStore,
  ) {
    this.memberStore = memberStore;
    this.globalModalStore = globalModalStore;
    this.userMessageStore = userMessageStore;
    this.locatorPageStore = locatorPageStore;
    this.updateStoreState = this.updateStoreState.bind(this);
    this.toggleShowPassword = this.toggleShowPassword.bind(this);
    this.hidePhoneNumberModal = this.hidePhoneNumberModal.bind(this);
    this.showPhoneNumberModal = this.showPhoneNumberModal.bind(this);
    this.setMobileNumber = this.setMobileNumber.bind(this);
    this.saveValue = this.saveValue.bind(this);
    this.toggleEdit = this.toggleEdit.bind(this);
    this.changePassword = this.changePassword.bind(this);
  }

  memberStore: MemberStore;

  globalModalStore: GlobalModalStore;

  userMessageStore: UserMessageStore;

  locatorPageStore: LocatorPageStore;

  @computed get currentMember() {
    return this.memberStore.currentMember;
  }

  @observable city: null;

  @observable line1: null;

  @observable state: null;

  @observable zip_code: null;

  @observable pharmacyName: null;

  @observable pharmacyCity: null;

  @observable pharmacyStreet: null;

  @observable pharmacyState: null;

  @observable pharmacyZip: null;

  @observable phoneNumber: null;

  @computed get address() {
    return {
      city: this.city || this.currentMember.address.city,
      street: this.line1 || this.currentMember.address.line1,
      state: this.state || this.currentMember.address.state,
      zip: this.zip_code || this.currentMember.address.zip_code,
    };
  }

  setPharmacyAddress(newPharmacy) {
    this.pharmacyName = newPharmacy.name;
    this.pharmacyCity = newPharmacy.address.city;
    this.pharmacyStreet = newPharmacy.address.street;
    this.pharmacyState = newPharmacy.address.state;
    this.pharmacyZip = newPharmacy.address.zipcode;
    this.phoneNumber = this.formatPhoneNumber(newPharmacy.phone);
  }

  @computed get pharmacyAddress() {
    const preferred_retail = this.currentMember.preferred_retail_pharmacy || {
      name: '',
      address: {
        city: '',
        street: '',
        state: '',
        zipcode: '',
      },
      phone: '',
    };
    return {
      pharmacyName: this.pharmacyName || preferred_retail.name,
      pharmacyCity: this.city || preferred_retail.address.city,
      pharmacyStreet: this.pharmacyStreet || preferred_retail.address.street,
      pharmacyState: this.pharmacyState || preferred_retail.address.state,
      pharmacyZip: this.pharmacyZip || preferred_retail.address.zipcode,
      phoneNumber:
        this.formatPhoneNumber(this.phoneNumber) || this.formatPhoneNumber(preferred_retail.phone),
    };
  }

  @observable currentTab: 'Account';

  @observable savingsEmailSwitch: false;

  @observable savingsSMSSwitch: false;

  @observable informationalEmailSwitch: false;

  @observable mobileNumberForm: true;

  @observable emailAddressForm: true;

  @observable addressForm: true;

  @observable passwordForm: true;

  @observable editMobile: null;

  @observable editEmail: null;

  @observable showPassword: false;

  @observable isMinLength: false;

  @observable isSpecialCharacter: false;

  @observable isLowercaseAndUppercaseCharacter: false;

  @observable isEmpty: true;

  @observable requirementsMet: true;

  @observable mobileError: false;

  @observable emailError: false;

  @observable editAddressState;

  @observable editAddressZip;

  @observable editAddressCity;

  @observable editAddressStreet;

  @observable editPassword;

  @observable addressError: false;

  @observable mobile_number: null;

  @observable currentMember_email: null;

  @observable notification_preferences: null;

  @observable smsWelcomeSent: null;

  @observable fromReminders: false;

  @observable fromPrimaryRetailPharmacyAlerts: false;

  @observable primaryPharmacyForm: false;

  @computed get mobileNumber() {
    return this.mobile_number || this.currentMember.mobile_number;
  }

  @computed get mobile() {
    if (this.mobileNumber !== null && this.mobileNumber !== undefined) {
      return this.formatPhoneNumber(this.mobileNumber);
    }
    return null;
  }

  @computed get email() {
    return this.currentMember_email || this.currentMember.email;
  }

  @computed get notificationPreferences() {
    return this.notification_preferences || this.currentMember.notification_preferences;
  }

  @computed get sms_welcome_sent() {
    return this.smsWelcomeSent || this.currentMember.sms_welcome_sent;
  }

  formatPhoneNumber(numb) {
    if (typeof numb !== 'string') {
      return '';
    }
    let val = '';
    let number = numb.replace(/\D/g, '');
    if (number.length > 10) {
      number = number.substr(0, 10);
    }
    const leng = number.length;
    if (leng === 0) {
      val = '';
    }
    if (leng > 0 && leng < 4) {
      val = '(' + number;
    }
    if (leng > 3 && leng < 7) {
      val = `(${number.substr(0, 3)}) ${number.substr(3)}`;
    }
    if (leng > 6) {
      val = `(${number.substr(0, 3)}) ${number.substr(3, 3)}-${number.substr(6)}`;
    }
    return val;
  }

  @action updateStoreState(obj) {
    for (const key in obj) {
      this[key] = obj[key];
    }
  }

  @action navigatedFromReminders() {
    this.fromReminders = true;
  }

  @action resetNavigatedFromReminders() {
    this.fromReminders = false;
  }

  checkPasswordComplexity(pswd) {
    this.updateStoreState({
      // Check the length to be greater than 8 characters
      isMinLength: pswd.length >= 8,
      // Check for at least one lowercase and one uppercase letter
      isLowercaseAndUppercaseCharacter: /^(?=.*[a-z])(?=.*[A-Z]).{1,}$/.test(pswd),
      // Check for at least one special character or digit or a space
      isSpecialCharacter: /^(?=.*[\W_\d]).{1,}$/.test(pswd),
      // Check is the password is an empty string
      isEmpty: pswd.length === 0,
    });
  }

  toggleShowPassword() {
    this.updateStoreState({ showPassword: !this.showPassword });
  }

  toggleSwitch(e, ind) {
    // toggles switches
    e.persist();
    // let {mobile, sms_welcome_sent} = this.props.profileStore;
    // let { memberStore } = this.props;
    const id = e.target.id.split('.')[0];
    const data = e.target.getAttribute('data-toggle').toLowerCase();

    const preferences = JSON.parse(JSON.stringify(this.notificationPreferences[ind]));

    preferences[`allow_${data}`] =
      preferences[`allow_${data}`] !== null ? !preferences[`allow_${data}`] : false;

    if (data === 'sms') {
      (this.mobile && this.sms_welcome_sent) || !preferences.allow_sms
        ? axios
            .put('/v2/notification-preferences', {
              allow_email: preferences.allow_email || false,
              allow_push: preferences.allow_push || false,
              allow_sms: preferences.allow_sms || false,
              notification_type_id: Number(id),
            })
            .then((res) => {
              this.updateStoreState({
                notification_preferences: res.data.members.notification_preferences,
              });
              this.memberStore.refresh();
            })
            .catch(() => {
              this.userMessageStore.open('ERROR');
            })
        : this.showPhoneNumberModal(e, ind);
    } else {
      axios
        .setIsVerbose(false)
        .put('/v2/notification-preferences', {
          allow_email: preferences.allow_email || false,
          allow_push: preferences.allow_push || false,
          allow_sms: preferences.allow_sms || false,
          notification_type_id: Number(id),
        })
        .then(({ members: { notification_preferences: notificationPreferences } }) => {
          this.updateStoreState({
            notification_preferences: notificationPreferences,
          });
          this.memberStore.refresh();
        })
        .catch(() => {
          this.userMessageStore.open('ERROR');
        });
    }
  }

  setMobileNumber(number, event, index) {
    // sets mobile number state, then toggles the modal shut
    if (number) {
      axios
        .put(`/members?id=${this.currentMember.id}`, {
          mobile_number: number,
          attempting_sms: 1,
        })
        .then(() => {
          this.userMessageStore.registerMessage({
            type: 'success',
            message:
              'Thanks for signing up for text message savings alerts. Please check your phone to make sure you received the welcome text!',
            name: 'PasswordUpdateSuccess',
          });
          this.updateStoreState({
            mobile_number: number,
            smsWelcomeSent: 1,
          });
          this.toggleSwitch(event, index);
          this.memberStore.refresh();
        })
        .catch(() => {
          this.userMessageStore.open('ERROR');
        });
    }
    this.globalModalStore.kill();
  }

  showPhoneNumberModal(e, idx) {
    // shows modal number input if field is empty but notifications are toggled
    this.globalModalStore.make(
      'SmsToggleModal',
      {
        setMobileNumber: this.setMobileNumber,
        hidePhoneNumberModal: this.hidePhoneNumberModal,
        event: e,
        index: idx,
      },
      'medium',
    );
  }

  hidePhoneNumberModal() {
    // kills the modal
    this.globalModalStore.kill();
  }

  saveValue(oldState, newState) {
    // saves values of edited fields to state
    if (oldState === 'currentMember_email') {
      axios
        .put(`/members?id=${this.currentMember.id}`, {
          email: newState,
        })
        .then(() => {
          this.updateStoreState({
            [oldState]: newState,
          });
          this.userMessageStore.open('emailUpdateSuccess');
          this.toggleEdit();
          this.memberStore.refresh();
        })
        .catch((err) => {
          this.updateStoreState({
            emailError: `${err.response.data.error.message}`,
          });
        });
    } else if (oldState === 'currentMember_address') {
      axios
        .put(`/members?id=${this.currentMember.id}`, {
          address_line1: newState.street,
          address_city: newState.city,
          address_state: newState.state,
          address_zip_code: newState.zip,
        })
        .then(() => {
          this.updateStoreState({
            [oldState]: newState,
          });
          this.userMessageStore.open('addressUpdateSuccess');
          this.toggleEdit();
          this.memberStore.refresh();
        })
        .catch((err) => {
          this.updateStoreState({
            addressError: `${err.response.data.error.message}`,
          });
        });
    } else {
      axios
        .put(`/members?id=${this.currentMember.id}`, {
          mobile_number: newState,
        })
        .then(() => {
          this.updateStoreState({
            [oldState]: newState,
          });
          this.toggleEdit();
          this.userMessageStore.open('mobileUpdateSuccess');
          this.memberStore.refresh();
        })
        .catch((err) => {
          this.updateStoreState({
            mobileError: `${err.response.data.error.message}`,
          });
        });
    }
  }

  toggleEdit(e) {
    // enables and disabled edit fields
    this.updateStoreState({
      mobileNumberForm: true,
      emailAddressForm: true,
      addressForm: true,
      passwordForm: true,
      editMobile: this.mobile,
      editEmail: this.email,
      uppercase: false,
      lowercase: false,
      numbChar: false,
      isMinLength: false,
      isSpecialCharacter: false,
      isLowercaseAndUppercaseCharacter: false,
      showPassword: false,
      emailError: false,
      mobileError: false,
      addressError: false,
      requirementsMet: true,
      primaryPharmacyForm: true,
    });
    if (e === undefined) {
      this.updateStoreState({
        mobileNumberForm: true,
        emailAddressForm: true,
        addressForm: true,
        passwordForm: true,
      });
    } else if (e.target.id === 'addressForm') {
      this.updateStoreState({
        [e.target.id]: false,
        editAddressStreet: this.address.street,
        editAddressCity: this.address.city,
        editAddressState: this.address.state,
        editAddressZip: this.address.zip,
      });
    } else {
      this.locatorPageStore.allPharmacies = {};
      this.locatorPageStore.ready = false;
      this.updateStoreState({ [e.target.id]: false });
    }
  }

  changePassword(pswd) {
    // sends request to update password
    if (
      this.isSpecialCharacter &&
      this.isLowercaseAndUppercaseCharacter &&
      this.isMinLength &&
      !this.isEmpty
    ) {
      axios
        .put(`/members?id=${this.currentMember.id}`, {
          // Encoding the password to avoid axios from trimming white spaces
          new_password: encodeURIComponent(pswd),
        })
        .then(() => {
          this.userMessageStore.open('passwordUpdateSuccess');
          this.toggleEdit();
          this.memberStore.refresh();
        })
        .catch(() => {
          this.userMessageStore.open('ERROR');
        });
    } else {
      this.updateStoreState({
        requirementsMet: false,
      });
    }
  }

  @action.bound updatePreferredPharmacy(newPharmacy) {
    return axios
      .put('/members', {
        id: this.memberStore.currentMember.id,
        preferred_pharmacy: newPharmacy.id,
        primary_retail_ncpdp: newPharmacy.ncpdp,
      })
      .then((resp) => {
        this.toggleEdit();
        this.memberStore.refresh();
        this.setPharmacyAddress(newPharmacy);
        return resp;
      })
      .catch((err) => {
        Rollbar.warn(err);
        return err;
      });
  }

  @action navigatedFromPrimaryRetailPharmacyAlerts() {
    this.fromPrimaryRetailPharmacyAlerts = true;
  }

  @action resetNavigatedPrimaryRetailPharmacyAlerts() {
    this.fromPrimaryRetailPharmacyAlerts = false;
  }
}

export { ProfileStore };
