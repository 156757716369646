import axios from '@utils/axios/axios';

const checkUserIdentityApi = {
  /**
   * @returns {Promise<{
   *    meta: { doesEnvironmentEnforceUserImpersonationRestrictions: boolean, usingUserImpersonation: boolean }}>
   *  }
   */
  async checkIdentity() {
    try {
      const response = await axios.get(`/user/identity`);

      return {
        meta: {
          usingUserImpersonation: response?.data?.meta?.using_user_impersonation ?? false,
          doesEnvironmentEnforceUserImpersonationRestrictions:
            response?.data?.meta?.does_environment_enforce_user_impersonation_restrictions ?? true,
        },
      };
    } catch (error) {
      Rollbar.warn(error);
      throw error;
    }
  },
};

export default checkUserIdentityApi;
