/* @flow */

import axios from '@utils/axios/axios';
import type {
  SinglePharmacyResponse,
  MultiplePharmacyResponse,
  NonQualifyingResponse,
} from '@data/RxHomeTransfer/RxHomeDeliveryTypes';
import type { ClaimId, MemberUid } from '@features/savings/types/Identifiers';
import RxHomeTransferResponse from './RxHomeTransferResponse';
import RxHomeDeliveryStatuses from './RxHomeDeliveryStatuses';

const rxHomeTransferApi = {
  /**
   * Gets the claim's rx home delivery status and details from the home-delivery-transfers and claims endpoints
   *
   * @param {string} claimId - The claim id being searched for
   * @param {string} memberId - The id of the member who is currently active and to whom the claim belongs
   * @returns {Promise<RxHomeTransferResponse>} - A Promise that returns the RxHomeTransferResponse
   * object generated by the API call, be it a successful one or one that returns an error
   */
  getRxHomeDeliveryDetailsForClaimId(
    claimId: ClaimId,
    memberId: MemberUid,
    isUsingCache: boolean = false,
  ) {
    const eligibilityCall = axios
      .setIsVerbose(false)
      .setMaxCacheAge(isUsingCache ? 1800 : -1)
      .get<SinglePharmacyResponse | MultiplePharmacyResponse | NonQualifyingResponse>(
        `v2/${memberId}/home-delivery-transfers?claimId=${claimId}`,
      );

    const claimCall = axios.setIsVerbose(false).get(`v2/${memberId}/claims/${claimId}`);

    return Promise.all([eligibilityCall, claimCall])
      .then(([eligibilityResponse, claimResponse]) => {
        const { status } = eligibilityResponse;
        const details = eligibilityResponse.data ?? {};
        const {
          member_cost: memberCost,
          product: { id: productId, strength, secondary_name: secondaryName },
        } = claimResponse;

        return new RxHomeTransferResponse({
          claimId,
          status,
          details: {
            ...details,
            memberCost,
            productId,
            strength,
            secondaryName,
          },
        });
      })
      .catch(() => {
        return new RxHomeTransferResponse({
          claimId,
          status: RxHomeDeliveryStatuses.ERROR,
          details: null,
        });
      });
  },
};

export default rxHomeTransferApi;
