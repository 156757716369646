export default {
  footer: {
    leftText: {
      body: 'For assistance, our customer service is available to help. The fastest way to obtain service is by using the phone number and member info on your Member ID card.',
    },
    whitelabel:
      'BLUE CROSS®, BLUE SHIELD® and the Cross and Shield Symbols are registered marks of the Blue Cross and Blue Shield Association, an association of independent Blue Cross and Blue Shield Plans.  Blue Cross and Blue Shield of North Carolina is an independent licensee of the Blue Cross and Blue Shield Association.  Rx Savings Solutions is an independent company providing retail prescription drug cost information on behalf of  Blue Cross NC.  Rx Savings Solutions does not offer Blue Cross or Blue Shield products or services.',
  },
  questions: {
    text: 'For assistance, our customer service is available to help. The fastest way to obtain service is by using the phone number and member info on your Member ID card.',
  },
  profile: {
    'success-messages': {
      email:
        'Your email address has been updated with Rx Savings.  To make edits to your Blue Connect info, you must make those updates in Blue Connect.',
      mobile:
        'Your mobile number has been updated with Rx Savings.  To make edits to your Blue Connect contact info, you must make those updates in Blue Connect.',
      address:
        'Your address has been updated with Rx Savings.  To make edits to your Blue Connect info, you must make those updates in Blue Connect.',
    },
  },
  'prescriber-letter-modal': {
    paragraph5:
      'Thank you in advance for reviewing our cost-saving recommendations. If you have questions about this program, please contact Blue Cross and Blue Shield of North Carolina’s Provider Blue Line at <a href="tel:18002144844">1-800-214-4844</a>.',
  },
  videos: {
    alerts: {
      title: 'How does Rx Savings Solutions work?',
      description: 'Watch to see how you can save on your prescriptions.',
    },
    noAlerts: {
      title: 'How does Rx Savings Solutions work?',
      description: 'Watch to see how you can save on your prescriptions.',
    },
  },
};
