import { addTag, removeTag } from '../modifyPharmacyTags/modifyPharmacyTags';

/**
 * Indicates whether the adjudilite price is blocked to determine if the price can be shown.
 * @param {Object} pharmacy - The pharmacy to evaluate for an adjudilite price blocker attribute
 * @return {boolean} True if the object contains the expected price blocker attribute and is true
 */
export function hasAdjudilitePriceBlocker(pharmacy) {
  return !!pharmacy?.prices?.adjudilite?.attributes?.is_blocker;
}

/**
 * Adds the No Price Available tag to pharmacies with an adjudilite price blocker.
 * @param {Object[]} pharmacies - The pharmacies to evaluate for the Price Not Available tag
 * @param {string} pricingKey - The current pricingKey which should be 'adjudilite' or 'coupon'
 * @return {Object[]} Pharmacies with the Price Not Available tag applied
 */
export function addNoPriceAvailableTagToPharmacies(pharmacies, pricingKey) {
  const noPriceAvailableTag = {
    type: 'no-price-available',
    text: 'price-not-available',
  };

  pharmacies.map((pharmacy) => {
    removeTag(pharmacy, noPriceAvailableTag);
  });

  if (pricingKey === 'adjudilite') {
    pharmacies.map((pharmacy) => {
      if (hasAdjudilitePriceBlocker(pharmacy)) {
        addTag(pharmacy, noPriceAvailableTag);
      }
    });
  }

  return pharmacies;
}
