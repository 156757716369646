export default {
  dash: {
    header: 'Welcome to Rx\u00A0Savings Solutions',
    'get-started': 'To get started, search for savings on your medication.',
  },
  'add-up': {
    cost: 'Your Cost',
    'you-pay': 'You Pay:',
    projected: 'Plan Impact as of {{date, "MM/DD/YY"}}',
    'not-available': 'N/A',
    'pbm-disclaimer':
      'Plan impact based on an out-of-pocket cost of ${{amount}}. Your actual out-of-pocket cost may be lower.',
  },
  app: {
    dashboard: 'Dashboard',
    contact: 'Help',
    profile: 'User Settings',
    logout: 'Logout',
    more: 'More',
    medication: 'Medication',
  },
  navSlide: {
    profile: 'User Settings',
    logout: 'Logout',
    closeDrawer: 'Close',
    reminders: 'Medication Reminders',
    pharmacyLocator: 'Find a Pharmacy',
  },
  castlight: {
    return: 'click here to return to',
  },
  claims: {
    'you-paid': 'You Last Paid',
    'plan-paid': 'Your Group Last Paid',
  },
  'claim-result': {
    'already-saving': 'Why are you not seeing any savings options? Either:',
    'already-saving__bullet-one':
      'The price has changed since the original alert was generated, due to a change in your health plan, deductible status or the market price of the drug, or',
    'already-saving__bullet-two': 'You are already saving as much as you can on this medication.',
    'quick-call':
      'A quick call to our Pharmacy Support Team at <a class="search-results__phone-number-desktop">{{number}}</a> <a class="search-results__phone-number-mobile" {{numberStripped}}>{{number}}</a> will get you the most up-to-date price or other available therapies.',
    'want-see': 'Do you still want to see pricing for your drug and other potential therapies?',
    'let-know': 'Let us know.',
  },
  'home-delivery-status-item': {
    'button-text': 'Request Delivery',
    'info-box-content':
      'This medication is available for delivery. Skip a trip to the pharmacy and let your medications come to you.',
  },
  'contact-prescriber': {
    title: 'Switching Is Simple',
    tabletSplit:
      'Our pharmacists reviewed this medication option and consider it safe and effective. Simply split by hand along the marked lines. If there are no marked lines, you can purchase a low-cost tablet splitter at your local pharmacy.',
    text: 'Getting a new prescription for <strong>{{suggestedDrugText}}</strong> is easier than you think. We can even submit the request to your prescriber on your behalf! <em>(We have their contact info from your prescription claim.)',
    'mail-order-text':
      'This medication is available for home delivery through <strong>{{pharmacy}}</strong>. Please call <a href="tel:{{number}}">{{number}}</a> to have <strong>{{pharmacy}}</strong> change your prescription to home delivery.',
    button: 'Contact my prescriber!',
    option1: 'Or ',
    option2: 'use this letter',
    option3: ' to guide your own conversation with your prescriber.',
    step2:
      '<strong>To make this change on your own, </strong> use the coupon(s) below to make sure you get the right price.',
    'step2-Pending': 'Use the coupon(s) below to make sure you get the right price.',
    'step2-Note':
      'NOTE: With this coupon, the amount you pay will not pay down your deductible unless you send a claim to your health insurance company. Contact them for instructions. ',
    'order-online': 'Order Online',
    'request-delivery': 'Request Delivery',
    'home-delivery-footer-text':
      'Skip a trip to the pharmacy and get your <strong>{{suggestedDrugText}}</strong> delivered to you.',
  },
  chat: {
    'live-chat': 'Live Chat',
    'chat-with-us': 'Chat with us',
  },
  'contact-prescriber-modal': {
    'start-header': 'Before You Submit Your Request',
    'start-message':
      'Please enter your {{method}} so we can reach out to you on the status of your request',
    'start-phone-placeholder': 'Phone Number',
    'start-phone': 'phone',
    'start-email-placeholder': 'Email Address',
    'start-email': 'email',
    'start-placeholder': 'Enter Your {{method}}',
    'start-transfer': 'Transfer My Prescription',
    'start-contact': 'Contact My Prescriber',
    'start-submit': 'Submit {{method}}',
    'start-toggle': 'No thanks, contact me via {{method}}',
    'start-error':
      'We were unable to submit your request at this time. Please contact our Pharmacy Support Team at 1-800-268-4476 if this persists.',
    'start-error-unauthorized': 'Sorry, you do not have permission to submit this request.',
    'mobile-number': 'Mobile Number',
    back: 'Back to Dashboard',
    processing: 'Processing Your Request',
    done: "You're all done! We'll take it from here.",
    working: "We're working on your request.",
    'email-type': 'email',
    'sms-type': 'phone',
    'reach-out':
      'A real, live person will <strong>reach out to you at <span class="contact-prescriber-modal__no-wrap" data-private>{{contact}}</span> </strong> {{cpResponseTime}} with a status update on your request',
    cancel:
      'Didn\'t mean to request the switch? <span class="contact-prescriber-modal__cancel-link" tabindex="0">Cancel the request</span>',
    disclaimer:
      'By providing my email address and/or my phone number and clicking submit, I expressly consent to Rx Savings, LLC d/b/a Rx Savings Solutions sending me emails and reaching out to me via phone regarding the requested Contact Prescriber service.',
    'cancel-before-submitting': 'Not the right pharmacy?',
    'cancel-before-submitting-link': '<a>Cancel request and find the right one.</a>',
    'step-1': '1',
    'step-1-header': '<strong>Please review the changes you are requesting.</strong>',
    'step-2': '2',
    'step-2-header': '<strong> How would you like us to contact you?</strong>',
    switchingPrescription: 'Switching your prescription to:',
    prescriptionApproval: 'Your prescriber has approved your prescription change to:',
    sendingToPharmacy: 'And sending to this pharmacy:',
    TransferringToPharmacy: 'Transferring to this pharmacy:',
    prescriberApproval: 'Once your prescriber approves, we will switch your prescription to:',
    sendToPharmacy: 'And send it to this pharmacy:',
    sentToPharmacy: 'And sent it to this pharmacy:',
    TransferToPharmacy: 'And transfer it to this pharmacy:',
    TransferredToPharmacy: 'Transferred it to this pharmacy:',
    takeAction:
      'If you haven’t heard from us yet, or need to cancel or change the request, please give us a call at:',
    additionalHelp: 'If you need any additional help, please give us a call at:',
    pharmacySupportAvailability:
      'Our Pharmacy Support team is available from 7 a.m. to 8 p.m. Central time Monday through Friday.',
    prescriptionChangeRequestCanceled: 'Your prescription change request has been canceled.',
  },
  'contact-prescriber-button': {
    contactPharmacy: 'Contact My Pharmacy',
    contact: 'Contact My Prescriber',
    transfer: 'Transfer My Prescription',
    'request-delivery': 'Request Delivery',
    getItDelivered: 'Get it Delivered',
  },
  'coverage-alert': {
    please: 'Please read before proceeding',
  },
  'family-signup': {
    verified:
      'Due to the age of this family member, privacy laws prevent us from showing you which medications {{name}} can save on. If over age 18, they can register for an account to use this feature.',
    activate: 'Should we ask them to activate their account?',
    emailOnBehalf:
      'We can send them an email on your behalf. Just enter their email address below.',
    emailRequired: 'Email Address Required',
    ccMe: 'Please CC me on this email.',
    messageSent:
      'We’ve sent a message to <span data-private>{{firstName}}</span> for you, and it should arrive any time now. This dependent can reply directly to you with any questions or contact the Rx Savings Solutions Pharmacy Support Team at {{phone}} or by replying to the email.',
    email: 'Email Address',
    button: 'Send Email',
    reSend: 'Try again.',
    notGoingThrough: 'Email not getting through? ',
  },
  'mail-order': {
    warning: 'You could be saving an additional amount by switching to home delivery.',
  },
  'medicine-cabinet': {
    'condition-updated': "Your medication's condition has been updated",
    'problem-associating-with-condition':
      'There was a problem associating your medication with a condition. Please try again',
    title: 'Medicine Cabinet',
    'maintenance-medications': 'Maintenance Medications',
    'short-term-medications': 'Short-term Use Medications',
    medications: 'Medications',
    uncategorized: 'Uncategorized Medication',
    'condition-management': {
      'choose-label': 'Choose Condition',
      confirm: 'Confirm',
      cancel: 'Cancel',
      'categorize-link': 'Categorize this by condition',
      'categorize-label': 'Want to better organize your medication?',
      treats: 'Treats: {{condition}}',
      edit: 'edit',
    },
    'actions-list': {
      title: 'Here are medications you’ve filled in the past 6 months. You can',
      'item-1': 'Click each to see what you paid for your last fill',
      'item-2': 'Set reminders to take on time',
      'item-3': 'Create a list for your next doctor visit',
      'item-4': 'Get available medications delivered to you',
    },
  },
  'mobile-search': {
    'norm-label': 'Number of {{label}}',
    required: '*Required',
  },
  'ndc-list': {
    header: 'National Drug Code(s) <br /> considered Generic Clones',
  },
  'no-med-alert': {
    paragraphOne:
      'It looks like you’re not currently taking any prescription medications. When you have savings available on medications, you’ll see your alerts here.',
    paragraphTwo:
      'Anytime you are prescribed a medication or simply want to look up a drug price, you can always search using the drug’s name.',
  },
  'other-suggestions': {
    options: 'Other Therapeutic Equivalents for foobar.',
  },
  'password-modal': {
    label: 'Password',
  },
  'pharmacy-hours': {
    title: 'Pharmacy Hours',
    closed: 'Closed',
    'no-hours': 'No pharmacy hours available',
    'open-24-hours': 'Open 24 Hours',
    'open-closes-at': 'Open - Closes at {{closes}}',
    'closed-opens-at': 'Closed - Opens at {{opens}}',
    'closed-today': 'Closed Today',
  },
  'pharmacy-item': {
    'mail-order': 'Home Delivery Pharmacy',
    'is-mail-order': 'MAIL ORDER',
    'is-out-of-network': 'Out-of-Network',
    'day-supply': 'Try a 90-day supply from Mail Order',
    directions: 'Directions',
    call: 'Call',
    'home-delivery-shipping': 'Conveniently ships to you',
    'free-shipping': 'Conveniently ships to you',
    'cost-per-quantity': 'Qty: {{quantity}} ({{formattedCostPerUnit}}/ea)',
    'cost-per-quantity-split': 'Qty: {{quantity}}<br /> ({{formattedCostPerUnit}}/ea)',
    'cost-per-day': 'Days: {{daySupply}} ({{formattedCostPerDay}}/day)',
  },
  'pharmacy-summary': {
    note: 'This is your estimated price at <strong>{{pharmacyName}}</strong>',
  },
  'pharmacy-list': {
    choose: 'Choose a Pharmacy',
    'show-more': 'Show More Pharmacies',
  },
  'pharmacy-picker-body': {
    choose: 'Choose Your Preferred Pharmacy',
    voucher: 'Present your voucher to your pharmacist when you fill your prescription.',
    'step2-PS': 'Use the coupon(s) below to make sure you get the right price.',
    step2:
      '<strong>Step 2: </strong> Use the coupon(s) below to make sure you get the right price.',
    'step2-Note':
      'NOTE: With this coupon, the amount you pay will not pay down your deductible unless you send a claim to your health insurance company. Contact them for instructions.',
    discountTitle: '<strong>Discount Only - Not Insurance</strong>',
    discountTitleText:
      'Discounts are available exclusively through participating pharmacies. The range of the discounts will vary depending on the type of medication and pharmacy' +
      ' chosen. This program does not make payments directly to pharmacies. Members are required to pay for all health care services. ' +
      'You may cancel your registration at any time or file a complaint by contacting us at (800)-268-4476.',
    insurance:
      'Present your insurance card to your pharmacist when you get your prescription filled.',
    nonPreferred:
      'Want to transfer your prescription to {{pharmacyName}}? We can submit the request on your behalf!',
    'mail-order':
      'Be sure to have your insurance card within reach so {{pharmacyName}} can confirm your information.',
    'mail-order-with-claim':
      'Want to transfer your prescription to {{pharmacyName}}? We can submit the request on your behalf!',
    preferredPharm:
      'Present your insurance card to your pharmacist when you get your prescription filled.',
  },
  'phase-details': {
    'prices-reflect': 'Prices above reflect that ',
    what: 'What does this mean?',
  },
  'phase-details-modal': {
    title: 'What are Plan Phases?',
    description:
      'The amount you pay for prescription drugs depends, in part, on which phase you are in of your medical/prescription drug plan. Below you will find each phase explained.',
    records: 'Our records show that',
    what: 'What Do the Phases Mean?',
  },
  'phase-select': {
    reflect: 'Prices reflect that you are',
  },
  'powered-footer': {
    title: 'Powered By',
  },
  'prescriber-modal': {
    dear: 'Dear Prescriber,',
    'your-patient':
      'Your patient, <span data-private>{{firstName}} {{lastName}}</span>, is interested in reducing his/her prescription medication costs. Your patient’s employer or health plan has partnered with Rx Savings Solutions to provide prescription medication savings. Our goal is to work with you to suggest potential savings options that make sense for your patient.',
    'as-prescriber':
      'As the prescriber, we realize there may be reasons why a particular drug has been selected. We ask for your consideration in evaluating the cost-benefit advantage of the less expensive alternative(s).',
    provided:
      'We have provided your patient some suggestions on how to achieve prescription medication savings. Below, you will find the suggestion they are most interested in.',
    'we-reccommend':
      'We recommend <span data-private>{{firstName}}</span> switch from <span class="prescriber-modal__paragraph--bold">{{currentDrug}}</span> to <span class="prescriber-modal__paragraph--bold">{{suggestedDrug}}</span>, a {{suggestionLabel}} suggestion.',
    contact:
      'We appreciate your review of our cost-saving recommendations. Please feel free to contact Rx Savings Solutions at 1-800-268-4476 between the hours of 7 a.m. – 8 p.m. CT with questions. You may also email us at pharmacist@rxsavingssolutions.com.',
    gratefully: 'Gratefully, <br /> Rx Savings Solutions',
    button: 'View Letter to Prescriber',
  },
  'prescription-info': {
    title: 'Prescription Information',
    'drug-dosage': 'Drug & Dosage',
    quantity: 'Quantity',
    cost: 'Discounted Price: {{cost}}',
    generated: 'This voucher was generated on {{date, "MM/DD/YYYY"}}',
  },
  'prescription-details': {
    'prescription-details': 'Prescription Details',
    'drug-details': 'Drug Details',
    medicine: 'Medicine',
    'you-paid': 'You Paid',
    'plan-paid': 'Plan Paid',
    'contact-prescriber-button': 'View Your Request',
    'savings-available': 'Savings Available',
    'rewards-available': 'Rewards Available',
    'pharmacy-information': 'Pharmacy Information',
    'cost-breakdown': 'Cost Breakdown',
    'negotiated-price': 'Negotiated Price:',
    'price-unavailable':
      'Price currently unavailable. Please select another pharmacy or call  <a href="tel:18002684476">1-800-268-4476</a> for help.',
  },
  'prices-section': {
    'total-cost': 'Total Cost',
    'per-day-cost': 'Per Day Cost',
    'check-other-options': 'Check Out Other Options',
    'try-these': 'Try One of These to Save',
    'earn-rewards':
      'Receive a ${{amount}} <span class="no-wrap">Rx Rewards</span> Visa<sup>®</sup> <span class="no-wrap">Prepaid Card</span>!',
    'purchased-on': 'Purchased on: {{date}}',
    'save-most-suggested': 'Suggested to Save the Most',
    collapse: 'Collapse',
    expand: 'View Options',
    'adjust-search': 'Adjust Your Search',
    'comparison-options': 'Comparison Options',
    'you-paid': 'You Paid:&nbsp;',
    'you-save': 'You Save: {{percentage}}%',
    'you-save-up-to': 'You Save: {{percentage}}% or more',
    'group-saves': 'Group Saves: {{percentage}}%',
    'group-saves-up-to': 'Group Saves: {{percentage}}% or more',
    'group-paid': 'Your Group Paid: {{cost}}',
    'plan-paid': 'Plan Paid: {{cost}}',
    'your-cost': 'Your Cost',
    'as-low-as': 'As low as',
    'as-low-as-cost': '&nbsp;{{cost}}',
    'with-insurance': 'Your Cost with Insurance',
    'with-coupon': 'with Coupon',
    'read-more': 'Read More',
    close: 'Close',
    'dr-green': 'Dr. Jeff Green',
    'dr-green-title': 'Clinical Pharmacist',
    'dr-green-says': '<strong>Dr Green Says:</strong> {{note}}',
    'msg-from-pharmacist': 'A Message from Our Pharmacist...',
    'mobile-navigation': {
      'savings-results': 'Savings Results',
      'search-results': 'Search Results',
      'pick-pharmacy': 'Pick a Pharmacy',
      'pharmacy-info': 'Pharmacy Info',
    },
    quantity: 'Quantity: ',
    retail: 'Retail',
    'mail-order': 'Mail',
    cta: {
      'pricing-button': 'Compare Pharmacy Pricing',
      'pricing-continue': 'Continue',
      'see-details': 'See Details',
      'no-price': '<span>N/A</span>',
      'drug-search-retail-header':
        'To get the best price on this option you will need to choose a pharmacy.',
      'not-covered': 'Not Covered <br /> by Insurance',
      'no-pricing-available':
        '<span>Price currently unavailable, please call <strong><a href="tel:18002684476">1-800-268-4476</a></strong> for help.</span>',
      'mail-order-header': '{{productName}} is available at your home delivery pharmacy.',
      'mail-order-note':
        "To get this prescription from home delivery, you'll pay more up front but save money in the long run and have to refill less often.",
      'coupon-title':
        'Want to use a coupon to reduce the cost of {{productName}} at one of your local pharmacies?',
      'coupon-note':
        "NOTE: When using a coupon, the amount you pay won't be applied to your insurance deductible unless you submit a claim to your health insurance provider. Contact them to determine the right way to file your claim.",
      'mfg-header':
        'To take advantage of the manufacturer card for {{productName}}, you will need to register and download your card at the link below.',
      'mfg-button': 'Go to Manufacturer Card',
      'mfg-notes':
        'NOTE: Keep the savings card details handy as you will need to show them to your pharmacist.',
      'retail-suggestion-title': 'Want us to talk to {{doctor}} about this option for you?',
      'retail-suggestion-title-doctor-fallback':
        'Want us to talk to your doctor about this option for you?',
      'rewards-header':
        'This option qualifies for the ${{amount}} <span class="no-wrap">Rx Rewards</span> Visa <span class="no-wrap">Prepaid Card.</span> The amount you pay may not change but the switch will reduce your plan\'s total prescription cost.',
    },
    'pbm-max-title': '${{amount}} or less',
    'see-details': 'See Details',
    'rewardable-header':
      'This option qualifies for the ${{amount}} <span class="no-wrap">Rx Rewards</span> Visa<sup>®</sup> <span class="no-wrap">Prepaid Card.</span>',
  },
  'product-details': {
    'you-pay': 'You Pay',
    cost: 'Cost',
    'pharmacy-picker': 'Pharmacy Picker',
    'search-results': 'Search Results',
    'alert-options': 'Alert Options',
  },
  'product-info': {
    what: 'What is {{product}}?',
    'no-details-header': 'Information on {{product}} not found',
    'no-details-body':
      "We're sorry, but we were unable to find any information on {{product}} at this time.",
  },
  profile: {
    header: 'User Settings',
    'user-header': 'Contact Info and Home Address',
    'user-mobile-contact': 'Contact Info',
    'user-mobile-home': 'Home Address',
    'dependent-header': 'Dependents',
    'password-header': 'Change Password',
    'notification-header': 'Receive Savings Notifications via:',
    'user-info': {
      'mobile-label': 'Mobile Number',
      'email-label': 'Email Address',
      'street-label': 'Street Address',
      'city-label': 'City',
      'state-label': 'State',
      'zip-label': 'ZIP Code',
      'password-label': 'Password',
      'update-button': 'Update Your Information',
      'contact-button': 'Update Contact Info',
      'address-button': 'Update Address',
    },
    'password-update': {
      'update-button': 'Update Password',
      'cancel-button': 'Cancel Update',
      'rules-header': 'Your Password must have each of the following:',
      'lowercase-rule': 'One lowercase character',
      'uppercase-rule': 'One uppercase character',
      'special-char-rule': 'One number or special character',
      'eight-char-rule': 'Must contain at least 8 characters',
    },
    'lightswitch-email': 'Email:',
    'lightswitch-sms': 'Text Message/SMS:',
    'success-messages': {
      email: 'Your email has been updated.',
      mobile: 'Your mobile number has been updated.',
      password: 'Your password has been updated.',
      address: 'Your address has been updated.',
    },
    privacy: {
      currentlyAllow: 'You currently allow <span data-private>{{name}}</span> to see your profile.',
      withdraw:
        'If you withdraw your permission, <span data-private>{{name}}</span>  will no longer be able to see your profile and medication(s).',
      'withdraw-permission': 'Withdraw permission',
      close: 'Close',
      wouldYouLike:
        'Would you like to allow <span data-private>{{name}}</span>  to see your profile?',
      withYourPermission:
        'With your permission, <span data-private>{{name}}</span>  can see which medications you take now or in the future, and whether cheaper options may be available.',
      readAndAgree: 'I have read and agree to the ',
      terms: ' terms and conditions.',
      seeYourMeds: ' see your profile',
      mustAgree:
        'You must agree to the terms and conditions before you can grant permission to view your profile',
      shareSuccess:
        'Your settings have been updated. <span data-private>{{name}}</span> can now view your profile.',
      revokeSuccess:
        'Your settings have been updated. Now <span data-private>{{name}}</span> cannot view your profile.',
      allowFamily: 'Allow these family members to see my profile',
      disclaimer:
        'Disclaimer: Minor dependents cannot see your profile and medication(s) because minors cannot register for an account.',
    },
  },
  reminders: {
    'no-email': "You don't have an email on file. Please add one on your ",
    'no-phone': "You don't have a mobile number on file. Please add one on your ",
    'home-title': 'Medication Reminders',
    am: 'a.m.',
    pm: 'p.m.',
    text: 'SMS/Text',
    push_notifications: 'Push Notifications',
    email: 'Email',
    time: 'Time',
    'sms-title': 'Text',
    'email-title': 'Email',
    'reminder-time': 'Reminder Time',
    'when-to-remind': 'When would you like to get your medication reminder?',
    morning: 'Morning',
    afternoon: 'Afternoon',
    evening: 'Evening',
    night: 'Night',
    'repeats-daily': 'Repeats every day',
    medications: 'Medications',
    'which-medications':
      'Please choose the medication(s) for which you would like a reminder. (Select as many that apply)',
    'communication-preferences': 'Communication Preferences',
    'communication-preference-header': 'Select your communication preference for your reminders',
    'no-reminders': 'You have not created any reminders yet. Set one below!',
    'current-reminders-header': 'Your Current Reminders',
    welcome:
      "Welcome to Medication Reminders, a handy resource to keep you on schedule with your prescriptions. Just set it and forget it &mdash; we'll remind you!",
    weekdays: {
      sunday: 'S',
      monday: 'M',
      tuesday: 'T',
      wednesday: 'W',
      thursday: 'T',
      friday: 'F',
      saturday: 'S',
    },
    'weekdays-long': {
      sunday: 'Sun',
      monday: 'Mon',
      tuesday: 'Tue',
      wednesday: 'Wed',
      thursday: 'Thu',
      friday: 'Fri',
      saturday: 'Sat',
    },
    daily: 'Daily',
    'notification-settings': 'Notification Settings',
    'next-reminder-set': 'Next reminder set for {{next}}',
    'next-reminder-today': 'today at {{time}}',
    'no-meds':
      "You currently have no prescriptions in our system. Adherence is critical for many medications, so remember this feature whenever you're prescribed a drug that needs to be taken on a specific schedule.",
    'no-meds-header': 'No Reminders Needed',
    edit: 'Edit',
    save: 'Save',
    add: 'Add',
    'days-error': 'You must select at least one day',
    'medication-error': 'You must select at least one medication',
    'add-another': 'Create Reminders',
    'create-reminder': 'Set Reminder Time',
    'update-reminder': 'Update Reminder Time',
    'set-medications': 'Set Medications',
    'update-medications': 'Save Changes',
    cancel: 'Cancel',
    'set-preferences': 'Set Preferences',
    on: 'On',
    off: 'Off',
    delete: 'Delete',
    'update-sms': 'update number',
    'set-sms': 'profile page',
    'update-email': 'update email',
    'set-email': 'profile page',
    'edit-reminder': 'Edit',
    'duplicate-error':
      'You have already created an alert with the same times, dates and medications',
    'email-error': 'Please enter a valid email address',
    'sms-error': 'Please enter a valid phone number.',
    'communication-preferences-updated':
      'Your Communication Preferences have been updated. To adjust your settings, click the communication preferences link.',
    'reminders-list-updated-successfully': 'Your reminder list has been successfully updated',
    'communication-preferences-sms-disclaimer':
      'By enabling SMS, you are providing consent to receive mobile alerts from Rx Savings Solutions. Message and data rates may apply. Text STOP to cancel, text HELP for assistance.',
    'report-card': {
      'your-report-card': 'Your Reminder Report Card',
      month: 'This Month',
      all: 'All Time',
      community: 'Community',
      'this-week': 'This Week',
      oops: 'Oops! It looks like you missed some medications on {{day}}, {{date}}.',
      success: 'Great Job! You took your dose{{plural}} on {{day}}, {{date}}',
      'oops-message': 'Just forgot to confirm you took them? Check them off below.',
      na: 'N/A',
      update: 'updating',
      fetch: 'fetching',
      'error-header': "It's us, not you!",
      error:
        "Sorry. There was a problem {{task}} your records. Please refresh the page and try again. If that doesn't work, please call {{number}} and a live person can help you.",
    },
  },
  'Remove-Alert-Survey': {
    banner: "You won't see savings for {{productName}} anymore.",
    'question-header': "Why don't you want to see this alert anymore?",
    'question-item-one': 'Too Many Emails',
    'question-item-two': 'Not Relevant',
    'question-item-three': "I Don't Want To Change My Prescription",
    'question-item-four': 'Other',
    'thank-you':
      'Thank you for your feedback! If you would like to check for savings on {{productName}} in the future you can perform a search above, or call our Pharmacy Support Team at 1-800-268-4476.',
  },
  'search-bar': {
    search: 'Search',
    'search-again': 'Search Again',
    clear: 'Cancel',
    'no-result': 'No results are available at this time.',
    'try-different': 'Try a different spelling or search for a different medication.',
  },
  'pill-toggle': {
    left: '<i class="icon-pills"></i> Total Cost',
    right: '<i class="icon-pill"></i> Per Day Cost',
  },
  'suggestion-item': {
    from: 'As low as <span class="suggestion-detail-text--price">{{price}} </span><span style="white-space:nowrap;">per fill</span>',
    save: 'Save <span class="suggestion-detail-text--price">{{price}}</span> <span style="white-space:nowrap;">per fill</span>',
    saveEmployer:
      'Save your group <span class="suggestion-detail-text--price">{{price}}</span> <span style="white-space:nowrap;">per fill</span>',
    requestFrom:
      'Request these medications for as low as <span style="white-space:nowrap;"><span class="suggestion-detail-text--price">{{price}}</span> per fill</span>',
    requestSave:
      'Request these medications and save <span style="white-space:nowrap;"><span class="suggestion-detail-text--price">{{price}}</span> per fill</span>',
  },
  'suggestion-item-combo-split': {
    header: 'Request These Medications',
    'as-low-as': 'As low as {{price}} for everything',
  },
  'suggestion-list-wrapper': {
    disclaimer:
      'These prices are personalized estimates and may not reflect the actual total price. The estimates are based upon your prescription drug plan as of today. Due to discounts or charges a particular pharmacy may apply actual cost may differ. Please do not rely exclusively on this estimate alone to make your purchasing decision.',
    pharmacist_disclaimer:
      'Rx Savings Solutions’ over-the-counter medication savings suggestions are for informational purposes only. The savings suggestion is not intended as a substitute for professional medical advice. Rx Savings Solutions does not provide professional pharmacy advice, but rather consumer cost saving information. Please do not rely on the suggestion as an alternative to qualified prescriber medical advice. Reliance on any suggestion is solely at your own risk. Rx Savings Solutions is not be liable for any resulting loss and/or harm. Consult your prescriber prior to discontinued use of any prescribed medications.  Also, prior to use of an over-the-counter medication please review the contents of the "Drug Facts" or visit the Food & Drug Administration’s website for "Buying and Using Medicines Safely."',
    pharmacist_note_disclaimer:
      'The Pharmacist Notes are for informational purposes only. Rx Savings Solutions’ pharmacists do not provide clinical, medical advice and the information provided should not be used as such. Nothing contained herein is intended to create a pharmacist-patient relationship. You should not solely rely on anything contained herein, please consult a licensed physician in all your health-related matters.',
    pharmacy_benefits: '',
    eligibility: '',
  },
  'claims-suggestion-list-wrapper': {
    unavailable: 'No savings? We can explain.',
    contact:
      'Occasionally, a change in your health plan, deductible status or drug price results in no savings opportunity. We apologize. Our Pharmacy Support Team is standing by Monday-Friday, 7 a.m.-8 p.m. CT to find the latest, lowest price for you: 1-800-268-4476 or support@rxsavingssolutions.com.',
  },
  'search-result-modal': {
    adjust: 'Adjust your search',
  },
  voucher: {
    'customer-call': 'Call Rx Savings Solutions at',
  },
  'voucher-header': {
    print: 'Print',
    email: 'Email',
    text: 'Text',
  },
  'voucher-info-list': {
    generated: 'This voucher was generated on {{date}}',
    'helpful-note': 'Give this to your pharmacist.',
    'price-based': 'Price based on the NDC: {{ndc}}',
    'coupon-question': 'Have a question about this coupon?',
  },
  'voucher-list': {
    'singular-title': 'Savings Voucher',
    'plural-title': 'Savings Vouchers',
  },
  'voucher-modal': {
    confirm: 'Please confirm the {{title}} you wish to send the voucher to.',
    success: 'Your savings voucher has been sent to the above {{alert}}.',
    sendIt: 'Send it!',
  },
  questions: {
    header: 'Can We Help You?',
    text: 'Our Pharmacy Support Team is available from 7 a.m. to 8 p.m. central time Monday through Friday at:',
    number: '1-800-268-4476',
    image: 'http://fillmurray.com/200/300',
  },
  'recent-searches': {
    header: 'Recent Searches',
    search: 'Search for a medication above to find potential savings.',
  },
  search: {
    placeholder: 'Search for a Medication',
    open: 'Search for a Medication',
    noResults:
      'No results are available at this time. <strong>Try a different spelling or search for a different medication.</strong>',
  },
  'search-field': {
    error: '*Required',
    'norm-label': 'Number of {{item}}',
    dosage: 'Dosage',
    zip: 'Zip Code',
    form: 'Form',
    submit: 'Search',
    cancel: 'Cancel',
  },
  'contact-modal': {
    return: 'Return to {{location}}',
    markup: `Have a question or need some help with your meds? <br/>
                 <span class='contact-modal__call'>
                  Call our local certified pharmacy techs at:
                 </span>`,
    help: 'How can we help?',
    label: 'Or, you can send us a message below',
    submit: 'Send your message',
    success: 'Thanks for reaching out! We will get in touch with you soon.',
    fail: 'Please enter a message for our Pharmacy Support Team.',
  },
  'disappearing-alert-modal': {
    title: 'Darn it! This opportunity to save is no longer available.',
    explanation:
      'What happened? Either the drug\'s price changed, you entered a new health insurance "plan year," or you\'re in a different deductible phase.',
    reminder:
      'Remember, you can find your lowest-cost option for any medication using the search feature at the top of your screen.',
    close: 'Close',
    'icon-alt': 'Magnifying Glass Icon',
  },
  'account-members': {
    'your-meds': 'Your Meds',
    'name-meds': "{{name}}'s Meds",
    'member-select': 'Medications For:',
    alerts: 'Savings Alerts',
    'over-eighteen':
      'Due to the age of this dependent, privacy laws prevent us from showing you which medications <span data-private>{{name}}</span> can save on. If over age 18, they can register for an account to use this feature.',
    'no-alerts-or-claims':
      'It looks like you haven’t taken any medications in the last 6 months. Medications for your dependent(s) under the age of 18 can be reviewed by clicking the tabs above.',
    'under-eighteen':
      'Due to the age of this dependent, privacy laws prevent us from showing you their medications or savings alerts.',
  },
  'savings-alert': {
    button: 'See All Savings Options',
    buttonMobile: 'Save {{amount}} per fill',
    'buttonMobile-up-to': 'Save {{amount}} or more per fill',
    'no-savings-button': 'View Options',
    foundSavings: 'Save up to {{amount}} per fill!',
    'foundSavings-up-to': 'Save {{amount}} or more per fill!',
    foundChildSavings: 'We found savings for <span data-private>{{name}}!</span>',
    paid: 'Save {{amount}} <span style="white-space:nowrap;">per fill</span>',
    alreadySaving: 'You last paid {{amount}} on {{date}}',
    savingsSuggestionHeader: 'Choose a Savings Suggestion.',
    saveMore: 'Try One of These to Save More',
    unsavingsPaid: 'Total Savings <span style="white-space:nowrap;">{{amount}} per fill</span>',
    unsavingsButton: 'See All Savings Options',
    unsavingsButtonMobile: 'Save your group {{amount}} <span class="no-wrap">per fill</span>',
    unsavingsBodyHeader:
      'Receive a ${{amount}} <span class="no-wrap">Rx Rewards</span> Visa<sup>®</sup> <span class="no-wrap">Prepaid Card</span>!',
    childUnsavingsBodyHeader:
      '<span data-private>{{name}}</span> can receive a ${{amount}} <span class="no-wrap">Rx Rewards</span> Visa<sup>®</sup> <span class="no-wrap">Prepaid Card</span>!',
    unsavingsBodyFooter:
      'For switching to a lower-cost suggestion, you\'ll receive a ${{amount}} <span class="no-wrap">Rx Rewards</span> Visa<sup>®</sup> <span class="no-wrap">Prepaid Card</span>. Use it at pharmacies, grocers, and warehouse stores to help with out-of-pocket healthcare expenses.',
    childUnsavingsBodyFooter:
      'For switching to a lower-cost suggestion, <span data-private>{{name}}</span>  will receive a ${{amount}} <span class="no-wrap">Rx Rewards</span> Visa<sup>®</sup> <span class="no-wrap">Prepaid Card</span>. Use it at pharmacies, grocers, and warehouse stores to help with out-of-pocket healthcare expenses.',
    unsavingsIconAltText: 'prescription bottle and $100 gift card',
    'unsavingsButtonMobile-up-to':
      'Save your group {{amount}} or more <span class="no-wrap">per fill</span>',
    unsavingsSuggestionHeader: 'Choose a Savings Suggestion.',
    unsavingsSuggestionBody:
      'Your out-of-pocket savings may be limited at this time. Choosing a lower-cost alternative now may save you money in the future and help keep your health premiums from rising.',
    bestSavingsSuggestionHeader: 'Save up to {{amount}} per fill!',
    'bestSavingsSuggestionHeader-up-to': 'Save {{amount}} or more per fill!',
    bestSavingsSuggestionButtonText: 'See Best Savings Option',
    bestSavingsSuggestionLinkTextDesktop: 'See All Savings Options',
    bestSavingsSuggestionLinkTextMobile: 'Show me all savings options',
  },
  'mail-order-alert': {
    header: 'Have it delivered and save {{amount}} in total.',
    button: 'See Savings Option',
    details: {
      header: 'Switch to a 90-day supply to save',
      'header-same-day': 'Switch to home delivery to save',
      'mobile-header': 'Try home delivery to save',
      'footer-no-cp':
        'Your Next Step: <br/> To get this prescription from your home delivery pharmacy, call {{pharmacyName}} at {{number}}.',
      'footer-for-cp': 'Can we transfer this prescription to home delivery for you?',
    },
    quantity: 'Quantity',
    'current-retail': 'Current Retail Pharmacy',
    'home-delivery': 'Home Delivery Pharmacy',
    'or-less': ' or less',
  },
  'available-dependent-alert': {
    save: 'Save {{amount}} per fill',
    'save-up-to': 'Save {{amount}} or more per fill',
  },
  'refill-reminder': {
    noSavings: {
      header: 'It’s almost time to refill your medication.',
      body: 'According to our claims data, you last refilled {{drug}} on {{date}}. This means you are due for a refill.',
    },
    memberSavings: {
      header: 'You can save on your next refill!',
      body: 'Your prescription for {{drug}} is due for a refill, AND you can save money on it.',
    },
    clientSavings: {
      header: 'Help your group save on your next refill!',
      body: 'Your prescription for {{drug}} is due for a refill. Trying a cheaper option will help everyone in your group.',
      footer:
        'Want to be the ultimate team player? If you act on this savings alert, you’ll help keep health premiums down for you and your co-workers.',
    },
  },
  'alert-list': {
    'empty-state': {
      'header-primary': 'Where are my savings?',
      'header-dependant': "Where are {{dependant}}'s savings?",
      'body-primary': {
        'line-1':
          'You may already be saving as much as you can. We may have previously sent you a savings message, but you’re not seeing it here because it\'s no longer active. This can happen when there are changes to drug prices, your plan year or your deductible. Please call our Pharmacy Support team for more information at <a href="tel:18002684476">1-800-268-4476.</a>',
        'line-2':
          'You can always find the lowest price for any medication by using the “Search for a Medication” feature at the top of your screen. For a list of your active medications, visit your ',
      },
      'body-dependant': {
        'line-1':
          '{{dependant}} may already be saving as much as they can. We may have previously sent your family a savings message, but you’re not seeing it here because it\'s no longer active. This can happen when there are changes to drug prices, the plan year or deductible. Please call our Pharmacy Support team for more information at <a href="tel:18002684476">1-800-268-4476.</a>',
        'line-2':
          "You can always find the lowest price for any medication by using the “Search for a Medication” feature at the top of your screen. For a list of {{dependant}}'s  active medications, visit {{dependant}}'s ",
      },
      'link-text': 'medicine cabinet.',
      'icon-alt-text': 'Empty Savings List Icon',
    },
    generated: 'Generated on: {{date}}',
    'no-alerts': 'You have no alerts at this time.',
    disclaimer:
      "Savings amounts are accurate at the time each alert is generated and may increase/decrease over time due to changes in your health plan, deductible status or the drug's market price.",
    loader:
      "We're checking savings opportunities for you<span>(Results may take a couple seconds)</span>",
    heading: 'Savings Alert',
  },
  'ie10-error': {
    markup: `<strong>Outdated Browser</strong> - The browser you're using is not fully supported by Rx Savings Solutions. <a href='http://outdatedbrowser.com/en' target='_blank' class='alert-link'>Click here to upgrade your browser.</a>`,
  },
  'manufacturer-card': {
    header: 'Manufacturer Card <br /> for {{drug}}',
    description:
      'To take advantage of the manufacturer card for {{drug}} {{form}}, you will need to register and download your card at the link below.',
    button: 'Go to Manufacturer Card',
    footer: 'Keep the savings card details handy as you will need to show them to your pharmacist.',
  },
  'search-results': {
    'product-header': 'Search Results For',
    'search-button': 'Adjust Your Search',
    'return-to-text': 'Back to Savings Results',
    'no-therapy': 'Why is this the only therapy option listed?',
    'no-therapy__explaination': 'This is currently the lowest-cost therapy option available.',
    'quick-call':
      'A quick call to our Pharmacy Support Team at <a class="search-results__phone-number-mobile" {{numberStripped}}>{{number}}</a> <a class="search-results__phone-number-desktop">{{number}}</a> will get you the most up-to-date price or other available therapies.',
    'see-pricing': 'Do you still want to see pricing for other potential therapies?',
    'let-know': 'Let us know.',
  },
  'suggestion-wrapper': {
    TE: 'You will need a new prescription for the therapeutic equivalent, {{suggestedDrug}}. Use the letter at the link below to help guide your conversation with your prescriber.',
    'TE-Coupon':
      '<strong>Step 1:</strong> You will need a new prescription for {{suggestedDrug}}. ',
    TA: 'You will need a new prescription for the therapeutic equivalent, {{suggestedDrug}}. Use the letter at the link below to help guide your conversation with your prescriber.',
    'TA-Coupon':
      '<strong>Step 1: </strong>You will need a new prescription for the therapeutic equivalent, {{suggestedDrug}}. ',
    GS: {
      details:
        'To take a generic of {{currentDrug}}, talk to your pharmacist about taking a generic.',
      detailsWithClaim:
        'Getting a new prescription for the generic of {{currentDrug}} is easier than you think. We can even submit the request on your behalf! Or, to make the switch yourself, simply ask your pharmacist for the generic.',
    },
    'GS-Coupon': {
      details:
        '<strong>Step 1: </strong>To take a generic of {{currentDrug}}, talk to your pharmacist about taking a generic.',
      detailsWithClaim:
        'Getting a new prescription for the generic of <strong>{{currentDrug}}</strong> is easier than you think. We can even submit the request on your behalf! Or, to make the switch yourself, simply ask your pharmacist for the generic.',
    },
    GC: {
      details:
        'To take the generic clone of {{currentDrug}}, talk to your pharmacist about taking the generic clone. Be certain to show them the list of NDCs (National Drug Codes) below that are considered generic clones. It is important you get one of these NDCs if you want to receive the same active and inactive ingredients as the branded product.',
      'ndc-text': 'National Drug Code(s) considered Generic Clones',
      detailsWithClaim:
        'Getting a new prescription for the generic clone of {{currentDrug}} is easier than you think. We can even submit the request on your behalf! Or, to make the switch yourself, just give your pharmacy one of the NDCs (National Drug Codes) below to ensure you get the same active and inactive ingredients as the brand-name drug.',
    },
    'GC-Coupon': {
      details:
        '<strong>Step 1: </strong>To take the generic clone of {{currentDrug}}, talk to your pharmacist about taking the generic clone. Be certain to show them the list of NDCs (National Drug Codes) below that are considered generic clones. It is important you get one of these NDCs if you want to receive the same active and inactive ingredients as the branded product.',
      'ndc-text': 'National Drug Code(s) considered Generic Clones',
      detailsWithClaim:
        'Getting a new prescription for the generic clone of <strong>{{currentDrug}}</strong> is easier than you think. We can even submit the request on your behalf! Or, to make the switch yourself, just give your pharmacy one of the NDCs (National Drug Codes) below to ensure you get the same active and inactive ingredients as the brand-name drug.',
    },
    DF: 'You will need a new prescription for the {{suggestedDrug}} form of {{currentDrug}}. Use the letter at the link below to help guide your conversation with your prescriber.',
    'DF-Coupon':
      '<strong>Step 1: </strong>You will need a new prescription for the {{suggestedDrug}} form of {{currentDrug}}.',
    CS: 'You will need a new prescription to take each active ingredient individually, {{suggestedDrug}}. Use the letter at the link below to help guide your conversation with your prescriber.',
    'CS-Coupon':
      '<strong>Step 1: </strong>You will need a new prescription to take each active ingredient individually, {{suggestedDrug}}.',
    TS: '<p class="contact-prescriber__tablet-split">Our pharmacists reviewed this medication option and consider it safe and effective. Simply split by hand along the marked lines. If there are no marked lines, you can purchase a low-cost tablet splitter at your local pharmacy.</p> You will need a new prescription for {{suggestedDrug}} with new directions to take half a tablet (delivering the same dosage to your body). Use the letter at the link below to help guide your conversation with your prescriber. ',
    'TS-Coupon':
      '<p class="contact-prescriber__tablet-split">Our pharmacists reviewed this medication option and consider it safe and effective. Simply split by hand along the marked lines. If there are no marked lines, you can purchase a low-cost tablet splitter at your local pharmacy.</p> <strong>Step 1: </strong>You will need a new prescription for {{suggestedDrug}} with new directions to take half a tablet (delivering the same dosage to your body).&nbsp;',
    PS: 'Sometimes pharmacies may vary in cost.  Choose a pharmacy option from the list on the left that best balances your needs of cost and care. Present the voucher below to your pharmacist when you fill the prescription.',
    DS: "You'll need a new prescription to get {{suggestedDrug}}, which you can take in a greater quantity to achieve the same dose and save money. Simply click the link below for a letter to send or use when talking with your prescriber.",
    CE: "You'll need a new prescription to take {{suggestedDrug}} individually and save money. Simply click the link below for a letter to send or use when talking with your prescriber.",

    'DS-Coupon':
      "<strong>Step 1: </strong>You'll need a new prescription to get {{suggestedDrug}}, which you can take in a greater quantity to achieve the same dose and save money. Simply click the link below for a letter to send or use when talking with your prescriber.",
    'CE-Coupon':
      "<strong>Step 1: </strong>You'll need a new prescription to take {{suggestedDrug}} individually and save money. Simply click the link below for a letter to send or use when talking with your prescriber.",

    'suggestion-Step1': ' Use this letter ',
    'suggestion-Step2': ' to help guide your conversation with your prescriber.',
  },
  'suggestion-details': {
    testing: {
      title: 'What is a TESTING PLS IGNOAR',
      text: 'This is a test of the emergency broadcast system!1!!1!1!OMG!11!1!111!',
    },
    TA: {
      title: 'What is a Therapeutic Equivalent?',
      label: 'Therapeutic Equivalent',
      text: 'When doctors prescribe a drug, they choose from several options. Sometimes, one drug is clearly best for you. Other times, the options below would work equally well. Each contains different active ingredients, but all have the same effect when taken in equivalent doses. Talk with your doctor – you will need a NEW prescription to switch to one of these.',
      'results-page-text':
        '<p>There are one or more alternatives available to treat the same condition <a href="/product-details/{{productID}}">{{productName}}</a> treats. To take advantage of this option, talk to your prescriber about switching to one of the medications below.</p>',
      'savings-option-tag': 'Try a different medication that treats the same condition.',
    },
    TE: {
      title: 'What is a Therapeutic Equivalent?',
      label: 'Therapeutic Equivalent',
      text: 'When doctors prescribe a drug, they choose from several options. Sometimes, one drug is clearly best for you. Other times, the options below would work equally well. Each contains different active ingredients, but all have the same effect when taken in equivalent doses. Talk with your doctor – you will need a NEW prescription to switch to one of these.',
      'results-page-text':
        '<p>There are one or more alternatives available to treat the same condition <a href="/product-details/{{productID}}">{{productName}}</a> treats. To take advantage of this option, talk to your prescriber about switching to one of the medications below.</p>',
      'savings-option-tag': 'Try a different medication that treats the same condition.',
    },
    CS: {
      title: 'What is a Combination Split?',
      label: 'Combination Split',
      text: 'This drug is actually a combination of two or more active ingredients. Take it instead as individual ingredients (separate “pills”), and you can save money.  You will need NEW prescriptions from your doctor for this option.',
      'results-page-text':
        '<p><a href="/product-details/{{productID}}">{{productName}}</a> is a combination of multiple active ingredients that you can take individually. Talk to your prescriber about taking them separately to save money.</p>',
      'savings-option-tag': 'Try taking the active ingredients individually.',
    },
    GC: {
      title: 'What is a Generic Clone?',
      label: 'Generic Clone',
      text: 'A generic clone contains the same active and same inactive ingredients as the brand-name drug your doctor prescribed, and you can expect the same effect as the brand-name drug. It is important to ask your pharmacist for the generic clone by its national drug code (NDC).  You can find the NDC on your prescription label. Always show your pharmacist the generic clone-specific NDC when filling your prescription.  Typically, you do not need a new prescription.',
      'results-page-text':
        '<p>Brand-name products cost more, including drugs. This generic is <em>the same in every way</em> as <a href="/product-details/{{productID}}">{{productName}}</a>, and has the exact same effect when taken at the same dose.</p>',
      'savings-option-tag':
        "Try taking the generic of this medication that's the same as the branded product in every way.",
    },
    GS: {
      title: 'What is a Generic Substitution?',
      label: 'Generic Substitution',
      text: 'Brand-name products cost more, including drugs. Generic substitutions contain the same active ingredient as the brand-name drug your doctor prescribed, and they have the same effect when taken at the same dose. Simply ask your pharmacist for the generic.  Typically, you do not need a new prescription.',
      'results-page-text':
        '<p>Brand-name products cost more, including drugs. This generic contains <em>the same active ingredient</em> as <a href="/product-details/{{productID}}">{{productName}}</a>, and has the same effect when taken at the same dose.</p>',
      'savings-option-tag': 'Try taking the generic of this medication.',
    },
    DF: {
      title: 'What is a Dosage Form Change?',
      label: 'Dosage Form Change',
      text: 'The prescribed drug is available in multiple dosage forms (such as capsules or tablets). By changing from taking the prescribed dosage form to one below, you could save, while still getting the same dosage and active ingredient as the one prescribed.  You will need a NEW prescription from your doctor for this option.',
      'results-page-text':
        '<p>By changing from one dosage form to another (e.g., tablet to capsule), you will receive the same dose of the active ingredient prescribed, while lowering your cost. To take advantage of this option talk to your prescriber about taking the medication below.</p>',
      'savings-option-tag': 'Try taking this medication in a different form.',
    },
    MC: {
      title: 'What is a Manufacturer Card?',
      label: 'Manufacturer Card',
      text: 'The manufacturer of this drug makes it easier for patients to afford by reducing out-of-pocket expense. Some restrictions may apply. Please consult card for details. You will not need a new prescription for this option.',
      'results-page-text':
        '<p>The manufacturer of this drug makes it easier for patients to afford by reducing out-of-pocket expense. Some restrictions may apply. Please consult card for details. You will not need a new prescription for this option.</p>',
      'savings-option-tag': "Try a manufacturer's card for {{productName}}.",
    },
    TS: {
      title: 'What is a Tablet Splitting Suggestion?',
      label: 'Tablet Split',
      text: 'Most tablets cost the same, regardless of strength. To save you money, your doctor may prescribe tablets that have twice the dose you need, and ask you to split them in half.  We have verified this drug is safe and appropriate for tablet splitting. You will need a NEW prescription for the higher-dose tablets to take advantage of this option.',
      'results-page-text':
        '<p>Splitting a higher dosage of <a href="/product-details/{{productID}}">{{productName}}</a> in half can save you up to 50% per fill. Check with your prescriber if splitting a higher dosage in half is right for you. Your prescriber will adjust your prescription for the new dosage and directions.</p>',
      'savings-option-tag': 'Try splitting a higher-dose tablet in half (achieves the same dose).',
    },
    PS: {
      title: 'What is a Pharmacy Shopping Suggestion?',
      label: 'Pharmacy Shopping',
      text: 'The price for this drug varies from pharmacy to pharmacy.  To find pricing better than what you are currently paying, select this option.  It may require you to transfer your prescription from one pharmacy to another.',
      'results-page-text':
        '<p>The price for {{productName}} may vary from pharmacy to pharmacy. To evaluate pricing at pharmacies near you, select this option. It may require you to transfer your prescription from one pharmacy to another.</p>',
      'savings-option-tag': 'See savings at these pharmacies.',
    },
    CE: {
      title: 'What is a Combo-Split Therapeutic Alternative Suggestion?',
      label: 'Combo-Split Therapeutic Alternative',
      text: 'Taking these medications individually will work in the same way as a single dose of {{productName}}.',
      'results-page-text':
        '<p>Taking these medications individually will work in the same way as a single dose of {{productName}}.</p>',
      'savings-option-tag': 'Try taking a combination of medications that work the same way.',
    },
    DS: {
      title: 'What is a Dose Multiplying Suggestion?',
      label: 'Dose Multiplying',
      text: 'These medications can be taken in greater quantities of a smaller dose to achieve the same effect as {{productName}}.',
      'results-page-text':
        '<p>These medications can be taken in greater quantities of a smaller dose to achieve the same effect as {{productName}}.</p>',
      'savings-option-tag': 'Try taking multiple lower doses of the same medication.',
    },
    MO: {
      'savings-option-tag': 'Switch to a 90 day supply from your home delivery pharmacy.',
    },
  },
  health_plan_info: {
    1: {
      label: 'you are in your deductible phase',
      title: 'Deductible Phase',
      content:
        'The Deductible Phase means you have not yet met your deductible for the plan year. A deductible is a specified amount of money you must pay before your medical/prescription plan will begin helping you pay for a prescription. Until you pay at least this amount (deductible), you will be responsible for 100% of the cost of your prescription, except for prescriptions where the deductible does not apply.',
    },
    2: {
      label: 'you are in your cost-share phase',
      title: 'Cost-Share Phase',
      content:
        'The Cost-Share Phase is a period where both you and your medical/prescription drug plan share in the cost of your prescription drugs. You will be paying less than you did in the Deductible Phase because your medical/prescription drug plan is helping reduce your costs.',
    },
    3: {
      label: 'your out-of-pocket maximum has been reached',
      title: 'Out-of-Pocket Maximum Met',
      content:
        'Once you have spent the maximum amount of out-of-pocket expenses as defined by your medical/prescription drug plan, your plan will be paying most, if not all of the cost of your prescription drugs.',
    },
    4: {
      label: 'you are in the Medicare Part D Deductible period.',
      title: 'Deductible Period',
      content:
        'Until you meet your Part D deductible, you will pay the full negotiated price for your covered prescription drugs. Once you have met the deductible, the plan will begin to cover the cost of your drugs. While deductibles can vary from plan to plan, no plan’s deductible can be higher than $505 in 2023, and some plans have no deductible.',
    },
    5: {
      label: 'you are in the Medicare Part D initial coverage period.',
      title: 'Initial Coverage Period',
      content: `
                After you meet your deductible, your plan will help pay for your covered prescription drugs. Your plan will pay some of the cost, and you will pay a copayment or coinsurance. How long you stay in the initial coverage period depends on your drug costs and your plan’s benefit structure. For most plans in 2023, the initial coverage period ends after you have accumulated $4,660 in total drug costs.
                <br />
                Note: Total drug costs include the amount you and your plan have paid for your covered drugs.
                `,
    },
    6: {
      label: 'you are in the Medicare Part D coverage gap period.',
      title: 'Coverage Gap Period',
      content:
        'After your total drug costs reach a certain amount ($4,660 for most plans), you enter the coverage gap, also known as the donut hole. The donut hole closed for all drugs in 2020, meaning that when you enter the coverage gap you will be responsible for 25% of the cost of your drugs. In the past, you were responsible for a higher percentage of the cost of your drugs. Although the donut hole has closed, you may still see a difference in cost between the initial coverage period and the donut hole. For example, if a drug’s total cost is $100 and you pay your plan’s $20 copay during the initial coverage period, you will be responsible for paying $25 (25% of $100) during the coverage gap.',
    },
    7: {
      label: 'you are in the Medicare Part D catastrophic coverage period.',
      title: 'Catastrophic Coverage Period',
      content:
        'In all Part D plans, after you have paid $7,400 in 2023 in out-of-pocket costs for covered drugs (this amount is just the amount you have paid, not the total drug costs that you and your plan have paid), you reach catastrophic coverage. During this period, you pay significantly lower copays or coinsurance for your covered drugs for the remainder of the year.  During catastrophic coverage, you will pay 5% of the cost for each of your drugs, or $4.15 for generics and $10.35 for brand-name drugs (whichever is greater).',
    },
  },
  'pharmacy-locator': {
    'home-title': 'Find a Pharmacy',
    'option-select': {
      title: 'Need to Find a Pharmacy?',
      subheading: 'Search By:',
      address: {
        title: 'Your Address',
      },
      location: {
        title: 'Your Location',
      },
      zip: {
        title: 'ZIP Code',
      },
      button: 'Search For a Retail Pharmacy',
    },
  },
  footer: {
    leftText: {
      header: 'Need Help?',
      body:
        'Our team is staffed with experts in pharmacy and insurance benefits. Call ' +
        '<a href="tel:{{number}}">{{number}}</a> ' +
        '(TTY: ' +
        '<a href="tel:18008778973">1-800-877-8973</a>' +
        ') for live, personal assistance (Monday–Friday, 7 a.m.–8 p.m. CT), or ' +
        '<a style="text-decoration: underline; cursor: pointer;"><span data-modal-id="contactmodal" tabindex="0">send us a message</span></a> ' +
        "and we'll get back to you shortly.",
      feedback: 'Have some feedback for us?',
      tellUs: 'Tell us what we can do better!',
    },
    rightText: {
      header: 'Download Our Mobile App',
      textLink: 'Or text me a link to ',
      download: 'download it on my phone',
    },
    whitelabel: '',
    chatIconAltText: 'question mark and chat bubbles',
    poweredByLogoAltText: 'powered by adjudilite',
  },
  'load-events': {
    headings: {
      'savings-heading':
        'Powerful, patented technology is building your personalized savings plan!',
      'savings-subheading': '',
    },

    events: {
      suggestions_called: 'Searching 100,000+ Rx products',
      pharmacies_called: 'Checking our 37,000+ clinical suggestions',
      pricing_called: 'Confirming pricing and coverage with your pharmacy benefits',
    },
  },
  productInfoItem: {
    disclaimer:
      'Disclaimer: This drug information is an information resource only and may be derived from a third-party source outside of Rx Savings Solutions. The information in no way constitutes medical advice and you should contact a physician before taking or making any changes to a medication. There is no guarantee on the accuracy or most up to date information regarding this drug. Rx Savings Solutions does not endorse any drug or makes any recommendation regarding a treatment based on this information. Rx Savings Solutions disclaims any liability related to any claim related to the provision of this drug information.',
  },
  SMSOptIn: {
    header: "Don't Miss Out on Savings",
    paragraphOne: 'Get prescription savings notifications sent directly to your phone!',
    paragraphTwo:
      "Sign up today and we'll notify you as soon as we find savings opportunities on the medications you, or your dependents, are taking.",
    paragraphThree:
      'Enter (or confirm) your mobile number below to receive savings notifications via text message.',
    disclaimer:
      'By clicking Sign Up, you consent to receive mobile alerts from Rx Savings Solutions. Up to 4 messages per member per month. Message and data rates may apply. Text STOP to cancel, text HELP for assistance.',
  },
  HistoricalReport: {
    BreadcrumbTextForMedicineCabinet: 'Medicine Cabinet',
    BreadcrumbTextForViewReport: 'View Report',
    ViewReport: 'View Report',
    MedListDescription:
      'The <strong>Medication List for a Doctor Visit</strong> report will give you a full list of the meds you are taking so when a doctor (or nurse) asks what you are taking, you can show them. We also include savings suggestions in case you want to ask your prescriber about them at the visit.',
    MedSpendingDescription:
      'The <strong>Medication Spending</strong> report will give you detailed spending information on every med you are taking or have taken over any time period where your claims are available.',
    ChooseReport: {
      title: 'Choose the Report Type You Want',
      optionOne: 'Medication List for Doctor Visit',
      optionTwo: 'Medication Spending',
      buttonOne: 'Next Step',
      buttonTwo: 'Next Step',
    },
    MedicationList: {
      title: 'Customize Your Medication List',
      optionOne: 'Just my current medications',
      optionTwo: 'Customized medication history',
      buttonOne: 'View Your Report',
      buttonTwo: 'Next Step',
    },
    DocMedHistory: {
      title: 'Choose Your Medication History Date Range',
      optionOne: 'From first claim available',
      optionTwo: 'Custom date range',
      buttonOne: 'View Your Report',
      buttonTwo: 'View Your Report',
    },
    MedSpending: {
      title: 'Customize Your Medication Spending Report',
      optionOne: 'Just show my latest fills',
      optionTwo: 'Show spending over time',
      buttonOne: 'View Your Report',
      buttonTwo: 'Next Step',
    },
    MedHistory: {
      title: 'Choose Your Medication Spending Date Range',
      optionOne: 'From first claim available',
      optionTwo: 'Tax year {{year}}',
      optionThree: 'Custom date range',
      buttonOne: 'View Your Report',
      buttonTwo: 'View Your Report',
      buttonThree: 'View Your Report',
    },
  },
  profileSettings: {
    edit: 'edit',
    change: 'Change',
    mobileNumberTitle: 'Mobile Number',
    emailAddressTitle: 'Email Address',
    addressTitle: 'Address',
    streetTitle: 'Street',
    cityTitle: 'City',
    stateTitle: 'State',
    zipTitle: 'Zip Code',
    passwordTitle: 'Password',
    choosePassword: 'Choose a new password',
    requirementsTitle: 'Your password must have each of the following:',
    requirementsSpecial: 'At least 1 number, special character or space',
    requirementsCharacterLimit: 'At least 8 characters',
    requirementsCharacters: 'At least 1 uppercase and 1 lowercase letter',
    toggleFieldEmail: 'Email',
    toggleFieldSMS: 'SMS',
    toggleFieldPush: 'Push Notifications',
    userSettings: 'User Settings',
    'sms-number-modal': {
      instructions: 'Please enter your mobile number to receive notifications via sms/text.',
      cancel: 'Cancel',
      disclaimer:
        'By enabling SMS, you are providing consent to receive mobile alerts from Rx Savings Solutions. Message and data rates may apply. Text STOP to cancel, text HELP for assistance.',
    },
    preferredPharmacy: 'Your Primary Retail Pharmacy has been updated.',
    communicationSettings: {
      heading: 'Med Reminder Settings',
      optionEmail: 'Email',
      optionSMS: 'Text/SMS',
      optionPush: 'Push Notification',
      manage: 'Need to manage your reminders?',
      successMessage: 'Your Communication Preferences have been updated.',
      click: 'Click here.',
      notSet: "You haven't created any reminders yet. To set one, ",
      pushNotificationPrompt: 'In order to receive push notifications, you must ',
      pushNotificationPromptLink: 'download our app.',
    },
    loginHeader: 'Login Information',
    contactHeader: ' Contact Information',
    primaryPharmacyHeader: 'Your Primary Retail Pharmacy',
    recentPharmaciesHeader: 'Or Choose a Retail Pharmacy From Your Claims',
    searchUsingAddressOrZip: 'Search for a Retail Pharmacy',
    noPharmaciesAddress: 'There are no pharmacies available at this Address.',
    noPharmaciesZipcode: 'There are no pharmacies available at this ZIP Code.',
    invalidAddress: 'Please Enter a Valid Address',
    invalidZipCode: 'Please Enter a Valid ZIP Code',
    emailMustBeValid: 'The email must be a valid email address.',
    mobileNumberInvalid: 'The mobile number format is invalid.',
    allFieldsRequired: 'All fields are required.',
  },
  reminderAcknowledgement: {
    morning: 'Good Morning!',
    afternoon: 'Good Afternoon!',
    evening: 'Good Evening!',
    night: 'Good Night!',
    thanks: 'Thanks for taking your medications',
    dashboard:
      "Don't forget to check your dashboard. There might be a fresh savings opportunity waiting for you.",
    itsTime: "It's time to take your medication{{plural}}",
    taken: "I've taken my medication{{plural}}",
    toDashboard: 'Take Me to My Dashboard',
    needUpdate: 'Need to update your reminders?',
    toReminders: 'Go to Reminders',
    error: 'There was a problem acknowledging your medications. Please try again.',
    alreadyAcknowledged:
      "You've already acknowledged that you took your medications for {{time}} on {{date}}.",
    email: 'email',
    sms: 'text messages',
    push: 'push notifications',
    expiredMessage: 'This reminder has expired. Please check for a new reminder.',
    errorMessage:
      "Oops! We can't find this reminder. Try refreshing the page. If this problem persists, please contact our Pharmacy Support Team at 1-800-268-4476.",
  },
  smsToggleModal: {
    header: 'Please enter your mobile number to receive notifications via sms/text.',
    disclaimer:
      'By enabling SMS, you are providing consent to receive mobile alerts from Rx Savings Solutions. Up to 3 messages per month. Message and data rates may apply. Text STOP to cancel, text HELP for assistance.',
    mobileNumberHeader: 'Mobile Number',
    errorLabel: 'Please enter a valid phone number.',
    Save: 'Save',
    Cancel: 'Cancel',
  },
  smsOptInModal: {
    signUp: 'Sign Up',
    noThanks: 'No Thanks',
  },
  'contact-prescriber-started': {
    header: 'Your request is currently in progress!',
    body: 'We are in the process of carrying out this prescription change with your provider and pharmacy. If you have not been notified of the status of your request, or need to cancel or change it in any way, please call 1-800-268-4476.',
  },
  MailOrderRefillModal: {
    almostTime: 'It’s almost time to refill your medication.',
    lastRefilled:
      'According to our claims data, you last refilled your medication on {{date}}. This means you should be due for a refill.',
    refillInfo: 'Use the information below to refill your prescription.',
    pharmacyDetails: 'Pharmacy Details',
    mailOrder: 'Home Delivery Pharmacy',
    refilled: "I've refilled my prescription",
  },
  reportModal: {
    rightText: {
      header: 'Can We Help You?',
      body: 'Our Pharmacy Support Team is available at: {{number}}',
    },
    preReport: 'Fetching your data',
  },
  doctorVisitReport: {
    BreadcrumbText: 'Doctor Visit Report',
    title: 'Bring the Below Info on Your Next Doctor Visit',
    noMeds: 'No historical claims found',
    DateRange: 'Prescriptions Filled From: {{from}} to {{to}}',
    LoadingStateLabel: 'Please wait while we gather your data and generate the report',
    PastMedsTableHeaders: {
      header: 'Medications Older Than a Year',
      drugName: 'Medication Name',
      dose: 'Dose',
      quantity: 'Quantity',
      form: 'Form',
      dates: 'Date(s) Filled',
    },
    CurrentMedsTableHeaders: {
      header: 'Current Medications',
      drugName: 'Medication',
      Adherent: '% Adherent',
    },
    waitState: {
      heading: 'Doctor Visit Report',
      'sub-heading': '(It will be ready in just a couple seconds.)',
    },
    savingsHeader: 'Savings Opportunities For Most Recent Fills',
    savingsText:
      "Below are opportunities for <span data-private>{{firstName}} {{lastName}}</span> to save on medications. As <span data-private>{{firstName}}'s </span> prescriber, please discuss these options with <span data-private>{{firstName}}</span> to determine if a change would be beneficial.",
    savingsFrom: 'Spending and Savings as of {{date}}',
    savingsPaid: 'You paid {{amount}}',
    paidOn: 'on {{date}}',
    savingsPerFill: 'Save {{amount}} per fill',
    errorMessage:
      'Sorry, there was a problem creating your report. Try refreshing the page. If this problem persists, please contact our Pharmacy Support Team at 1-800-268-4476.',
  },
  spendingReport: {
    BreadcrumbText: 'Spending Report',
    title: 'Med Spending Report for',
    DateRange: 'Total Spending From: {{from}} to {{to}}',
    noMeds: 'No historical claims found',
    LoadingStateLabel: 'Please wait while we gather your data and generate the report',
    MedsHeaders: {
      'short-term': 'Short-term Use Medications',
      maintenance: 'Maintenance Medications',
    },
    waitState: {
      heading: 'Spending Report',
      'sub-heading': '(It will be ready in just a couple seconds.)',
    },
    medList: {
      filledDate: 'Prescription Filled:',
      pharmacyHeader: 'Pharmacy Information',
      costHeader: 'Cost Breakdown',
      retail: 'Retail Price:',
      planPay: 'Plan Pays:',
      yourPay: 'You Paid',
    },
    errorMessage:
      'Sorry, there was a problem creating your report. Try refreshing the page. If this problem persists, please contact our Pharmacy Support Team at 1-800-268-4476.',
  },
  'prescriber-letter-modal': {
    rightText: {
      header: 'Can We Help You?',
      body: 'Our Pharmacy Support Team is available at: {{number}}',
    },
    salutation: 'Dear Prescriber,',
    paragraph1:
      'Your patient, <span data-private>{{firstName}} {{lastName}}</span>, is interested in reducing his/her prescription medication costs.\n' +
      'Your patient’s employer or health plan has partnered with Rx Savings Solutions to provide\n' +
      'prescription medication savings. Our goal is to work with you to suggest potential savings\n' +
      'options that make sense for your patient.',
    paragraph2:
      'As the prescriber, we realize there may be reasons why a particular drug has been selected.\n' +
      'We ask for your consideration in evaluating the cost-benefit advantage of the less expensive\n' +
      'alternative(s).',
    paragraph3:
      'We have provided your patient some suggestions on how to achieve prescription medication\n' +
      'savings. Below, you will find the suggestion they are most interested in.',
    paragraph4:
      'We recommend <span data-private>{{firstName}}</span> switch from <strong>{{product}} {{strength}} {{form}}</strong> to <strong>{{suggestedProducts}}</strong>, a {{suggestionType}} suggestion.',
    paragraph5:
      'If you approve this request,<span data-private>{{firstName}}</span> will begin <strong>saving ${{savings}} on every fill.</strong>',
    paragraph6:
      'We appreciate your review of our cost-saving recommendations. Please feel free to contact\n' +
      'Rx Savings Solutions at <a href="tel:{{number}}">{{number}}</a> with questions. You may also email us at <a class="pharmacist-email" href="mailto:pharmacist@rxsavingssolutions.com">pharmacist@rxsavingssolutions.com</a> .',
  },
  'family-permission': {
    'prescription-details': 'See your prescription details.',
    'contact-prescriber': {
      how: 'How can <span data-private>{{name}}</span> get these savings?',
      'how-reward':
        '<strong>How to Switch and receive a ${{amount}} <span class="no-wrap">Rx Rewards</span> Visa<sup>®</sup> <span class="no-wrap">Prepaid Card</span>?</strong>',
      fromPharmacy:
        'Because <span data-private>{{name}}</span> is over age 18, he/she will need to request {{drug}} from the pharmacy.',
      requestPrescription:
        'Because <span data-private>{{name}}</span> is over age 18, he/she will need to request a prescription for {{drug}}:',
      toCompleteReward:
        'To complete this switch and receive a ${{amount}} <span class="no-wrap">Rx Rewards</span> Visa<sup>®</sup> <span class="no-wrap">Prepaid Card</span>, ask <span data-private>{{name}}</span> to sign in to our web portal.',
      pleaseTell:
        'Please tell <span data-private>{{name}}</span> to log in, click on the Savings Alert for {{drug}}, and click the "Contact My Prescriber" button. Our Pharmacy Support team will handle everything.',
      downloadLetter:
        '<span data-private>{{name}}</span> can download a letter to send to the prescribing doctor. The link to the letter can be found next to the “Contact My Prescriber” button.',
      simplyCall:
        '<span data-private>{{name}}</span> can simply call the prescribing doctor and make the request over the phone.',
      requestLetter:
        'Please tell <span data-private>{{name}}</span> to log in to Rx Savings Solutions and click on the Savings Alert for {{drug}}. <span data-private>{{name}}</span> can then click and download the request letter and send it to the doctor. All the necessary information is automatically included in the letter.',
      or: 'or',
    },
    accept: 'I accept',
    decline: 'Decline',
    'reactive-grant': {
      //shows when family member requests to view your medication
      header: '<span data-private>{{name}}</span> would like to view your profile.',
      body: 'With your permission, <span data-private>{{name}}</span> can see which medications you take now or in the future, and whether cheaper options may be available.',
    },
    'proactive-grant': {
      //shows on dashboard to proactively grant permission
      header: 'Would you like to allow <span data-private>{{name}}</span> to see your profile?',
      body: 'With your permission, <span data-private>{{name}}</span> can see which medications you take now or in the future, and whether cheaper options may be available.',
    },
    'accept-button': 'Let <span data-private>{{name}}</span> see my profile',
    'ignore-button': 'Ignore',
    terms: 'I have read and agree to ',
    termsTwo: 'the terms and conditions.',
    modalTerms:
      'Before you can share your profile and medication(s) with <span data-private>{{name}}</span>, you must agree to the Terms and Conditions.',
    shareWithPrimaryModal:
      'By clicking “I accept” you are giving permission for Rx Savings Solutions to disclose your prescription information ' +
      'to the parent/guardian responsible for the payment of your care as the primary policyholder. Please be aware that certain sensitive prescriptions will not ' +
      'be shared with the designated individual based on your particular health plan or provider.  Additionally, you agree that you have read and ' +
      'understand the following:  This consent shall be valid until you are no longer listed as a dependent on the parent/guardian’s health insurance policy.  ' +
      'Only the primary policyholder of your insurance policy will have access to your prescription information.  This access will be granted solely in the parent/guardian’s ' +
      'capacity as the primary policyholder.  Rx Savings Solutions will provide the minimum necessary amount of your prescription information to the parent/guardian in their capacity as ' +
      'the primary policyholder.  You have the right to object or withdraw your consent at any time by emailing Rx Savings Solution’s Privacy Officer at Privacy@rxsavingssolutions.com. ' +
      'Consent to share your information with a designated individual is not required in order to enroll in Rx Savings Solutions’ services.',
    shareWithDependentModal:
      'By clicking “I agree” you are giving permission for Rx Savings Solutions to disclose your prescription information for limited notification purposes to the ' +
      'individual you designate as having involvement with your care. Please be aware that certain sensitive prescriptions will not be shared with the designated individual based on your ' +
      'particular health plan or provider. Additionally, you agree that you have read and understand the following:  (1) This consent shall be valid until you withdraw or ' +
      'object to having the designated individual have access to your prescription information; (2) this access is being granted to the designated individual because it is in your best ' +
      'interest that the designated individual have access; and (3) Rx Savings Solutions has provided you with the opportunity to agree or object to the use or disclosure of your ' +
      'prescription information.  You have the right to object or withdraw your consent at any time either through the member portal or by emailing Rx Savings Solution’s Privacy ' +
      "Officer at Privacy@rxsavingssolutions.com. Consent to share your information with a designated individual is not required to enroll in Rx Savings Solutions' services.",
    sharing: {
      confirmation:
        'An email asking for <span data-private>{{name}}</span>’s permission has been sent.',
      confirmationQuestion:
        'Do you want to share your profile and medication info with <span data-private>{{name}}?</span>',
      confirmationButtonYes: 'Yes',
      confirmationButtonNo: 'No',
      readTerms: 'I have read and agree to the ',
      conditions: ' terms and conditions.',
      success:
        'Success! We’ve sent a message to <span data-private>{{name}}</spandata-private> on your behalf, asking if it’s okay for you to view their profile.',
      successAlso:
        '<span data-private>{{name}}</span> will also be able to view your profile. You can always change this in your ',
      privacySettings: 'privacy settings.',
      askSuccess:
        'Success! We’ve sent a message to <span data-private>{{name}}</span> on your behalf, asking if it’s okay for you to view their profile.',
      dueToPrivacy:
        "Due to privacy laws, we can’t display without <span data-private>{{name}}'s</span> permission.",
      request:
        'Want us to send an email to <span data-private>{{name}}</span> asking for permission for you to see this family member’s savings?',
      button: 'Send Request',
      context:
        '(With permission, you’ll be able to see an adult dependent’s profile and let them know when savings are available.)',
    },
    alert: {
      mustAgree:
        'Before you can share your profile with {{name}}, you must agree to the Terms and Conditions.',
      success:
        'Success! <span data-private>{{name}}</span> can now view your profile. You can always change this in your ',
      privacySettings: 'privacy settings.',
    },
  },
  videos: {
    downloadAppText: 'Be ready by ',
    downloadAppButton: 'downloading our mobile app.',
    alerts: {
      title: "On regular medications? We've got you covered!",
      description: 'Watch how the savings find you, not the other way around.',
    },
    noAlerts: {
      title: 'Next doctor visit, bring a friend with you!',
      description: 'See how you can save money before you reach the pharmacy.',
    },
  },
  'contact-prescriber-alert': {
    seeMore: 'See more details',
    active: {
      header: 'Your prescription change for {{product}} is in the works!',
      dependentHeader: "{{name}}'s prescription change for {{product}} is in the works!",
      body: 'We’re working with your prescriber and/or pharmacy to make the change.',
    },
    complete: {
      header: 'Your prescription change for {{product}} is complete.',
      dependentHeader: "{{name}}'s prescription change for {{product}} is complete.",
      body: 'Check with your pharmacy. It could be ready for pick-up or delivery.',
    },
  },
  pharmacySections: {
    primaryPharmacyHeader: 'Your Primary Pharmacy',
    preferredPharmaciesHeader: 'Plan Preferred Pharmacies',
    nearbyPharmaciesHeader: 'Pharmacies Near You',
    mailOrderPharmaciesHeader: 'Home Delivery Pharmacies',
  },
  'express-alert': {
    'navigation-header': 'Best Way to Save',
    'comparisonView-navigation-header': "Here's how to switch!",
    'rewardable-header': 'Receive a ${{amount}} Rx Rewards Visa® Prepaid Card',
    'reward-description':
      'Plus earn ${{amount}} <span class="no-wrap">Rx Rewards</span> <span class="no-wrap">Visa® Prepaid Card</span>',
    header: 'Saving {{amount}} is easy!',
    'header-up-to': 'Saving {{amount}} or more is easy!',
    clientSavingsHeader: 'Saving your group {{amount}} is easy!',
    'clientSavingsHeader-up-to': 'Saving your group {{amount}} or more is easy!',
    subHeader: 'Here’s what we will do for you.',
    'footer-button': "Let's Get Started",
    'footer-link': 'Show Me Other Options',
    'comparisonView-footer-link': 'Compare Pharmacy Pricing',
    'or-less': ' or less',
    'step-2': 'Get the prescription filled by:',
    'change-pharmacy': 'Change Pharmacy',
    'rewardable-step-2':
      'You will receive a ${{amount}} Rx Rewards Visa® Prepaid Card after the new prescription is filled and the claim is processed.',
    'medication-switch': {
      to: 'to',
      TA: {
        'switching-header': 'We’ll get approval from {{doctor}} to switch you from:',
        'switching-disclaimer-text':
          'Our pharmacists think this medication can work the same in treating your condition.',
        'switching-disclaimer-link': 'Tell me more.',
      },
      DF: {
        'switching-header':
          "We'll talk to {{doctor}} about switching your {{primaryMedication}} from a:",
        'switching-disclaimer-text':
          'By switching from a {{medicationFrom}} to a {{medicationTo}}, you will receive the same active ingredient prescribed. Need more info?',
        'switching-disclaimer-link': 'Click here.',
      },
      GC: {
        'switching-header': 'We’ll talk to {{doctor}} about switching you from:',
        'switching-disclaimer-text':
          'This generic has the exact same active and inactive ingredients as the brand-name {{medicationFrom}}, and has the exact same effect when taken at the same dose.',
        'switching-disclaimer-link': 'Tell me more.',
      },
      GS: {
        'switching-header': 'We’ll talk to {{doctor}} about switching you from:',
        'switching-disclaimer-text':
          'This generic contains the same active ingredient as {{medicationFrom}}, and has the same effect when taken at the same dose.',
        'switching-disclaimer-link': 'Tell me more.',
      },
      TS: {
        'switching-header':
          "We'll talk to {{doctor}} about a prescription for a higher dose of {{primaryMedication}} you can split.",
        'switching-disclaimer-text':
          'Splitting a higher dosage of {{medicationFrom}} in half can save you per fill. {{doctor}} will adjust your prescription and directions for the new dosage.',
        'switching-disclaimer-link': 'Tell me more.',
      },
    },
    'contact-prescriber': {
      'contact-info': {
        'navigation-header': 'Confirm Contact Info',
        header: 'Let us know how best to reach you.',
        'confirm-number':
          'To make the switch, enter (or confirm) your mobile number so we can text you about the status of your request.',
        'mobile-button-text': 'Submit Phone Number',
        'email-button-text': 'Email Me Instead',
        'more-info': 'We’ll use the email on your account.',
        consent:
          'By providing my email address and/or phone number and clicking submit, I expressly consent to Rx Savings Solutions sending me emails and/or reaching out via phone regarding the Contact Prescriber request.',
        'rewardable-consent-1':
          'By providing my email address and/or my phone number and clicking submit, you consent to receive the PayForward Rx Savings Solutions Visa® Prepaid Card at no cost to you, preloaded with your incentive reward, the use of which is subject to the <span data-modal-id="agreement" tabindex="0">Cardholder Agreement</span>, <span data-modal-id="tos" tabindex="0">Terms of Service</span>, and <span data-modal-id="privacy" tabindex="0">Privacy Policy</span>.',
        'rewardable-consent-2':
          'The PayForward® Rx Savings Solutions Visa® Prepaid Card is issued by Stride Bank, N.A., Member FDIC, pursuant to a license from Visa USA Inc. PayForward is an independent company that administers the member rewards program on behalf of Rx Savings Solutions.',
        'footer-cancel-link': 'Cancel Request',
        'email-info-bubble': "We'll use the email on your account.",
      },
      'rx-home-delivery': {
        'confirm-header': 'Confirm Home Delivery',
      },
      confirmation: {
        'navigation-header': 'Request Submitted',
        header: "We'll take it from here!",
        'doctor-request': 'We’re asking {{doctor}} to switch your prescription to:',
        'sending-to': 'And sending it to be filled at:',
        'sending-to-MO': 'And sending it to your home delivery pharmacy:',
        'support-text':
          'You will receive request updates via {{contactType}} at <span data-private>{{value}}</span>. If our team or your doctor has any questions about the switch, our pharmacy support team will reach out to you via {{contactType}}.',
        'back-to-dashboard-button': 'Back to Dashboard',
        'footer-text': 'Didn’t mean to make this switch?',
        'footer-cancel-link': 'Cancel the Request.',
        'rewards-1':
          'You will receive a ${{amount}} Rx Rewards Visa® Prepaid Card after the new prescription is filled and the claim is processed.',
        'rewards-2': 'Earns a ${{amount}} Rx Rewards <br /> Visa® Prepaid Card',
      },
    },
    productDetails: {
      BreadcrumbTextForDrugDetail: 'Drug Detail',
      'navigation-header': 'Why You Can Trust This Option',
      jeff: {
        header: 'Dr. Green says:',
        details: {
          designation: 'Director of Pharmacy',
          degree: 'Dr. Jeff Green, PharmD',
          summary:
            'Jeff has more than 13 years of experience in retail pharmacy and pharmacy benefits experience with Walgreens and Aetna.',
        },
        DF: 'Believe it or not, the same medication may have a much different price depending on the form (capsule, tablet, liquid, etc.). I always tell my friends and family to check them all. In this case,<strong> you’ll save money by switching to a different form.</strong> Just click the button below and we’ll ask your doctor to prescribe the lower-cost option for you.',
        TS: 'I’ve reviewed this suggestion and consider it safe and effective to split these tablets in half, even if they are not pre-scored for easy splitting. Tablet-splitting tools are inexpensive and can be found at pharmacies everywhere. <strong>This is an easy way to save money, because you get the same number of doses at half the price.</strong> Just click the button below and we’ll ask your doctor to prescribe the larger dose.',
      },

      stone: {
        header: 'Dr. Stone Says:',
        details: {
          designation: 'Clinical Pharmacy Manager',
          degree: 'Dr. Nikki Stone, PharmD',
          summary:
            'Nikki has more than 15 years of clinical pharmacy experience in the retail sector for Walgreens, CVS and Publix Supermarkets.',
        },
        TA: 'I’ve reviewed your savings suggestion and think this is a great alternative, something I would recommend to my own family.<strong> This medication will save you money and can be just as effective.</strong> Simply click the button below and we’ll get in touch with your doctor to make sure it’s right for you.',
        GC: 'This suggestion is an easy one!<strong> You can get exactly the same medication, just without the brand name and higher price.</strong> I would definitely tell a friend or family member to make the switch. Simply click the button below and we’ll ask your doctor to prescribe the lower-cost generic for you.',
      },

      matt: {
        header: 'Dr. Jarvis says:',
        details: {
          designation: 'Director of Pharmacy',
          degree: 'Dr. Matt Jarvis, PharmD',
          summary:
            'Dr. Jarvis served in a variety of roles with Walgreens as a Pharmacy Manager, Specialty Pharmacy Manager and Healthcare Supervisor.',
        },
        GS: 'I’ve reviewed your savings suggestion for this generic and think it’s a smart move. You’ll save money and get <strong>the same active ingredient as your current medication.</strong> If a friend or family member asked me, I would say “Definitely!” Simply click the button below and we’ll ask your doctor to prescribe the lower-cost generic for you.',
      },
      'switch-text': 'Ready to make the switch?',
      'switch-button': "Let's Get Started",
      'comparison-header': 'Compare the Medications',
      'tablet-split-header': 'About {{medication}}',
      'comparison-footer':
        'Disclaimer: This drug information is an information resource only and may be derived from a third-party source outside of Rx Savings Solutions. The information in no way constitutes medical advice and you should contact a physician before taking or making any changes to a medication. There is no guarantee on the accuracy or most up to date information regarding this drug. Rx Savings Solutions does not endorse any drug or makes any recommendation regarding a treatment based on this information. Rx Savings Solutions disclaims any liability related to any claim related to the provision of this drug information.',

      old: 'OLD',
      new: 'NEW',
      'side-effects': 'Side Effects',
      Interactions: 'Interactions',
      Details: 'Details',
    },
    pharmacyList: {
      header: 'Select a Pharmacy',
      'primary-pharmacy': 'Primary Pharmacy',
      'best-savings': 'Best Savings',
      confirm: 'Confirm',
      quantity: 'Qty: {{quantity}} ({{costPerUnit}}/ea)',
      days: 'Days: {{daySupply}} ({{costPerDay}}/day)',
      limitedPharmaciesHeader: "Why don't I see the pharmacy I'm looking for?",
      limitedPharmaciesBody:
        'This list shows the cheapest prices at in-network pharmacies near the ZIP code of your primary pharmacy ({{zip}}). To change this please ',
      limitedPharmaciesBodyNoZip:
        'This list shows the cheapest prices at in-network pharmacies near the ZIP code of your primary pharmacy. To change this please ',
      limitedPharmaciesLink: 'edit your primary pharmacy',
    },
  },
  'primary-pharmacy-alerts': {
    confirm: {
      header: 'Is this your current pharmacy?',
      body: 'We noticed you’ve filled prescriptions at:',
      buttonText: 'Confirm Primary Pharmacy',
      footerText: 'Not your pharmacy?',
      footerTextLink: 'Change it in your settings.',
    },
    confirmed: {
      header: 'Thank you for confirming your primary pharmacy.',
      body: 'To change it, go to your',
      bodyLink: 'settings.',
    },
    update: {
      header: 'Is this your new pharmacy?',
      body: 'We noticed you filled a prescription at:',
      buttonText: 'Update Primary Pharmacy',
      footerTextLink: 'Ignore This Pharmacy',
    },
    updated: {
      header: 'Great! We’ve updated your primary pharmacy.',
      body: 'You can always change it in the',
      bodyLink: 'settings.',
    },
    ignored: {
      header: 'Thanks! Your primary pharmacy location will stay:',
      footerText: 'Still not your primary pharmacy?',
      footerTextLink: 'Change it in your settings.',
    },
  },
  logoutPage: {
    logoAltText: 'logo',
    iconAltText: 'cursor click icon',
    header: 'You’re Logged Out',
    message:
      'Come back to your Rx Savings Solutions account any time to see how you can save on your prescriptions.',
    ssoButton: 'Take Me Back to {{page}}',
    ssoSignInAgain: 'Or you can <a href="{{url}}">sign in</a> again',
    needHelp: 'Need Help?',
    contactSupport: 'Contact our Pharmacy Support Team',
    phoneNumber:
      '<a href="tel:18002684476">1-800-268-4476</a> (TTY: <a href="tel:18008778973">1-800-877-8973</a>)',
    hours: 'M-F: 7 a.m.–8 p.m. CT, or by email at',
    email: '<a href="mailto:support@rxsavingssolutions.com">support@rxsavingssolutions.com</a>',
    signInAgain: 'Log In',
    failure: 'There was a problem logging you out. Please try again.',
  },
  'rx-rewards-ppp': {
    and: 'and ',
    comma: ', ',
    period: '.',
    header:
      '<strong>Yes, please send me a ${{amount}} <span class="no-wrap">Rx Rewards</span> Visa<sup>®</sup> <span class="no-wrap">Prepaid Card</span> once the new prescription is filled!</strong>',
    terms:
      'By clicking "{{button}}," I consent to receive the PayForward<sup>®</sup> Rx Savings Solutions Visa<sup>®</sup> <span class="no-wrap">Prepaid Card</span> at no cost to me, preloaded with my incentive reward, the use of which is subject to the ',
    'agreement-link': 'Cardholder Agreement',
    'agreement-html': `
                <div class="rx-rewards-ppp__modal">
                    <h2>
                      <strong>
                        PAYFORWARD<sup>®</sup> RX SAVINGS SOLUTIONS<sup>®</sup> Visa<sup>®</sup> PREPAID CARDHOLDER AGREEMENT IMPORTANT TERMS AND CONDITIONS PLEASE READ CAREFULLY
                      </strong>
                    </h2>
                    <p>
                      <strong>Current as of June 17, 2019</strong>
                    </p>
                    <hr />
                    <p>
                      <strong>
                        NOTICE: THIS CARDHOLDER AGREEMENT CONTAINS AN ARBITRATION CLAUSE. PLEASE READ THIS PROVISION CAREFULLY, AS IT AFFECTS
                    YOUR LEGAL RIGHTS. IT PROVIDES THAT ANY CLAIM RELATING TO YOUR ACCOUNT MAY BE RESOLVED BY BINDING ARBITRATION. YOU ARE
                    ENTITLED TO A FAIR HEARING, BUT THE ARBITRATION PROCEDURES ARE SIMPLER AND MORE LIMITED THAN RULES APPLICABLE IN COURT,
                    AND ARBITRATION DECISIONS ARE SUBJECT TO VERY LIMITED REVIEW.
                      </strong>
                    </p>
                    <p>
                      <strong>
                        CLAIMS MAY BE ARBITRATED ONLY ON AN INDIVIDUAL BASIS. YOU EXPRESSLY WAIVE ANY RIGHT THAT YOU MAY HAVE TO ARBITRATE A
                    CLASS ACTION. IF EITHER PARTY CHOOSES TO ARBITRATE A CLAIM, NEITHER PARTY WILL HAVE THE RIGHT TO LITIGATE THAT CLAIM IN
                    COURT OR TO HAVE A JURY TRIAL ON THAT CLAIM, OR TO PARTICIPATE IN A CLASS ACTION OR REPRESENTATIVE ACTION WITH RESPECT TO
                    SUCH CLAIM. THE TERMS OF THE ARBITRATION CLAUSE APPEAR AT THE END OF THIS AGREEMENT.
                      </strong>
                    </p>
                    <p>This agreement may change from time to time; please visit PayForward.com to view the most recent version.</p>
                    <ol class="rx-rewards-list--numbered">
                    <li>
                        <p>
                          <strong>Terms and Conditions.</strong> By activating the Card, using, or authorizing the use of the Card, you represent and warrant to us and agree that: (i)
                        you are a U.S. citizen or legal alien residing in one of the 50 states or the District of Columbia; (ii) the personal information that you provide to
                        us in connection with the Card is true, correct, and complete; (iii) You agree to appoint Stride Bank, N.A. as your agent to pool your funds,
                        together with funds from other Cardholders and to deposit those funds at one or more FDIC insured banks, and obtain your funds from the
                        pooled funds account(s) in order to transfer your funds as you may direct; and (iv) you received a copy of this Agreement and agree to be bound
                        by and to comply with its terms, including the Arbitration Provision set forth below. This agreement outlines the terms and conditions,
                        governing your use of the PayForward Visa Prepaid Card (“Card”) issued by Stride Bank, N.A., Member FDIC and supersedes any terms and
                        conditions that you may have previously received (the “Agreement”). <strong>PLEASE READ THIS AGREEMENT CAREFULLY AND KEEP IT FOR FUTURE
                        REFERENCE.</strong>
                        </p>
                    </li>
                    <li>
                        <p>
                          <strong>Definitions.</strong> “Account Number” means the 16 digit number used to identify your Card Account. Our “Business Days” are Monday through
                          Friday excluding certain federal holidays. “Card” means the PayForward Rx Savings Solutions Visa Prepaid Card issued to you: the personalized
                          card that you may receive after you sign up online (your “Personalized Card”). “Card Account” means the records we maintain to account for
                          your available funds and transactions made using your Card. “Card Number” is the 16-digit number embossed on your Card. “You” and “your”
                          mean the person to whom the Card Account is registered and who is authorized to use the Card as provided by this Agreement. “We”, “us”,
                          “our” and “Bank” means Stride Bank, N.A., its successors and assigns. “Servicer” means PayForward, which functions as a Card distributor and
                          servicer on behalf of the Bank. “Employer” means the employer you work for. “Unauthorized Use” means that you did not provide, directly, by
                          implication or otherwise, the right to use your Card and you received no benefit from the "unauthorized" purchase. “Pharmacy Incentive
                          Rewards” means funds that are awarded to you by the PayForward Enterprise Partner for prescription related choices.
                        </p>
                    </li>
                    <li>
                        <p>
                            <strong>Fee Schedule.</strong> All fees assessed by us are deducted from the available balance in your Card Account. If your Card Account does not have
                        sufficient funds available to cover a fee, the amount of such fee will be deducted from any funds subsequently loaded to your Card Account.
                        The fees that apply to your Card Account are as follows:
                        </p>
                        <table class="rx-rewards-ppp__modal-table--striped">
                          <thead>
                            <tr>
                              <th>All Fees</th>
                              <th>Amount</th>
                              <th>Details</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td colspan='3'>Get Started</td>
                            </tr>
                            <tr>
                              <td>Card Issuance</td>
                              <td>$0.00</td>
                              <td>There is no fee charged for obtaining the Prepaid Card.</td>
                            </tr>
                            <tr>
                              <td colspan='3'>Monthly Usage</td>
                            </tr>
                            <tr>
                              <td>Monthly Fee</td>
                              <td>$0.00</td>
                              <td>There is no Monthly Maintenance Fee.</td>
                            </tr>
                            <tr>
                              <td colspan='3'>Add Money</td>
                            </tr>
                            <tr>
                              <td>Pharmacy Reward Credits</td>
                              <td>$0.00</td>
                              <td>There is no fee for Pharmacy Rewards posted to your account.</td>
                            </tr>

                            <tr>
                              <td colspan='3'>Spend Money</td>
                            </tr>
                            <tr>
                              <td>POS Purchases</td>
                              <td>$0.00</td>
                              <td>There is no fee for using your card at point-of-sale within the United States.</td>
                            </tr>
                            <tr>
                              <td colspan='3'>Information</td>
                            </tr>
                            <tr>
                              <td>Automated & Live Agent Phone Calls</td>
                              <td>$0.00</td>
                              <td>There is no fee for automated service or contact with a live agent.</td>
                            </tr>
                            <tr>
                              <td>Email and Text Message Alerts</td>
                              <td>$0.00</td>
                              <td>Standard text messaging rates may apply.</td>
                            </tr>
                            <tr>
                              <td>Mobile Application</td>
                              <td>$0.00</td>
                              <td>Standard data rates and service carrier fees may apply.</td>
                            </tr>
                            <tr>
                              <td>Mailed Periodic Statement Fee</td>
                              <td>$0.00</td>
                              <td>Statement will be printed and mailed on request each month for no fee.</td>
                            </tr>
                            <tr>
                              <td colspan='3'>Using your card outside the U.S.</td>
                            </tr>
                            <tr>
                              <td>Foreign Transaction Fee</td>
                              <td>N/A</td>
                              <td>The card cannot be used outside of the United States.</td>
                            </tr>
                            <tr>
                              <td colspan='3'>Other</td>
                            </tr>
                            <tr>
                              <td>Inactivity Fee</td>
                              <td>$0.00</td>
                              <td>There is no Monthly Inactivity Fee.</td>
                            </tr>
                            <tr>
                              <td>Replacement Card</td>
                              <td>$0.00</td>
                              <td>There is no fee to replace lost or stolen cards.</td>
                            </tr>
                          </tbody>
                        </table>
                        <p>Funds will be held at Stride Bank, N.A., an FDIC-insured institution. Funds are insured up to $250,000 by the FDIC in the event Stride Bank, N.A.
                        fails, if specific deposit insurance requirements are met. See fdic.gov/deposit/deposits/prepaid.html for details.</p>
                        <p>There are no overdraft/credit features.</p>
                        <p>Contact PayForward Visa Prepaid Card Member Services by calling 844-944-9273, by email at <a href='mailto:Support@PayForward.com'>Support@PayForward.com</a>, by mail at PO Box
                        2155, Sioux Falls, SD 57101 or visit PayForward.com.</p>
                        <p>For general information about prepaid accounts, visit cfpb.gov/prepaid. If you have a complaint about a prepaid account, call the Consumer
                        Financial Protection Bureau at 1-855-411-2372 or visit cfpb.gov/complaint.</p>
                    </li>
                    <li>
                    <p>
                          <strong>Prepaid Account Description.</strong> The Card is a reloadable prepaid card. The Card allows you to access funds loaded or deposited to your Card
                        Account on your behalf by PayForward’s Enterprise Partner. Your Card Account does not constitute a checking or savings account and is not
                        connected in any way to any other account you may have. The Card is not a gift card, nor is it intended to be used for gifting purposes. The
                        Card is not a credit card. You will not receive any interest on the funds in your Card Account. The funds in your Card Account will be insured to
                        the maximum limit provided by the Federal Deposit Insurance Corporation (FDIC) provided we have been able to fully verify your identity. The
                        expiration date of the Card is identified on the front of your Card. Any unused Pharmacy Incentive Rewards will expire at the end of the Benefit
                        Period. The Card is not transferable, may not be returned or sold and, subject to applicable law, may be cancelled, repossessed or revoked at
                        any time without prior notice.
                        </p>
                    </li>
                    <li>
                        <p>
                          <strong>Verification of Identity.</strong> The USA PATRIOT Act is part of a federal law (the Bank Secrecy Act) that requires all financial institutions to obtain,
                        verify and record information that identifies each person who opens a Card Account with a maximum balance that could exceed $1,000.00
                        “(Verification Information”). What this means for you: When you open a Card Account, we require your name, physical address, and date of
                        birth and other information that will allow us to reasonably identify you. We may also ask to see your driver’s license, Social Security card, or
                        other identifying documents at any time. Upon successful verification, you will be able to use your Card via the methods detailed within this
                        Agreement.
                        </p>
                    </li>
                    <li>
                        <p>
                          <strong>Activating and Signing your Card.</strong> Before you can use your Card, you will need to activate it by following the activation instructions
                        accompanying your Card. You should also sign the back of the Card where indicated. Write down the Card Number and the toll-free number
                        on a separate piece of paper in case the Card is lost or stolen. We reserve the right to delay activation and use of the Card for up to twentyfour (24) hours. During activation or any Customer Service call, we may request that you provide the card security code printed on the front
                        and/or back of your Card, as well as additional Verification Information including, but not limited to, your name, your address, home phone
                        number, date of birth, zip code, and a form of identification.
                        </p>
                    </li>
                    <li>
                        <p>
                          <strong>Card Account Access.</strong> Subject to the limitations set forth in this Agreement, once your Card has been successfully activated and your identity
                        verified, you may use your Card, Card Number, or Account Number, as applicable, to facilitate payment services that can include: (1) receiving
                        funds on your Card Account that are added by PayForward’s Enterprise Partner on your behalf (see section below labeled “Adding Funds to
                        Your Card Account”), (2) purchase goods or services wherever Visa debit cards are accepted (see section below labeled “Using Your Card for
                        Purchases”), Some of these services may not be available at all terminals. There may be fees associated with some of these transactions. For
                        fee information, see the Fee Schedule above.
                        </p>
                        <p>
                          Each time you use the Card, you authorize us to reduce the funds available in your Card Account by the amount of the purchase or withdrawal
                        in addition to any applicable fees, costs or holdings. There is no credit line associated with your Card. This means that at the time of the
                        transaction you must have sufficient funds available in your Card Account to pay for the transaction and any applicable fees and you are never
                        allowed to exceed the available balance in your Card Account. Nevertheless, if you exceed the available balance you shall remain fully liable to
                        us for the amount of the transactions and any applicable fees and charges, and you agree to pay us immediately on demand. We may deduct
                        the negative balance owing from any current or future funds loaded to your Card Account or any other account you activate or maintain with
                        us. If your Card Account has a zero or negative balance, we may, at our option, cancel your Card Account without notice. You can get a receipt
                        at the time you initiate a transaction and you should retain the receipt to verify your transactions. Some merchants may not provide paper
                        receipts for small dollar transactions.
                        </p>
                    </li>
                    <li>
                        <p>
                          <strong>Limitations on Frequency and Dollar Amounts of Transactions.</strong> We impose certain limitations on the number or dollar amount of
                        transactions you can make with your Card. Detailed at the end of this Agreement is a table of limitations that apply to the Card. For security
                        reasons, we may further limit the number or dollar amount of transactions you can make with your Card. We may increase or decrease these
                        limits from time to time in our sole discretion and, to the extent permitted by applicable law, without prior notice to you.
                        </p>
                    </li>
                    <li>
                        <p>
                          <strong>Adding Funds to Your Card Account.</strong> Adding funds is referred to as “loading”. Subject to the limitations in this Agreement, funds may be
                        loaded to your Card Account at any time by PayForward’s Enterprise Partner. All loads are subject to anti-fraud verification procedures that
                        may delay access to the funds. You agree to present the Card and meet identification requirements to complete load transactions as may be
                        required from time to time. We also reserve the right to reject any requests to load funds to your Card Account in our sole discretion. All loads
                        must be made in U.S. dollars. The maximum amount of funds you may load on a daily and monthly basis is described in the section below
                        entitled “Card Limitations”. You cannot load funds to your Card Account at any ATMs. PayForward will credit your Card Account with Pharmacy
                        Incentive Rewards provided by PayForward Enterprise Partners. You can obtain information on Pharmacy Incentive Rewards at
                        <u>PayForward.com after you have registered for the Pharmacy Rewards program.</u>
                        </p>
                    </li>
                    <li>
                        <p>
                          <strong>Personal Identification Number (“PIN”).</strong> You will be asked to create a PIN when you activate your Card. Your PIN is a security feature
                        which will be needed for certain transactions including cash withdrawals and certain purchase transactions. You agree to take reasonable steps
                        to protect the security and confidentiality of your PIN, Card, and any other security information used to access your Card Account, including
                        user names and passwords (collectively “Access Information”). Your PIN identifies you as the proper user of the Card and authorizes
                        transactions that you make with the Card. You should never write your PIN on your Card, do not keep it with your Card, and do not reveal it to
                        any unauthorized person. You should contact us immediately if you believe that anyone has gained unauthorized access to your PIN. You should
                        not reveal the PIN to anyone and you assume full responsibility for any and all PIN transactions. If you believe that your PIN has been stolen
                        and that unauthorized transactions are occurring on your Card, please contact us immediately at the number on the back of your Card so that
                        we may close the Card and issue you a new Card. If you lose or forget your PIN, you may visit our website, mobile app, or call us (see the
                        “Customer Information” section below) to reset your PIN. Our customer service agents cannot access or communicate your current PIN.
                        </p>
                    </li>
                    <li>
                        <p>
                          <strong>Using Your Card for Purchases.</strong> Subject to the limitations set forth in this Agreement, you can use your Card to purchase goods and services
                        everywhere Visa debit cards are accepted. The maximum amount of purchases you may make on a daily and monthly basis is described in the
                        section below entitled “Card Limitations”. If you do not have enough funds available in your Card Account for the entire purchase, you can
                        instruct the merchant to charge part of the purchase to your Card Account and pay the remaining amount with other funds. These are called
                        split transactions and some merchants do not permit them. If you use your Card Number or Account Number without presenting your Card
                        (such as for mail order, telephone or Internet purchases), the legal effect will be the same as if you used the Card itself.
                        </p>
                    </li>
                    <li>
                        <p>
                          <strong>Our Liability for Failure to Complete Transactions.</strong> If we do not complete a transfer to or from your Card Account on time or in the correct
                        amount according to our Agreement with you, we may be liable for your losses or damages. However, there are some exceptions. We will not
                        be liable, for instance:
                          <ul>
                            <li>If, through no fault of ours, you do not have enough money in your Card Account to make the transfer;</li>
                            <li>If the automated teller machine where you are making the transfer does not have enough cash;</li>
                            <li>If the electronic terminal where you attempted to make the transaction was not working properly and you knew about the problem when you started the transaction;</li>
                            <li>If circumstances beyond our control (such as fire, flood, national emergency or computer or communication failure) prevent the transaction, despite reasonable precautions that we have taken;</li>
                            <li>If a merchant refuses to accept your Card;</li>
                            <li>If access to your Card has been blocked after you reported your Card or PIN lost or stolen;</li>
                            <li>If there is a hold or your funds are subject to legal process or other encumbrance restricting their use;</li>
                            <li>If we have reason to believe the requested transaction is unauthorized; or</li>
                            <li>as otherwise provided in this Agreement.</li>
                          </ul>
                        </p>
                    </li>
                    <li>
                        <p>
                          <strong>Limitations on Use.</strong> We may refuse to process any transaction that we believe may violate the law or the terms of this Agreement. Your
                        transaction may be denied if you (i) use the Card at unlawful domestic or international gambling web sites, or at payment processors supporting
                        unlawful gambling web sites, or to purchase illegal goods or services; (ii) exceed the transaction limitations described in this Agreement, (ii) do
                        not have adequate funds available in your Card Account for the transaction, (iii) do not enter the correct PIN, (iv) attempt to make a purchase
                        that originates outside of the United States, (v) attempt to make a purchase from sellers outside of the United States, or (vi) we otherwise have
                        reason to believe the transaction is fraudulent or not in compliance with the terms of this Agreement. Except as otherwise stated in this
                        Agreement, you do not have the right to stop payment on any purchase or withdrawal originated through your Card.
                        The Card may be used only at drug stores and pharmacies associated with the following Merchant Category Codes: Drugstores and Pharmacies
                        (5912), Supermarkets (5411), and Wholesale Clubs (5300).
                        </p>
                    </li>
                    <li>
                        <p>
                          <strong>Merchandise Returns.</strong> PLEASE BE AWARE OF THE MERCHANT’S RETURN POLICIES PRIOR TO COMPLETING THE TRANSACTION. If you wish
                        to return any merchandise purchased with the Card, you will be subject to the merchant’s return policies. The Servicer, the Bank, the card
                        network and their affiliates, employees, and agents are not responsible for the services or merchandise purchased with the Card. The Bank is
                        not responsible for the return or exchange of merchandise purchased with the Card. By use of this Card, you, the cardholder, agree that the
                        Bank is not liable for any consequential damages, direct or indirect. Exchange or return of merchandise purchased in whole or in part with the
                        Card is governed by the policies of each merchant and applicable law. If you have a dispute with a merchant, you agree to settle the dispute directly with the Merchant. If the merchant agrees to issue a credit to the Card, such funds may not be available for up to seven (7) business
                        days.
                        </p>
                    </li>
                    <li>
                        <p>
                          <strong>Disclaimer of Warranties.</strong> EXCEPT AS EXPRESSLY OTHERWISE PROVIDED IN THIS AGREEMENT OR REQUIRED BY LAW, WE MAKE NO
                        REPRESENTATIONS OR WARRANTIES OF ANY KIND TO YOU, WHETHER EXPRESS OR IMPLIED, REGARDING THE CARD OR THE QUALITY, SAFETY
                        OR LEGAL STATUS OF ANY GOODS OR SERVICES PURCHASED WITH THE CARD, INCLUDING, WITHOUT LIMITATION, ANY IMPLIED WARRANTIES
                        OF MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE.
                        </p>
                    </li>
                    <li>
                        <p>
                          <strong>Account Information and Periodic Statements.</strong> You may obtain information about the amount of money you have remaining in your
                        prepaid account by calling 844-944-9273. This information, along with a 12-month history of account transactions, is also available online via
                        the PayForward web site or Mobile Application. You also have the right to obtain at least twenty-four (24) months of written history of account
                        transactions by calling 844-944-9273 or by writing us at PO Box 2155, Sioux Falls, SD 57101. You will not be charged a fee for this information.
                        </p>
                    </li>
                    <li>
                        <p>
                          <strong>Card Security.</strong> This Card may be used without a personal identification number (PIN) to make purchases. Therefore, if you lose your Card,
                        someone will be able to use it. Protect your Card and PIN at all times.
                        </p>
                    </li>
                    <li>
                        <p>
                          <strong>Lost or Stolen Card.</strong> Except as otherwise stated herein, you are responsible for all transactions initiated through the use of your Card,
                        including any PIN-based transactions, any transactions initiated by presenting the Card Number only and any authorized transactions initiated
                        by someone else. If you believe your Card or Access Information has been lost or stolen, call the number or write to the address listed in the
                        “Contact Information” section below. You should also call the number or write to the address listed in the “Contact Information” section below
                        if you believe a transfer has been made using the information from your Card or Access Information without your permission. You also agree
                        to cooperate completely with the Servicer in attempts to recover funds from unauthorized users and to assist in their prosecution.
                        </p>
                    </li>
                    <li>
                        <p>
                          <strong>Your Liability for Unauthorized Transactions.</strong> Tell us AT ONCE if you believe your Card or Access Information has been lost or stolen or if
                        you believe that a transaction has been made without your permission. Your Card can be marked lost or stolen by telephoning us at 844-944-
                        9273. Reporting your card lost or stolen as soon as you are aware it’s missing is the best way to minimize your possible losses. You could lose
                        all the money in your Account. If you tell us within two (2) business days after you learn of the loss or theft of your Card or Access Information
                        you can lose no more than $50 if someone used your Card or Access Information without your permission. If you DO NOT tell us within two (2)
                        business days after you learn of the loss or theft of your Card or Access Information, and we can prove that we could have stopped someone
                        from using your Card or Access Information without your permission if you had told us, you could lose as much as $500. If your Card is a Visa
                        Card, you will not be liable for the amounts stated above provided that the following preconditions are met: (i) Your Card is in good standing;
                        (ii) You have exercised reasonable care in safeguarding your Card from any Unauthorized Use (e.g. you were not grossly negligent or fraudulent
                        in the handling of your Card); and You have not reported two or more unauthorized events in the past twelve (12) months. Also, if your online
                        or paper transaction history shows transfers you did not make, including those made by Card, code or other means, tell us at once. If you do
                        not tell us within 60 days from the earlier of: (i) the date you electronically access your Card Account, if the unauthorized transfer could be
                        viewed in your electronic history; or (ii) the date we sent the FIRST written history on which the unauthorized transfer appeared, you may not
                        get back any money you lost after the sixty (60) days if we can prove that we could have stopped someone from taking the money if you had
                        told us in time. If a good reason (such as a long trip or a hospital stay) kept you from telling us, we will extend the time periods. NOTE: Visa
                        Zero Liability covers U.S.-issued cards only; and does not apply to ATM transactions, PIN transactions not processed by Visa, or certain
                        commercial card transactions. Individual provisional credit amounts are provided on a provisional basis and may be withheld, delayed, limited,
                        or rescinded by the Servicer based on factors such as gross negligence or fraud, delay in reporting unauthorized use, investigation and
                        verification of claim and account standing and history. You must notify the Servicer immediately of any unauthorized use. The transaction(s) at
                        issue must be posted to your account before provisional credit may be issued. If Visa Rules are changed to affect your liability, those changes
                        will become applicable to your transactions under this Agreement upon notice to you as provided by law.
                        </p>
                    </li>
                    <li>
                        <p>
                          <strong>No Warranty of Availability or Interrupted Use.</strong> From time to time, the Card service may be inoperative, and when this happens, you may
                        be unable to use your Card or obtain information about your Card balance. Please notify us immediately if you have any problems using the
                        Card. By use of the Card, you agree and accept that we are not responsible for any interruption of service.
                        </p>
                    </li>
                    <li>
                        <p>
                          <strong>Error Resolution.</strong> In Case of Errors or Questions About Your Prepaid Card Account, telephone us at 844-944-9273, write us at PO Box 2155,
                        Sioux Falls, SD 57101, or email us at Support@PayForward.com as soon as you can, if you think an error has occurred in your prepaid card
                        account. We must allow you to report an error until sixty (60) days after the earlier of the date you electronically access your account, if the
                        error could be viewed in your electronic history, or the date we sent the FIRST written history on which the error appeared. You may request
                        a written history of your transactions at any time by calling us at 844-944-9273 or writing us at PO Box 2155, Sioux Falls, SD 57101. You will
                        need to tell us:
                        </p>
                        <blockquote>
                          Your name and prepaid account number.<br />
                          Why you believe there is an error, and the dollar amount involved.<br />
                          Approximately when the error took place.
                        </blockquote>
                        <p>
                            If you tell us orally, we require that you send us your complaint or question in writing within ten (10) business days.
                        </p>
                        <p>
                          We will determine whether an error occurred within ten (10) business days after we hear from you and will correct any error promptly. If we
                        need more time, however, we may take up to forty-five (45) days to investigate your complaint or question. If we decide to do this, we will
                        credit your account within ten (10) business days for the amount you think is in error, so that you will have access to the money during the
                        time it takes us to complete our investigation. If we ask you to put your complaint or question in writing and we do not receive it within ten
                        (10) business days, we may not credit your account.
                        </p>
                        <p>
                          For errors involving new accounts or point-of-sale, we may take up to ninety (90) days to investigate your complaint or question. We may
                        take up to twenty (20) business days to credit your account for the amount you think is in error.
                        </p>
                        <p>
                          We will tell you the results within three (3) business days after completing our investigation. If we decide that there was no error, we will send
                        you a written explanation.
                        </p>
                        <p>
                          You may ask for copies of the documents that we used in our investigation.
                        </p>
                        <p>
                        If you need more information about our error-resolution procedures, call us at 844-944-9273, email us at <a href='mailto:Support@PayForward.com'>Support@PayForward.com</a>, or write
                        to us at PO Box 2155, Sioux Falls, SD 57101.
                        </p>
                    </li>
                    <li>
                        <p>
                          <strong>Recurring Transactions.</strong> If you intend to use the Card for recurring transactions, you should monitor your balance and ensure you have
                        funds available in your Card Account to cover the transactions. “Recurring Transactions” are transactions that are authorized in advance by you
                        to be charged to your Card at substantially regular intervals. If these Recurring Transactions may vary in amount, the person you are going to
                        pay should tell you, 10 days before each payment, when it will be made and how much it will be. (You may choose instead to get this notice
                        only when the payment would differ by more than a certain amount from the previous payment, or when the amount would fall outside certain
                        limits that you set.) If you have told us in advance to make Recurring Transactions from your Card Account, you can stop the payment by calling
                        us at 844-944-9273 or writing us at PO Box 2155, Sioux Falls, SD 57101 in time for us to receive your request at least three (3) business days
                        before the scheduled date of the payment. If you call, we also may require you to put your request in writing and get it to us within fourteen
                        (14) days after you call. If you order us to stop one of these payments three (3) business days or more before the transfer is scheduled, and
                        we do not do so, we may be liable for your losses or damages. If you have authorized a merchant to make the recurring payment, you should
                        also contact the applicable merchant in order to stop the transaction.
                        </p>
                    </li>
                    <li>
                        <p>
                          <strong>Address or Name Changes.</strong> You are responsible for notifying us of changes in your mailing address, email address or telephone number
                        within two weeks of the change. Any written communications with you will be only by use of the most recent mailing address, email address
                        and telephone number that you provided and you agree that any notice or communication sent to the mailing address, email address or
                        telephone number noted in the account records shall be effective unless a notice is received from you. Failure to promptly notify us of changes
                        in your contact information may result in information being mailed or delivered to the wrong person or your transactions being declined. Any
                        notice given by us shall be deemed given to you if mailed to you at the last U.S. mailing address furnished by you for the Card Account. You
                        agree that we may accept changes of address from the U.S. Postal Service. You also agree that if you attempt to change your address to a nonU.S. address, your Card Account may be cancelled and funds returned to you in accordance with this Agreement.
                        </p>
                    </li>
                    <li>
                        <p>
                          <strong>Unclaimed Property.</strong> If there is no record of Card activity for an extended period of time, applicable state law may require the Bank to
                        report the balance on the Card as unclaimed property. If this occurs, we may try to locate the owner of the Card at the address shown in our
                        records. If we are unable to locate you, we may be required to deliver any value remaining on the Card to the state as unclaimed property.
                        </p>
                    </li>
                    <li>
                        <p>
                          <strong>Changing the Terms and Conditions, Amendment and/and Cancellation.</strong> Subject to the limitations of applicable law, we may change the
                        terms of, or add new terms to, the terms of this Agreement at any time, or close or suspend your Card Account, with or without cause, and
                        without giving you notice. We may not give you advance notice if we need to make the change immediately in order to comply with applicable
                        law or to maintain or restore the security of the Card or any related payment system. If any such change becomes permanent and disclosure
                        to you of the change would not jeopardize the security of the Card or any related payment system, we will provide notice to you within 30 days
                        from the making of the change or as otherwise permitted or required by law. Any changes to the Terms and Conditions are maintained online
                        after logging into your Card account and replace any previous Agreement of this Card. In addition, we may suspend, cancel, add, modify or
                        delete any feature offered in connection with your Card at our sole discretion at any time, with or without cause, and without giving you notice,
                        subject to applicable law. If we cancel your Card, any available funds remaining on the Card upon such cancellation, after payment of all
                        applicable charges and fees, will be returned to you unless we have reason to believe you have engaged in fraudulent or illegal activity. You
                        may cancel this Agreement by telephoning or writing us and you may be charged a Balance Refund Check fee (see Schedule of Fees above).
                        Upon cancellation, you will no longer be able to use the Card and it should be destroyed. A paper check for the balance remaining in your Card
                        Account less any applicable Balance Refund Check Fee will be mailed to you. Your cancellation of the Agreement will not affect any of our rights
                        or your obligations that arose prior to the cancellation.
                        </p>
                    </li>
                    <li>
                        <p>
                          <strong>Communications:</strong> If you provide us with your mobile phone number or contact us from your mobile number, you expressly agree that you
                        are providing this phone number for us or any third party acting on our behalf to contact you at this number. You agree that we may use this
                        phone number to contact you for any business purpose about your Card Account and you agree to be responsible for any fees or charges you
                        incur as a result of providing this information. You may request this number not be used. We may offer options that allow you to receive or
                        access text messages or other electronic communications from your mobile phone. By enrolling for these types of communications, you
                        understand and agree to be responsible for any fees or charges you incur as a result of this enrollment. You agree that we may contact you
                        from time to time regarding your Card Account in any manner we choose unless the law says we cannot. For example, we may contact you by
                        mail, telephone, email, fax, recorded message, text message, by using an automated dialer device. We may contact you at home, at your place
                        of employment, on your mobile telephone, at any time including weekends and holidays, at any frequency and leave prerecorded messages or
                        messages with others. When we attempt to contact you, we may identify ourselves, our relationship and our purpose for contacting you even
                        if others might hear or read it. Our contacts with you about your Card Account are not unsolicited. We may monitor or record any conversation
                        or other communication with you.
                        </p>
                    </li>
                    <li>
                        <p>
                          <strong>Transactions Where Funds May Be Held Until Settlement.</strong> When you use your Card to pay for goods or services, certain merchants (such
                        as hotels, rental car companies, gas stations, and restaurants, etc.) may ask us to pre-authorize the transaction in advance and may estimate
                        the final purchase amount. Please be advised that while this additional amount may be unavailable for up to ninety (90) days, your Card will
                        only be charged the actual amount of the purchase at the time the transaction is settled. Please note that we may not manually release
                        authorizations without a certified letter or fax from the merchant. In addition, if you commence a purchase and the merchant obtains an
                        authorization, and then you cancel the purchase without completing it, the authorization may result in a temporary hold for that amount of
                        funds for 10 days, or longer in some cases.
                        </p>
                    </li>
                    <li>
                        <p>
                          <strong>Card Valid Thru Date.</strong> The Card is valid and usable until it is past the expiration date embossed on the Card.
                        </p>
                    </li>
                    <li>
                        <p>
                          <strong>Unused Funds.</strong> Pharmacy Incentive Rewards are issued on an a “use it or lose it” basis. Therefore, at the end of the Benefit Period any
                        unused Pharmacy Incentive Rewards will be forfeited and automatically revert to the PayForward Enterprise Partner. In the event that your
                        Card Account is cancelled, closed, or terminated for any reason, any unused funds will be forfeited and automatically revert to the PayForward
                        Enterprise Partner.
                        </p>
                    </li>
                    <li>
                        <p>
                          <strong>Data Protection and Privacy.</strong> The Bank knows that your privacy is very important. As such, safeguarding your privacy will always be one of
                        our highest priorities. A copy of the Bank’s Privacy Notice is included in your Card packaging. Please review it. It may also be found on the
                        cardholder website along with a copy of this Agreement. If you have any concerns about our policy, please contact us at the Contact Information
                        listed below. We reserve the right to modify or supplement our Privacy Notice at any time. Our Privacy Notice is posted on the cardholder
                        website and you agree to review it on at least an annual basis. We will disclose information to third parties about your account or the transfers
                        you make: (i) where it is necessary for completing transfers; (ii) in order to verify the existence and condition of your account for a third party,
                        such as a credit bureau or merchant; (iii) in order to comply with government agency or court orders; or (iv) if you give us written permission.
                        </p>
                    </li>
                    <li>
                        <p>
                          <strong>Governing Law/Jurisdiction.</strong> This Agreement will be governed by and interpreted in accordance with Federal law and, to the extent Federal
                        law does not apply, by the laws of the State of Oklahoma. You consent and submit to the exclusive jurisdiction of the state and federal courts
                        located in Oklahoma with respect to all controversies arising out of or in connection with the use of the Card and this Agreement that are not
                        subject to arbitration or to any proceedings to enforce the arbitration provision or to confirm or vacate an arbitration award.
                        </p>
                    </li>
                    <li>
                        <p>
                          <strong>Void Where Prohibited.</strong> Not all services described in this Agreement are available to all persons or at all locations. We reserve the right to
                        limit, at our sole discretion, the provision of any such services to any person or in any location. Any offer of a service in this Agreement shall be
                        deemed void where prohibited.
                        </p>
                    </li>
                    <li>
                        <p>
                          <strong>Assignability.</strong> The Bank may transfer our rights under this Agreement at any time with or without providing prior notice to you of the
                        assignment. You may not assign or transfer this Agreement or any of your rights or obligations under this Agreement. Any attempt to the
                        contrary shall be null and void. This Agreement shall be binding on you, your executors, administrators, and any permitted assigns.
                        </p>
                    </li>
                    <li>
                        <p>
                          <strong>Limited Liability.</strong> UNLESS OTHERWISE REQUIRED BY LAW OR THIS AGREEMENT, WE WILL NOT BE LIABLE TO YOU FOR: DELAYS OR MISTAKES
                        RESULTING FROM ANY CIRCUMSTANCES BEYOND OUR CONTROL, INCLUDING, WITHOUT LIMITATION, ACTS OF GOVERNMENTAL AUTHORITIES,
                        NATIONAL EMERGENCIES, INSURRECTION, WAR, OR RIOTS; THE FAILURE OF MERCHANTS TO HONOR THE CARD; THE FAILURE OF MERCHANTS
                        TO PERFORM OR PROVIDE SERVICES; COMMUNICATION SYSTEM FAILURES; OR FAILURES OR MALFUNCTIONS ATTRIBUTABLE TO YOUR
                        EQUIPMENT, ANY INTERNET SERVICE, OR ANY PAYMENT SYSTEM. IN THE EVENT THAT WE ARE HELD LIABLE TO YOU, YOU WILL ONLY BE
                        ENTITLED TO RECOVER YOUR ACTUAL DAMAGES. IN NO EVENT SHALL YOU BE ENTITLED TO RECOVER ANY INDIRECT, CONSEQUENTIAL,
                        EXEMPLARY OR SPECIAL DAMAGES (WHETHER IN CONTRACT, TORT OR OTHERWISE), EVEN IF YOU HAVE ADVISED US OF THE POSSIBILITY OF
                        SUCH DAMAGES. THIS PROVISION SHALL NOT BE EFFECTIVE TO THE EXTENT OTHERWISE REQUIRED BY LAW. TO THE EXTENT PERMITTED BY
                        LAW, YOU AGREE THAT YOUR RECOVERY FOR ANY ALLEGED NEGLIGENCE OR MISCONDUCT BY THE BANK OR [INSERT SERVICER/PROGRAM
                        MANGER] SHALL BE LIMITED TO THE TOTAL AMOUNT LOADED ON THE CARD.
                        </p>
                    </li>
                    <li>
                        <p>
                          <strong>Entire Agreement.</strong> This Agreement sets forth the entire understanding and agreement between you and us, whether written or oral, with
                        respect to its subject matter and supersede any prior or contemporaneous understandings or agreements with respect to their subject matter.
                        </p>
                    </li>
                    <li>
                        <p>
                          <strong>Severability.</strong> If any of the terms of this Agreement are invalid, changed by applicable law or declared invalid by order of court or regulatory
                        authority, the remaining terms of this Agreement shall not be affected, and this Agreement shall be interpreted as if the invalid terms had not
                        been included in this Agreement.
                        </p>
                    </li>
                    <li>
                        <p>
                          <strong>Waiver.</strong> We do not waive our rights by delaying or failing to execute them at any time. To the extent permitted by law and as permitted
                        by the Arbitration section below, you agree to be liable to us for any loss, costs, or expenses that we may incur as a result of any dispute or
                        legal proceeding involving your Card Account. If a court finds any provision of this Agreement invalid or unenforceable, such finding shall not
                        make the rest of this Agreement invalid or unenforceable. To the fullest extent possible, any such provision shall be deemed to be modified so
                        as to be rendered enforceable or valid; however, if such provision cannot be so modified, it shall be stricken and all other provisions of this
                        Agreement in all other respects shall remain valid and enforceable.
                        </p>
                    </li>
                    <li>
                        <p>
                          <strong>Acknowledgment of Arbitration. Your Card is being made available and priced by the Bank on the basis of your acceptance of the
                        following arbitration clause. By accepting your Card, you acknowledge that you are giving up the right to litigate Claims if either party elects
                        arbitration of the Claims pursuant to this clause, except as otherwise expressly provided herein, and you hereby knowingly and voluntarily
                        waive the right to trial of all Claims subject to this Agreement. You further acknowledge that you have read this arbitration provision
                        carefully, agree to its terms, and are entering into this Agreement voluntarily and not in reliance on any promises or representations
                        whatsoever except those contained in this Agreement.</strong>
                        <p>
                        <h3>ARBITRATION NOTICE</h3>
                        <p>
                          <strong>PLEASE READ THIS ARBITRATION PROVISION CAREULLY. THIS ARBITRATION CLAUSE SUBSTANTIALLY AFFECTS YOUR LEGAL RIGHTS,
                        INCLUDING YOUR RIGHT TO BRING OR PARTICIPATE IN A CLASS ACTION, IN THE EVENT OF A DISPUTE.</strong>
                        </p>
                        <p>
                          Arbitration of Claims. Except as expressly provided herein, any claim, dispute or controversy (whether based upon contract; tort, intentional
                        or otherwise; constitution; statute; common law; or equity and whether pre-existing, present or future), including initial claims, counter-claims,
                        cross-claims and third party claims, arising from or relating to (i) the Card; (ii) any service relating to the Card; (iii) the marketing of the Card;
                        (iv) this Cardholder Agreement, including the validity, enforceability, interpretation, scope, or application of the Agreement and this arbitration
                        provision (except for the prohibition on class or other non-individual claims, which shall be for a court to decide); and (v) any other agreement
                        or instrument relating to the Card or any such service (“Claim”) shall be decided, upon the election of you or the Bank (or PayForward or the
                        Bank’s agents, employees, successors, representatives, affiliated companies, or assigns), by binding arbitration pursuant to this arbitration
                        provision and the applicable rules and procedures of the arbitration administrator in effect at the time the Claim is filed. The American
                        Arbitration Association (“AAA”) shall serve as the arbitration administrator. You may obtain copies of the current rules, forms, and instructions
                        for initiating an arbitration with the AAA by contacting the AAA as follows: on the web at www.adr.org or by writing to AAA at 1633 Broadway,
                        10th Floor, New York, NY 10019.
                        </p>
                        <p>
                          Other Claims Subject to Arbitration. In addition to Claims brought by either you or the Bank, Claims made by or against the Bank or by or against
                        anyone connected with you or the Bank or claiming through you or the Bank (including a second cardholder, employee, agent, representative,
                        affiliated company, predecessor or successor, heir, assignee, or trustee in bankruptcy) shall be subject to arbitration as described herein.
                        </p>
                        <p>
                          Exceptions. You and we agree not to invoke our right to arbitrate any individual Claim you bring in small claims court or an equivalent court so
                        long as the Claim is pending only in that court. This arbitration provision also does not limit or constrain the Bank’s right to interplead funds in
                        the event of claims to Collateral Account funds by several parties.
                        </p>
                        <p>
                          Individual Claims Only. It is the intent of the parties to require Claims to be submitted to arbitration on an individual basis only. Claims subject
                        to this arbitration provision may not be joined or consolidated in arbitration with any Claim of any other person or be arbitrated on a class
                        basis, in a representative capacity on behalf of the general public or on behalf of any other person, unless otherwise agreed to by the parties
                        in writing. However, co-applicants, second cardholders and authorized users of a single Card and/or related cards are considered as one person,
                        and the Bank, its officers, directors, employees, agents, and affiliates are considered as one person.
                        </p>
                        <p>
                          Arbitration Fees. If you initiate arbitration, the Bank will advance any arbitration fees, including any required deposit. If the Bank initiates or
                        elects arbitration, the Bank will pay the entire amount of the arbitration fees, including any required deposit. Notwithstanding any provision
                        of this arbitration provision or the rules and procedures of the arbitration administrator, the Bank will be responsible for payment and/or
                        reimbursement of any arbitration fees to the extent that such fees exceed the amount of the filing fees you would have incurred if your Claim
                        had been brought in the state or federal court nearest your residence with jurisdiction over the Claims.
                        </p>
                        <p>
                          Procedure. A single arbitrator will resolve the Claims. The arbitrator will be a lawyer with at least ten years’ experience or who is a former or
                        retired judge. The arbitration shall follow the rules and procedures of the arbitration administrator in effect on the date the arbitration is filed,
                        except when there is a conflict or inconsistency between the rules and procedures of the arbitration administrator and this arbitration provision,
                        in which case this arbitration provision shall govern. Any in-person arbitration hearing for a Claim shall take place within the federal judicial
                        district in which you live or at such other reasonably convenient location as agreed by the parties. The arbitrator shall apply applicable
                        substantive law consistent with the Federal Arbitration Act, 9 U.S.C. § 1 et seq. (the “FAA”) and shall honor all claims of privilege and
                        confidentiality recognized at law. All statutes of limitations that would otherwise be applicable shall apply to any arbitration proceeding. The
                        arbitrator shall be empowered to grant whatever relief would be available in court under law or in equity. At the request of any party, the
                        arbitrator will provide a written explanation of the basis for the disposition of each claim, including written findings of fact and conclusions of
                        law. This arbitration provision is made pursuant to a transaction involving interstate commerce, and shall be governed by the FAA.
                        </p>
                        <p>
                          Survival of Arbitration. This arbitration provision shall survive: i) the termination of the Agreement; ii) the bankruptcy of any party; iii) any
                        transfer, sale or assignment of your Card Account; or iv) closing of the Card Account. If any portion of this arbitration provision is deemed
                        invalid or unenforceable, the remaining portions shall remain in force.
                        </p>
                        <p>
                          This Card is issued by Stride Bank, N.A, Member FDIC, pursuant to a license by Visa<sup>®</sup> U.S.A. Inc. Visa<sup>®</sup> is a registered trademark of Visa<sup>®</sup> U.S.A.
                        Inc.
                        </p>
                    </li>
                    <li>
                        <p>
                          <strong>Card Limitations</strong>
                          <table class="rx-rewards-ppp__modal-table--striped">
                            <thead>
                              <tr>
                                <th>
                                  Limitations (with Identity<br />
                                  Verification Process)
                                </th>
                                <th>
                                  <u>Limit / Day</u>
                                </th>
                                <th>
                                  <u>Limit / Month</u>
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>Maximum Card Balance</td>
                                <td>$10,000</td>
                                <td>$10,000</td>
                              </tr>
                              <tr>
                                <td>Purchases</td>
                                <td>$3,000</td>
                                <td>$15,000</td>
                              </tr>
                            </tbody>
                              <thead>
                                <tr>
                                  <th>
                                    Limitations (without Identity<br />
                                    Verification Process)
                                  </th>
                                  <th>
                                    <u>Limit / Day</u>
                                  </th>
                                  <th>
                                    <u>Limit / Month</u>
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>Maximum Card Balance</td>
                                  <td>$1,000</td>
                                  <td>$11,000</td>
                                </tr>
                                <tr>
                                  <td>Purchases</td>
                                  <td>$1,000</td>
                                  <td>$1,500</td>
                                </tr>
                              </tbody>
                          </table>
                    </li>
                    <li>
                        <p>
                          <strong>Contact Information</strong><br />
                          Use the ‘Contact Us’ feature from any of the below locations.
                          <table class='rx-rewards-ppp__modal-table--empty'>
                            <tr>
                              <td>
                                Mobile App:
                              </td>
                              <td>
                                PayForward Mobile Application, available for download from your ap store on your smart device.<br />
                                Or follow the Apple and Android links on PayForward.com.
                              </td>
                            </tr>
                            <tr>
                              <td>
                                Cardholder Website:
                              </td>
                              <td>
                                PayForward.com
                              </td>
                            </tr>
                            <tr>
                              <td>
                                Phone:
                              </td>
                              <td>
                                844-944-9273
                              </td>
                            </tr>
                            <tr>
                              <td>
                                Email
                              </td>
                              <td>
                                Support@PayForward.com
                              </td>
                            </tr>
                            <tr>
                              <td>
                                In Writing to:
                              </td>
                              <td>
                                PayForward Member Services<br />
                                PO Box 2155<br />
                                Sioux Falls, SD 57101
                              </td>
                            </tr>
                          </table>
                        </p>
                        <p>
                          Issuing Bank Mailing Address:<br />
                          Stride Bank, N.A., Payments Division<br />
                          PO Box 3003<br />
                          Enid OK 73703
                        </p>
                    </li>
                    <li>
                        <p>
                          <strong>Hours of Operation</strong>
                          <table class='cardholder-agreement__empty-table'>
                            <td>
                              <tr>
                                Integrated Voice Response (IVR) Phone Platform <br />
                                PayForward.com web site and mobile app <br />
                                Member Services Agents <br />
                                Member Services Agents - Bank Holidays
                              </tr>
                              <tr>
                                24 hours a day, 7 days a week <br />
                                24 hours a day, 7 days a week <br />
                                8:30 a.m. - 8 p.m. Eastern Time 7 days a week<br />
                                Closed
                              </tr>
                            </td>
                          </table>
                        </p>
                        <p>
                          Hours of Operation are subject to change. Please visit PayForward.com for the current hours.
                        </p>
                    </li>
                    </ol>
                </div>
            `,
    'tos-link': 'Terms of Service',
    'tos-html': `
                <div class="rx-rewards-ppp__modal">
                    <h2>PayForward Terms of Service</h2>
                    <p>
                      These Terms of Service (the "<strong>Terms of Service</strong>") govern your use of all Web sites and mobile applications owned or operated by PayForward, LLC ("<strong>PayForward</strong>" or "<strong>we</strong>" or "<strong>us</strong>") including the Web site currently located at PayForward.com and any PayForward site or mobile application (the "<strong>Site</strong>") offered exclusively for a PayForward enterprise partner, (our "<strong>Enterprise Partner</strong>"), any PayForward applications that may be offered by PayForward, and any other applications, interactive features, widgets and resources offered by PayForward through traditional Internet Web sites, mobile devices or other platforms and any and all associated services provided by PayForward in connection with any of the foregoing (all of which, together with the Site, are collectively referred to as the "<strong>PayForward Service</strong>" or the "<strong>Service</strong>"). Depending on the type of PayForward program chosen by the Enterprise Partner sponsoring your membership, the Service may allow users to earn and use wellness incentive rewards, earn rebates from merchants (each a "<strong>Merchant</strong>") that may be allocated to one or more user-selected charities or Savings Goals, and also may allow users to interact with other users. Please note that the terms “rebates” and “cash back” describe a type of PayForward reward paid to consumers in some PayForward programs and are used interchangeably in these Terms of Service. The terms “reward” and “offer” are also used interchangeably.
                    </p>
                    <p>
                      Please read these Terms of Service carefully. These Terms of Service constitute a binding agreement between you and PayForward regarding your use of the Service. By using the Service, you agree to be bound by these Terms of Service and the PayForward <a href='https://payforward.com/pdf/privacy_policy.pdf' target='_blank'>Privacy Policy</a>. If you do not agree to any provision of these agreements, you may not use the Service.
                    </p>
                    <ol class='rx-rewards-list--numbered'>
                      <li>
                        <p>
                          <strong>Terms of Service Changes.</strong> PayForward may, in its sole and absolute discretion, change these Terms of Service from time to time. PayForward will post a copy of these Terms of Service as changed on the <a href='https://payforward.com/#terms' target='_blank'>Site</a>. Your continued use of the Service constitutes your agreement to abide by these Terms of Service as changed. If you object to any such changes, your sole recourse shall be to cease using the Service.
                        </p>
                      </li>
                      <li>
                        <p>
                          <strong>Eligibility to Use Service</strong>. Except as expressly provided below, the Service may only be used by individuals and entities who can form legally binding contracts under applicable law. No person under the age of 18 may use the Services without the supervision of a parent or legal guardian. Your use of the Service will be deemed to be a representation that you are of the age of majority or older or are using the Service with the permission of your parent or guardian. We require that all purchases be made either (a) by individuals of the age of majority or older or (b) by minors given verifiable permission by their parent or legal guardian to purchase products or services through the Service.
                        </p>
                      </li>
                      <li>
                        <p>
                          <strong>Enterprise Partner Rewards Programs</strong>. PayForward rewards, if applicable, are available only to users affiliated with PayForward’s Enterprise Partners. We will verify your status as an active customer, member or employee of our Enterprise Partner before accepting your registration for the PayForward Service. If your affiliation with our Enterprise Partner ceases after registering, you may  keep your account with PayForward, but you will no longer be able to earn cash back rebates and other rewards from PayForward merchants and other partners, and you may lose access to unused wellness incentive rewards previously earned.
                        </p>
                        <p>
                          In some cases, the wellness incentive rewards earned through PayForward may have an expiration date, after which you will lose access to unused funds. Also, your use of active wellness incentive rewards may be restricted to certain merchants, e.g., healthcare providers. For PayForward participants in the BlueEssentials<sup>SM</sup> Blue Rewards program, rewards can be used only toward copays, coinsurance, and out-of-pocket expenses for BlueEssentials<sup>SM</sup> members to use toward covered medical services; pharmacy is excluded. PayForward may share wellness reward transaction information with the Enterprise Partner sponsoring your PayForward membership, for verification that your usage of the rewards is consistent with these Terms of Service.  The Enterprise Partner sponsoring your PayForward membership may audit your usage of the rewards against healthcare claims activity to ensure appropriate use.  If the Enterprise Partner sponsoring your PayForward membership finds reward transactions to be out of compliance with the terms of service herein, the Enterprise Partner can and may suspend your use of the BlueEssentials<sup>SM</sup> Blue Rewards program and you will lose access to unused funds.
                        </p>
                        <p>
                          Enterprise partners are not responsible for maintenance, creation, or operation of the Application or access portals as well as any liabilities and claims that may arise from use of the Application or access portals.
                        </p>
                      </li>
                      <li>
                        <p>
                          <strong>Account and Password Security</strong>. You may create your own account with PayForward by completing the online registration process on the Site or mobile application, and you must do so if you would like to use the Service. As part of the registration process, you will be asked to provide certain personal information. Your decision to provide any information to PayForward is purely optional. If you elect to provide information to PayForward, you agree to provide information that is true and accurate, and to update such information if it changes. If you elect to not provide that information, or if the information you provide is not complete, current, true and accurate, you may not be able to use some or all of the Service. You may also be required to choose a username and/or password. Your username and password will authenticate your identity for purposes of interacting with PayForward and third parties through the Service. You shall use your account, username and password solely in accordance with these Terms of Service. You are solely responsible for maintaining the security of your username and password and any activity that occurs under your account. You will promptly notify PayForward via e-mail, <a href='mailto:support@payforward.com'>support@payforward.com</a>, if you learn of or suspect any loss, theft or unauthorized use of your account, username or password. PayForward does not have control over the security of your mobile device, and assumes no responsibility for losses related to failure to adequately secure your mobile device or information you have stored on the device.
                        </p>
                      </li>
                      <li>
                        <p>
                          <strong>Use of the Service.</strong> You may not reproduce, duplicate, copy, sell, resell or exploit for any purpose the Service, or any portion of the Service, including, without limitation, PayForward Content and Marks (as such terms are defined below), except as authorized by these Terms of Service. You must abide by all copyright notices, information or restrictions contained in or associated with any PayForward Content. The Service is provided for your personal, non-commercial use only, except for Merchants (and then only as authorized in our agreements with them).
                        </p>
                        <p>
                          Prohibited activities include, without limitation, the following:
                          <ul class='rx-rewards-list--ring'>
                            <li>violating any local, state, national or international law or regulation or any provision of these PayForward Terms of Service;</li>
                            <li>collecting personal information, including, without limitation, account names or e-mail addresses, of other users from the Service through automated means or otherwise;</li>
                            <li>impersonating any person or entity or otherwise misrepresenting your affiliation with a person or entity;</li>
                            <li>providing any false or misleading information on the Service, or creating an account for anyone other than yourself; and</li>
                            <li>engaging in, authorizing or promoting any behavior or activity that is abusive, harassing, tortious, defamatory, vulgar, pornographic, obscene, libelous, hateful, or racially, ethnically, or otherwise objectionable;</li>
                            <li>violating or infringing other people's intellectual property, privacy, publicity, or other legal rights of someone else, such as by posting content or photos for which you do not have all necessary rights;</li>
                            <li>knowingly transmitting any material that contains adware, malware, spyware, software viruses, or any other computer code, files, or programs designed to interrupt, destroy, or limit the functionality of any computer software or hardware or telecommunications equipment;</li>
                            <li>interfering with or disrupting the Service or servers or networks connected to the Service, or disobeying any requirements, procedures, policies, or regulations of networks connected to the Services;</li>
                            <li>attempting to decipher, decompile, disassemble, or reverse engineer any of the software comprising any part of the Service;</li>
                            <li>attempting to interfere with any security feature of the Service, or circumvent, disable or interfere with any copyright management or other technological measure that effectively controls access to, use or copying of content of the Service;</li>
                            <li>using any means to "scrape", "crawl", "reverse engineer" or "spider"" any Web pages or content contained available on or through the Service (although PayForward may allow operators of public search engines to use spiders to index some materials from the Site for the sole purpose of creating publicly available searchable indices of the materials, but not caches or archives of such materials, and PayForward reserves the right to revoke these exceptions either generally or in specific cases);</li>
                            <li>launching any automated system, including, without limitation, "robots", "spiders", "offline readers", etc., that accesses the Service in a manner that sends more request messages to the Service in a given period of time than a single human can reasonably produce in the same period by using a conventional Web browser.</li>
                            <li>Engaging in any action or behavior that harms PayForward, or that PayForward finds objectionable for any reason.</li>
                          </ul>
                        </p>
                      </li>
                      <li>
                        <p>
                          <strong>User Content.</strong> As between you and us, you own all content and information you post or share on or through Service (referred to as "<strong>User Content</strong>"), such as posting or sharing photos, profile information, text, images, logos, audio and video clips and other information. By posting or sharing User Content on the Site or otherwise through the Service, you hereby grant to PayForward and its affiliates, licensees, successors and assigns a non-exclusive, worldwide, perpetual, fully-paid, royalty-free, transferable, sub-licensable license and right, through any manner, means and media now known or hereafter devised, to reproduce, copy, display, publicly perform, edit, modify, create derivative works of, transmit, produce, market, distribute and otherwise use and reuse your User Content, on or in connection with the Service. In other words, for example, if you post your content on PayForward, the content still belongs to you, but PayForward can use it for various purposes in connection with the Services, for example, by making the content available to others in the service, and others can re-post it. Our use of User Content will be subject to your applicable privacy settings at the time the User Content is posted. We may display advertisements in connection with your User Content or on pages where your User Content may be viewed by you or others.
                        </p>
                        <p>
                          You agree that you are solely responsible for all User Content you transmit on or through the Service.
                        </p>
                        <p>
                          You promise that:
                          <ul class='rx-rewards-list--ring'>
                            <li>you own all rights in your User Content or, alternatively, you have the right to give PayForward the rights described above;</li>
                            <li>you have paid and will pay in full any fees or other payments that may be related to the use of your User Content;</li>
                            <li>your User Content does not violate or infringe intellectual property rights, privacy rights, publicity rights, or other legal rights of any third party; and</li>
                            <li>your User Content does not contain any material that is abusive, harassing, tortious, defamatory, vulgar, pornographic, obscene, libelous, hateful, or racially, ethnically, or otherwise objectionable.</li>
                          </ul>
                        </p>
                        <p>
                          You acknowledge that, although PayForward does not necessarily pre-screen User Content prior to your use of the Service and PayForward has no obligation to review your User Content, PayForward may pre-screen or review your User Content at any time for any reason, including, without limitation, for compliance with these Terms of Service. PayForward shall have the right to refuse or remove any User Content that, in its sole discretion, violates or may violate these Terms of Service or is otherwise objectionable, and, if you have commenced your use of the Service, PayForward is under no obligation to continue to provide the Service for your use in connection with such User Content.
                        </p>
                      </li>
                      <li>
                        <p>
                          <strong>Ownership.</strong> Other than User Content, the content on the Site and all aspects of the Service, including, without limitation, text, software, scripts, graphics, photos, sounds, music, videos, interactive features and the like (collectively, "<strong>PayForward Content</strong>"), and the PayForward word marks and design marks, as well as certain other of the names, logos and materials displayed on the Site or through the Service that constitute trademarks (collectively, "<strong>Marks</strong>"), are owned by or licensed to PayForward and are subject to copyright, trademark and other intellectual property rights under U.S. and international laws.
                        </p>
                      </li>
                      <li>
                        <p>
                          <strong>Interactions with Users on PayForward.</strong> Other PayForward users, including Merchants and Enterprise Partners, may interact directly with you or your business on or through the Service. The Service may also facilitate your ability to communicate with other users via email and other means that are not part of the Service.
                        </p>
                        <p>
                          PayForward is not responsible or liable for any damage or loss related to your interactions with other users or their interactions with you or anyone else. PayForward is not a party to any transaction between you and anyone else and has no obligation to monitor or enforce any such transactions.
                        <p>
                      </li>
                      <li>
                        <p>
                          <strong>Feedback.</strong> We always welcome and appreciate your feedback and suggestions about PayForward. You understand that we cannot necessarily respond to or implement all feedback or suggestions, but if we do, you understand and agree that we may use them without compensation to you. All comments, reviews, messages, ideas, suggestions or any and all other communications and materials (collectively "Comments") sent to us shall be and remain the exclusive property of PayForward. Your submission of any such Comments shall constitute an assignment to us of all worldwide rights, titles and interests in all copyrights and other intellectual property rights in the Comments. We may use, reproduce, disclose, publish and distribute any Comments you submit for any purpose, without restriction and without compensating you in any way. We may, but are not obligated to, restrict or remove any and all content from any Comment. You have no expectation of privacy with regard to any Comment you submit.
                        </p>
                      </li>
                      <li>
                        <p>
                          <strong>Privacy Policy.</strong> PayForward respects your privacy. Our <a href='https://payforward.com/pdf/privacy_policy.pdf' target='_blank'>Privacy Policy</a> explains how we collect, use, and disclose information about you. You understand and agree that when you share your information with a third party (whether through the Site or other parts of the Service or otherwise) such third party is responsible for protecting your privacy and complying with any applicable privacy laws, rules and regulations. The Privacy Policy is merely a statement of administrative protocol; it is not a contract nor does it create any contractual or legal rights.
                        </p>
                      </li>
                      <li>
                        <p>
                          <strong>Transactions with Merchants.</strong> The Service may enable you to purchase products and services from Merchants. PayForward is not responsible for examining or evaluating, and does not warrant the offerings of, any of these businesses or individuals or the content of their Web sites. If you choose to make a purchase from a Merchant, you may be taken to that Merchant's Web site and, in any event, are subject to the policies and the terms and conditions of such Merchant. You should carefully review their privacy statements and other terms and conditions of use. PayForward is not responsible for fulfilling any orders, shipping any products or services from Merchants, updating travel reservations, or handling any refund, return, or exchange requests for products or services ordered from Merchants, except to the extent that PayForward may make appropriate adjustments to your rebate account based upon refunds made by Merchants to you. If you have any questions or service requests for travel or dining booked through the PayForward online purchasing service, you must contact the affected hotel, airline, and/or restaurant directly.
                        </p>
                        <p>
                          If you participate in a cash back (rebate) service with PayForward, you will be given the option to register (link) your Visa<sup>®</sup> and MasterCard® payment card(s) to PayForward for purposes of earning cash back when you shop at participating merchants.
                        </p>
                        <p>
                          If you are a User of the PayForward Service as a result of having linked your eligible Visa card, please note that not all Visa cards are eligible for this Service. Without limitation, Visa Buxx, Flexible Spending Account (FSA) Visa cards, EBT Visa cards and other Visa-branded cards whose transactions are not processed through the Visa U.S.A. payment system are not eligible to participate in the Service. In addition, please note that not all transactions made with your eligible registered card (including PIN-based transactions, payment through a third-party digital wallet or payment app) can be monitored for eligibility to earn rebates.  <strong>Do not use a PIN when making purchases if you want the transaction to be applied towards your reward completion.</strong>
                        </p>
                        <p>
                          PayForward is not responsible for rewards offered by the bank that issues your linked card. Credit and debit card transactions made through PayForward may or may not be eligible for points or other rewards offered by your bank. Linked card rewards programs are governed by the terms of the applicable <u>card member agreement</u> or membership rewards program.
                        </p>
                        <p>
                          If you are a user of the PayForward cash back service, we will use reasonable efforts to credit rebates to your PayForward account balance in a timely fashion. Cash back is awarded at the time a transaction posts to PayForward, not when the purchase is made. In some cases your cash back will arrive within seconds, whereas others can take minutes, hours, or days. PayForward is not responsible for merchant delays in processing transactions. In some cases, the rebate credit may be delayed if we are unable to determine the exact purchase amount at the time of purchase authorization. (For example, if you earn a rebate on a hotel stay or a rental car, we typically cannot credit a rebate to your PayForward account balance until your hotel stay or car rental ends, because we will not know the exact purchase amount until you conclude your hotel stay or car rental.)
                        </p>
                        <p>
                          If you use PayForward’s Online Shopping service, you will receive your rebate upon merchant confirmation in approximately 30 days.
                        </p>
                        <p>
                          In cases where the rebate is paid on transaction authorization, the authorized amount may be different from the settled amount. For example, the authorized amount on a restaurant purchase transaction does not include the tip. Therefore we would not award the rebate on the amount of the tip.
                        </p>
                        <p>
                          Merchants, not PayForward, provide rebates. Rebates are in no way sponsored, endorsed, or associated with PayForward. Merchants may change the rebate terms, amount and/or percentage of rebates and other offers at any time. To verify an offer is valid, please check the website or app prior to making your purchase.
                        </p>
                        <p>
                          PayForward uses reasonable efforts to collect the rebates from the Merchants. If, however, PayForward is not able to collect the rebate from the Merchant, then PayForward is neither responsible nor liable to pay the rebate to you. Your recourse, in such an instance, is with the Merchant, not PayForward.
                        </p>
                        <p>
                          <span class='blue'>In the PayForward cash back program,</p> if you return purchased merchandise to the Merchant, or otherwise obtain a credit or refund (in whole or in part) for purchases or services from a Merchant, and you previously obtained a rebate for such transaction, then after you receive credit from the Merchant, we will debit your PayForward account the amount of the rebate that you previously received (or portion thereof on a partial return or partial credit). If you allocated the original rebate to one or more of your nonprofit organizations or Savings Goals as part of our rebate allocation program, those allocated amounts will remain where they were allocated. Your recourse, in such an instance, would be for you to contact the entity to which you allocated the original rebate and seek to recover the allocated amounts directly from there. PayForward does not reverse paid rebate allocations.
                        </p>
                        <p>
                          <strong>Electronic Gift Cards.</strong>
                        </p>
                        <p>
                          If you participate in a PayForward program that offers the purchase of eGift Cards on the Site, the following terms apply:
                          <ol class='rx-rewards-list--alphabetical'>
                            <li>
                              For some purchase transactions, you are purchasing a merchant-specific electronic stored value card, certificate, voucher or similar product that will be displayed on your smartphone or other compatible device through the PayForward mobile app as an electronic code, symbol, set of letters, set of numbers or any combination thereof ("Gift Card" aka “eCard” aka “eGift Card” aka “Scan” purchase), and the Gift Card is intended to be redeemed for the full amount of the Gift Card immediately with such merchant (the "Merchant") for your desired products or services.
                            </li>
                            <li>
                              You may fund the purchase of the Gift Card with the balance in your PayForward account or with any Visa<sup>®</sup> or MasterCard® you have linked to your PayForward account as described earlier in this section of these Terms of Service.
                            </li>
                            <li>
                              Any returns, credits or exchanges of the products or services that are purchased using such a Gift Card via PayForward will be governed by the applicable Merchant's policies, terms and conditions for purchases made with Gift Cards. Such Merchant's policies, terms and conditions may be subject to change in the Merchant's sole discretion, if permitted by law. You may be entitled only to store credit with any return or exchange. For details, review the applicable gift card terms available in the PayForward purchase path, and contact the applicable Merchant for additional information.
                            </li>
                            <li>
                              Except as specifically permitted by the Merchant's policies, terms and conditions, Gift Cards are not redeemable for cash or for other Merchants' Gift Cards, are not valid for prior purchases, and may not be returned, credited or exchanged.
                            </li>
                            <li>
                              Value may not be combined among or transferred between separate Gift Cards.
                            </li>
                            <li>
                              You are responsible for keeping track of your available Gift Card balance, if any.
                            </li>
                            <li>
                              If we fail to deliver a Gift Card you have purchased, or if we deliver a Gift Card with an electronic code that does not function properly, or if you have a question about rebates awarded in connection with any PayForward transaction, please contact us at support@PayForward.com or at 1-844-944-9273.
                            </li>
                            <li>
                              Disputes or claims regarding any failure of any Gift Card to be purchased or delivered, or any other failure related to using PayForward to complete your transaction, will be subject to these Terms of Service, and neither the Merchant, nor the issuer of the payment card (or other payment product) used to purchase the Gift Card (the "Issuer"), nor any non-merchant distributor of the Gift Card ("Distributor"), shall have any responsibility with respect to such disputes or claims.
                            </li>
                            <li>
                              If you are entitled to a refund for any reason for products or services obtained with a Gift Card, you agree to accept credits to a Gift Card or a replacement Gift Card for such refunds or such other handling as provided in the applicable Merchant's policies, terms and conditions. If you have a problem with a purchase that you made with your Gift Card, or if you have a dispute with the Merchant, you must handle it directly with the Merchant. Any disputes or claims relating to Gift Cards that are purchased or delivered using PayForward, including without limitation any disputes or claims relating to products or services purchased with any Gift Card, must be handled in accordance with the applicable Merchant's policies, terms and conditions, and none of PayForward, the Issuer and any Distributor shall have any responsibility with respect to such disputes or claims.
                            </li>
                            <li>
                              Gift Cards may not be re-sold. Gift cards may not be otherwise transferred to third parties, except via the sharing function available in PayForward.
                            </li>
                            <li>
                              By sharing a Gift Card, the sender allows the recipient to use any available Gift Card balance. The sender of a shared Gift Card retains access to the Gift Card and the ability to use any available Gift Card balance. No PayForward rewards will be paid to the recipient of any shared Gift Card in connection with the receipt or use of such Gift Card.
                            </li>
                            <li>
                              If the purchase price of any products or services purchased from a Merchant exceeds the dollar amount of a Gift Card being used for such purchase, you will be asked to pay for additional charges associated with the purchase.
                            </li>
                            <li>
                              Because any stored Gift Card numbers or Gift Card barcodes are Gift Cards that can be used like cash for purchases from the applicable Merchant, you are responsible for all transactions made with your unused credits, including unauthorized or fraudulent transactions. If you notice unauthorized or fraudulent use of any unused credits, contact PayForward as soon as possible at Support@PayForward.com or at 1-844-944-9273.
                            </li>
                            <li>
                              The gift card is not redeemable for cash except where cash redemption is offered by the individual merchant and/or required by applicable state law.
                            </li>
                            <li>
                              The gift card has no value until activated and will not be replaced if lost or stolen, except as offered by the individual merchant and/or required by law.
                            </li>
                            <li>
                              If you use a PayForward eGift Card to purchase a gift card from a PayForward merchant, you will not receive cash back from PayForward for that purchase.
                            </li>
                          </ol>
                        </p>
                      </li>
                      <li>
                        <p>
                          <strong>Online Purchases.</strong> If you participate in a PayForward program that offers online purchases on the Site, this section applies. An online offer is not valid in combination with any other offers. You must be logged in to your PayForward account prior to clicking on the “”Online” link. You must link to the merchant site from the PayForward “Shop” page before completing each order, and use your linked card or your PayForward<sup>®</sup> Prepaid Visa<sup>®</sup> Debit Card for each transaction within the same site session upon clicking the offer link. If you visit other sites before completing your purchase or use coupons issued by other shopping services, you might not earn cash back from PayForward. Cookies must be enabled in your browser in order to earn cash back on your purchase. If you wish to earn cash back on multiple orders, you must return to <a href='https://payforward.com/' target='_blank'>PayForward.com</a> and click through the Shop Now link before completing each transaction. Members will receive cash back on qualified purchases during valid promotion dates; you will receive your rebate upon merchant confirmation of the transaction in approximately 30 days. In most cases, you will not receive cash back if your transaction is made with an electronic wallet or through a third party or if the merchant uses a mobile or wireless card reader to process it – unless you perform the transaction using the PayForward eGift Card service. Online orders picked up in a store may not be eligible for cash back.
                        </p>
                        <p>
                          Purchases of gift cards from merchants on PayForward’s Online Shopping service are not eligible for PayForward rebates. Some merchants may exclude a number of other products from their cash back offering; you will not receive rebates from PayForward for the excluded items.
                        </p>
                      </li>
                      <li>
                        <p>
                          <strong>Transaction Monitoring.</strong> By registering a MasterCard or Visa payment card in connection with transaction monitoring, or by using your PayForward Prepaid Visa Debit Card, you authorize PayForward to share your payment card information with MasterCard and/or Visa so they know you enrolled. You authorize MasterCard and Visa to monitor transactions on your registered card(s) to identify qualifying purchases in order to determine whether you have qualified for or earned an offer linked to your payment card.  You also authorize MasterCard and Visa to share such transaction details with PayForward so that PayForward can enable your card-linked offer(s) and target offers that may be of interest to you. To opt-out of transaction monitoring on the payment card(s) you have registered, log in to your account on PayForward.com and visit the Wallet page. Next, select each card you wish to opt-out of, and click “Unlink this Card.”
                        </p>
                      </li>
                      <li>
                        <p>
                          <strong>Links to Other Web Sites.</strong> The Service may contain links to third-party Web sites or Internet resources that are not owned or controlled by PayForward, including, without limitation, Web sites of Merchants. PayForward's provision of a link to any other Web site or Internet resource is for your convenience only and does not signify PayForward's endorsement of such other Web site or resource or its contents. The privacy policies of those sites, not these Terms of Service, govern the use of any information you share with those third parties. PAYFORWARD SHALL NOT BE RESPONSIBLE FOR THE AVAILABILITY OF ANY THIRD-PARTY WEB SITES OR RESOURCES, AND PAYFORWARD DOES NOT WARRANT, ENDORSE GUARANTEE OR ASSUME RESPONSIBILITY FOR ANY CONTENT, INFORMATION, SOFTWARE, MATERIALS OR PRACTICES OF ANY SUCH THIRD-PARTY WEB SITES OR RESOURCES, INCLUDING, WITHOUT LIMITATION, ANY PRODUCTS OR SERVICES ADVERTISED OR OFFERED BY A THIRD PARTY THROUGH THE SERVICE OR THROUGH ANY LINKED WEB SITES OR FEATURED IN ANY ADVERTISING.
                        </p>
                      </li>
                      <li>
                        <p>
                          <strong>Disclaimer.</strong> YOU EXPRESSLY AGREE THAT USE OF THE SERVICE IS AT YOUR SOLE RISK. THE SERVICE IS PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS. TO THE FULLEST EXTENT PERMITTED UNDER APPLICABLE LAW, PAYFORWARD AND ITS OFFICERS, EMPLOYEES, DIRECTORS, SHAREHOLDERS, MEMBERS, MANAGERS, PARTNERS, PARENTS, SUBSIDIARIES, AFFILIATES, AGENTS, LICENSORS, SUCCESSORS AND ASSIGNS (REFERRED TO COLLECTIVELY AS THE "<strong>PAYFORWARD PARTIES</strong>" AND INDIVIDUALLY AS "<strong>PAYFORWARD PARTY</strong>") EXPRESSLY DISCLAIM ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED (INCLUDING, WITHOUT LIMITATION, IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR USE OR PURPOSE, ACCURACY AND NON-INFRINGEMENT), WITH RESPECT TO THE SERVICE (INCLUDING ANY CONTENT SUBMITTED BY A USER) OR THE CONTENT OF ANY WEB SITES OR RESOURCES LINKED TO THE SERVICE. NO PAYFORWARD PARTY MAKES ANY REPRESENTATION OR WARRANTIES ABOUT THE ACCURACY, QUALITY, RELIABILITY OR COMPLETENESS OF CONTENT AVAILABLE ON OR THROUGH THE SERVICE AND ASSUMES NO LIABILITY OR RESPONSIBILITY FOR ANY:
                          <ul class='rx-rewards-list--ring'>
                            <li>
                              ERRORS, MISTAKES, OR INACCURACIES OF CONTENT OBTAINED FROM THE USE OF THE SERVICE;
                            </li>
                            <li>
                              PERSONAL INJURY, PROPERTY DAMAGE, OR OTHER HARM, OF ANY NATURE WHATSOEVER, RESULTING FROM YOUR ACCESS TO OR USE OF THE SERVICE;
                            </li>
                            <li>
                              UNAUTHORIZED ACCESS TO OR USE OF OUR SERVERS OR ANY AND ALL INFORMATION OR FINANCIAL INFORMATION STORED THEREIN;
                            </li>
                            <li>
                              ANY INTERRUPTION OR CESSATION OF TRANSMISSION TO OR FROM THE SERVICE;
                            </li>
                            <li>
                              ANY BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE WHICH MAY BE TRANSMITTED TO OR THROUGH THE SERVICE BY ANY THIRD PARTY; AND
                            </li>
                            <li>
                              ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF THE USE OF ANY CONTENT POSTED, E-MAILED, TRANSMITTED OR OTHERWISE MADE AVAILABLE ON OR THROUGH THE SERVICE.
                            </li>
                          </ul>
                        </p>
                        <p>
                          THE PAYFORWARD PARTIES DO NOT WARRANT, ENDORSE, GUARANTEE, OR ASSUME RESPONSIBILITY FOR ANY PRODUCT OR SERVICE ADVERTISED OR OFFERED BY A THIRD PARTY ON OR THROUGH THE SERVICE OR ANY LINKED WEB SITE.
                        </p>
                        <p>
                          YOU UNDERSTAND AND AGREE THAT ANY MATERIAL OR INFORMATION DOWNLOADED OR OTHERWISE OBTAINED THROUGH THE USE OF THE SERVICE IS DONE AT YOUR OWN RISK AND THAT YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE ARISING FROM DOING SO. NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED BY YOU FROM ANY PAYFORWARD PARTY OR THROUGH THE SERVICE WILL CREATE ANY WARRANTY NOT EXPRESSLY MADE IN THESE TERMS OF SERVICE.
                        </p>
                        <p>
                          THE PAYFORWARD PARTIES WILL NOT BE A PARTY TO OR IN ANY WAY BE RESPONSIBLE FOR MONITORING ANY TRANSACTION BETWEEN YOU AND THIRD-PARTY PROVIDERS OF PRODUCTS OR SERVICES. ALL PRODUCTS ARE SUBJECT ONLY TO ANY APPLICABLE WARRANTIES OF THEIR RESPECTIVE MANUFACTURERS, DISTRIBUTORS, SUPPLIERS OR SERVICE PROVIDERS IF ANY. TO THE FULLEST EXTENT PERMISSIBLE BY APPLICABLE LAW, THE PAYFORWARD PARTIES HEREBY DISCLAIM ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED (INCLUDING, WITHOUT LIMITATION, IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR USE OR PURPOSE, ACCURACY AND NON-INFRINGEMENT), WITH RESPECT TO THIRD-PARTY PRODUCTS AND SERVICES.
                        </p>
                        <p>
                          WITHOUT LIMITING THE FOREGOING, PAYFORWARD, THE ISSUER AND DISTRIBUTOR EACH DISCLAIM ANY AND ALL WARRANTIES, EXPRESS OR IMPLIED, FOR ANY GIFT CARDS, AND FOR PRODUCTS OR SERVICES PURCHASED WITH GIFT CARDS OR OFFERED THROUGH OR VIA PAYFORWARD, INCLUDING, BUT NOT LIMITED TO, WARRANTIES OF TITLE, MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE OR USE. THIS DISCLAIMER DOES NOT APPLY TO ANY WARRANTY OFFERED BY ANY MERCHANT.
                        </p>
                      </li>
                      <li>
                        <p>
                          <strong>Limitation of Liability.</strong> YOU UNDERSTAND THAT, TO THE FULLEST EXTENT PERMITTED UNDER APPLICABLE LAW, IN NO EVENT WILL THE PAYFORWARD PARTIES BE LIABLE UNDER ANY THEORY OF LIABILITY (WHETHER IN CONTRACT, TORT, STATUTORY, OR OTHERWISE) FOR ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, OR EXEMPLARY DAMAGES, INCLUDING, WITHOUT LIMITATION, DAMAGES FOR LOSS OF REVENUES, PROFITS, GOODWILL, USE, DATA OR OTHER INTANGIBLE LOSSES (EVEN IF PAYFORWARD WAS ADVISED OF, KNEW OF, OR SHOULD HAVE KNOWN OF THE POSSIBILITY OF SUCH DAMAGES).
                          <ol class='rx-rewards-list--alphabetical'>
                            <li>
                              NONE OF PAYFORWARD, THE ISSUER AND THE DISTRIBUTOR IS RESPONSIBLE FOR ANY PRODUCTS OR SERVICES OF ANY OTHER PAYFORWARD PARTICIPATING COMPANIES OR PARTNERS.
                            </li>
                            <li>
                              IN MOST CASES, A MERCHANT WHOSE GIFT CARD OR PRODUCT OR SERVICE YOU ARE PURCHASING OR USING IS IN NO WAY AFFILIATED WITH PAYFORWARD, THE ISSUER OR THE DISTRIBUTOR; NO GIFT CARD IS VALID FOR ANY PAYFORWARD, ISSUER OR DISTRIBUTOR PRODUCT OR SERVICE; AND NONE OF PAYFORWARD, THE ISSUER AND THE DISTRIBUTOR SHALL HAVE ANY RESPONSIBILITY WITH RESPECT TO ANY GIFT CARD OR ANY PRODUCT OR SERVICE PURCHASED WITH ANY GIFT CARD OR OTHERWISE OBTAINED THROUGH PAYFORWARD, OTHER THAN AS EXPRESSLY PROVIDED IN THESE PAYFORWARD TERMS AND CONDITIONS OR AS PROVIDED BY APPLICABLE LAW.
                            </li>
                            <li>
                              NONE OF PAYFORWARD, THE ISSUER AND THE DISTRIBUTOR IS RESPONSIBLE FOR ANY DAMAGE, THEFT, LOSS OR IMPAIRMENT OF USE OR VALUE OF YOUR PAYFORWARD-COMPATIBLE DEVICE OR ANY GIFT CARD THAT OCCURS IN CONNECTION WITH YOUR USE OF PAYFORWARD OR ACCEPTANCE OR USE ANY GIFT CARD.
                            </li>
                            <li>
                              IN NO EVENT WILL PAYFORWARD'S, THE ISSUER'S OR THE DISTRIBUTOR'S LIABILITY TO YOU EXCEED THE AMOUNT PAID BY YOU FOR ANY GIFT CARD OR OTHER PRODUCT OR SERVICE OBTAINED BY YOU THROUGH OR VIA PAYFORWARD.
                            </li>
                          </ol>
                        </p>
                        <p>
                          Some jurisdictions do not allow the exclusion of certain warranties or the limitation or exclusion of liability for incidental or consequential damages. Accordingly, some of the above limitations and disclaimers may not apply to you. To the extent PayForward may not, as a matter of applicable law, disclaim any implied warranty or limit its liabilities, the scope and duration of such warranty and the extent of PayForward's liability shall be the minimum permitted under such applicable law.
                        </p>
                      </li>
                      <li>
                        <p>
                          <strong>Indemnification.</strong> You agree to indemnify, defend, and hold harmless the PayForward Parties from and against any and all claims, liabilities, damages, losses, costs, expenses, fees (including reasonable attorneys' fees and costs) that such parties may incur as a result of or arising from (a) any information you provide to PayForward, including, without limitation, any User Content; (b) your use of the Service and the use of the Service under your account; or (c) your violation of these Terms of Service, or (d) acceptance or use of any Gift Card. PayForward reserves the right, at its own expense, to assume the exclusive defense and control of any matter otherwise subject to indemnification by you, in which event you will cooperate with PayForward in asserting any available defenses.
                        </p>
                      </li>
                      <li>
                        <p>
                          <strong>Additional Terms.</strong> Additional terms and conditions may apply to certain portions or features of the Service ("<strong>Additional Terms</strong>"). Where Additional Terms apply to a particular portion or feature of the Service, we will make them available for you to read through your use of that Service. By using the Service, you agree to the Additional Terms. For PayForward participants in the BlueEssentials<sup>SM</sup> rewards program, rewards can be used only toward copays, coinsurance, and out-of-pocket expenses for BlueEssentials<sup>SM</sup> members to use toward covered medical services; pharmacy is excluded.
                        </p>
                      </li>
                      <li>
                        <p>
                          <strong>Termination.</strong> PayForward may terminate or suspend your access to all or any portion of the Service immediately, without prior notice or liability, for any reason, including if you breach any provision of these Terms of Service. In the event of any such termination, the indemnification provisions set forth above shall survive.
                        </p>
                      </li>
                      <li>
                        <p>
                          <strong>Modifications to the Services.</strong> PayForward reserves the right to modify or discontinue all or any part of the Service with or without notice to you. PayForward shall not be liable to you or any third party should PayForward exercise its right to modify or discontinue all or any part of the Service.
                        </p>
                      </li>
                      <li>
                        <p>
                          <strong>Arbitration.</strong> You and PayForward agree to resolve any disputes through binding and final arbitration instead of through court proceedings. All controversies, claims, counterclaims, or other disputes arising between you and PayForward relating to the Service or these Terms of Service (each a "<strong>Claim</strong>") shall be submitted for binding arbitration in accordance with the Rules of the American Arbitration Association ("<strong>AAA Rules</strong>"). The arbitration will be heard and determined by a single arbitrator in Los Angeles, California with significant experience in the subject matter of the Claim. The arbitrator's decision in any such arbitration will be final and binding upon the parties and may be enforced in any court of competent jurisdiction. The parties agree that the arbitration will be kept confidential and that the existence of the proceeding and any element of it (including, without limitation, any pleadings, briefs, documents, or other evidence submitted or exchanged and any testimony or other oral submissions and awards) will not be disclosed beyond the arbitration proceedings, except as may lawfully be required in judicial proceedings relating to the arbitration or by applicable disclosure rules and regulations of securities regulatory authorities or other governmental agencies.
                        </p>
                        <p>
                          You may not act as a class representative or private attorney general, nor participate as a member of a class of claimants, with respect to any Claim. Claims may not be arbitrated on a class or representative basis. The arbitrator can decide only your and/or PayForward's individual Claims. The arbitrator may not consolidate or join the claims of other persons or parties who may be similarly situated.
                        </p>
                        <p>
                          THIS SECTION LIMITS CERTAIN RIGHTS, INCLUDING THE RIGHT TO MAINTAIN A COURT ACTION, THE RIGHT TO A JURY TRIAL, THE RIGHT TO PARTICIPATE IN ANY FORM OF CLASS OR REPRESENTATIVE CLAIM, THE RIGHT TO ENGAGE IN DISCOVERY EXCEPT AS PROVIDED IN AAA RULES, AND THE RIGHT TO CERTAIN REMEDIES AND FORMS OF RELIEF. OTHER RIGHTS THAT YOU OR PAYFORWARD WOULD HAVE IN COURT ALSO MAY NOT BE AVAILABLE IN ARBITRATION.
                        </p>
                      </li>
                      <li>
                        <p>
                          <strong>General Provisions.</strong> Under no circumstances will PayForward be held liable for any delay or failure in performance due in whole or in part to any acts of nature or other causes beyond its reasonable control. These Terms of Service shall be governed by and construed in accordance with the laws of the State of California, without giving effect to any conflict of laws rules or provisions. If any provision of these Terms of Service shall be unlawful, void, or for any reason unenforceable, then that provision shall be deemed severable from these Terms of Service and shall not affect the validity and enforceability of any remaining provisions. The failure of PayForward to enforce any right or provision of these Terms of Service will not be deemed a waiver of such right or provision. These Terms of Service, the PayForward <a href='https://payforward.com/pdf/privacy_policy.pdf' target='_blank'>Privacy Policy</a> and, if you are a Merchant, the agreement between you and us regarding your participation as a Merchant, together with and any other Additional Terms or other policies, rules or guidelines posted in connection with the Service, constitute the entire and exclusive agreement between PayForward and you regarding the Service, and the foregoing, as applicable, supersedes and replaces any prior agreements between PayForward and you regarding the Service.
                        </p>
                      </li>
                    </ol>
                    <p>
                      Effective Date:  January 1, 2019
                    </p>
                </div>
            `,
    'privacy-link': 'Privacy Policy',
    'privacy-html': `
                <div class="rx-rewards-ppp__modal">
                    <h2>Privacy Policy</h2>
                    <p>
                      This is the Privacy Policy for all Web sites and mobile applications owned or operated by PayForward, LLC ("<strong>PayForward</strong>" or "<strong>we</strong>" or "<strong>us</strong>") including the Web site currently located at PayForward.com (the "<strong>Site</strong>"), any PayForward applications that may be offered by PayForward, and any other applications, interactive features, widgets and resources offered by PayForward through traditional Internet websites, mobile devices or other platforms and any and all associated services provided by PayForward in connection with any of the foregoing (all of which, together with the Site are collectively referred to as the "<strong>PayForward Service</strong>" or the "<strong>Service</strong>"). Depending on the type of PayForward program chosen by the Enterprise Partner sponsoring your membership, the Service may allow users to earn and use wellness incentive rewards, earn rebates from merchants (each a "<strong>Merchant</strong>") that may be allocated to one or more user-selected charities or Savings Goals, and also may allow users to interact with other users.
                    <p>
                    <p>
                      Your privacy is important to us and we have prepared this Privacy Policy to explain how we collect, use and protect Personal Information and Non-Personal Information (each, as defined below) when you use the Service and your choices for managing your information preferences. By using the Service, you agree to be bound by this Privacy Policy and the PayForward <a href='https://payforward.com/#terms' target='_blank'>Terms of Service</a> and any other applicable agreements between you and us.
                    </p>
                    <ol class='rx-rewards-list--number'>
                      <li>
                        <p>
                          <strong>Application of Privacy Policy.</strong> This Privacy Policy applies solely to PayForward and the PayForward Service. We have no control over, and we assume no responsibility for, the content, privacy policies or practices of any third-party websites or services, including any third-party website reached through a link on or through the Service or any third-party website through which you reach the Service. You are responsible for reviewing and understanding the privacy policies (if any) of any such third parties.  By using the Service, you release PayForward from any claim or liability arising from your use of any such third-party Web site or services.
                        </p>
                      </li>
                      <li>
                        <p>
                          <strong>Information You Choose to Submit.</strong> We collect information that personally identifies you, such as your name, telephone number, address, credit card number, bank account information and other payment information, tax identification number, email address, date of birth, social security number and other data that can be reasonably linked to such information ("Personal Information") only if you choose to share such information with us. For example, you will be asked to provide us with certain Personal Information to register with the Site and become a PayForward member, sign up for certain services available through the Service (such as newsletter communications), create an account, purchase products through the Service, and at other times. The decision to provide this information is optional; however, if you decide not to register or provide such information, you may not be able to use some or all of the features of the Service, including, without limitation, receiving wellness rewards and contributing to nonprofits and Savings Goals. You may review, correct, update or change your Personal Information, or remove your Personal Information from our direct marketing efforts, at any time by visiting your profile or account page on the Site, or by contacting us at: <strong>27200 Tourney Road, Valencia, CA 91355, (661) 295-7135, or at <a href='mailto:info@PayForward.com'>info@PayForward.com</a>.</strong>
                        </p>
                        <p>
                          By using the Service you may make certain of your Personal Information available to others. For example, if you post a comment in a public area of the Site, other users may see your name and other information from your public profile that you choose to make available to others. Information you choose to make publicly available may be accessed by users who use the Service and may be accessed by commercial search engines such as Google, Yahoo! and Bing to the extent that such engines are permitted to access the Site.
                        </p>
                        <p>
                          If you ask us to invite someone else to join PayForward, we will send that person messages on your behalf using your name and other Personal Information you choose to disclose.
                        </p>
                        <p>
                          You may limit the information from your profile that other users may see, including by using the privacy settings made available on the <a href='https://payforward.com/pdf/privacy_policy.pdf' target='_blank'>Site</a>.
                        </p>
                        <p>
                          If you post things like photos or videos on the Service, we may receive additional related data (or metadata), such as the time, date and place you took the photo or video. You have the ability to add photos to your PayForward profile if you wish to do so. Please be aware that other users of the PayForward Service may be able to view your photos. At any time you may remove a photo by logging into your account, hovering over your photo, and clicking on the "x."
                        </p>
                        <p>
                          If you send us personal correspondence, such as e-mails or letters, we may keep a record of that correspondence. We also periodically ask users for feedback that we use to improve the Service and products and add new features. If you respond to these requests, we may keep a record of your response.
                        </p>
                        <p>
                          You should not grant anyone access to your account. PayForward does not and cannot control how anyone else may use your information or account. Personal Information you provide to others may not be protected, kept private or be secure. If you allow someone else to have access to your account, they will have access to your Personal Information, and they may share it with others or use your financial information and other Personal Information for their own purposes. You are solely responsible for all use of your account, by yourself or anyone who uses it. PayForward will not be liable for any disclosure or use of Personal Information or other information by you or anyone using your account.
                        </p>
                        <p>
                          From time to time, you may be asked whether you would like to share personal information with a third party, for example, to join an email list in order to receive information and updates directly from a Merchant. Your decision to submit your personal information in these circumstances is optional. If you are a third party, you agree not to share any Personal Information you may collect from a user through the Service with any other third party and to otherwise treat all such Personal Information in accordance with all applicable terms of this Privacy Policy and any other agreement between you and us.
                        </p>
                      </li>
                      <li>
                        <p>
                          <strong>Information We Automatically Collect.</strong> We automatically collect information that does not reflect or reference an individually identifiable user ("<strong>Non-Personal Information</strong>"). Like most Web sites, we automatically collect and use the data contained in log files. The information in the log files may include the IP address of the computer or server that you are using to access the Services, the IP address and/or the URL from the Web site you visited just before you visited our Services, the URL of the page you visit upon leaving the Services, if any, and the type of browser and operating system you are using. This anonymous usage information may be associated with your user name and profile, but we will not disclose the associated information to third parties.
                        </p>
                      </li>
                      <li>
                        <p>
                          <strong>Information Others May Share About You.</strong> We may receive information about you from users who invite you to join PayForward through the Service.  When people use the Service, they may store and share information about you, such as when they upload and manage their invites and contacts. We may also obtain information about you from third parties such as credit bureaus and identity verification services.
                        </p>
                        <p>
                          If you participate in a cash back (rebate) service with PayForward, you will be asked to register (link) your Visa<sup>®</sup> and MasterCard® payment card(s) to PayForward for purposes of earning cash back when you shop at participating merchants.
                        </p>
                        <p>
                          If you register a Visa payment card or use your PayForward prepaid card, Visa will provide us with details about your qualifying purchases with that card at participating merchants, including the date, time, and amount of the purchase or transaction.  We use this information solely to administer the program and provide you with your rebates.  We may share details of your transactions with the participating merchants as part of administering the program.
                        </p>
                        <p>
                          If you register a MasterCard payment card in connection with transaction monitoring, you authorize PayForward to share your payment card information with MasterCard so it knows you enrolled. You authorize MasterCard to monitor transactions on your registered card(s) to identify qualifying purchases in order to determine whether you have qualified for or earned a rebate linked to your payment card, and for MasterCard to share such transaction details with PayForward to enable your card-linked rebate(s) and target offers that may be of interest to you. You may opt-out of transaction monitoring on the payment card(s) you have registered by visiting the Wallet page and selecting each card you wish to opt-out of, and clicking “Unlink this Card.”
                        </p>
                        <p>
                          We may receive information about you, and your activities on and off the Service, from third-party entities, such as information from a third-party entity when we jointly offer services.
                        </p>
                        <p>
                          Through the Service, you may elect to access third-party services and applications, such as Facebook and other social networks. When you do so, you may be able to import information from those other services and applications and share content with those services. Some of those third-party services may also provide us with information from your accounts which may enable us to enhance and personalize your use of the Service (e.g., so that you can follow your Facebook friends' activity on the Site). If you elect to enable such third-party services or applications on PayForward, the information you share with such third-party services and applications will be subject to their privacy policies and your privacy settings for such services. You also may be able to control or block such sharing by changing your privacy settings in such services or applications.
                        </p>
                      </li>
                      <li>
                        <p>
                          <strong>Cookies and Web Beacons.</strong> We may use cookies (a small file containing a string of characters that uniquely identifies your Web browser) and Web beacons (an electronic file placed within a Web site that monitors usage). We may use cookies and Web beacons to improve the quality of the Service. Most Web browsers are initially set up to accept cookies, but you can reset your Web browser to refuse all cookies or to indicate when a cookie is being sent. However, certain features of the Service may not work if you delete or disable cookies. Certain of our Service Providers (defined below) may use cookies and Web beacons in connection with the services they perform on our behalf, including, without limitation, targeted ad serving. The Site does not respond to Web browser "do not track signals or other mechanisms that provide consumers the ability to exercise choice regarding the collection of personally identifiable information about an individual consumer's online activities over time.
                        </p>
                        <p>
                          We may work with third-party advertising companies which may use information about your visit to the Service in order to provide advertisements about goods and services that may be of interest to you on the Service and on third-party Web sites. From the Service, these companies may place or recognize a unique cookie on your computer or use other technologies such as Web beacons or pixel tags. Our Privacy Policy does not cover any use of information that a third party advertisement serving company may collect from you. For more information about ad cookies and companies that provide users the ability to "opt-out" of the collection of information from such cookies, please <a href='http://www.networkadvertising.org/managing/opt_out.asp' target='_blank'>click here</a>.
                        </p>
                      </li>
                      <li>
                        <p>
                          <strong>How We Use Your Information.</strong> We may use Personal Information and Non-Personal Information for internal purposes, such as to notify you of new products or services, to send you notifications, to allocate rebates, to allow you to view statements, make payments and view your transaction activity, to provide customer support, to process transactions, to customize the advertising and content you see, to provide and maintain the Service, to improve the Service, to conduct research and surveys, to test features in development, to solicit your feedback and input about the Service and to provide more relevant products, services and advertisements on or through the Service. Such information may be combined with data collected from other sources so that we may further improve the relevance of products, services and advertisements offered on or through the Service.
                        </p>
                        <p>
                          From time to time, we may contract with Service Providers (not including advertisers or marketers) to provide certain services, such as hosting and maintenance, data storage and management services, and identity verification.  We provide our Service Providers with the information reasonably necessary for them to perform these services, which information may include certain Personal Information such as your name and email address. Each Service Provider must agree to implement and maintain reasonable security procedures and practices appropriate to the nature of the information involved in order to protect your information from unauthorized access, destruction, use, modification or disclosure.
                        </p>
                        <p>
                          In addition, we may work with other third-party companies who use marketing, advertising, or related products which makes it possible to offer and operate the Service. We want our Service to be relevant and interesting to you. With this in mind, we may use all of the information we have about you to show you relevant advertising and offers. We do not share information with advertisers or marketers that personally identifies you unless you give us permission. We may use, and disclose to third parties, certain Non-Personal Information regarding the Service (e.g., the aggregate number of inquiries from a particular geographic location, etc.). However, such information does not identify you individually.  For example, we may provide partners with information about the reach and effectiveness of their advertising, products, or services without providing information that personally identifies you. We can do so, for example, by aggregating the information so that it does not personally identify you. As an example, (a) we may tell a partner or third-party company how its advertising, products, or services performed, or how many people viewed their ads (or used their products/services), or (b) we may provide non-personally identifying information to a partner or third-party company to help them understand their audience or customers.
                        </p>
                        <p>
                          We may share some of your contact information, such as email address and phone number, with the enterprise partner that sponsors your PayForward membership (e.g., the carrier that provides your health insurance).
                        </p>
                        <p>
                          We may disclose your Personal and Non-Personal information with entities controlling, controlled by or under common control with PayForward.
                        </p>
                        <p>
                          In certain instances, we may disclose your information when we believe it is necessary to identify, contact or bring legal action against persons or entities in order to protect PayForward or our users, or when we believe that the law or legal process requires such disclosure. For example, we may share information to reduce the risk of fraud or if someone uses or attempts to use the Service for illegal reasons, such as an intellectual property rights violation, or to commit fraud.
                        </p>
                        <p>
                          If the ownership or control of all or part of PayForward or its assets changes, we may transfer and disclose your Personal and Non-Personal information to the new owner.
                        </p>
                      </li>
                      <li>
                        <p>
                          <strong>Communications.</strong> We may communicate with you at various times, with administrative emails or phone calls, about transactions and Service announcements, customer service responses and promotional emails like special offers, newsletters and updates. You can choose to stop receiving promotional emails at any time simply by changing your preferences or using an email's unsubscribe function. You may not opt out of receiving administrative messages or customer service responses.
                        </p>
                      </li>
                      <li>
                        <p>
                          <strong>PayForward Invitations.</strong> If you choose to invite friends and family members to join PayForward, we will ask you for your invitee's name and email address. You will use the PayForward email system to send an email inviting him or her to register on PayForward. PayForward stores this information for the sole purpose of sending the initial and follow-up invitations and messages and tracking the status of your invitations to allow you to follow-up at your discretion. The PayForward system does not display your invitee's email address to you or others on the Service. Your invitee may contact us at <a href='mailto:privacy@payforward.com'>privacy@PayForward.com</a> to request that we remove this information from our database.
                        </p>
                      </li>
                      <li>
                        <p>
                          <strong>Security.</strong> Information that we collect is stored on servers, using standard security procedures and practices appropriate to the nature of the information in an effort to protect information from unauthorized access, destruction, use, modification or disclosure. However, no data transmission over the Internet can be guaranteed to be 100% secure. As a result, while we strive to protect information transmitted on or through the Service, we cannot and do not guarantee or warrant the security of any information you transmit on or through the Service, and you do so at your own risk.
                        </p>
                      </li>
                      <li>
                        <p>
                          <strong>Processing in the United States.</strong> Please be aware that your Personal Information and communications may be transferred to and maintained on servers or databases located outside your state. If you are located outside of the United States, please be advised that the laws in the United States may not be as protective of your privacy as those in your location. By using our Site or Service, you agree that the collection, use, transfer, and disclosure of your Personal Information and communications will be governed by the applicable laws in the United States.
                        </p>
                      </li>
                      <li>
                        <p>
                          <strong>Children's Privacy Statement.</strong> We do not knowingly collect Personal Information from children under the age of 13 in connection with the Service. If we become aware that we have inadvertently received Personal Information from a child under the age of 13, we will delete such information from our records.
                        </p>
                      </li>
                      <li>
                        <p>
                          <strong>Updating Information.</strong> If your Personal Information changes, or if you no longer desire our Service, you may correct, delete inaccuracies, amend or remove it by making the change within your account at <a href='https://payforward.com/' target='_blank'>www.PayForward.com</a>.
                        </p>
                        <p>
                          We will retain your information for as long as your account is active or as needed to provide you the Service. If you wish to cancel your account contact us at Support@PayForward.com. We will retain and use your information as necessary to comply with our legal obligations, resolve disputes, and enforce our agreements.
                        </p>
                        <p>
                          When you register or log in to the Service, you can choose to receive newsletters, information and/or emails from PayForward. If you change your mind, you can update your account information on the Site or follow the unsubscribe link located at the bottom of the relevant communication.
                        </p>
                      </li>
                      <li>
                        <p>
                          <strong>Privacy Policy Changes and Questions.</strong> PayForward may, in its sole and absolute discretion, change this Privacy Policy from time to time. PayForward will post a copy of the Privacy Policy as changed on the Service. Your continued use of the Service constitutes your agreement to abide by the Privacy Policy as changed. If you object to any such changes, your sole recourse shall be to cease using the Service.
                        </p>
                        <p>
                          If you have questions, concerns, or would like additional information about our privacy practices, please contact us at privacy@payforward.com. Written inquiries may be sent to: PayForward LLC, Attention: Privacy Policy, 27200 Tourney Road, Valencia, CA 91355, USA.
                        </p>
                      </li>
                    </ol>
                    <p>Effective Date: January 1, 2019</p>
                </div>

            `,
    disclaimer:
      'The PayForward<sup>®</sup> Rx Savings Solutions Visa<sup>®</sup> Prepaid Card is issued by Stride Bank, N.A., Member FDIC, pursuant to a license from Visa USA Inc. PayForward is an independent company that administers the member rewards program on behalf of Rx Savings Solutions.',
  },
  'pbm-max-info': {
    'or-more': 'or more',
    'or-less': 'or less',
    header: 'Will this be my cost at the pharmacy?',
    body: 'Drug prices change frequently, so we estimated based on the latest information available. Your plan may contribute to the cost of this drug, but when we aren’t sure of your cost share, or whether you’ve met your deductible or out-of-pocket maximum, we will display the medication’s full cost. You shouldn’t spend any more than this estimate, and you may spend less!',
  },
  'gpi-suppression': {
    disclaimer:
      'Disclaimer: Based on your particular health plan or provider certain privacy concerns may require us to restrict disclosure of your dependent’s full prescription information related to the class of drug involved. However, your dependent may access a full prescription savings profile by logging in to their own account, if they have the access to do so.',
  },
  tags: {
    'primary-pharmacy': 'Primary Pharmacy',
    'best-savings': 'Best Savings',
    'home-delivery': 'Home Delivery',
    'three-month-supply': '3 Month Supply',
    'price-not-available': 'Price Not Available',
  },
  'home-delivery': {
    convenience: {
      from: 'Sending your prescription for:',
      to: 'To this pharmacy:',
    },
    'rx-home-delivery': 'RX HOME DELIVERY',
    'rx-home-delivery-lc': 'Rx Home Delivery',
    'behavior-change-view': {
      convenience: 'Get it shipped to you',
      continue: 'Continue',
      heading: 'Switch to convenient home delivery',
      'transfer-prescription-instructions':
        'Transfer your prescription for {{drugWithStrength}} from:',
      to: 'to',
      'rx-home-delivery-card': {
        name: 'RX HOME DELIVERY',
        'shipping-price': 'INCLUDES FREE SHIPPING',
      },
      'confirm-step-instructions':
        'Confirm your contact information and the prescription will be shipped to you.',
      'order-online': 'Order Online',
    },
    'contact-prescriber': {
      'complete-switch':
        'To complete your switch, you will be redirected to Rx Home Delivery to confirm your information.',
      'confirm-mobile':
        'Enter or confirm your mobile number so our Pharmacy Support team can contact you if any questions arise.',
      'mobile-button-text': 'Confirm and Continue',
      'new-tab-disclaimer':
        "Note: The Rx Home Delivery website will open in a new tab. If you don't see it, please check your pop-up blocker and make sure it's disabled.",
    },
    status: {
      qualifies: 'Switch to Rx Home Delivery',
      'switch-submitted': 'Switch Request Submitted',
      'switch-completed': 'Switch Completed',
    },
    'external-link-view': {
      header: 'Just one last step!',
      complete: 'Complete this process by confirming your information with Rx Home Delivery.',
      button: 'Go to Rx Home Delivery',
      done: 'Done',
      footer:
        'Note: The Rx Home Delivery website will open in a new tab. If you don’t see it, please check your pop-up blocker and make sure it’s disabled. Or call our support team at <span class="no-wrap">1-800-268-4476</span> to complete your request.',
    },
    confirmation: {
      payment: 'Still need to complete your order? ',
      'home-delivery': 'Visit Rx Home Delivery',
      'footer-cancel-text': 'To cancel this switch, contact our Pharmacy Support team:',
      'contact-info': '1-800-268-4476 from 7 a.m.\u20148 p.m. CT, Monday\u2014Friday',
      'free-shipping': 'Includes free shipping',
    },
  },
  'telephone-input': {
    'default-error-message': 'Please enter a valid phone number.',
  },
  'dashboard-value-message-banner': {
    primary:
      'Take advantage of simple, doctor-approved ways to pay less for your medications. How it works:',
    'review-savings': {
      'icon-alt': 'Review your savings icon',
      header: 'Review your savings.',
      body: 'We show your best opportunities to save.',
    },
    'click-to-switch': {
      'icon-alt': 'Click to switch icon',
      header: 'Click to switch.',
      body: 'We handle approvals and transfers with your doctor.',
    },
    relax: {
      'icon-alt': 'Relax icon',
      header: 'Relax.',
      body: 'We watch prices 24/7 and notify you when anything changes.',
    },
    'save-up-to': 'Save up to',
    'per-year': 'per year',
    'savings-button': 'Save Now',
  },
  // items below this comment use the new standard, with the pattern "componentname.labelname". this is something that
  // can be easily understood and promotes ease-of-search. nested keys are supported but the base key should follow
  // this standard. future efforts can be made to uplift existing keys to this standard.
  RemoveAlert: {
    TurnOffAlert: 'Turn Off Alert',
  },
  MedicineCabinetDashboard: {
    NoMedicationsLabel: 'You have no medications to display at this time.',
    View: 'View',
    ViewAndPrintReport: 'View & Print Report',
  },
  MedicineListItem: {
    SetReminder: 'Set reminder',
  },
  BackNavigation: {
    Back: 'Back',
  },
  LocatorOptionZipcode: {
    PharmaciesNear: 'Pharmacies Near',
  },
  Locator: {
    BreadcrumbTextForFindAPharmacy: 'Find a Pharmacy',
    BreadcrumbTextForSearchResults: 'Search Results',
    PharmacyResultsFor: 'Pharmacy results for:',
    BackToResults: 'Back to Results',
    InNetworkPharmacy: 'In Network Pharmacy',
    OutOfNetworkPharmacy: 'Out-of-Network Pharmacy',
    ShowingPage: 'Showing page {{page}} of {{totalPages}}',
  },
  Profile: {
    BreadcrumbText: 'User Settings',
    Account: 'Account',
    Notifications: 'Notifications',
    Privacy: 'Privacy',
    RxSSHomeDelivery: 'Delivery', // needs to be translated for the new home delivery flow
    CommunicationPreferences: {
      SavingNotificationsHeader: 'Savings Notification',
    },
  },
  PasswordTab: {
    Show: 'Show',
    Hide: 'Hide',
    PasswordDoesNotMeetRequirements: 'Please match the password requirements listed below.',
    PleaseEnterAPassword: 'Please enter a password.',
    Cancel: 'Cancel',
    Save: 'Save',
  },
  ContactTab: {
    Edit: 'edit',
    Add: 'add',
    Cancel: 'Cancel',
    Save: 'Save',
    ChangePrimaryRetailPharmacy: 'Change Primary Retail Pharmacy',
  },
  AdjustSearch: {
    TotalCost: 'Total Cost',
    PerDayCost: 'Per Day Cost',
  },
  PrescriptionDetails: {
    PrescriptionFilledDate: 'Prescription Filled: {{fillDate}}',
  },
  BreadcrumbStore: {
    Dashboard: 'Dashboard',
  },
  ReportBody: {
    WhatsTheDifference: "What's the difference?",
  },
  DateSelector: {
    StartDate: 'Start Date',
    Month: 'Month',
    Year: 'Year',
    EndDate: 'End Date',
    Months: {
      January: 'January',
      February: 'February',
      March: 'March',
      April: 'April',
      May: 'May',
      June: 'June',
      July: 'July',
      August: 'August',
      September: 'September',
      October: 'October',
      November: 'November',
      December: 'December',
    },
  },
  CoverageAlert: {
    ClinicalPharmacist: 'Clinical Pharmacist',
    DoctorSays: 'Dr. Green says: ',
  },
  RxHomeDeliveryPharmacyInformation: {
    DeliveryImageAlt: 'Rx Home Delivery',
    GetItDeliveredWith: 'Get it delivered with',
    ShipsToYou: 'Ships to you',
    IncludesFreeShipping: 'Get it shipped to you',
  },
  // Spanish translations needed for these keys as well
  HomeDeliveryRequest: {
    RxssLogo: {
      LogoAltText: 'Rx Savings Solutions Logo',
    },
    SubmittingModal: {
      Title: "We're On It!",
      Subtitle: 'Submitting your request may take a few seconds.',
    },
    Legal: {
      Line1:
        'The price above is a personalized estimate based on your prescription drug plan as of today.',
      Line2:
        'By providing my email address and/or my phone number and clicking submit, I expressly consent to Rx Savings, LLC d/b/a Rx Savings Solutions sending me emails and contacting me via phone regarding a prescription change request.',
    },
    InsuranceSection: {
      Title: 'Prescription Insurance Card',
      PricingBox: {
        PricingSubtitle:
          'With this coupon, the amount you pay will not pay down your deductible unless you send a claim to your health insurance company. Contact them for instructions.',
        CouponHeader: 'Coupon available!',
        CouponSubheader:
          'A coupon is available at a cheaper price than through your insurance. Would you like to use this option?',
        CouponLabel1: 'Yes, pay ',
        CouponLabel2: ' with GoodRx\xAE coupon',
        PlanLabel1: 'No, pay ',
        PlanLabel2: ' with insurance',
      },
      InsuranceCardIntro:
        'Enter your insurance details to transfer your prescription. Find this information on your health insurance or prescription drug benefit card.',
      DependentInsuranceCardIntro:
        'Please enter {{firstName}}’s insurance details to transfer to the RxSS Home Delivery pharmacy. Find this information on your health insurance or prescription drug benefit card.',
      Labels: {
        CardHolderId: 'ID Number',
        RxBin: 'Rx BIN',
        RxGroup: 'Group Number',
        RxPCN: 'Rx PCN',
        IfApplicable: '(If Applicable)',
      },
      Errors: {
        CardHolderIdRequired: 'Please enter an ID number.',
        RxBinRequired: 'Please enter an Rx BIN.',
        MaxCharCardholderId: 'Please enter an ID Number 20 characters or fewer.',
        MaxCharRxBin: 'Please enter an Rx BIN 6 digits or fewer.',
        MaxCharRxPcn: 'Please enter an Rx PCN 10 characters or fewer.',
        MaxCharRxGroup: 'Please enter a Group Number 15 characters or fewer.',
      },
      CouponSelectedView: 'Coupon chosen (no insurance needed)',
    },
    MedicalSection: {
      Title: 'Medical Information',
      Tooltip:
        'Pharmacists will use this information to check for any potential drug interactions.',
      MedicalInformationIntro: 'Check all that apply. If none, leave unchecked.',
      DependentMedicalInformationIntro:
        'Check all that apply for {{firstName}}. If none, leave unchecked.',
      ViewLabels: {
        Medications: 'Current Medications',
        Allergies: 'Allergies',
        Conditions: 'Medical Conditions',
      },
      EditLabels: {
        Medications: "I'm currently taking medications",
        Allergies: 'I have allergies',
        Conditions: 'I have medical conditions',
      },
      Placeholders: {
        Medications: 'Search Medications',
        Allergies: 'Search Allergies',
        Conditions: 'Enter Medical Condition',
      },
      Errors: {
        MaxCharConditions: 'Please enter a medical condition fewer than 255 characters.',
        EmptyConditions: 'Please add at least one medical condition to the list.',
        MaxCharAllergies: 'Please enter an allergy fewer than 255 characters.',
        EmptyAllergies: 'Please add at least one allergy to the list.',
        MaxCharMedications: 'Please enter a medication fewer than 255 characters.',
        EmptyMedications: 'Please add at least one medication to the list.',
      },
      AddToList: 'Add to List',
    },
    PaymentSection: {
      Title: 'Payment Information',
      CardSaved: 'Card successfully saved!',
      Labels: {
        CardholderName: 'Cardholder Name',
        BillingZip: 'Billing ZIP Code',
        CardNumber: 'Card Number',
        ExpirationDate: 'Expiration Date',
        Cvc: 'CVC',
      },
      NoPermissions: 'Sorry, you do not have permissions to view this section.',
    },
    ShippingAndContactSection: {
      Title: 'Shipping Information',
      Free: 'FREE',
      Labels: {
        ShippingMethod: 'Shipping Method',
        FullName: 'Full Name',
        ShippingAddressFirstLine: 'Address Line 1',
        ShippingAddressSecondLine: 'Address Line 2 (Optional)',
        ShippingAddressCity: 'City',
        ShippingAddressState: 'State',
        ShippingAddressZipCode: 'ZIP Code',
        MobilePhoneNumber: 'Mobile Phone Number',
      },
      Errors: {
        InvalidFullName: 'Please enter a name.',
        MaxCharFullName: 'Please enter a name less than 255 characters.',
        InvalidShippingAddressFirstLine: 'Please enter an address.',
        MaxCharShippingAddressFirstLine: 'Please enter an address less than 255 characters.',
        MaxCharShippingAddressSecondLine: 'Please enter an address less than 255 characters.',
        InvalidShippingAddressCity: 'Please enter a city.',
        MaxCharShippingAddressCity: 'Please enter a city less than 255 characters.',
        InvalidShippingAddressState: 'Please enter a state.',
        InvalidShippingAddressZipCode: 'Please enter a valid ZIP Code.',
        InvalidMobilePhoneNumber: 'Please enter a valid mobile phone number.',
      },
      MobilePhoneNumberInstruction: 'How can we contact you about the status of this request?',
    },
    OrderSummarySection: {
      Title: 'Order Summary',
      Shipping: 'Shipping',
      Price: 'Total',
      ProcessingFeeItem: {
        Title: 'Processing Fee',
        TooltipIconAltText: 'Info Icon',
        TooltipTitle: 'What is this?',
        TooltipContent: 'A processing fee is included for making an online payment for your order.',
      },
      InitialFillButton: 'Submit Request',
      RefillButton: 'Confirm Refill',
      Coupon: 'Coupon - Not Insurance',
      InitialFillFooter:
        'If the prescription is approved for transfer, we’ll send you an invoice with the final price before shipping.',
      RefillFooter:
        'You won’t be charged until your prescription is approved and shipped. Please see our full <a href="https://rxss.com/terms-and-conditions/" target="_blank">terms and conditions</a>.',
      ToolTip:
        "When using a coupon, the amount you pay won't be applied to your insurance deductible unless you submit a claim to your health insurance provider. Contact them to determine the right way to file your claim.",
      Free: 'FREE',
    },
    InitialFillHeader: "Just confirm a few details and we'll take it from there.",
    RefillHeader: 'Please confirm the details of your upcoming fill.',
    UnsavedChangesPrompt: 'Are you sure? If you leave this page, your changes may be lost.',
    InfoCard: {
      Add: 'Add',
      Edit: 'Edit',
      Remove: 'Remove',
      Save: 'Save',
      SaveAndContinue: 'Save & Continue',
      Cancel: 'Cancel',
    },
    SuccessScreen: {
      CompleteText: 'All done!',
      BodyText:
        'Your delivery request has been submitted. If the prescription is approved for transfer, we’ll send you an invoice with the final price before shipping.',
      RefillBodyText:
        'Your delivery request has been submitted. Our team will reach out when the prescription is approved and shipped.',
      ButtonText: 'See Your Medications',
    },
    RefillErrorScreen: {
      HeaderText: 'Refill expired or already confirmed',
      BodyText:
        'If you need further assistance, call us at <span style="white-space: nowrap;"><a href="tel:18002684476">1-800-268-4476</a></span> (Monday-Friday; 7 a.m.-8 p.m. CST).',
    },
    SubmissionUnauthorizedError: 'Sorry, you do not have permission to submit this request.',
  },
  // Spanish translations needed for these keys as well
  HomeDeliveryUserSettings: {
    NoPreferencesInfoTitle: 'No Preferences Yet',
    Legal:
      'Your payment information is securely stored with our partner, Stripe, according to their <a href="https://stripe.com/privacy-center/legal">privacy statement</a>.',
    NoPreferencesInfoMessage:
      'Your settings for things like shipping information and payment options will display here once you\'ve filled a prescription using RxSS Home Delivery. Remember, you can always <span style="white-space: nowrap;"><a target="_self" href="{{url}}">review your medications</a></span> to see if you have an opportunity to get your prescriptions delivered.',
    NoInsuranceCardSaved: 'No insurance card yet.',
  },
  TechnicalError:
    'A technical error occurred. Please try again in a few moments. If the issue continues, contact us at <span style="white-space: nowrap;"><a href="tel:18002684476">1-800-268-4476</a></span> (Monday-Friday; 7 a.m.-8 p.m. CT).',
  GenericError: {
    Unavailable: "Whoops. Looks like we're having a technical issue.",
    ApologiesForInconvenience: 'Our apologies for the inconvenience.',
    Contact:
      'Please refresh the page or try again soon. If the issue continues, contact us at <span style="white-space: nowrap;"><a href="tel:18002684476">1-800-268-4476</a></span> (Monday-Friday; 7 a.m-8 p.m. CT) or email <a href="mailto:support@rxsavingssolutions.com">support@rxsavingssolutions.com</a>.',
  },
  MobileAppDeprecationNotice: {
    Header: '<strong>We have a new app.</strong>',
    Retired: 'This version of our app is now retired and will no longer be updated.',
    Download: '<strong>Please download the new RxSS app to access all our new features.</strong>',
    DownloadButton: '<strong>Download New App</strong>',
    LaterLink: '<strong>Later</strong>',
  },
  PageNotFound: {
    Header: '<strong>Huh? What the heck does 404 mean, anyway?</strong>',
    BodyText:
      "It's definitely not the page you're looking for. Could be a bad address or an old link. No worries – simply head back to the dashboard.",
    ButtonText: 'Go to Dashboard',
    GifAltText: '404 error with magnifying glass',
  },
  pageHeader: {
    homeNav: 'Dashboard',
    backNav: 'Back',
  },
  drugDetails: {
    memberSpendPerDay: '{{memberSpendPerDay}}/day',
    costPerDayLabel: '({{costPerDay}}/day)',
    daySupplyLabel: '{{daySupply}} day supply',
    planSpendLabel: 'Plan pays:',
    memberSpendLabel: 'You pay:',
    quantityAbbreviation: 'Qty: {{quantity}}',
    sideEffectsLabel: 'Side Effects',
    interactionsLabel: 'Interactions',
  },
  universalSavings: {
    tags: {
      currentMedication: 'Current Medication',
      highestSavingsOption: 'Highest Savings Option',
      shippingTag: 'Simple & Convenient',
    },
    linkCopy: {
      helpMeSwitch: 'Help Me Switch',
      compareMedication: 'Compare Medications',
      seeAllSavings: 'See All Savings Options',
      seeAllOptions: 'See All Options',
      howDoesRxssWork: 'How Does RxSS Work?',
      whyDidWeSuggestThis: 'Why did we suggest this?',
      confirm: 'Confirm',
      confirmPharmacy: 'Confirm Pharmacy',
      confirmYourChoice: 'Confirm Your Choice',
      confirmSelection: 'Confirm Selection',
      showMorePharmacies: 'Show More Pharmacies',
    },
    pbmMaxCostCopy: {
      min: 'As low as {{savings}}',
      max: '{{savings}} or less',
    },
    comparePage: {
      header: 'Compare Medications',
      intro: {
        TA: {
          forSelf: {
            withDoctorName:
              "This medication has been suggested by our clinical pharmacists. If you'd like to save <strong>{{savings}} per fill</strong>, we'll contact your doctor, {{doctorName}}, to approve this switch.",
            withoutDoctorName:
              "This medication has been suggested by our clinical pharmacists. If you'd like to save <strong>{{savings}} per fill</strong>, we'll contact your doctor to approve this switch.",
          },
          forFamily: {
            withDoctorName:
              "This medication has been suggested by our clinical pharmacists. If you'd like to save <strong>{{savings}} per fill</strong>, we'll contact <span data-private>{{memberName}}</span>'s doctor, {{doctorName}}, to approve this switch.",
            withoutDoctorName:
              "This medication has been suggested by our clinical pharmacists. If you'd like to save <strong>{{savings}} per fill</strong>, we'll contact <span data-private>{{memberName}}</span>'s doctor to approve this switch.",
          },
        },
        DF: {
          forSelf: {
            withDoctorName:
              "This medication has been suggested by our clinical pharmacists. If you'd like to save <strong>{{savings}} per fill</strong>, we'll contact your doctor, {{doctorName}}, to approve this switch.",
            withoutDoctorName:
              "This medication has been suggested by our clinical pharmacists. If you'd like to save <strong>{{savings}} per fill</strong>, we'll contact your doctor to approve this switch.",
          },
          forFamily: {
            withDoctorName:
              "This medication has been suggested by our clinical pharmacists. If you'd like to save <strong>{{savings}} per fill</strong>, we'll contact <span data-private>{{memberName}}</span>'s doctor, {{doctorName}}, to approve this switch.",
            withoutDoctorName:
              "This medication has been suggested by our clinical pharmacists. If you'd like to save <strong>{{savings}} per fill</strong>, we'll contact <span data-private>{{memberName}}</span>'s doctor to approve this switch.",
          },
        },
        TS: {
          forSelf: {
            withDoctorName:
              "This medication has been suggested by our clinical pharmacists. If you'd like to save <strong>{{savings}} per fill</strong>, we'll contact your doctor, {{doctorName}}, to approve this switch.",
            withoutDoctorName:
              "This medication has been suggested by our clinical pharmacists. If you'd like to save <strong>{{savings}} per fill</strong>, we'll contact your doctor to approve this switch.",
          },
          forFamily: {
            withDoctorName:
              "This medication has been suggested by our clinical pharmacists. If you'd like to save <strong>{{savings}} per fill</strong>, we'll contact <span data-private>{{memberName}}</span>'s doctor, {{doctorName}}, to approve this switch.",
            withoutDoctorName:
              "This medication has been suggested by our clinical pharmacists. If you'd like to save <strong>{{savings}} per fill</strong>, we'll contact <span data-private>{{memberName}}</span>'s doctor to approve this switch.",
          },
        },
        GS: {
          forSelf:
            "This medication has been suggested by our clinical pharmacists. If you'd like to <strong>save {{savings}} on your next fill</strong>, we'll be happy to help you make the switch.",
          forFamily:
            "This medication has been suggested by our clinical pharmacists. If you'd like to <strong>save {{savings}} on your next fill</strong>, we'll be happy to help you make the switch.",
        },
        GC: {
          forSelf:
            "This medication has been suggested by our clinical pharmacists. If you'd like to <strong>save {{savings}} on your next fill</strong>, we'll be happy to help you make the switch.",
          forFamily:
            "This medication has been suggested by our clinical pharmacists. If you'd like to <strong>save {{savings}} on your next fill</strong>, we'll be happy to help you make the switch.",
        },
      },
      disclaimer:
        'Disclaimer: This drug information is an information resource only and may be derived from a third-party source outside of Rx Savings Solutions. The information in no way constitutes medical advice and you should contact a physician before taking or making any changes to a medication. There is no guarantee on the accuracy or most up to date information regarding this drug. Rx Savings Solutions does not endorse any drug or makes any recommendation regarding a treatment based on this information. Rx Savings Solutions disclaims any liability related to any claim related to the provision of this drug information.',
    },
    suggestionPage: {
      header: 'How to Save the Most',
      intro: {
        TA: {
          forSelf: {
            withDoctorName:
              "You can <strong>save {{savings}} per fill!</strong> Simply <strong>take another medication that treats your condition.</strong> We'll work with your doctor, {{doctorName}}, to approve this switch.",
            withoutDoctorName:
              "You can <strong>save {{savings}} per fill!</strong> Simply <strong>take another medication that treats your condition.</strong> We'll work with your doctor to approve this switch.",
          },
          forFamily: {
            withDoctorName:
              "You can <strong>save {{savings}} per fill!</strong> <span data-private>{{memberName}}</span> can simply <strong>take another medication that treats this condition.</strong> We'll work with <span data-private>{{memberName}}</span>'s doctor, {{doctorName}}, to approve this switch.",
            withoutDoctorName:
              "You can <strong>save {{savings}} per fill!</strong> <span data-private>{{memberName}}</span> can simply <strong>take another medication that treats this condition.</strong> We'll work with <span data-private>{{memberName}}</span>'s doctor to approve this switch.",
          },
        },
        DF: {
          forSelf: {
            withDoctorName:
              "You can <strong> save {{savings}} per fill!</strong> Simply <strong>take this medication in a different form.</strong> It's the same active ingredients in your current medication, just in another form. We'll work with your doctor, {{doctorName}}, to approve this switch.",
            withoutDoctorName:
              "You can <strong> save {{savings}} per fill!</strong> Simply <strong>take this medication in a different form.</strong> It's the same active ingredients in your current medication, just in another form. We'll work with your doctor to approve this switch.",
          },
          forFamily: {
            withDoctorName:
              "You can <strong>save {{savings}} per fill!</strong> <span data-private>{{memberName}}</span> can simply <strong>take this medication in a different form.</strong> It's the same active ingredients as the current medication, just in another form. We'll work with <span data-private>{{memberName}}</span>'s doctor, {{doctorName}} to approve this switch.",
            withoutDoctorName:
              "You can <strong>save {{savings}} per fill!</strong> <span data-private>{{memberName}}</span> can simply <strong>take this medication in a different form.</strong> It's the same active ingredients as the current medication, just in another form. We'll work with <span data-private>{{memberName}}</span>'s doctor to approve this switch.",
          },
        },
        TS: {
          forSelf: {
            withDoctorName:
              "You can <strong>save {{savings}} per fill!</strong> Simply <strong>split a higher-dose tablet in half to get the same dose you're currently taking.</strong> We'll work with your doctor, {{doctorName}}, to approve this switch.",
            withoutDoctorName:
              "You can <strong>save {{savings}} per fill!</strong> Simply <strong>split a higher-dose tablet in half to get the same dose you're currently taking</strong>. We'll work with your doctor to approve this switch.",
          },
          forFamily: {
            withDoctorName:
              "You can <strong>save {{savings}} per fill!</strong> Simply <strong>split a higher-dose tablet in half to get the same dose <span data-private>{{memberName}}</span> is currently taking.</strong> We'll work with <span data-private>{{memberName}}</span>'s doctor, {{doctorName}}, to approve this switch.",
            withoutDoctorName:
              "You can <strong>save {{savings}} per fill!</strong> Simply <strong>split a higher-dose tablet in half to get the same dose <span data-private>{{memberName}}</span> is currently taking.</strong> We'll work with <span data-private>{{memberName}}</span>'s doctor to approve this switch.",
          },
        },
        GS: {
          forSelf:
            "You can <strong>save {{savings}} per fill!</strong> Simply <strong>take a generic version of your current medication.</strong> We'll even help you make the switch.",
          forFamily:
            "You can <strong>save {{savings}} per fill!</strong> <span data-private>{{memberName}}</span> can simply <strong>take a generic version of the current medication.</strong> We'll even help you make the switch.",
        },
        GC: {
          forSelf:
            "You can <strong>save {{savings}} per fill!</strong> Simply <strong>take a generic option that is identical to your current medication.</strong> We'll even help you make the switch.",
          forFamily:
            "You can <strong>save {{savings}} per fill!</strong> <span data-private>{{memberName}}</span> can simply <strong>take a generic option that is identical to the current medication.</strong> We'll even help you make the switch.",
        },
      },
      tags: {
        current: 'Current Medication',
        highest: 'Highest Savings Option',
      },
      supportCopy:
        'This medication has been selected by our clinical pharmacist. <a>Why did we suggest this?</a>',
      linkCopy: {
        helpMeSwitch: 'Help Me Switch',
        compareMedication: 'Compare Medications',
        seeAllSavings: 'See All Savings Options',
        howDoesRxssWork: 'How Does RxSS Work?',
      },
      disclaimer:
        'These prices are personalized estimates and may not reflect the actual total price. The estimates are based upon your prescription drug plan as of today. Due to discounts or charges a particular pharmacy may apply actual cost may differ. Please do not rely exclusively on this estimate alone to make your purchasing decision.',
      medIcons: {
        altText: {
          pillBottleWithTabletsInside: 'Pill bottle with tablets inside',
          prescriptionBottle: 'Prescription bottle',
          medicineBottle: 'Medicine bottle',
          pillBottle: 'Pill bottle',
          tablet: 'Tablet',
          tabletSplitInTwo: 'Tablet split in two',
          openCapsuleSprinklingContents: 'Open capsule sprinkling contents',
          capsuleAndTablet: 'Capsule and tablet',
        },
      },
    },
    fulfillmentPage: {
      header: {
        default: 'How do you want this prescription filled?',
        shippingOnly: 'How would you like this prescription delivered?',
      },
      pickUpCardLbl: 'Pickup',
      shippingCardLbl: 'Shipping',
      intro: {
        drugChange: {
          forSelf: {
            withDoctorName:
              "We'll talk to {{doctorName}} about your switch. Confirm how you would like the prescription filled, below.",
            withoutDoctorName:
              "We'll talk to your doctor about your switch. Confirm how you would like the prescription filled, below.",
          },
          forFamily: {
            withDoctorName:
              "We’ll talk to {{doctorName}} about <span data-private>{{memberName}}</span>'s switch. Confirm how you would like the prescription filled, below.",
            withoutDoctorName:
              "We’ll talk to <span data-private>{{memberName}}</span>'s doctor about their switch. Confirm how you would like the prescription filled, below.",
          },
        },
        requestDeliveryCopy:
          'Get your prescription shipped to your door by choosing one of the options below.',
        genericChange: 'Confirm how you would like the prescription filled, below.',
      },
      confirmBtn: 'Confirm',
      changePharmacyBtn: 'Change Pharmacy',
      disclaimer:
        'These prices are personalized estimates and may not reflect the actual total price. The estimates are based upon your prescription drug plan as of today. Due to discounts or charges a particular pharmacy may apply actual cost may differ. Please do not rely exclusively on this estimate alone to make your purchasing decision.',
      pharmaciesListLbl: 'Nearby Pharmacies:',
      shippingCopy: {
        shippingOptionsLbl: 'Shipping Options:',
        cardLabel: 'Ships to you',
        pharmacies: {
          base: 'Conveniently ships to you',
          withInsurance: 'Cost With Insurance',
        },
        medicaidNote:
          'The cost without insurance option is not related to your insurance and in some instances cannot be used in conjunction with any federal or state funded program, such as Medicare or Medicaid.',
        shippingFeesNote: 'Additional shipping and processing fees may apply.',
      },
      toolTip: {
        cash: "This option doesn't use your health insurance, so the amount you pay may not count toward your deductible. Depending on your plan, you might be able to submit a separate claim to your health insurance company.",
        insurance:
          'This option uses your health insurance, so the amount you pay will be applied to your deductible.',
      },
    },
    pharmacistNote: {
      stone: {
        name: 'Dr. Nikki Stone, PharmD',
        jobDetails: 'Licensed Pharmacist Since 2004',
        imageAltText: 'Dr. Nikki Stone',
      },
      green: {
        name: 'Dr. Jeff Green, PharmD',
        jobDetails: 'Licensed Pharmacist Since 2004',
        imageAltText: 'Dr. Jeff Green',
      },
      note: {
        TA: {
          forSelf: `"{{drugName}} is a common alternative to your current treatment and is safe and effective."`,
          forFamily: `"{{drugName}} is a common alternative to <span data-private>{{memberName}}</span>'s current treatment and is safe and effective."`,
        },
        DF: {
          forSelf: `"{{drugName}} is just a different form of the active ingredient in your current medication and is safe and effective for most patients."`,
          forFamily: `"{{drugName}} is just a different form of the active ingredient in <span data-private>{{memberName}}</span>'s current medication and is safe and effective for most patients."`,
        },
        TS: {
          forSelf: `"By splitting a higher-dose tablet in half, you can get the same dose you're currently taking and save money."`,
          forFamily: `"By splitting a higher-dose tablet in half, <span data-private>{{memberName}}</span>'s can get the same dose they're currently taking and save money."`,
        },
        GS: {
          forSelf: `"This is a generic version of your current medication using the same active ingredients."`,
          forFamily: `"This is a generic version of <span data-private>{{memberName}}</span>'s current medication using the same active ingredients."`,
        },
        GC: {
          forSelf: `"This is a generic option identical to your current medication."`,
          forFamily: `"This is a generic option identical to <span data-private>{{memberName}}</span>'s current medication."`,
        },
      },
    },
  },
  logoAltText: 'RxSS Logo',
  tooltipIconAltText: 'Info',
  costWithoutInsurance: 'Cost Without Insurance',
};
