/* @flow */

import { baseAxios, CancelToken, isCancel } from './baseAxios';
import Interceptors from './interceptors';

// region custom types
/**
 * The structure of the configuration object passed to the Axios constructor
 * @typedef {Object} ConfigObject
 * @property {baseAxios} baseAxios - the axios instance used to make calls
 * @property {Function} isCancel - the function used to determine if an error is an axios cancel error
 * @property { CancelToken } - the token used to allow call cancellation
 * @property { Interceptors } interceptors - the interceptors object used to handle HTTP Error codes
 */
type ConfigObject = {
  baseAxios: baseAxios,
  isCancel: Function,
  CancelToken: CancelToken,
  interceptors: Interceptors,
};
/**
 * The base structure for a key/value pairs for POST and PUT request payloads
 * @typedef {{[key: string] | number }} HttpPayload
 */
type HttpPayload = { [key: string]: string | number };

/**
 * The available HTTP verbs for making calls with
 * @typedef {string} HttpVerbs
 */
type HttpVerbs = 'get' | 'post' | 'put' | 'delete';

/**
 * The options used to configure a makeRequest() call
 * @typedef {Object} MakeRequestConfig
 * @property {HttpVerbs} verb - the particular HTTP verb being invoked
 * @property {string} url - the url being hit
 * @property {HttpPayload} [data] - the optional data payload being sent in POST and PUT requests
 */

type MakeRequestConfig = {
  verb: HttpVerbs,
  url: string,
  data?: HttpPayload,
};
/**
 * The return value of makeRequest (and thus also the verb methods), returning either only a Promise
 * or an array of a Promise and a cancel function, depending on whether or not isCancellable is true
 * or false
 * @typedef {Promise | [Promise, Function]} OptionallyCancellableResponse
 */
type OptionallyCancellableResponse<Response> = Promise<Response> | [Promise<Response>, Function];
/**
 * @description a helper class for making axios-based ajax calls to our api
 * @tutorial https://rxsavings.atlassian.net/wiki/spaces/MP/pages/1749221492/Custom+Axios+Implementation+Module
 */
// endregion

class Axios {
  /**
   * @description Instantiate the class by injecting configuration items and binding relevant methods
   * @param {Object} config
   * @param {baseAxios} config.baseAxios
   * @param {Function} config.isCancel
   * @param {Object} config.cancelToken
   * @param {Interceptors} config.interceptors
   */
  constructor(config: ConfigObject) {
    this.axios = config.baseAxios;
    this.isCancel = config.isCancel;
    this.CancelToken = config.CancelToken;
    this.interceptors = config.interceptors;
    this.cache = {};
    this.reset = this.reset.bind(this);
    this.makeRequest = this.makeRequest.bind(this);
    this.get = this.get.bind(this);
    this.post = this.post.bind(this);
    this.put = this.put.bind(this);
    this.delete = this.delete.bind(this);
    this.reset();
  }

  // region PRIVATE IMPLEMENTATION PROPERTIES
  axios: baseAxios;

  requestURL: string;

  interceptors: Interceptors;

  cache: {};

  // endregion

  // region PRIVATE CALL CONFIGURATION PROPERTIES
  config = {};

  isCancellable: boolean;

  isVerbose: boolean;

  checkAuthorization: boolean;

  maxCacheAge: number;
  // endregion

  // region CHAINABLE CALL CUSTOMIZATION METHODS
  /**
   * @description This sets the config configuration object to the passed value, with a default of an empty object
   * @param {Object} config
   * @returns {Axios}
   */
  setConfiguration(config: any = {}): Axios {
    this.config = config;
    return this;
  }

  /**
   * @description This sets the isCancellable configuration option to the passed value, with a default of false
   * @param {boolean} isCancellable
   * @returns {Axios}
   */
  setIsCancellable(isCancellable: boolean = false): Axios {
    this.isCancellable = isCancellable;
    return this;
  }

  /**
   * @description This sets the isVerbose config option to the passed value, with a default of true
   * @param {boolean} isVerbose
   * @returns {Axios}
   */
  setIsVerbose(isVerbose: boolean = true): Axios {
    this.isVerbose = isVerbose;
    return this;
  }

  /**
   * @description This sets the checkAuthorization configuration option to the passed value, with a default of false
   * @param {boolean} checkAuthorization
   * @returns {Axios}
   */
  setCheckAuthorization(checkAuthorization: boolean = false): Axios {
    this.checkAuthorization = checkAuthorization;
    return this;
  }

  /**
   * @description This sets the maxCacheAge for the call being made, with a default value of 1800 (30 minutes)
   * @param {number} maxAge
   * @returns {Axios}
   */
  setMaxCacheAge(maxAge: number = 1800): Axios {
    this.maxCacheAge = maxAge;
    return this;
  }

  /**
   * @description This is a function for removing entities from the cache. If a url is passed in, only that entity will
   * be removed. If no url is passed, however, the entire cache will be cleared out.
   * @param {string|boolean} url
   * @returns void
   */
  clearCache(url = false): void {
    if (url) {
      delete this.cache[url];
    } else {
      this.cache = {};
    }
  }
  // endregion

  // region PRIVATE UTILITY METHODS
  /**
   * @description This function resets all configuration options back to their default values
   * @returns {void}
   */
  reset(): void {
    this.config = {};
    this.isCancellable = false;
    this.maxCacheAge = -1;
    this.isVerbose = true;
    this.checkAuthorization = false;
    this.requestURL = '';
  }

  /**
   * @description Returns the UNIX timestamp, used for cache management
   * @returns {number}
   */
  getTime(): number {
    return Math.floor(new Date().getTime() / 1000);
  }

  /**
   * @description This takes the raw promise returned by the underlying axios ajax call and applies
   * a number of transformations. These include:
   *   - extracting the data property if isVerbose is false
   *   - dispatching errors with statuses to any matching interceptors
   *   - resetting the configuration options
   * @param {Promise} promise
   * @returns {Promise}
   */
  processPromise(promise: Promise): Promise {
    const currentOptions = {
      isVerbose: this.isVerbose,
      isCancellable: this.isCancellable,
      config: { ...this.config },
      checkAuthorization: this.checkAuthorization,
      requestURL: this.requestURL,
    };
    this.reset();
    return promise
      .then((response) => (currentOptions.isVerbose ? response : response.data))
      .catch((error) => {
        this.clearCache(this.requestURL);
        if (this.isCancel(error)) {
          return Promise.reject(error);
        }
        if (
          error.response &&
          error.response.status &&
          typeof this.interceptors[error.response.status] === 'function'
        ) {
          return Promise.reject(this.interceptors[error.response.status](error, currentOptions));
        }
        return Promise.reject(error);
      })
      .then((response) => {
        return response;
      });
  }

  /**
   * @description This makes an axios request using the provided verb with the provided url and optional data object,
   * then configures the resulting promise based on the configuration settings, and returns it either
   * alone or along with a cancel function, depending on how the call was configured
   * @param {HttpVerbs} verb
   * @param {string} url
   * @param {HttpPayload} data
   * @returns {OptionallyCancellableResponse}
   */
  makeRequest({ verb, url, data = {} }: MakeRequestConfig): OptionallyCancellableResponse {
    this.requestURL = url;
    const { isCancellable } = this;
    const noDataVerbs = ['get', 'delete'];
    const cancelSource = this.CancelToken.source();
    const cancel = cancelSource.cancel.bind(cancelSource);
    const config = { ...this.config, cancelToken: cancelSource.token };
    const args = noDataVerbs.includes(verb) ? [url, config] : [url, data, config];
    const rawPromise = this.axios[verb](...args);
    if (verb === 'get') {
      this.cache[url] = {
        time: this.getTime(),
        promise: rawPromise,
        cancel,
      };
    }
    const promise = this.processPromise(rawPromise);
    return isCancellable ? [promise, cancel] : promise;
  }
  // endregion

  // region HTTP VERB METHODS
  /**
   * @description This makes a makeRequest() invocation with `get` as the verb and the passed url, after checking to make
   * sure that there is no valid cached promise for that get request.
   * @param {string} url
   * @returns {OptionallyCancellableResponse}
   */
  get<Response>(url: string): OptionallyCancellableResponse<Response> {
    const cachedCall = this.cache[url];
    const { isCancellable } = this;
    /*
     * A cache is valid if:
     * 1) there exists an entry for the url being checked (i.e. cachedCall is not null)
     * 2) the `promise` property of the cached call is an instance of a promise
     * 3) the time the cached call was made, plus the maxCacheAge, is greater than the current time (meaning it isn't stale)
     */
    const isValidCache =
      !!cachedCall &&
      cachedCall.promise instanceof Promise &&
      cachedCall.time + this.maxCacheAge > this.getTime();

    if (isValidCache) {
      const { promise, cancel } = cachedCall;
      const processedPromise = this.processPromise(promise);
      return isCancellable ? [processedPromise, cancel] : processedPromise;
    }
    return this.makeRequest({ verb: 'get', url });
  }

  /**
   * @description This makes a makeRequest() invocation with `post` as the verb and the passed url and optional data object
   * @param {string} url
   * @param {HttpPayload} data
   * @returns {OptionallyCancellableResponse}
   */
  post(url: string, data: HttpPayload = {}): OptionallyCancellableResponse {
    return this.makeRequest({ verb: 'post', url, data });
  }

  /**
   * @description This makes a makeRequest() invocation with `put` as the verb and the passed url and optional data object
   * @param {string} url
   * @param {HttpPayload} data
   * @returns {OptionallyCancellableResponse}
   */
  put(url: string, data: HttpPayload = {}): OptionallyCancellableResponse {
    return this.makeRequest({ verb: 'put', url, data });
  }

  /**
   * @description This makes a makeRequest() invocation with `delete` as the verb and the passed url
   * @param {string} url
   * @returns {OptionallyCancellableResponse}
   */
  delete(url: string): OptionallyCancellableResponse {
    return this.makeRequest({ verb: 'delete', url });
  }
  // endregion
}
const axios = new Axios({
  baseAxios,
  isCancel,
  CancelToken,
  interceptors: new Interceptors(baseAxios),
});
export { Axios };
export default axios;
