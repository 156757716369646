/* @flow */

import { observable, action } from 'mobx';

import { UserMessage } from '../../aliases/UserMessage';

const ErrorMessage: UserMessage = {
  type: 'danger',
  message: 'There was an error processing your request.',
  name: 'ERROR',
};

class UserMessageStore {
  @observable messages: Array<UserMessage> = [];

  @observable activeMessage: Object;

  @observable show: boolean;

  @action
  close() {
    this.show = false;
  }

  @action
  open(name: string) {
    this.activeMessage = this.fetchMessage(name);
    this.show = true;
  }

  @action
  registerMessage(userMessage: UserMessage | Array<UserMessage>) {
    this.messages = this.messages.concat(userMessage);
  }

  @action
  fetchMessage(name: string): UserMessage {
    const messageFound = this.messages.filter((message) => {
      return message.name === name;
    });
    if (messageFound.length) {
      return messageFound[0];
    }

    return ErrorMessage;
  }
}

const userMessageStore = new UserMessageStore();

export default userMessageStore;
export { UserMessageStore };
