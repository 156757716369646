/* @flow */

import { observable } from 'mobx';

class HomePageStore {
  @observable loaded: boolean;

  gatekeeperStore: Object;

  memberStore: Object;

  apiBase: String;

  constructor(gatekeeperStore: Object, memberStore: Object) {
    this.loaded = false;
    this.gatekeeperStore = gatekeeperStore;
    this.memberStore = memberStore;
    this.apiBase = window.api_url || global.api_url;
  }

  async init() {
    if (this.gatekeeperStore && !this.memberStore.currentMember) {
      const family = await this.memberStore.initMember(this.gatekeeperStore.config.member_id);
      if (family) {
        this.loaded = true;
      }
    }

    this.loaded = true;
  }
}

const homePageStore = new HomePageStore();

export default homePageStore;
export { HomePageStore };
