/* @flow */

import { action, computed, observable, toJS } from 'mobx';
import { suggestionApi } from '@data/api';
import {
  addBestSavingsTagToPharmacy,
  addThreeMonthSupplyTagToPharmacy,
  formatMoney,
  getClientConfigurationValue,
  getDistance,
  positionHomeDeliveryPharmacies,
} from '@utils/Functions';
import { clientConfigurationKeys } from '@utils/Functions/getClientConfigurationValue/clientConfigurationKeys';
import {
  addNoPriceAvailableTagToPharmacies,
  hasAdjudilitePriceBlocker,
} from '@utils/Functions/addNoPriceAvailableTagToPharmacies/addNoPriceAvailableTagToPharmacies';
import type { BaseSuggestion } from '@features/savings/types/Suggestion';
import { GatekeeperStore } from '@stores_old/gatekeeperStore/gatekeeperStore';
import { MemberStore } from '@stores_old/memberStore/memberStore';
import type { PricedPharmacy, FormattedPharmacy } from '@features/savings/types/Pharmacy';
import {
  doesPharmacyListContainCashPharmacy,
  isPharmacyCashPricing,
} from '@utils/Functions/cashPricingHelpers/cashPricingHelpers';

/**
 * The default 1-index based position where Rx Home Delivery should appear within the pharmacy list. This value will
 * be used if a specific value cannot be retrieved from client configuration.
 */
const DEFAULT_PHARMACY_LIST_RX_HOME_DELIVERY_POSITION: number = 2;

const DEFAULT_PHARMACY_LIST_TRUNCATED_LENGTH: number = 3;

class CouponSuggestionsStore {
  memberStore: MemberStore;

  gatekeeperStore: GatekeeperStore;

  @observable product: Object;

  @observable suggestions: Array<BaseSuggestion>;

  @observable selectedSuggestionId: number | null;

  @observable selectedPharmacyId: number | null;

  @observable preferredPharmacyId: number;

  @observable selectedPricing: string;

  @observable userPreferredPharmacyId: number;

  @observable loading: boolean;

  @observable loaded: boolean;

  @observable sourceInfo: Object;

  @observable sourceType: string;

  constructor(memberStore: MemberStore, gatekeeperStore: GatekeeperStore) {
    this.product = {};
    this.suggestions = [];
    this.selectedSuggestionId = null;
    this.selectedPharmacyId = null;
    this.loading = true;
    this.loaded = false;
    this.sourceInfo = {};
    this.memberStore = memberStore;
    this.gatekeeperStore = gatekeeperStore;
    this.priceKey = 'per_refill';
    this.setPriceKey = this.setPriceKey.bind(this);
    this.doneLoading = this.doneLoading.bind(this);
    this.sortPharmacyByMemberAndPlanPerDayPrice =
      this.sortPharmacyByMemberAndPlanPerDayPrice.bind(this);
    this.sortPharmacyByMemberPriceAndDistance =
      this.sortPharmacyByMemberPriceAndDistance.bind(this);
    this.decoratePharmacies = this.decoratePharmacies.bind(this);
    this.floatPharmacyBy = this.floatPharmacyBy.bind(this);
    this.addDistance = this.addDistance.bind(this);
    this.setBlockedPricesToNull = this.setBlockedPricesToNull.bind(this);
  }

  @action
  getSuggestions(member_uid, data, currentAlert) {
    this.loaded = false;
    const flaggedData = { ...data, 'with-coupons': true };

    if (data.suggestion_id) {
      this.suggestions = this.transformAndDecorateSuggestions([
        currentAlert.express_suggestion.suggestion,
      ]);
      this.product = currentAlert.medication_claim.package.product;
      this.loaded = true;
      this.priceKey = 'per_refill';
      this.doneLoading();
      this.setSelectedSuggestion(Number(data.suggestion_id));
    } else {
      this.loading = suggestionApi
        .getAll(member_uid, flaggedData)
        .then(({ suggestions, product }) => {
          this.suggestions = this.transformAndDecorateSuggestions(suggestions);
          this.product = product;
          this.loaded = true;
          this.priceKey = 'per_refill';
          window.setTimeout(() => {
            this.doneLoading();
          }, 1700);
        });
    }
    return this.loading;
  }

  @action doneLoading() {
    this.loading = false;
  }

  @action setSourceInfo(info, type) {
    this.sourceInfo = info ?? {};
    this.sourceType = type ?? '';
  }

  @computed get sourceInfoDrugDetails() {
    try {
      if (this.sourceType === 'claim') {
        const drugInfo = this.sourceInfo.alertObject.medication_claim.package.product;

        return {
          name: drugInfo.name.name,
          strength: drugInfo.strength,
          dispensable_unit: drugInfo.dispensable_unit,
          id: drugInfo.id,
        };
      }

      if (this.sourceType === 'search') {
        return {
          name: this.sourceInfo.productName,
          strength: this.sourceInfo.dosage,
          dispensable_unit: this.sourceInfo.label,
          id: this.sourceInfo.productId,
        };
      }

      throw new TypeError(
        `Could not determine sourceInfoDrugDetails for sourceType ${this.sourceType}`,
      );
    } catch (e) {
      Rollbar.error(e);
      return {};
    }
  }

  @action reset() {
    this.suggestions = [];
    this.selectedSuggestionType = null;
    this.selectedSuggestionId = null;
    this.selectedPharmacyId = null;
    this.loading = true;
    this.loaded = false;

    this.setSourceInfo({}, '');
  }

  @action
  /**
   * Sets the selected suggestion and selected suggestion id
   */
  setSelectedSuggestion(id: number) {
    if (typeof id !== 'number' || Number.isNaN(id)) {
      Rollbar.error(
        'setSelectedSuggestion called with incorrect type. Only accepts number parameters.',
      );
    }
    this.selectedSuggestionId = id;
  }

  @computed get selectedSuggestion(): ?BaseSuggestion {
    try {
      if (!this.selectedSuggestionId) {
        Rollbar.error(`Cannot get selectedSuggestion because selectedSuggestionId is not set`);
        return undefined;
      }
      const { selectedSuggestionId } = this;
      const foundSuggestion = this.suggestions.find(
        (suggestion) => suggestion.id === selectedSuggestionId,
      );
      if (!foundSuggestion) {
        Rollbar.error(
          `selectedSuggestionId ${selectedSuggestionId.toString()} not found in the suggestions array of length ${
            this.suggestions.length
          }`,
        );
      }
      return foundSuggestion;
    } catch (err) {
      Rollbar.error(err);
      return null;
    }
  }

  @action setPreferredPharmacyId(id) {
    this.preferredPharmacyId = id;
  }

  @action setUserPreferredPharmacyId(id) {
    this.userPreferredPharmacyId = id;
  }

  @computed get preferredPharmacy() {
    try {
      return this.selectedSuggestion.pharmacies.find(
        (pharm) => pharm.id === this.preferredPharmacyId,
      );
    } catch (err) {
      return {};
    }
  }

  @computed get userPreferredPharmacy() {
    try {
      return this.selectedSuggestion.pharmacies.find(
        (pharm) => pharm.id === this.userPreferredPharmacyId,
      );
    } catch (err) {
      return {};
    }
  }

  @computed get bestPricePharmacy() {
    const bestPricePharm =
      this.selectedPricing === 'mail_order'
        ? this.selectedSuggestion.pharmacies
            .filter(
              (pharmacy) => pharmacy.type.label !== 'retail' || !!pharmacy.home_delivery_provider,
            )
            .filter((pharmacy) => !!pharmacy.prices[this.pricingKey])
            .sort(this.sortPharmacyByMemberAndPlanPerDayPrice)[0]
        : this.selectedSuggestion.pharmacies
            .filter(
              (pharmacy) => pharmacy.type.label === 'retail' || !!pharmacy.home_delivery_provider,
            )
            .filter((pharmacy) => !!pharmacy.prices[this.pricingKey])
            .map(this.addDistance)
            .sort(this.sortPharmacyByMemberPriceAndDistance)[0];

    if (
      this.preferredPharmacy &&
      this.preferredPharmacy.prices[this.pricingKey] &&
      this.preferredPharmacy.type.label === bestPricePharm.type.label &&
      bestPricePharm.prices[this.pricingKey].cost_per_refill ===
        this.preferredPharmacy.prices[this.pricingKey].cost_per_refill
    )
      return this.preferredPharmacy;
    if (
      this.userPreferredPharmacy &&
      this.userPreferredPharmacy.type.label === bestPricePharm.type.label &&
      bestPricePharm.prices[this.pricingKey].cost_per_refill ===
        this.userPreferredPharmacy.prices[this.pricingKey].cost_per_refill
    )
      return this.userPreferredPharmacy;
    return this.selectedSuggestion.pharmacies.find((pharm) => pharm.id === bestPricePharm.id);
  }

  @computed get bestPricePharmacyId(): ?number {
    return this.bestPricePharmacy.id;
  }

  @computed
  get activePreferredPharmacyNcpdp() {
    try {
      return this.memberStore.activeMember.preferred_retail_pharmacy.ncpdp;
    } catch (error) {
      Rollbar.error(error);
      return undefined;
    }
  }

  @computed
  get activeUserPreferredPharmacyId() {
    try {
      return this.memberStore.activeMember.subgroup.preferred_pharmacy_id;
    } catch (error) {
      Rollbar.error(error);
      return undefined;
    }
  }

  /**
   * Provides a new copy of the pharmacy object with a "tags" property used to show the users different features of
   * this pharmacy.
   *
   * @param {Object} pharmacy - An individual object matching the structure of a pharmacy.
   * @returns {Object} - A copy of the original pharmacy with an added "tag" property.
   */
  addTagsToPharmacies = (pharmacy) => {
    const { tags = [], home_delivery_provider, ncpdp } = pharmacy;

    if (!!home_delivery_provider) {
      tags.push({ type: 'primary', text: 'home-delivery' });
    }
    if (ncpdp === this.activePreferredPharmacyNcpdp) {
      tags.push({ type: 'primary', text: 'primary-pharmacy' });
    }

    return {
      ...pharmacy,
      tags,
    };
  };

  floatPharmacyBy(key) {
    return (a, b) => {
      if ([a.id, a.ncpdp].indexOf(this[key]) > -1) {
        return -1;
      } else if ([b.id, b.ncpdp].indexOf(this[key]) > -1) {
        return 1;
      }
      return 0;
    };
  }

  /**
   * A comparator function to compare two pharmacies by member price and distance. Intended for use with the Array#sort
   * function.
   *
   * @param {Object} a - A pharmacy to compare
   * @param {Object} b - A pharmacy to compare
   * @returns {number} - 1, 0, or -1 based on whether the first or second pharmacy should come first in a sorted list.
   */
  sortPharmacyByMemberPriceAndDistance(a, b) {
    // Always put pharmacies with valid prices before those with null prices
    if (a.prices[this.pricingKey].cost_per_day === null) {
      return 1;
    }

    if (b.prices[this.pricingKey].cost_per_day === null) {
      return -1;
    }

    const diff = a.prices[this.pricingKey].cost_per_day - b.prices[this.pricingKey].cost_per_day;
    const distanceDiff = !!a.distance && !!b.distance && a.distance - b.distance;

    return diff === 0 ? distanceDiff : diff;
  }

  /**
   * A comparator function to compare two pharmacies by member and plan prices per day. Intended for use with the
   * Array#sort function.
   *
   * @param {Object} a - A pharmacy to compare
   * @param {Object} b - A pharmacy to compare
   * @returns {number} - 1, 0, or -1 based on whether the first or second pharmacy should come first in a sorted list.
   */
  sortPharmacyByMemberAndPlanPerDayPrice(a, b) {
    const {
      cost_per_day: aPerDay,
      attributes: { plan_pay_per_day: aPlanPerDay },
    } = a.prices[this.pricingKey];
    const {
      cost_per_day: bPerDay,
      attributes: { plan_pay_per_day: bPlanPerDay },
    } = b.prices[this.pricingKey];

    // Always put pharmacies with valid prices before those with null prices
    if (aPerDay === null) {
      return 1;
    }

    if (bPerDay === null) {
      return -1;
    }

    return aPerDay - bPerDay === 0 ? aPlanPerDay - bPlanPerDay : aPerDay - bPerDay;
  }

  /**
   * This function takes a pharmacy object and adds flags saying if it is a mail order pharmacy or
   * not, whether its id matches one of the special pharmacy ids (plan preferred, user preferred,
   * or best price) and flags it accordingly.
   *
   * @param {Object} pharmacy - The unflagged pharmacy object.
   * @returns {Object} - The newly flagged pharmacy object.
   */
  flagPharmacies = (pharmacy) => {
    return {
      ...pharmacy,
      isMailOrder: pharmacy.type.label !== 'retail',
      isPlanPreferred: pharmacy.id === this.activeUserPreferredPharmacyId,
      isMemberPreferred: pharmacy.ncpdp === this.activePreferredPharmacyNcpdp,
      isBestPrice: pharmacy.id === this.bestPricePharmacyId,
    };
  };

  addDistance(pharmacy) {
    return {
      ...pharmacy,
      distance: getDistance(pharmacy.address, this.memberStore.currentMember.address),
    };
  }

  @computed
  get mailOrderPharmaciesList(): Array<PricedPharmacy> {
    if (!this.selectedSuggestionId) {
      return [];
    }
    let sortedPharmacies = this.selectedSuggestion.pharmacies
      .filter((pharmacy) => pharmacy.type.label !== 'retail' || !!pharmacy.home_delivery_provider)
      .filter((pharmacy) => !!pharmacy.prices[this.pricingKey])
      .map(this.flagPharmacies)
      .sort(this.sortPharmacyByMemberPriceAndDistance)
      .sort(this.floatPharmacyBy('activeUserPreferredPharmacyId'))
      .sort(this.floatPharmacyBy('activePreferredPharmacyNcpdp'));

    sortedPharmacies = addThreeMonthSupplyTagToPharmacy(sortedPharmacies, this.pricingKey);

    return positionHomeDeliveryPharmacies({
      sortedPharmacies,
      position: this._getRxHomeDeliveryListPosition(),
    });
  }

  @computed
  get retailPharmaciesList(): Array<PricedPharmacy> {
    if (!this.selectedSuggestionId) {
      return [];
    }

    let sortedPharmacies = this.selectedSuggestion.pharmacies
      .filter((pharmacy) => pharmacy.type.label === 'retail' || !!pharmacy.home_delivery_provider)
      .filter((pharmacy) => !!pharmacy.prices[this.pricingKey])
      .map(this.addDistance)
      .sort(this.sortPharmacyByMemberPriceAndDistance)
      .sort(this.floatPharmacyBy('bestPricePharmacyId'))
      .sort(this.floatPharmacyBy('activeUserPreferredPharmacyId'))
      .sort(this.floatPharmacyBy('activePreferredPharmacyNcpdp'));

    addNoPriceAvailableTagToPharmacies(sortedPharmacies, this.pricingKey);

    sortedPharmacies = positionHomeDeliveryPharmacies({
      sortedPharmacies,
      position: this._getRxHomeDeliveryListPosition(),
    });

    return addBestSavingsTagToPharmacy({
      pharmacies: sortedPharmacies,
      pricingMethod: this.pricingKey,
      memberSetPrimaryPharmacyNcpdp: this.activePreferredPharmacyNcpdp,
      planPreferredPharmacyId: this.activeUserPreferredPharmacyId,
    });
  }

  /**
   * This will return a shortened array of pharmacies derived from the sortedPharmacyList. The list
   * will be shortened if all of the following are true:
   *   - isTruncated is true and
   *   - the list is longer than the default truncated list length
   *
   * In addition to this, if the 3rd entry in the sortedPharmacyList has the `isBestPrice` flag then
   * the length the list will be truncated to will be increased by one. This is because we want to
   * ensure that the best priced pharmacy shows up in the list, and if it is at the #3 position then
   * it would normally be pushed off the edge of the trimmed list.
   *
   * @param {boolean} isTruncated - A flag that says whether or not to trim the list
   * @returns {Array} - The (optionally truncated) list of pharmacies.
   */
  getTrimmedPharmacyList(isTruncated) {
    let listLength = this.sortedPharmacyList.length;
    if (isTruncated && this.sortedPharmacyList.length > DEFAULT_PHARMACY_LIST_TRUNCATED_LENGTH) {
      listLength = DEFAULT_PHARMACY_LIST_TRUNCATED_LENGTH;
      if (this.sortedPharmacyList[2].isBestPrice) {
        listLength += 1;
      }
    }
    return this.sortedPharmacyList.slice(0, listLength);
  }

  @computed
  get sortedPharmacyList() {
    return this.selectedPricing === 'mail_order'
      ? this.mailOrderPharmaciesList
      : this.retailPharmaciesList;
  }

  transformAndDecorateSuggestions = (suggestions) => {
    return suggestions.map((suggestion) => {
      return {
        ...suggestion,
        pharmacies: this.decoratePharmacies(suggestion.pharmacies),
      };
    });
  };

  /**
   * Decorates pharmacy information to be attached to the suggestion object.
   * @param pharmacies The list of pharmacies.
   * @throws TypeError If the given pharmacies list is undefined or null.
   * @returns {*} A mapped list of pharmacy objects with all decorated info provided.
   */
  decoratePharmacies = (pharmacies) => {
    if (pharmacies) {
      return pharmacies
        .map(this.addTagsToPharmacies)
        .map(this.addDistance)
        .map(this.setBlockedPricesToNull);
    }
    throw new TypeError('A suggestion is missing pharmacies info.');
  };

  @action.bound
  setSelectedPharmacy(id: ?number) {
    this.selectedPharmacyId = parseInt(id);
  }

  @action.bound
  setSelectedPharmacyByNcpdp(ncpdp: ?string | ?number) {
    try {
      if (!ncpdp) {
        throw new Error(`Cannot setSelectedPharmacyByNcpdp: ncpdp passed invalid value: ${ncpdp}`);
      }

      const ncpdpInt = parseInt(ncpdp);
      if (this.selectedSuggestion) {
        const suggestion = this.selectedSuggestion;
        const foundPharmacy = suggestion.pharmacies.find(
          (pharmacy) => parseInt(pharmacy.ncpdp) === ncpdpInt,
        );
        if (foundPharmacy) {
          this.selectedPharmacyId = foundPharmacy.id;
        } else {
          throw new Error(
            `Cannot setSelectedPharmacyByNcpdp: Pharmacy with ncpdp ${ncpdp} was not found on suggestion ${suggestion.id}`,
          );
        }
      } else {
        throw new Error('Cannot setSelectedPharmacyByNcpdp: no selectedSuggestion was found');
      }
    } catch (error) {
      Rollbar.error(error);
    }
  }

  @action.bound resetSelectedPharmacy() {
    this.selectedPharmacyId = null;
  }

  @computed
  get selectedPharmacy(): ?PricedPharmacy | {||} {
    try {
      if (!this.selectedSuggestion) {
        throw Error('Cannot select pharmacy because no suggestion is selected');
      }
      const filtered = this.selectedSuggestion.pharmacies.find((pharmacy) => {
        return (
          pharmacy.id ===
          (this.selectedPharmacyId ? this.selectedPharmacyId : this.bestPricePharmacyId)
        );
      });
      return filtered
        ? filtered
        : this.selectedSuggestion?.pharmacies.find(
            (pharm) => pharm.ncpdp === this.sortedPharmacyList[0].ncpdp,
          );
    } catch (err) {
      Rollbar.error(err);
      return {};
    }
  }

  @computed // returns a list of products with prices available at the selected pharmacy
  get selectedPharmacyProducts() {
    try {
      return this.selectedPharmacy.suggested_products;
    } catch (err) {
      return undefined;
    }
  }

  @computed
  get selectedPharmacyPlanInfo() {
    const pricing = this.selectedPharmacy.prices[Object.keys(this.selectedPharmacy.prices)[0]];
    const { attributes, cost_per_refill } = pricing;
    const { deductible, out_of_pocket, plan_pay } = attributes;
    const is_pbm_max_cost = this.selectedPharmacyProducts.reduce(
      (is_pbm_max_cost, product) =>
        is_pbm_max_cost ||
        (product.prices.adjudilite &&
          product.prices.adjudilite[0] &&
          product.prices.adjudilite[0].attributes.is_pbm_max_cost),
      false,
    );
    try {
      const planInfo = {
        cost: cost_per_refill,
        costDetails: {
          items: [
            {
              amount:
                plan_pay === null || cost_per_refill === null ? null : plan_pay + cost_per_refill,
              label: 'Negotiated Price:',
            },
            { amount: plan_pay, label: 'Plan Pays:' },
          ],
        },
        planBreakdown: {
          items: [
            {
              abr: 'AD',
              amount: deductible.applied,
              label: 'Applied to Deductible:',
            },
            {
              abr: 'DR',
              amount: deductible.remaining,
              label: 'Deductible Remaining:',
            },
            {
              abr: 'AO',
              amount: out_of_pocket.applied,
              label: 'Applied to Out-of-Pocket Max:',
            },
            {
              abr: 'OR',
              amount: out_of_pocket.remaining,
              label: 'Out-of-Pocket Max Remaining:',
            },
          ],
        },
        is_pbm_max_cost,
        products: this.selectedPharmacyProducts,
        quantity: null,
      };
      return planInfo;
    } catch (err) {
      return undefined;
    }
  }

  @computed
  get selectedPharmacyVoucherInfo() {
    let voucherInfo = null;
    const pharmacyInfo = this.selectedPharmacy;
    if (this.selectedSuggestion && this.selectedPricing === 'coupon') {
      const pharmacy = this.selectedPharmacy;
      if (pharmacy) {
        const products = pharmacy.suggested_products;

        const attrs = products[0].prices.coupon[0].attributes;
        const isPlan = !attrs.pcn && !attrs.bin && !attrs.member_id && !attrs.group_id;

        if (!isPlan) {
          voucherInfo = products.map((product) => {
            const { attributes, cost_per_refill, quantity, day_supply } = product.prices.coupon[0];
            const voucherAttrs = this.getVoucherInfo(attributes);
            return {
              productWithPackage: product,
              pharmacy: pharmacyInfo,
              cost: cost_per_refill,
              quantity,
              daySupply: day_supply,
              customerCall: '(800)-268-4476',
              voucherInfo: voucherAttrs,
            };
          });
        }
      }
    }
    return voucherInfo;
  }

  getVoucherInfo(attributes: Object): Array<Object> {
    const { pcn, bin, member_id, group_id } = attributes;
    return [
      {
        label: 'rx bin',
        value: bin,
      },
      {
        label: 'rx pcn',
        value: pcn,
      },
      {
        label: 'rx grp',
        value: group_id,
      },
      {
        label: 'mem id',
        value: member_id,
      },
    ];
  }

  // NEW STUFF

  priceIsNull(price) {
    return !price
      ? true
      : typeof price.cost_per_day !== 'number' && typeof price.cost_per_refill !== 'number';
  }

  @action
  setSelectedPricing(newPricing, newId) {
    this.selectedSuggestionId = newId;
    this.selectedPricing = newPricing;
  }

  /**
   * @private
   * formats best prices per suggestion
   *
   * @param {Object} best_prices - from a given suggestion holds coupon, mail, and retail information
   * @param {Boolean} mfc - whether or not it is a Manufacturer's Coupon
   * @param {Array} coverage_alerts - list of coverage alerts per suggestion
   */
  _formatBestPrices(best_prices, mfc, coverage_alerts) {
    switch (this.pricingMethod) {
      case 'insurance':
        return this._formatBestPricesInsurance(best_prices, mfc, coverage_alerts);
      case 'coupon':
        return this._formatBestPricesCoupon(best_prices, coverage_alerts);
      case 'both':
        return this._formatBestPricesBoth(best_prices, mfc, coverage_alerts);
    }
  }

  _formatBestPricesBoth(best_prices, mfc, coverage_alerts) {
    const prices = [];
    const { retail, coupon } = best_prices;
    if (mfc) {
      prices.push(
        this._getManufacturerCoupon(retail),
        this._getNoPrices('mail-order'),
        this._getNoPrices('retail'),
      );
    } else {
      prices.push(...this._formatBestPricesInsurance(best_prices, false, coverage_alerts));
      prices.push(coupon ? this._getCoupon(coupon) : this._getNoPrices('retail'));
    }
    return prices;
  }

  /**
   * @private
   * Pick the right coverage alert to show when pricing data is not available for a suggestion
   *
   * @param {Array} coverage_alerts - all coverage alerts for the suggestion
   * @returns {Object} NotCovered or NoPricing available object
   */
  _handleNoPricingAvailable(coverage_alerts) {
    let isNotCovered = false;
    coverage_alerts.find((coverageAlert) => {
      if (coverageAlert.id === 'NotCovered') {
        isNotCovered = true;
      }
    });
    if (isNotCovered) {
      return [this._getNotCovered()];
    }
    return [this._getNoPricingAvailable()];
  }

  _formatBestPricesCoupon(best_prices, coverage_alerts) {
    const { retail, coupon } = best_prices;

    if (this.priceIsNull(retail) && this.priceIsNull(coupon)) {
      return this._handleNoPricingAvailable(coverage_alerts);
    }
    return [retail ? this._getManufacturerCoupon(retail) : this._getCoupon(coupon)];
  }

  _formatBestPricesInsurance(best_prices, mfc, coverage_alerts) {
    const { retail, mail } = best_prices;
    if (this.priceIsNull(retail) && this.priceIsNull(mail)) {
      return this._handleNoPricingAvailable(coverage_alerts);
    } else {
      const prices = [];
      prices.push(
        retail
          ? mfc
            ? this._getManufacturerCoupon(retail)
            : this._getRetail(retail)
          : this._getNoPrices('retail'),
      );
      prices.push(mail ? this._getMail(mail) : this._getNoPrices('mail-order'));
      return prices;
    }
  }

  _getNotCovered() {
    return {
      type: 'not-covered',
      message: 'prices-section.cta.not-covered',
      span: 2,
    };
  }

  _getNoPricingAvailable() {
    return {
      type: 'no-pricing-available',
      message: 'prices-section.cta.no-pricing-available',
      span: 2,
    };
  }

  _getNoPrices(typ) {
    return {
      type: 'no-price',
      titleString: `prices-section.${typ}`,
      message: 'prices-section.cta.no-price',
      span: 1,
    };
  }

  _getRetail(info) {
    if (info[`cost_${this.priceKey}`] === null) {
      return this._getNoPrices('retail');
    }

    let ctaTitle: string;
    if (this.sourceType === 'claim') {
      if (this.sourceInfo.doctorFullName) {
        ctaTitle = 'prices-section.cta.retail-suggestion-title';
      } else {
        ctaTitle = 'prices-section.cta.retail-suggestion-title-doctor-fallback';
      }
    } else {
      ctaTitle = 'prices-section.cta.drug-search-retail-header';
    }

    return {
      title: 'prices-section.retail',
      price: parseFloat(info[`cost_${this.priceKey}`].toFixed(2)),
      quantity: this.priceKey === 'per_refill' ? info.quantity : 0,
      quantityLabel: 'prices-section.quantity',
      daySupply: info.day_supply,
      span: 1,
      type: 'retail',
      is_pbm_max_cost: info.attributes.is_pbm_max_cost,
      cta: {
        title: ctaTitle,
        button: 'prices-section.cta.pricing-button',
        continueButton: 'prices-section.cta.pricing-continue',
      },
    };
  }

  _getMail(info) {
    return info[`cost_${this.priceKey}`] === null
      ? this._getNoPrices('mail-order')
      : {
          title: 'prices-section.mail-order',
          price: parseFloat(info[`cost_${this.priceKey}`].toFixed(2)),
          quantity: this.priceKey === 'per_refill' ? info.quantity : 0,
          quantityLabel: 'prices-section.quantity',
          daySupply: info.day_supply,
          span: 1,
          type: 'mail_order',
          is_pbm_max_cost: info.attributes.is_pbm_max_cost,
          cta: {
            title: 'prices-section.cta.mail-order-header',
            button: 'prices-section.cta.see-details',
            continueButton: 'prices-section.cta.pricing-continue',
          },
        };
  }

  _getCoupon(info) {
    return info[`cost_${this.priceKey}`] === null
      ? this._getNoPrices('retail')
      : {
          title: 'prices-section.retail',
          price: parseFloat(info[`cost_${this.priceKey}`].toFixed(2)),
          quantity: this.priceKey === 'per_refill' ? info.quantity : 0,
          quantityLabel: 'prices-section.quantity',
          span: 1,
          type: 'coupon',
          cta: {
            title: 'prices-section.cta.coupon-title',
            button: 'prices-section.cta.pricing-button',
            continueButton: 'prices-section.cta.pricing-continue',
            note: 'prices-section.cta.coupon-note',
          },
        };
  }

  _getManufacturerCoupon(info) {
    return {
      title: 'prices-section.retail',
      price: parseFloat(info[`cost_${this.priceKey}`].toFixed(2)),
      quantity: this.priceKey === 'per_refill' ? info.quantity : 0,
      quantityLabel: 'prices-section.quantity',
      span: 1,
      type: 'manufacturer_copay',
      cta: {
        title: 'prices-section.cta.mfg-header',
        button: 'prices-section.cta.mfg-button',
        note: 'prices-section.cta.mfg-notes',
      },
    };
  }

  /**
   * @returns {number} A zero-based index of where the Rx Home Delivery pharmacies should be in the pharmacy list
   * @private
   */
  _getRxHomeDeliveryListPosition() {
    const homeDeliveryPosition =
      Number(
        getClientConfigurationValue(
          clientConfigurationKeys.PHARMACY_LIST_RX_HOME_DELIVERY_POSITION,
        ),
      ) || DEFAULT_PHARMACY_LIST_RX_HOME_DELIVERY_POSITION;

    // Subtract 1 to convert to a zero-based index
    return homeDeliveryPosition - 1;
  }

  /**
   * Transforms pharmacy costs for price blocked pharmacies
   * @param {Object} pharmacy to examine for price blockers
   * @returns {Object} pharmacy with adjudilite prices set to null if price is blocked
   * @private
   */
  setBlockedPricesToNull(pharmacy) {
    if (hasAdjudilitePriceBlocker(pharmacy)) {
      pharmacy.prices.adjudilite.cost_per_refill = null;
      pharmacy.prices.adjudilite.cost_per_day = null;
      pharmacy.prices.adjudilite.attributes.plan_pay = null;
      pharmacy.prices.adjudilite.attributes.plan_pay_per_day = null;
    }

    return pharmacy;
  }

  @observable
  priceKey: null;

  @action setPriceKey(newKey) {
    this.priceKey = newKey;
  }

  @computed
  get pricingKey() {
    return this.selectedPricing === 'coupon' ? 'coupon' : 'adjudilite';
  }

  @computed
  get suggestionTypes() {
    // Group raw suggestions by type (i.e. PS, TA, etc)
    const suggestionsByType = this.suggestions.reduce((suggestionByType, suggestion) => {
      const { suggested_products, best_prices, coverage_alerts, id, type, pharmacist_note } =
        suggestion;

      suggestionByType[type] = suggestionByType[type] || [];
      const { retail, mail, coupon } = best_prices;
      const formattedPrices = this._formatBestPrices(best_prices, type === 'MC', coverage_alerts);
      const sortedPrices = formattedPrices
        .filter((price) => !!price && typeof price.price === 'number')
        .sort((a, b) => a.price - b.price);
      const bestMemberPrice = sortedPrices[0];
      const bestClientPricePerDay = [
        retail && retail.attributes && retail.attributes.plan_pay_per_day,
        mail && mail.attributes && mail.attributes.plan_pay_per_day,
        coupon && coupon.attributes && coupon.attributes.plan_pay_per_day,
      ]
        .filter((bestPlanPayPerDay) => bestPlanPayPerDay !== null)
        .sort((a, b) => a - b)[0];

      suggestionByType[type].push({
        id,
        drugInfo: suggested_products.map((product) => {
          const pricer =
            product.prices.adjudilite && product.prices.adjudilite.length > 0
              ? 'adjudilite'
              : product.prices.coupon && product.prices.coupon.length > 0
              ? 'coupon'
              : product.prices.manufacturer_copay && product.prices.manufacturer_copay.length > 0
              ? 'manufacturer_copay'
              : false;

          const queryInfo = !pricer ? false : product.prices[pricer][0];
          const cost =
            queryInfo.cost_per_refill === null ? '' : `cost=${queryInfo.cost_per_refill}&`;
          const query = !!queryInfo
            ? `?${cost}quantity=${queryInfo.quantity}${
                pricer === 'adjudilite' ? `&package_id=${queryInfo.attributes.package_id}` : ''
              }`
            : '';
          const packageId = !!queryInfo ? queryInfo.attributes.package_id : '';
          return {
            id: product.id,
            query,
            image: product.image_url || require('../../assets/images/no-medication-image.svg'),
            name: `${product.primary_name ? product.primary_name : product.product_name} ${
              product.strength
            }`.trim(),
            dosage: !!product.secondary_name
              ? `${product.secondary_name} ${product.dispensable_unit}`
              : '',
            primaryName: product.primary_name,
            secondaryName: product.secondary_name,
            strength: product.strength,
            quantity: product.normalized_dispensable_unit.singular,
            packageId,
          };
        }),
        prices: formattedPrices,
        best_price: bestMemberPrice ? bestMemberPrice.price : null,
        best_plan_pay_per_day: bestClientPricePerDay,
        coverageAlert: toJS(coverage_alerts),
        pharmacist_note,
        raw_suggestion: suggestion,
      });

      return suggestionByType;
    }, {});

    // Sort suggestions within each group, then sort groups based on best suggestion within each group
    const sortedSuggestionsByType = Object.keys(suggestionsByType)
      .map((group) => {
        const suggestionsSortedByBestPrice = suggestionsByType[group].sort((a, b) => {
          // Sort suggestions within each group by what the member pays if prices are different
          const bestMemberPriceA = typeof a.best_price === 'number' ? a.best_price : Infinity;
          const bestMemberPriceB = typeof b.best_price === 'number' ? b.best_price : Infinity;

          if (bestMemberPriceA !== bestMemberPriceB) {
            return bestMemberPriceA - bestMemberPriceB;
          }

          // Sort in favor of what the client pays if the member pays the same amount for either suggestion
          const bestClientPricePerDayA =
            typeof a.best_plan_pay_per_day === 'number' ? a.best_plan_pay_per_day : Infinity;
          const bestClientPricePerDayB =
            typeof b.best_plan_pay_per_day === 'number' ? b.best_plan_pay_per_day : Infinity;
          return bestClientPricePerDayA - bestClientPricePerDayB;
        });

        const sanitizedBestPrices = suggestionsByType[group].reduce((acc, val) => {
          const bestSuggPrice = val.prices
            .filter((vl) => vl && typeof vl.price === 'number')
            .sort((a, b) => a.price - b.price)[0];

          return bestSuggPrice ? (acc < bestSuggPrice.price ? acc : bestSuggPrice.price) : acc;
        }, Infinity);

        return {
          type: group,
          suggestions: suggestionsSortedByBestPrice,
          best_price: sanitizedBestPrices,
          best_plan_pay_per_day: suggestionsByType[group][0].best_plan_pay_per_day,
        };
      })
      .sort((a, b) => {
        // Sort each group based on the best suggestion price within each group
        if (a.best_price !== b.best_price) {
          return a.best_price - b.best_price;
        }

        if (a.best_plan_pay_per_day !== b.best_plan_pay_per_day) {
          return a.best_plan_pay_per_day - b.best_plan_pay_per_day;
        }

        return a.type === 'PS' ? -1 : b.type === 'PS' ? 1 : 0;
      });

    return sortedSuggestionsByType;
  }

  @computed
  get targetProduct() {
    const { product } = this;
    return (
      this.loaded && {
        productID: product.id,
        productName: `${product.primary_name} ${product.strength}`,
      }
    );
  }

  @computed
  get bestPriceType() {
    const bestPrice = this.suggestionTypes.length > 0 ? this.suggestionTypes[0].best_price : null;
    return this.suggestionTypes &&
      this.suggestionTypes.length > 0 &&
      this.suggestionTypes.filter((type) => type.best_price === bestPrice).length === 1
      ? this.suggestionTypes[0].type
      : 'MULTI';
  }

  @computed
  get pricingMethod() {
    const hasCouponPricing = this.gatekeeperStore.isFeatureEnabled('has_coupon_pricing');
    const hasInsurancePricing = this.gatekeeperStore.isFeatureEnabled('has_insurance_pricing');

    if (hasCouponPricing && hasInsurancePricing) {
      return 'both';
    }
    if (hasCouponPricing) {
      return 'coupon';
    }
    if (hasInsurancePricing) {
      return 'insurance';
    }
    Rollbar.log('Unsupported Pricing Method / Pricing Method Not Found');
  }

  @computed
  get pharmaciesSelectable() {
    try {
      return this.sortedPharmacyList.length > 1;
    } catch (err) {
      return false;
    }
  }

  // region Express Alert Information
  @computed
  get selectedPharmacyExpressDisplayInfo() {
    const expressSuggestionDisplayInfo = this.selectedSuggestion.express_display_info;
    const { cost_per_refill } = this.selectedPharmacy.prices[this.pricingKey];
    return {
      prescriber: expressSuggestionDisplayInfo.prescriber,
      primaryMedication: expressSuggestionDisplayInfo.from.compare_info.primary_name,
      savings: expressSuggestionDisplayInfo.from.price - cost_per_refill,
      from: {
        displayStrength: expressSuggestionDisplayInfo.from.display_strength,
        price: expressSuggestionDisplayInfo.from.price,
        simpleName: expressSuggestionDisplayInfo.from.simple_name,
      },
      to: {
        price: cost_per_refill,
        simpleName: expressSuggestionDisplayInfo.to.simple_name,
      },
    };
  }

  /**
   * A computed array of the pharmacies associated with the selected suggestion, formatted for display in the
   * pharmacy picker list and sorted with the primary pharmacy on top, followed by the other pharmacies sorted by price,
   * home delivery status, and distance
   */
  @computed
  get selectedSuggestionExpressPharmacyList(): Array<FormattedPharmacy> {
    let primaryPharmacy = null;
    let nonPrimaryPharmacies: Array<FormattedPharmacy> = [];
    const primaryRetail = this.memberStore.activeMember.primary_retail_pharmacy.ncpdp;
    // Separating the primary pharmacy from the others, and formatting them
    this.sortedPharmacyList.forEach((pharmacy) => {
      if (pharmacy.ncpdp === primaryRetail) {
        const formattedPharmacy = this.formatPharmacy(pharmacy);
        primaryPharmacy = {
          ...formattedPharmacy,
          tags: [...formattedPharmacy.tags, { type: 'primary', text: 'primary-pharmacy' }],
        };
      } else {
        nonPrimaryPharmacies.push(this.formatPharmacy(pharmacy));
      }
    });
    nonPrimaryPharmacies = nonPrimaryPharmacies.sort(this.sortOtherPharmacies);
    // Aggregate the formattedPharmacies back together with the primary pharmacy on top
    const pharmacyList = [primaryPharmacy, ...nonPrimaryPharmacies].filter(
      (pharmacy) => !!pharmacy,
    );
    return positionHomeDeliveryPharmacies({
      sortedPharmacies: pharmacyList,
      position: this._getRxHomeDeliveryListPosition(),
      isPharmacyHomeDeliveryPredicate: (pharmacy) => pharmacy.isHomeDelivery,
    });
  }

  /**
   * Sorting function based on pharmacies' cost and distance, where cost is the primary sorting method, and distance
   * is used as a tiebreaker between pharmacies with the same cost
   *
   * @param pharmacyA
   * @param pharmacyB
   * @returns {number}
   */
  sortOtherPharmacies(pharmacyA: FormattedPharmacy, pharmacyB: FormattedPharmacy) {
    const aRefill = pharmacyA.priceInfo.priceRaw;
    const bRefill = pharmacyB.priceInfo.priceRaw;
    const aPerDay = pharmacyA.priceInfo.costPerDayRaw;
    const bPerDay = pharmacyB.priceInfo.costPerDayRaw;
    const aDistance = pharmacyA.distance;
    const bDistance = pharmacyB.distance;

    // if the refill costs are identical
    if (aRefill === bRefill) {
      // and both have the same home delivery status
      if (aPerDay === bPerDay) {
        // sort based on distance
        return aDistance - bDistance;
      }
      // otherwise move the pharmacy with the cheaper per day cost up
      return aPerDay - bPerDay;
    }
    // if costs are not identical, move the cheaper pharmacy up
    return aRefill - bRefill;
  }

  /**
   *
   * @param pharmacy
   * @returns {{
   *    id: number,
   *    name: string,
   *    street: string,
   *    city: string,
   *    state: string,
   *    zipcode: number,
   *    distance: number,
   *    priceInfo: {
   *      price: string,
   *      priceRaw: number,
   *      daySupply: number,
   *      costPerDay: string,
   *      costPerDayRaw: number,
   *      quantity: number,
   *      costPerUnit: string,
   *      costPerUnitRaw: number
   *    }
   *    }}
   */
  formatPharmacy(pharmacy: PricedPharmacy): FormattedPharmacy {
    const memberAddress = this.memberStore.currentMember.address;
    const {
      cost_per_refill: costPerRefill,
      day_supply: daySupply,
      quantity,
      cost_per_day: costPerDay,
      attributes: { plan_pay_per_day: planCostPerDay, plan_pay: planCostPerRefill },
    } = pharmacy.prices[this.pricingKey];
    return {
      id: pharmacy.id,
      name: pharmacy.name,
      street: pharmacy.address.street,
      city: pharmacy.address.city,
      state: pharmacy.address.state,
      zipcode: pharmacy.address.zipcode,
      distance: getDistance(pharmacy.address, memberAddress),
      isHomeDelivery: !!pharmacy.home_delivery_provider,
      isCashPricing: isPharmacyCashPricing(pharmacy),
      tags: !!pharmacy.home_delivery_provider ? [{ type: 'primary', text: 'home-delivery' }] : [],
      priceInfo: {
        price: formatMoney(costPerRefill),
        priceRaw: costPerRefill,
        daySupply,
        costPerDay: formatMoney(costPerDay),
        costPerDayRaw: costPerDay,
        quantity,
        planCostPerDay,
        planCostPerRefill,
        costPerUnit: formatMoney(costPerRefill / quantity),
        costPerUnitRaw: costPerRefill / quantity,
      },
    };
  }

  // endregion

  // region Cash Pricing
  /**
   * Checks if the selectedSuggestion's pharmacies array contains at least one pharmacy whose price came from the cash pricing module
   */
  @computed
  get suggestionHasCashPricing(): boolean {
    return doesPharmacyListContainCashPharmacy(this.sortedPharmacyList);
  }

  /**
   * Checks if the selected pharmacy's price came from the cash pricing module
   */
  @computed
  get selectedPharmacyIsCash(): boolean {
    return isPharmacyCashPricing(this.selectedPharmacy);
  }

  // endregion
}

export { CouponSuggestionsStore };
