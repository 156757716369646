/* @flow */
import { action, computed, observable } from 'mobx';
import {
  addBestSavingsTagToPharmacy,
  addThreeMonthSupplyTagToPharmacy,
  formatMoney,
  getClientConfigurationValue,
  positionHomeDeliveryPharmacies,
} from '@utils/Functions';
import { addNoPriceAvailableTagToPharmacies } from '@utils/Functions/addNoPriceAvailableTagToPharmacies/addNoPriceAvailableTagToPharmacies';
import { clientConfigurationKeys } from '@utils/Functions/getClientConfigurationValue/clientConfigurationKeys';
import BaseSuggestion from '@data/models/Suggestion/BaseSuggestion/BaseSuggestion';
import type { ComputedPriceProduct } from '@features/savings/types/Product';
import BaseMember from '@data/models/Member/BaseMember/BaseMember';
import type { PricedPharmacy } from '@features/savings/types/Pharmacy';
import type { PharmacyTags } from '@widgets/TagList/TagList';

type PricingTypes = 'retail' | 'mail' | 'coupon';

type formatPharmacyPriceParam = {
  cost_per_refill: number,
  day_supply: number,
  quantity: number,
  cost_per_day: number,
  attributes: { plan_pay_per_day: number, plan_pay: number },
};

export type FormattedPharmacy = {
  id: number,
  name: string,
  street: string,
  city: string,
  state: string,
  zipcode: string,
  distance: number,
  isHomeDelivery: boolean,
  tags: PharmacyTags,
  priceInfo: {
    price: string,
    priceRaw: number,
    daySupply: number,
    costPerDay: string,
    costPerDayRaw: number,
    quantity: number,
    planCostPerDay: number,
    planCostPerRefill: number,
    costPerUnit: string,
    costPerUnitRaw: number,
  },
  isCashPricing: boolean,
};

class SelectedSuggestion extends BaseSuggestion {
  /**
   * The pharmacy currently selected. All prices should belong to this pharmacy.
   * @type {Object}
   */
  @observable selectedPharmacy: PricedPharmacy;

  /**
   * The selected pricing type. Defaults to retail if not passed in constructor.
   * @type {string}
   */
  @observable selectedPricing: PricingTypes = 'retail';

  constructor({
    json,
    suggestionMember,
    authenticatedMember,
    selectedPricing,
  }: {
    json: {||},
    suggestionMember: BaseMember,
    authenticatedMember: BaseMember,
    selectedPricing: PricingTypes,
  }) {
    super({ json, suggestionMember, authenticatedMember });
    this.sortPharmacyByMemberPriceAndDistance =
      this.sortPharmacyByMemberPriceAndDistance.bind(this);
    this.setSelectedPricing(selectedPricing);
    this.setSelectedPharmacy();
  }

  @action
  setSelectedPharmacy(pharmacyId: ?number) {
    if (pharmacyId) {
      this.selectedPharmacy = this.getPharmacyById(pharmacyId);
      if (!this.selectedPharmacy) {
        throw new Error(`Pharmacy ${pharmacyId} not found for suggestion ${this.id}`);
      }
    } else {
      this.selectedPharmacy = this.bestPricePharmacy;
    }
  }

  @action
  setSelectedPricing(pricingType: PricingTypes) {
    this.selectedPricing = pricingType;
  }

  /* Pharmacy Sorting Functions */

  /**
   * The default 1-index based position where Rx Home Delivery should appear within the pharmacy list. This value will
   * be used if a specific value cannot be retrieved from client configuration.
   */
  DEFAULT_PHARMACY_LIST_RX_HOME_DELIVERY_POSITION: Number = 2;

  @computed
  get pricingKey(): 'coupon' | 'adjudilite' {
    return this.selectedPricing === 'coupon' ? 'coupon' : 'adjudilite';
  }

  @computed
  get isMaxPricing(): boolean {
    return (
      Object.keys(this.best_prices).reduce((acc, key) => {
        return this.best_prices[key]?.attributes.is_pbm_max_cost || acc;
      }, false) && this.selectedPharmacyExpressDisplayInfo?.to.price > 0
    );
  }

  floatPharmacyBy(value) {
    return (a, b) => {
      if ([a.id, a.ncpdp].indexOf(value) > -1) {
        return -1;
      } else if ([b.id, b.ncpdp].indexOf(value) > -1) {
        return 1;
      }
      return 0;
    };
  }

  /**
   * @returns {number} A zero-based index of where the Rx Home Delivery pharmacies should be in the pharmacy list
   * @private
   */
  _getRxHomeDeliveryListPosition() {
    const homeDeliveryPosition =
      Number(
        getClientConfigurationValue(
          clientConfigurationKeys.PHARMACY_LIST_RX_HOME_DELIVERY_POSITION,
        ),
      ) || this.DEFAULT_PHARMACY_LIST_RX_HOME_DELIVERY_POSITION;

    // Subtract 1 to convert to a zero-based index
    return homeDeliveryPosition - 1;
  }

  /**
   * A comparator function to compare two pharmacies by member price and distance. Intended for use with the Array#sort
   * function.
   *
   * @param {Object} a - A pharmacy to compare
   * @param {Object} b - A pharmacy to compare
   * @returns {number} - 1, 0, or -1 based on whether the first or second pharmacy should come first in a sorted list.
   */
  sortPharmacyByMemberPriceAndDistance(a, b) {
    // Always put pharmacies with valid prices before those with null prices
    if (a.prices[this.pricingKey].cost_per_day === null) {
      return 1;
    }

    if (b.prices[this.pricingKey].cost_per_day === null) {
      return -1;
    }

    const diff = a.prices[this.pricingKey].cost_per_day - b.prices[this.pricingKey].cost_per_day;
    const distanceDiff = !!a.distance && !!b.distance && a.distance - b.distance;

    return diff === 0 ? distanceDiff : diff;
  }

  @computed
  get mailOrderPharmaciesList(): array {
    let sortedPharmacies = this.pharmacies
      .filter((pharmacy) => pharmacy.type.label !== 'retail' || !!pharmacy.home_delivery_provider)
      .filter((pharmacy) => !!pharmacy.prices[this.pricingKey])
      .map(this.flagPharmacies)
      .sort(this.sortPharmacyByMemberPriceAndDistance)
      .sort(this.floatPharmacyBy(this.suggestionMember.planPreferredPharmacyId))
      .sort(this.floatPharmacyBy(this.suggestionMember.primaryPharmacyNcpdp));

    sortedPharmacies = addThreeMonthSupplyTagToPharmacy(sortedPharmacies, this.pricingKey);

    return positionHomeDeliveryPharmacies({
      sortedPharmacies,
      position: this._getRxHomeDeliveryListPosition(),
    });
  }

  @computed get bestPricePharmacy() {
    const bestPricePharm =
      this.selectedPricing === 'mail_order'
        ? this.pharmacies
            .filter(
              (pharmacy) => pharmacy.type.label !== 'retail' || !!pharmacy.home_delivery_provider,
            )
            .filter((pharmacy) => !!pharmacy.prices[this.pricingKey])
            .sort(this.sortPharmacyByMemberAndPlanPerDayPrice)[0]
        : this.pharmacies
            .filter(
              (pharmacy) => pharmacy.type.label === 'retail' || !!pharmacy.home_delivery_provider,
            )
            .filter((pharmacy) => !!pharmacy.prices[this.pricingKey])
            .sort(this.sortPharmacyByMemberPriceAndDistance)[0];

    if (
      this.primaryPharmacy &&
      this.primaryPharmacy.prices[this.pricingKey] &&
      this.primaryPharmacy.type.label === bestPricePharm.type.label &&
      bestPricePharm.prices[this.pricingKey]?.cost_per_refill ===
        this.primaryPharmacy.prices[this.pricingKey].cost_per_refill
    )
      return this.primaryPharmacy;
    if (
      this.planPreferredPharmacy &&
      this.planPreferredPharmacy.prices[this.pricingKey] &&
      this.planPreferredPharmacy.type.label === bestPricePharm.type.label &&
      bestPricePharm.prices[this.pricingKey]?.cost_per_refill ===
        this.planPreferredPharmacy.prices[this.pricingKey].cost_per_refill
    )
      return this.planPreferredPharmacy;
    return this.pharmacies.find((pharm) => pharm.id === bestPricePharm.id);
  }

  @computed
  get retailPharmaciesList(): array {
    let sortedPharmacies = this.pharmacies
      .filter((pharmacy) => pharmacy.type.label === 'retail' || !!pharmacy.home_delivery_provider)
      .filter((pharmacy) => !!pharmacy.prices[this.pricingKey])
      .sort(this.sortPharmacyByMemberPriceAndDistance)
      .sort(this.floatPharmacyBy(this.bestPricePharmacy.id))
      .sort(this.floatPharmacyBy(this.suggestionMember.planPreferredPharmacyId))
      .sort(this.floatPharmacyBy(this.suggestionMember.primaryPharmacyNcpdp));

    addNoPriceAvailableTagToPharmacies(sortedPharmacies, this.pricingKey);

    sortedPharmacies = positionHomeDeliveryPharmacies({
      sortedPharmacies,
      position: this._getRxHomeDeliveryListPosition(),
    });

    return addBestSavingsTagToPharmacy({
      pharmacies: sortedPharmacies,
      pricingMethod: this.pricingKey,
      memberSetPrimaryPharmacyNcpdp: this.suggestionMember.primaryPharmacyNcpdp,
      planPreferredPharmacyId: this.suggestionMember.planPreferredPharmacyId,
    });
  }

  @computed
  get sortedPharmacyList(): array {
    return this.selectedPricing === 'mail_order'
      ? this.mailOrderPharmaciesList
      : this.retailPharmaciesList;
  }

  /**
   *
   * @param pharmacy
   * @returns {{
   *    id: number,
   *    name: string,
   *    street: string,
   *    city: string,
   *    state: string,
   *    zipcode: number,
   *    distance: number,
   *    priceInfo: {
   *      price: string,
   *      priceRaw: number,
   *      daySupply: number,
   *      costPerDay: string,
   *      costPerDayRaw: number,
   *      quantity: number,
   *      costPerUnit: string,
   *      costPerUnitRaw: number
   *    }
   *    }}
   */
  formatPharmacy(pharmacy: {
    id: number,
    name: string,
    address: {
      street: string,
      city: string,
      state: string,
      zipcode: string,
    },
    distance: number,
    home_delivery_provider: string | null,
    prices: {
      coupon: formatPharmacyPriceParam,
      adjudilite: formatPharmacyPriceParam,
    },
    isCashPricing: boolean,
  }): FormattedPharmacy {
    const {
      cost_per_refill: costPerRefill,
      day_supply: daySupply,
      quantity,
      cost_per_day: costPerDay,
      attributes: { plan_pay_per_day: planCostPerDay, plan_pay: planCostPerRefill },
    } = pharmacy.prices[this.pricingKey];
    return {
      id: pharmacy.id,
      name: pharmacy.name,
      street: pharmacy.address.street,
      city: pharmacy.address.city,
      state: pharmacy.address.state,
      zipcode: pharmacy.address.zipcode,
      distance: pharmacy.distance,
      isHomeDelivery: !!pharmacy.home_delivery_provider,
      tags: !!pharmacy.home_delivery_provider ? [{ type: 'primary', text: 'home-delivery' }] : [],
      priceInfo: {
        price: formatMoney(costPerRefill),
        priceRaw: costPerRefill,
        daySupply,
        costPerDay: formatMoney(costPerDay),
        costPerDayRaw: costPerDay,
        quantity,
        planCostPerDay,
        planCostPerRefill,
        costPerUnit: formatMoney(costPerRefill / quantity),
        costPerUnitRaw: costPerRefill / quantity,
      },
      isCashPricing: pharmacy.isCashPricing,
    };
  }

  /**
   * Sorting function based on pharmacies' cost and distance, where cost is the primary sorting method, and distance
   * is used as a tiebreaker between pharmacies with the same cost
   *
   * @param pharmacyA
   * @param pharmacyB
   * @returns {number}
   */
  sortOtherPharmacies(pharmacyA: FormattedPharmacy, pharmacyB: FormattedPharmacy) {
    const aRefill = pharmacyA.priceInfo.priceRaw;
    const bRefill = pharmacyB.priceInfo.priceRaw;
    const aPerDay = pharmacyA.priceInfo.costPerDayRaw;
    const bPerDay = pharmacyB.priceInfo.costPerDayRaw;
    const aDistance = pharmacyA.distance;
    const bDistance = pharmacyB.distance;

    // if the refill costs are identical
    if (aRefill === bRefill) {
      // and both have the same home delivery status
      if (aPerDay === bPerDay) {
        // sort based on distance
        return aDistance - bDistance;
      }
      // otherwise move the pharmacy with the cheaper per day cost up
      return aPerDay - bPerDay;
    }
    // if costs are not identical, move the cheaper pharmacy up
    return aRefill - bRefill;
  }

  /**
   * A computed array of the pharmacies associated with the selected suggestion, formatted for display in the
   * pharmacy picker list and sorted with the primary pharmacy on top, followed by the other pharmacies sorted by price,
   * home delivery status, and distance
   */
  @computed
  get expressPharmacyList(): Array<FormattedPharmacy> {
    let primaryPharmacy: null | FormattedPharmacy = null;
    let nonPrimaryPharmacies: Array<FormattedPharmacy> = [];
    // Separating the primary pharmacy from the others, and formatting them
    this.sortedPharmacyList.forEach((pharmacy) => {
      if (pharmacy.ncpdp === this.suggestionMember.primaryPharmacyNcpdp) {
        const formattedPharmacy = this.formatPharmacy(pharmacy);
        primaryPharmacy = {
          ...formattedPharmacy,
          tags: [...formattedPharmacy.tags, { type: 'primary', text: 'primary-pharmacy' }],
        };
      } else {
        nonPrimaryPharmacies.push(this.formatPharmacy(pharmacy));
      }
    });
    nonPrimaryPharmacies = nonPrimaryPharmacies.sort(this.sortOtherPharmacies);
    // Aggregate the formattedPharmacies back together with the primary pharmacy on top
    const pharmacyList = primaryPharmacy
      ? [primaryPharmacy, ...nonPrimaryPharmacies]
      : nonPrimaryPharmacies;
    return positionHomeDeliveryPharmacies({
      sortedPharmacies: pharmacyList,
      position: this._getRxHomeDeliveryListPosition(),
      isPharmacyHomeDeliveryPredicate: (pharmacy) => pharmacy.isHomeDelivery,
    });
  }

  @computed
  get selectedPharmacyExpressDisplayInfo() {
    const { cost_per_refill } = this.selectedPharmacy.prices[this.pricingKey];
    return {
      prescriber: this.express_display_info.prescriber,
      primaryMedication: this.express_display_info.from.compare_info.primary_name,
      savings: this.express_display_info.from.price - cost_per_refill,
      from: {
        displayStrength: this.express_display_info.from.display_strength,
        price: this.express_display_info.from.price,
        simpleName: this.express_display_info.from.simple_name,
      },
      to: {
        price: cost_per_refill,
        simpleName: this.express_display_info.to.simple_name,
      },
    };
  }

  /**
   * Returns the product info of the selected suggestion
   *
   * We currently always return one product in suggested_product.
   * In the future we will return multiple for combo splits.
   *
   * @type {Object}
   */
  @computed
  get selectedProduct(): ComputedPriceProduct {
    const { primary_name, secondary_name, form, strength } = this.suggested_products[0];
    const { cost_per_day, cost_per_refill, day_supply, quantity, attributes } =
      this.best_prices[this.selectedPricing] ?? {};

    return {
      quantity,
      day_supply,
      cost_per_refill,
      cost_per_day,
      primary_name,
      secondary_name,
      form,
      strength,
      attributes,
    };
  }
}

export default SelectedSuggestion;
