/* @flow */

import { CookieStorage } from 'cookie-storage';

/**
 * Class providing helper methods for accessing storage object
 * Storage options include sessionStorage, localStorage, and Cookie
 */
export class SessionManager {
  /**
   * Storage object, either sessionStorage, localStorage, or Cookie
   * @type {Storage | CookieStorage}
   */
  store: Storage | CookieStorage;

  /**
   * Creates a manager for the given storage object
   * @param {Storage | CookieStorage} store - Storage object, either sessionStorage, localStorage, or CookieStorage
   */
  constructor(store: Storage | CookieStorage) {
    if (store instanceof Storage || store instanceof CookieStorage) {
      this.store = store;
    } else {
      this.store = new CookieStorage();
    }
  }

  /**
   * Sets the given item to the given value in storage
   * @param {any} item The key to set in storage, or an object with multiple key-value pairs to set in storage
   * @param {any} value The value to set in storage
   * @return {Object} The JSON parsed key-value pairs as set in storage
   */
  set(item: any, value?: any): Object {
    if (typeof item === 'string') {
      const response = {};
      const stringValue = JSON.stringify(value);

      this.store.setItem(item, stringValue);
      response[item] = stringValue;

      return response;
    }

    const keys = Object.keys(item);
    const response = {};

    keys.forEach((key) => {
      const stringValue = JSON.stringify(item[key]);
      response[key] = stringValue;
      this.store.setItem(key, stringValue);
    });

    return response;
  }

  /**
   * Returns the given item's value
   * @param {string} item The key to retrieve from storage
   * @return {Object} The JSON parsed value for the given item
   */
  get(item: string): Object {
    const response = JSON.parse(this.store.getItem(item));

    return response;
  }

  /**
   * Removes the given item from storage
   * @param {string} item The key to remove from storage
   * @return {boolean}
   */
  remove(item: string): Object {
    this.store.removeItem(item);

    return true;
  }

  /**
   * Returns the given item's value, then deletes it from storage
   * @param {string} item The key to retrieve from storage
   * @return {Object} The JSON parsed value for the given item
   */
  pull(item: string): Object {
    const response = this.get(item);

    this.remove(item);

    return response;
  }

  /**
   * Returns all key-value pairs currently in storage
   * @return {Object} The JSON parsed key-value pairs currently in storage
   */
  getAll(): Object {
    const response = {};
    const keys = Object.keys(this.store);

    keys.forEach((key) => {
      response[key] = this.get(key);
    });

    return response;
  }

  /**
   * Removes given item from store.
   *
   * Removes all items in storage if no parameter passed.
   * @param {?string} item The key to retrieve from storage
   */
  clear(item?: string) {
    if (item) {
      this.store.removeItem(item);
      return;
    }

    this.store.clear();
  }
}

export default SessionManager;
