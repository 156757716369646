/* @flow */
import { observable, action, computed } from 'mobx';
import rxHomeTransferApi from '@data/RxHomeTransfer/RxHomeTransferApi';
import RxHomeDeliveryStatuses from '@data/RxHomeTransfer/RxHomeDeliveryStatuses';
import RxHomeTransferResponse from '@data/RxHomeTransfer/RxHomeTransferResponse';
import { AlertListStore } from '@data/stores/alertListStore/alertListStore';
import { MemberStore } from '@stores_old/memberStore/memberStore';
import type { ClaimId, Ncpdp } from '@features/savings/types/Identifiers';
import type { PAYMENT_COLLECTION_TYPE } from '@utils/Constants/HomeDeliveryEnums';
import PRICING_MODULES from '@utils/Constants/pricingModules';
import { PAYMENT_COLLECTION } from '@utils/Constants/HomeDeliveryEnums';
import queryString from 'query-string';
import type { LocationShape } from 'react-router-dom';

class RxHomeTransferStore {
  alertListStore: AlertListStore;

  memberStore: MemberStore;

  @observable transferForConvenienceOpportunities: { [ClaimId]: RxHomeTransferResponse } = {};

  @observable currentTransferClaimId: ClaimId = '';

  @observable externalLinkClicked = false;

  constructor({
    memberStore,
    alertListStore,
  }: {
    memberStore: MemberStore,
    alertListStore: AlertListStore,
  }) {
    this.alertListStore = alertListStore;
    this.memberStore = memberStore;
  }

  /**
   * An internal helper function that updates the response hash in such a way that triggers a rerender
   * of the component
   *
   * @param {string} claimId - The id of the claim being updated
   * @param {RxHomeTransferResponse} homeTransferResponse - The new response object being stored under
   * the given claimId
   * @returns {void}
   */
  @action.bound
  updateClaim(claimId: ClaimId, homeTransferResponse: RxHomeTransferResponse) {
    this.transferForConvenienceOpportunities = {
      ...this.transferForConvenienceOpportunities,
      [claimId]: homeTransferResponse,
    };
  }

  /**
   * If an RxHomeTransferResponse hasn't been loaded for the passed claim id, this function will set
   * a placeholder response object with a loading status, then call the api to load the claim's true
   * status, setting whatever response is returned by the api to the `transferForConvenienceOpportunities`
   * property under the given claim id
   *
   * @param {string} claimId - The id of the claim being fetched
   * @returns {void}
   */
  @action.bound
  async getRxHomeDeliveryDetailsForClaimId(claimId: ClaimId) {
    if (!this.transferForConvenienceOpportunities[claimId]) {
      this.updateClaim(
        claimId,
        new RxHomeTransferResponse({
          claimId,
          status: RxHomeDeliveryStatuses.LOADING,
          details: null,
        }),
      );

      return rxHomeTransferApi
        .getRxHomeDeliveryDetailsForClaimId(claimId, this.memberStore.activeMember.id)
        .then((response) => {
          this.updateClaim(claimId, response);
        });
    }
  }

  /**
   * This function will set whether the external link for the Rx Home Delivery has been clicked
   *
   * @returns {void}
   */
  @action
  updateExternalLinkClicked() {
    this.externalLinkClicked = true;
  }

  /**
   * This function will set the current transfer claim id and invoke the getClaim function to load in
   * the RxHomeTransferResponse if it hasn't already been loaded
   *
   * @param {string} claimId - A claim id that will be used to fetch the RxHomeTransferResponse object
   * @returns void
   */
  @action.bound
  async setCurrentTransferClaimId(claimId: ClaimId) {
    this.alertListStore.resetCurrentAlertId();
    this.currentTransferClaimId = claimId;
    return this.getRxHomeDeliveryDetailsForClaimId(claimId);
  }

  /**
   * A computed value that returns the claim information associated with the currently set claim id
   * if it exists, otherwise it resolves to false
   *
   * @returns {RxHomeTransferResponse|boolean}
   */
  @computed
  get currentTransferClaim() {
    return this.transferForConvenienceOpportunities[this.currentTransferClaimId] || false;
  }

  /**
   * Returns react router location object to redirect to appropriate location based on HD transfer eligibility
   * and available HD pharmacies
   */
  getRedirectLocationForClaim = (claimId: ClaimId): LocationShape => {
    const RxHomeTransferDetails = this.transferForConvenienceOpportunities[claimId].details;

    let ncpdp: ?Ncpdp;
    let paymentCollection: ?PAYMENT_COLLECTION_TYPE;

    if (
      RxHomeTransferDetails?.priced_pharmacies &&
      RxHomeTransferDetails.priced_pharmacies.length > 0
    ) {
      const pricedPharmacy = RxHomeTransferDetails.priced_pharmacies[0];
      ncpdp = pricedPharmacy.ncpdp;
      paymentCollection =
        pricedPharmacy.suggested_drugs[0].source === PRICING_MODULES.CASH
          ? PAYMENT_COLLECTION.CASH
          : PAYMENT_COLLECTION.INSURANCE;
    } else if (RxHomeTransferDetails?.price) {
      const { price } = RxHomeTransferDetails;
      ncpdp = price.ncpdp;
    } else {
      throw new Error('Missing HD Transfer data: Cannot redirect to home delivery checkout');
    }

    const query = {
      member: this.memberStore.activeMember.id,
      claimId,
      ncpdp,
      paymentCollection,
      // TODO: REPLACE THIS HARD CODED VALUE WITH DYNAMIC ONE, SEE PORTAL-3281
      pricing: 'retail',
    };
    return {
      pathname: '/home-delivery-request',
      search: queryString.stringify(query),
    };
  };
}

export default RxHomeTransferStore;
