/**
 * This creates a suggestion pseudo-id by adding 1,000,000 to the product id. This is the method of
 * creating a fake suggestion that the backend will understand, which is the standard approach for
 * price shopping suggestions
 *
 * @param {number} productId - The id of the product a suggestion is being faked for
 * @returns {number} The fake suggestion id generated
 */
function generateSuggestionIdFromProductId(productId) {
  return parseInt(productId) + 1000000;
}

export default generateSuggestionIdFromProductId;
