/* @flow */
import { toFixed } from 'accounting';

function copy(obj) {
  return JSON.parse(JSON.stringify(obj));
}

function scrollToElement(element, _duration) {
  const y = element.offsetTop;
  const x = element.offsetLeft;
  if (!!_duration) {
    const duration = parseInt(_duration);
    const wx = window.pageXOffset;
    const wy = window.pageYOffset;
    const steps = Math.round(duration / 10);
    const dx = (x - wx) / steps;
    const dy = (y - wy) / steps;
    let stepsTaken = 0;
    const interval = window.setInterval(() => {
      window.scrollTo(window.pageXOffset + dx, window.pageYOffset + dy);
      if (stepsTaken < steps) {
        stepsTaken += 1;
      } else {
        window.clearInterval(interval);
      }
    }, 10);
  } else {
    window.scrollTo(x, y);
  }
}

function formatPhoneNumber(num) {
  num.trim();
  return `(${num.substr(0, 3)}) ${num.substr(3, 3)}-${num.substr(6)}`;
}

/**
 * Removes country code from phone number
 * Regex matches the beginning of the string for a +1 or 00 if there are 10 more digits in the string, replacing it with an empty string
 *
 * @param {?string} num Phone number in 10 digit format with or without country code (+1000000000 or 000000000)
 * @returns {string}
 */
function removePhoneCountryCode(num) {
  return num?.replace(/^(\+1|00)(?=[0-9]{10})/, '') ?? num;
}

function maskString(target, mask, placeholder) {
  let returnValue = mask;
  const targetString = typeof target === 'string' ? target : target.toString();
  targetString.split('').forEach((character) => {
    returnValue = returnValue.replace(placeholder, character);
  });
  return returnValue;
}

function isMobile() {
  return window.matchMedia('(max-width: 767px)').matches;
}

function toRad(angle) {
  return (angle * Math.PI) / 180;
}

function getDistance({ latitude: lat1, longitude: lon1 }, { latitude: lat2, longitude: lon2 }) {
  const R = 6371;
  const dLat = toRad(lat2 - lat1);
  const dLon = toRad(lon2 - lon1);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(toRad(lat1)) * Math.cos(toRad(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const d = R * c;
  return d;
}

/**
 * This creates a random id string, prefixed with the value passed in and with a random character
 * substring length equal to the length param.
 *
 * @param {string} prefix
 * @param {number} [length=8]
 * @returns {string}
 */
function generateMockId(prefix: string, length: number = 8): string {
  return `${prefix}-${Math.random().toString(16).split('.')[1].substr(0, length)}`;
}

export { default as getPharmacyHours } from './getPharmacyHours/getPharmacyHours';
export { default as getReport } from './getReport/getReport';
export { default as positionHomeDeliveryPharmacies } from './positionHomeDeliveryPharmacies/positionHomeDeliveryPharmacies';
export { default as getClientConfigurationValue } from './getClientConfigurationValue/getClientConfigurationValue';
export {
  copy,
  scrollToElement,
  formatPhoneNumber,
  removePhoneCountryCode,
  isMobile,
  toRad,
  getDistance,
  generateMockId,
  maskString,
};
export { default as addBestSavingsTagToPharmacy } from './addBestSavingsTagToPharmacy/addBestSavingsTagToPharmacy';
export { default as addThreeMonthSupplyTagToPharmacy } from './addThreeMonthSupplyTagToPharmacy/addThreeMonthSupplyTagToPharmacy';
export { default as generateSuggestionIdFromProductId } from './generateSuggestionIdFromProductId/generateSuggestionIdFromProductId';
export { default as getWindowOpenTriggerFunction } from './getWindowOpenTriggerFunction/getWindowOpenTriggerFunction';
export { default as allSettled } from './allSettled/allSettled';
export { default as getDomainFromUrl } from './getDomainFromUrl/getDomainFromUrl';
export { default as pendoTrack } from './pendoTrack/pendoTrack';
export { default as formatMoney } from './formatMoneyFunction/formatMoney';
