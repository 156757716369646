/* @flow */
import type { PricedPharmacy } from '@features/savings/types/Pharmacy';
import PRICING_MODULES from '@utils/Constants/pricingModules';

/**
 * Checks if the passed pharmacy's price came from the cash pricing module
 */
const isPharmacyCashPricing = (pharmacy: ?PricedPharmacy | {||}): boolean => {
  return pharmacy?.prices?.adjudilite?.description === PRICING_MODULES.CASH;
};

/**
 * Checks if the passed pharmacies array contains at least one pharmacy whose price came from the cash pricing module
 */
const doesPharmacyListContainCashPharmacy = (pharmacies: ?Array<PricedPharmacy>): boolean => {
  return (
    pharmacies?.some((pharmacy) => {
      return isPharmacyCashPricing(pharmacy);
    }) ?? false
  );
};

export { isPharmacyCashPricing, doesPharmacyListContainCashPharmacy };
