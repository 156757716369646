/* @flow */
import { observable, action, computed, toJS } from 'mobx';
import { without, chain, sortBy, isEmpty, first } from 'lodash';

import defaultSelectOptions from './defaultSelectOptions';
import defaultSelections from './defaultSelections';
import defaultDaySupply from './defaultDaySupply';

/**
 * Stores state for all searches used in Home and SearchField component.
 * @memberof Stores
 */
class SearchFormStore {
  /*
   * TODO: change booleans to indicate how they are held:
   * openMobile => isMobileOpen
   * showDaySupply => isDaySupplyShown
   * loading => isLoading
   * showSelection => shouldShowSelection
   */
  @observable openMobile: boolean = false;

  @observable focusIdx: number = 0;

  @observable dispensableLabel: string = '';

  @observable showDaySupply: boolean = false;

  @observable selections: Object = defaultSelections;

  @observable showSelection: boolean = false;

  @observable loading: boolean = true;

  @observable selectionOptionsObj: Object = defaultSelectOptions;

  @observable apiBase: string;

  @observable formErrors: Array<string>;

  @observable searchResults: Object;

  daySupply: Object = defaultDaySupply;

  currentQuery: Object;

  productStore: Object;

  makeSearch: Function;

  autocompleteStore: Object;

  eventEmiiter: Object;

  constructor(productStore: Object, autocompleteStore: Object) {
    this.productStore = productStore;
    this.autocompleteStore = autocompleteStore;
    this.apiBase = window.api_url || global.api_url;
    this.makeSearch = this.makeSearch.bind(this);
  }

  /**
   * Rebuilds search form given a product id and values for the form fields.
   *
   * @param {Number} id - medication Id
   * @param {Object} selections - a give users selections
   */
  async rebuildAutocomplete(id: number, selections: Object) {
    const result = await this.autocompleteStore.rebuildById(id);

    // Repopulate each field with proper value
    const selectionKeys = Object.keys(selections);

    // https://www.youtube.com/watch?v=V1M6EYA14eU
    this.updateSelection('form', selections.form);

    selectionKeys.forEach((key) => {
      this.updateSelection(key, selections[key]);
    });

    // Open up form
    this.startSearch(result, { updateForm: false });

    // Make sure quantity options get set correctly
    this.updateSelection('quantity', selections.quantity);

    return {};
  }

  /**
   * TODO: remove not used??
   * Handles loading logic when running autocomplete
   *
   * @param {String} term - The search term.
   */
  async updateAutocomplete(term: string) {
    this.loading = true;
    await this.autocompleteStore.getAutocomplete(term);
    this.loading = false;
  }

  /**
   * TODO: remove not used??
   *
   * @returns {Object} - form errors
   */
  makeSearch(): Object {
    this.formErrors = [];

    if (this.selections['quantity'] === '') {
      this.formErrors.push('quantity');
    }

    if (this.selections['zip'] === '') {
      this.formErrors.push('zip');
    }

    const { dosage } = this.selections;
    const { form } = this.selections;

    const productForm = chain(this.autocompleteStore.autocompleteResult.forms.slice(0))
      .find({ description: form })
      .value();

    const product = chain(productForm.strengths.slice(0)).find({ description: dosage }).value();

    const productId = product.product_id;
    if (!this.formErrors.length) {
      this.openMobile = false;
    }

    return { errors: !!this.formErrors.length, productId };
  }

  /**
   * Clears out search and sets to default.
   */
  clearSearch() {
    this.autocompleteStore.searchValue = '';
    this.selections = { ...defaultSelections };
    this.autocompleteStore.autocompleteResult = {};
  }

  /**
   * TODO: remove not used?
   *
   * @param {String} formStr - The form you want to get the label from.
   * @param {String} dosageStr - The dosage you want to get the label from.
   *
   * @returns {Object} minimum quantity of medication/tablets.
   */
  getLabel(formStr: string, dosageStr: string) {
    if (isEmpty(this.autocompleteStore.autocompleteResult)) {
      return '';
    }

    const selectedForm = this.autocompleteStore.autocompleteResult.forms.filter((form) => {
      return form.description === formStr;
    })[0];

    if (!selectedForm) {
      return '';
    }

    const dosage = selectedForm.strengths.filter((dosage) => {
      return dosage.description === dosageStr;
    })[0];

    return dosage.dispensable_unit;
  }

  /** ACTIONS - TODO: separate actions per store to actions file then add a module {X}StoreActions where X is the given store */

  /**
   * Initialize and show search form
   *
   * @param {Object} result - result of the rebuildById call
   * @param {Object} config
   * @param {Boolean} config.updateForm - wither or not the form should be updated
   */
  @action
  async startSearch(result: Object, config: ?Object) {
    this.showSelection = true;
    this.formErrors = [];

    // WARNING: loading is opposite logic in autocomplete land
    this.loading = false;
    await this.autocompleteStore.initAutocomplete(result, this);
    this.loading = true;

    if (config) {
      this.updateSelectionOptions(null, config);
    } else {
      this.updateSelectionOptions();
    }
  }

  /**
   * Normalizes form and dosage from results and assigns
   * Proper values to selection options on initialization and
   * When forms are changed
   *
   * @param {String} [form] - forms value to be updated.
   * @param {Object} [config] - specific configurations for the provided form to be updated.
   */
  @action
  updateSelectionOptions(form: ?string, config: ?Object) {
    const forms = this.autocompleteStore.autocompleteResult.forms.map((form) => {
      return form.description;
    });

    if (!forms.length) {
      Rollbar.warn('No forms on autocomplete result.');
      return;
    }

    let dosage = [];
    let quantity = [];

    // If form sent in then update otherwise get defaults
    if (form) {
      // use form sent in
      const formDefault = this.autocompleteStore.autocompleteResult.forms.filter((item) => {
        return item.description === form;
      });

      // string dosage
      dosage = formDefault[0].strengths.map((strength) => {
        return strength.description;
      });

      // Sets to default if previous dosage not found in new dosage list
      // ex. Enbrel
      if (dosage.indexOf(this.selections['dosage']) === -1) {
        const [firstDosage, ...dosages] = dosage;
        this.selections['dosage'] = firstDosage;
      }

      // dosage object
      const strength = formDefault[0].strengths.filter((strength) => {
        return this.selections['dosage'] === strength.description;
      })[0];

      this.dispensableLabel = strength.dispensable_unit;

      // Pulls correct strength from selected dosage and grabs the quantities
      quantity = formDefault[0].strengths.filter((strength) => {
        return strength.description === this.selections.dosage;
      })[0].quantities;

      this.setQuantityType(quantity, strength);
    } else if (!config || config.updateForm) {
      // use first form from autocomplete
      const formDefault = this.autocompleteStore.autocompleteResult.forms[0];
      this.selections['form'] = formDefault.description;
      dosage = formDefault.strengths.map((strength) => {
        return strength;
      });

      // Quantity needs to be a string because of how select box logic works
      quantity = formDefault.strengths[0].quantities;

      this.setQuantityType(quantity, dosage[0]);

      // Normalize to look like object in selectionOptions array

      this.selections['dosage'] = dosage[0].description;
      this.dispensableLabel = dosage[0].dispensable_unit;

      dosage = dosage.map((value) => value.description);
    } else {
      // use form that matches form selected
      const formValue = this.autocompleteStore.autocompleteResult.forms.filter((item) => {
        return item.description === this.selections['form'];
      });

      dosage = formValue[0].strengths.map((strength) => {
        return strength.description;
      });
    }

    const newOptions = { ...this.selectionOptionsObj };

    // Add dosage and form back to options in correct order
    newOptions.dosage = {
      id: 'dosage',
      label: 'Dosage',
      type: 'select',
      values: this.normalizeValues(dosage),
    };

    newOptions.form = {
      id: 'form',
      label: 'Form',
      type: 'select',
      values: this.normalizeValues(forms),
    };

    this.selectionOptionsObj = newOptions;

    if (this.showDaySupply) {
      this.addDaySupply();
    } else {
      this.removeDaySupply();
    }
  }

  /**
   * Removes day supply from the form options
   */
  @action
  removeDaySupply() {
    const newOptions = { ...this.selectionOptionsObj };
    delete newOptions.daySupply;
    this.selectionOptionsObj = newOptions;
  }

  /**
   * Adds day supply to the form options
   */
  @action
  addDaySupply() {
    const hasDaySupply = this.selectionOptionsObj.daySupply;

    if (!hasDaySupply) {
      // Uncomment this if we add back day supply
      // this.selectiionOptionsObj['daySupply'] = this.daySupply
    }
  }

  /**
   * Update a selections value
   *
   * @param {String} key - the key of the selection to be updated.
   * @param {String} value - the value of the selection to be updated.
   */
  @action
  updateSelection(key: string, value: any) {
    this.formErrors = [];
    switch (key) {
      case 'form':
        this.updateSelectionOptions(value);
        break;
      case 'dosage':
        this.updateDosage(key, value);
        break;
      case 'quantity':
        this.updateQuantity(key, value);
        this.removeEmptyError(key, value);
        break;
      case 'zip':
        this.removeEmptyError(key, value);
        break;
    }

    this.selections[key] = value;
  }

  /** PRIVATE FUNCTIONS */
  /**
   * @private
   * Removes error from zip and quantity if no longer empty
   *
   * @param {String} key - zip and quantity key.
   * @param {Any} value - zip and quantity value.
   */
  removeEmptyError(key: string, value: any) {
    if (value.length) {
      this.formErrors = without(this.formErrors, key);
    }
  }

  /**
   * @private
   * Set the Quantity type and determines wether to show the day supply or not.
   *
   * @param {Array.<String>} quantity - quantity per dose.
   * @param {Object} strength - The dosage object.
   */
  setQuantityType(quantity: Array<string>, strength: Object) {
    if (!quantity.length) {
      this.selectionOptionsObj.quantity.type = 'input';
      this.showDaySupply = true;
      this.selections['quantity'] = '';
    } else {
      this.selectionOptionsObj.quantity.type = 'select';
      this.showDaySupply = false;

      this.selectionOptionsObj.quantity.values = quantity.map((item) => {
        return { label: item.toString(), badge: '', text: '' };
      });

      // Set Correct Selections
      this.selections['quantity'] = strength.default_quantity.toString();
      this.updateDaySupply(strength);
    }
  }

  /**
   * @private
   * Sets the correct quantity and label when a dosage is updated.
   *
   * @param {String} key - dosage key.
   * @param {Any} value - dosage value.
   */
  updateDosage(key: string, value: any) {
    const form = this.autocompleteStore.autocompleteResult.forms.filter((form) => {
      return this.selections['form'] === form.description;
    })[0];

    const dosage = form.strengths.filter((strength) => {
      return strength.description === value;
    })[0];

    if (dosage.quantities.length) {
      this.selectionOptionsObj.quantity.type = 'select';
      this.showDaySupply = false;
      this.selections['quantity'] = dosage.default_quantity.toString();
      this.removeDaySupply();
    } else {
      this.selectionOptionsObj.quantity.type = 'input';
      this.showDaySupply = true;
      this.addDaySupply();
    }

    const newValues = dosage.quantities.map((quantity) => {
      return { badge: '', label: quantity.toString(), text: '' };
    });

    this.selectionOptionsObj.quantity.values = newValues;
    this.dispensableLabel = dosage.dispensable_unit;
  }

  /**
   * @private
   * Normalize data so it can be a special snowflake in the mobile select options
   *
   * @param {Array.<String>} values - dosage or form values.
   * @returns {Object} that hold label, badge, and text all are strings for a given value.
   */
  normalizeValues(values: Array<string>): Object {
    return values.map((value) => {
      return {
        label: value,
        badge: '',
        text: '',
      };
    });
  }

  /**
   * @private
   * Adds quantity if it doesn't exists and updates day supply.
   *
   * @param {String} key - quantities key.
   * @param {Any} value - quantities value.
   */
  updateQuantity(key: string, value: any) {
    this.selections[key] = value;

    const filteredOptions = this.selectionOptionsObj.quantity.values.filter((option) => {
      return option.label === value.toString();
    });

    // Adds quantity option if it doesn't exist
    if (!filteredOptions.length) {
      const newValues = this.selectionOptionsObj.quantity.values;
      newValues.push({
        badge: '',
        label: value.toString(),
        text: '',
      });
      this.selectionOptionsObj.quantity.values = sortBy(newValues, ['label']);
    }

    const form = this.autocompleteStore.autocompleteResult.forms.filter((form) => {
      return this.selections.form === form.description;
    })[0];

    const dosage = form.strengths.filter((strength) => {
      return strength.description === this.selections.dosage;
    })[0];

    this.updateDaySupply(dosage);
  }

  /**
   * @private
   * Sets the correct day supply or defaults to 30
   *
   * @param {Object} dosage - the dosage information for the given supply.
   * @param {Array} dosage.day_supply - the supply for the given.
   *
   * @throws {Error} when the day_supply array is out of bounds or empty.
   * @throws {Error} when the day_supply array is undefined.
   */
  updateDaySupply(dosage: Object) {
    let day_supply = 30;
    const dosageDaySupply = toJS(dosage.day_supply);

    try {
      // Guards against mobx warning for out of bounds array access
      if (Array.isArray(dosageDaySupply)) {
        throw new Error('Dosage day_supply is empty');
      }

      day_supply = dosage.day_supply[this.selections.quantity];

      if (day_supply === undefined) {
        day_supply = 30;
        throw new Error('Day supply undefined');
      }

      // guards against not being able to call toString
      day_supply = day_supply.toString();
    } catch (error) {
      if (!isEmpty(this.currentQuery) && this.currentQuery.daySupply) {
        day_supply = this.currentQuery.daySupply.toString();
      } else {
        Rollbar.warn(
          'Day supply did not exist for quantity given, nor was it available in the query string.',
        );
      }
      Rollbar.log('Day supply did not exist for quantity given.');
    }

    this.selections['daySupply'] = day_supply;
  }

  // TODO: removed not used?
  @computed
  get selectionOptions(): Array<Object> {
    const keys = Object.keys(this.selectionOptionsObj);

    return keys.map((key) => {
      return this.selectionOptionsObj[key];
    });
  }
}

export { SearchFormStore };
