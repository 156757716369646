/* @flow */

import { observable, action, computed } from 'mobx';
import { MemberStore } from '@stores_old/memberStore/memberStore';
import { AlertListStore } from '@data/stores/alertListStore/alertListStore';
import BaseAlert from '@data/models/AlertItemTypes/BaseAlert/BaseAlert';
import BaseMember from '@data/models/Member/BaseMember/BaseMember';
import SelectedSuggestion from '@data/models/Suggestion/SelectedSuggestion/SelectedSuggestion';
import BaseClaim from '@data/models/Claim/BaseClaim';
import { GatekeeperStore } from '@stores_old/gatekeeperStore/gatekeeperStore';

export class SavingsDomainStore {
  memberStore: MemberStore;

  gatekeeperStore: GatekeeperStore;

  alertListStore: AlertListStore;

  /**
   * The member being viewed. All alerts and suggestions should belong to this member.
   * @type {Object}
   */
  @observable selectedMember: BaseMember;

  /**
   * The alert being viewed. All suggestions and pharmacies should belong to this alert.
   * @type {Object}
   */
  @observable selectedAlert: BaseAlert | $Shape<{||}>;

  /**
   * The claim being viewed. All suggestions and pharmacies should belong to this claim.
   * @type {Object}
   */
  @observable selectedClaim: BaseClaim;

  /**
   * The suggestion being viewed. All pharmacies and prices should belong to this suggestion.
   * @type {Object}
   */
  @observable selectedSuggestion: SelectedSuggestion;

  @computed
  get selectedSuggestionId() {
    return this.selectedSuggestion?.id;
  }

  /**
   * The pharmacy currently selected. All prices should belong to this pharmacy.
   * @type {Object}
   */
  @computed
  get selectedPharmacy() {
    return this.selectedSuggestion?.selectedPharmacy;
  }

  /**
   * The selected pricing type. Only retail is supported for now.
   * @type {string}
   */
  @computed
  get selectedPricing(): 'retail' | 'mail' | 'coupon' {
    return this.selectedSuggestion?.selectedPricing;
  }

  @computed
  get pricingKey() {
    return this.selectedSuggestion?.pricingKey;
  }

  @observable status: string = 'loading';

  @observable error: string | Error;

  @computed get isLoading(): boolean {
    return this.status === 'loading';
  }

  @computed get isError(): boolean {
    return this.status === 'error';
  }

  @computed get isSuccess(): boolean {
    return this.status === 'success';
  }

  @action throwError(error: string | Error) {
    Rollbar.error(error);
    this.error = error;
    this.status = 'error';
  }

  constructor({
    memberStore,
    gatekeeperStore,
    alertListStore,
  }: {
    memberStore: MemberStore,
    gatekeeperStore: GatekeeperStore,
    alertListStore: AlertListStore,
  }) {
    this.memberStore = memberStore;
    this.gatekeeperStore = gatekeeperStore;
    this.alertListStore = alertListStore;
  }

  @computed get isNewHomeDeliveryEnabled(): boolean {
    return this.gatekeeperStore.isFeatureEnabled('new_home_delivery');
  }

  @action
  async setSelectedMember(memberId: string) {
    const member = this.memberStore.getMemberById(memberId);

    if (!member) {
      return this.throwError(`Member ${memberId} not found`);
    }
    // Set active member on alertListStore which fetches alerts for that member
    try {
      await this.alertListStore.setActiveMember(member);
    } catch (error) {
      this.throwError(error);
    }
    this.selectedMember = new BaseMember({
      json: this.memberStore.activeMember,
      authenticatedMemberId: this.memberStore.currentMember.id,
    });
  }

  @action
  setSelectedAlert(alertId: number) {
    this.alertListStore.setCurrentAlertId(alertId);
    this.selectedAlert = this.alertListStore.currentAlert;
    if (!this.selectedAlert) {
      this.throwError(`Alert ${alertId} not found for member ${this.selectedMember.id}`);
    }
    if (this.selectedAlert.viewed_at === null) this.selectedAlert.view();
  }

  setClaimFromAlert() {
    if (this.selectedAlert.alertObject && this.selectedAlert.alertObject.medication_claim) {
      this.selectedClaim = new BaseClaim({ json: this.selectedAlert.alertObject.medication_claim });
    } else {
      this.throwError(`alertObject or claim not found on ${this.selectedAlert?.id}`);
    }
  }

  @action
  setSelectedClaim(claimId: ?number) {
    this.setClaimFromAlert();
  }

  @action
  setSelectedSuggestion(suggestionId: number) {
    const suggestionObject = this.selectedAlert.alertObject?.suggestions.find(
      (suggestion) => suggestion.id === suggestionId,
    );
    if (suggestionObject) {
      this.selectedSuggestion = new SelectedSuggestion({
        json: suggestionObject,
        suggestionMember: this.selectedMember,
        authenticatedMember: this.memberStore.currentMember,
        selectedPricing: 'retail',
      });
    } else {
      this.throwError(`suggestionId ${suggestionId} not found in alert ${this.selectedAlert?.id}`);
    }
  }

  @action
  setSelectedPharmacy(pharmacyId: number) {
    try {
      this.selectedSuggestion.setSelectedPharmacy(pharmacyId);
    } catch (e) {
      this.throwError(e);
    }
  }

  @action
  async setSelectedFromQuery(queryString: string) {
    const params = new URLSearchParams(queryString);
    const { member: memberId, alertId, suggestionId, pharmacyId } = Object.fromEntries(params);

    await this.setSelectedMember(memberId);
    this.setSelectedAlert(Number(alertId));
    this.setSelectedClaim();
    this.setSelectedSuggestion(Number(suggestionId));
    this.setSelectedPharmacy(Number(pharmacyId));
    if (!this.isError) {
      this.status = 'success';
    }
  }
}
