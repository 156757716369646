import axios from '@utils/axios/axios';

const medicineCabinetApi = {
  /**
   * This gets all the medicine cabinets associated with the member (both theirs and those of their dependents/)
   * @param {string} memberId
   * @returns {OptionallyCancellableResponse}
   */
  getMemberMedicineCabinet(memberId) {
    return axios.setIsVerbose(false).setMaxCacheAge().get(`/v2/${memberId}/medicine-cabinets`);
  },
};

export default medicineCabinetApi;
