/* @flow */

import { observable, action, computed } from 'mobx';
import { findIndex } from 'lodash';
import SessionManager from '../../utils/session-manager';

class ProgressIndicatorStore {
  @observable indicator: Object;

  sessionManager: Object;

  constructor() {
    this.indicator = [
      {
        name: 'Dashboard',
        url: '/dashboard',
        mobileOnly: false,
        status: 'checked',
      },
      {
        name: 'Options',
        url: null,
        mobileOnly: false,
        status: 'empty',
      },
      {
        name: 'Details',
        url: null,
        mobileOnly: false,
        status: 'empty',
      },
      {
        name: 'Select a Pharmacy',
        url: null,
        mobileOnly: true,
        status: 'empty',
      },
      {
        name: 'Review',
        url: null,
        mobileOnly: false,
        status: 'empty',
      },
    ];

    this.sessionManager = new SessionManager(sessionStorage);
    this.sessionKey = 'indicator';

    const current_history = this.sessionManager.get(this.sessionKey);

    if (!current_history) {
      this.sessionManager.set(this.sessionKey, this.indicator);
    } else {
      this.indicator = current_history;
    }
  }

  @action
  overWriteFirstIndicator(name) {
    const cloneHistory = this.indicator.slice(0);
    cloneHistory[0].name = name;

    this.indicator = cloneHistory;
    this.sessionManager.set(this.sessionKey, this.indicator);

    return this.indicator;
  }

  @action
  setCurrentPage(name, url) {
    const search = findIndex(this.indicator, (history) => {
      return history.name === name;
    }); // search is an index of where this particular page is

    if (search >= 0) {
      const cloneHistory = this.indicator.slice(0);
      const historyObject = cloneHistory[search];

      // overwrite current location
      if (historyObject) {
        historyObject.url = url;
        historyObject.status = 'current';
      }

      // unset any future search keys
      cloneHistory.forEach((history, key) => {
        if (key > search) {
          history.url = null;
          history.status = 'empty';
        }

        if (key < search) {
          history.status = 'checked';
        }
      });

      // stupid cl0ne warz
      this.indicator = cloneHistory;
      this.sessionManager.set(this.sessionKey, this.indicator);
    }

    return this.indicator;
  }

  @action
  getCurrentPageIndex(name) {
    const search = findIndex(this.desktop, (history) => {
      return history.name === name;
    }); // search is an index of where this particular page is

    return search;
  }

  @computed
  get desktop() {
    const { indicator } = this;

    return indicator.filter((i) => {
      return !i.mobileOnly;
    });
  }

  @computed
  get mobile() {
    const { indicator } = this;

    return indicator.filter(() => {
      return true;
    });
  }
}

const progressIndicatorStore = new ProgressIndicatorStore();

export default progressIndicatorStore;
export { ProgressIndicatorStore };
