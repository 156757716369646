/* @flow */
import * as React from 'react';
import PropTypes from 'prop-types';
import { createBrowserHistory } from 'history';
import type { BrowserHistory } from 'history';
import { Router } from 'react-router-dom';

type Props = {
  children: React.Node,
  navigationListeners: Array<any>,
};

/**
 * The public API for a <Router> that uses HTML5 history.
 */
class AppRouter extends React.Component<Props> {
  static propTypes = {
    children: PropTypes.node,
    navigationListeners: PropTypes.array,
  };

  history: BrowserHistory;

  constructor(props: Props) {
    super(props);

    this.history = this.initHistory();
  }

  /**
   * Create the history object for the router and bind listeners to it
   *
   * @returns {*}
   */
  initHistory() {
    const history = createBrowserHistory(this.props);

    this.props.navigationListeners.forEach((listener) => {
      history.listen(listener);
    });

    return history;
  }

  /**
   * Render the router with the custom history we created
   *
   * @returns {*}
   */
  render() {
    return <Router history={this.history} children={this.props.children} />;
  }
}

export default AppRouter;
