/* @flow */
/**
 * Enum representing paymentCollection values to be passed as query parameters to /home-delivery-request.
 * @enum {string}
 */
export const PAYMENT_COLLECTION = Object.freeze({
  /**
   * Enum value for cash pricing.
   */
  CASH: 'cash',
  INSURANCE: 'insurance',
});

export type PAYMENT_COLLECTION_TYPE = $Values<typeof PAYMENT_COLLECTION>;
