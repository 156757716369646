import { Factory, trait } from 'miragejs';

const newResponse = {
  id: 1,
  ncpdp: '5660091',
  npi: '1',
  name: 'Pharmacy Name',
  type: 'retail',
  address: {
    street: '123 Fake Street',
    city: 'Kansas City',
    state: 'MO',
    zip: 64110,
    latitude: '45.49731000',
    longitude: '-122.63407000',
  },
  phone: '8888888888',
  extension: null,
  fax: '8888888888',
  email: 'fake@rxss.io',
  home_delivery_provider: 'Pharmacy Name',
  member_cost: 10,
  client_cost: 5,
  coupon_member_cost: null,
  suggested_drugs: [
    {
      ndc: 1,
      name: 'Drug Name',
      primary_name: 'Drug',
      secondary_name: 'Name',
      strength: '20mg',
      form: 'Tablet',
      is_brand: false,
      is_off_market: false,
      is_clone: false,
      is_over_the_counter: false,
      image_url: 'https://images.rxsavingssolutions.com/DrugItem_6983.JPG',
      days_supply: 30,
      quantity: 30,
      quantity_with_unit: '30 Tablets',
      member_cost: 10,
      client_cost: 5,
      is_pbm_max_cost: false,
      source: 'module_plan_modifiers',
      coupon_member_cost: null,
      coupon_details: {},
      coverage_alerts: [],
    },
  ],
};

const cashPharmacy = {
  ...newResponse,
  name: 'RxSS, delivered by ScriptHero Pharmacy',
  home_delivery_provider: 'RxSS, delivered by ScriptHero Pharmacy',
  suggested_drugs: [
    {
      ...newResponse.suggested_drugs[0],
      source: 'module_cash_pricing',
    },
  ],
};

const insurancePharmacy = {
  ...newResponse,
  name: 'TRUEPILL',
  home_delivery_provider: 'TRUEPILL',
  suggested_drugs: [
    {
      ...newResponse.suggested_drugs[0],
      source: 'module_plan_modifiers',
    },
  ],
};

/**
 * Creates an individual opportunity with customizable suggestions relationship
 * - Will automatically add opportunity to combined-alerts response
 * - Will automatically create one related suggestion if none are passed upon creation
 * - `withSuperAlert` trait takes the first suggestion on the opportunity and makes it into a Super Alert
 */
export const homeDeliveryTransferFactory = Factory.extend({
  status: 'QUALIFIES',
  data: {
    claim_pharmacy: {
      ncpdp: '1719953',
      name: 'GENOA HEALTHCARE, LLC',
      address: {
        street: '208 EAST 7TH ST',
        city: 'HAYS',
        state: 'KS',
        zipcode: '67601',
      },
    },
    drug: {
      dispensable_unit: 'Tablet(s)',
      name: 'Propranolol',
      ndc: '00115166003',
    },
  },
  withOldResponse: trait({
    afterCreate(homeDeliveryTransfer, server) {
      homeDeliveryTransfer.data = {
        ...homeDeliveryTransfer.data,
        price: {
          day_supply: 30,
          ncpdp: '5660091',
          ndc: '00115166003',
          quantity: 60,
          total_client_cost: 20.03,
          total_member_cost: 5.01,
        },
      };
      homeDeliveryTransfer.save();
    },
  }),
  withCashPharmacy: trait({
    afterCreate(homeDeliveryTransfer, server) {
      homeDeliveryTransfer.data = {
        ...homeDeliveryTransfer.data,
        priced_pharmacies: [cashPharmacy],
      };
      homeDeliveryTransfer.save();
    },
  }),
  withInsurancePharmacy: trait({
    afterCreate(homeDeliveryTransfer, server) {
      homeDeliveryTransfer.data = {
        ...homeDeliveryTransfer.data,
        priced_pharmacies: [insurancePharmacy],
      };
      homeDeliveryTransfer.save();
    },
  }),
  withMultiplePharmacies: trait({
    afterCreate(homeDeliveryTransfer, server) {
      homeDeliveryTransfer.data = {
        ...homeDeliveryTransfer.data,
        priced_pharmacies: [cashPharmacy, insurancePharmacy],
      };
      homeDeliveryTransfer.save();
    },
  }),
});
