/** @module Stores */
// KEEP THIS IN ALPHABETICAL ORDER ಠ_ಠ

import { AlertListStore } from '@data/stores/alertListStore/alertListStore';
import RxHomeTransferStore from '@data/RxHomeTransfer/RxHomeTransferStore';
import { SavingsDomainStore } from '@features/savings/stores/SavingsDomainStore/SavingsDomainStore';
import { SavingsUiStore } from '@features/savings/stores/SavingsUiStore/SavingsUiStore';
import autocompleteStore from './autocompleteStore/autocompleteStore';
import { BreadcrumbStore } from './breadcrumbStore/breadcrumbStore';
import emailModalStore from './emailModalStore/emailModalStore';
import gatekeeperStore from './gatekeeperStore/gatekeeperStore';
import locatorPageStore from './locatorPageStore/locatorPageStore';
import memberStore from './memberStore/memberStore';
import mfCardModalStore from './mfCardModalStoreStore/mfCardModalStoreStore';
import prescriberModalStore from './prescriberModalStore/prescriberModalStore';
import productStore from './productStore/productStore';
import textModalStore from './textModalStore/textModalStore';
import themeStore from './themeStore/themeStore';
import userMessageStore from './userMessageStore/userMessageStore';
import progressIndicatorStore from './progressIndicatorStore/progressIndicatorStore';
import waitStateStore from './waitStateStore/waitStateStore';
import globalModalStore from './globalModalStore/globalModalStore';
import familyPermissionsStore from './familyPermissionsStore/familyPermissionsStore';

import { TranslationStore } from './translationStore/translationStore';
import { CouponSuggestionsStore } from '../dataStores/couponSuggestionStore/couponSuggestionStore';
import { ProfileStore } from './profileStore/profileStore';
import { HomePageStore } from './homePageStore/homePageStore';
import { DrugSearchStore } from './drugSearchStore/drugSearchStore';
import { MedicineCabinetStore } from './medicineCabinetStore/medicineCabinetStore';
import { RemindersStore } from './remindersStore/remindersStore';
import { PasswordModalStore } from './passwordModalStore/passwordModalStore';
import { PhaseDetailsModalStore } from './phaseDetailsModalStore/phaseDetailsModalStore';
import { SearchFormStore } from './searchFormStore/searchFormStore';
import { ContactModalStore } from './contactModalStore/contactModalStore';
import { RecentSearchStore } from './recentSearchStore/recentSearchStore';
import { LocatorOptionStore } from './locatorOptionStore/locatorOptionStore';
import { ContactPrescriberModalStore } from './contactPrescriberModalStore/contactPrescriberModalStore';
import { PrescriberLetterModalStore } from './prescriberLetterModalStore/prescriberLetterModalStore';
import { SpendingReportStore } from './spendingReportStore/spendingReportStore';
import { DoctorVisitReportStore } from './doctorVisitReportStore/doctorVisitReportStore';
import { GeneralMessagingStore } from './generalMessagingStore/generalMessagingStore';
import { UserIdentityStore } from './userIdentityStore/userIdentityStore';

const translationStore = new TranslationStore();
// Establish an initial translation so other stores can utilize an initial value
translationStore.setTranslation('rxss');

const couponSuggestionsStore = new CouponSuggestionsStore(memberStore, gatekeeperStore);

const medicineCabinetStore = new MedicineCabinetStore(
  memberStore,
  translationStore,
  userMessageStore,
);

const remindersStore = new RemindersStore(memberStore, medicineCabinetStore);

const searchFormStore = new SearchFormStore(productStore, autocompleteStore);

const homePageStore = new HomePageStore(gatekeeperStore, memberStore);

const profileStore = new ProfileStore(
  memberStore,
  globalModalStore,
  userMessageStore,
  locatorPageStore,
);

const drugSearchStore = new DrugSearchStore(memberStore);

const passwordModalStore = new PasswordModalStore(memberStore);

const phaseDetailsModalStore = new PhaseDetailsModalStore(memberStore);

const contactModalStore = new ContactModalStore();

const recentSearchStore = new RecentSearchStore(productStore);

const locatorOptionStore = new LocatorOptionStore(memberStore);

const prescriberLetterModalStore = new PrescriberLetterModalStore(memberStore);

const spendingReportStore = new SpendingReportStore(memberStore);

const doctorVisitReportStore = new DoctorVisitReportStore(memberStore);

const generalMessagingStore = new GeneralMessagingStore(memberStore);

const contactPrescriberModalStore = new ContactPrescriberModalStore();

const alertListStore = new AlertListStore({
  memberStore,
  childStores: {
    profileStore,
    couponSuggestionsStore,
    gatekeeperStore,
    globalModalStore,
    memberStore,
    translationStore,
    familyPermissionsStore,
  },
});

const breadcrumbStore = new BreadcrumbStore({ translationStore });

const rxHomeTransferStore = new RxHomeTransferStore({
  memberStore,
  alertListStore,
});

const savingsDomainStore = new SavingsDomainStore({ memberStore, gatekeeperStore, alertListStore });

const savingsUiStore = new SavingsUiStore({ savingsDomainStore, translationStore });

const userIdentityStore = new UserIdentityStore();
userIdentityStore.init();

export {
  alertListStore,
  breadcrumbStore,
  contactModalStore,
  contactPrescriberModalStore,
  couponSuggestionsStore,
  homePageStore,
  drugSearchStore,
  emailModalStore,
  gatekeeperStore,
  memberStore,
  passwordModalStore,
  prescriberModalStore,
  productStore,
  searchFormStore,
  textModalStore,
  profileStore,
  themeStore,
  translationStore,
  medicineCabinetStore,
  mfCardModalStore,
  phaseDetailsModalStore,
  remindersStore,
  recentSearchStore,
  autocompleteStore,
  locatorOptionStore,
  locatorPageStore,
  userMessageStore,
  progressIndicatorStore,
  waitStateStore,
  prescriberLetterModalStore,
  globalModalStore,
  spendingReportStore,
  doctorVisitReportStore,
  familyPermissionsStore,
  generalMessagingStore,
  rxHomeTransferStore,
  savingsDomainStore,
  savingsUiStore,
  userIdentityStore,
};
