const english = {
  code: 'en',
};

const spanish = {
  code: 'es',
};

export { english as en };
export { spanish as es };
