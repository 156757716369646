export default {
  'contact-prescriber': {
    'step2-Note': 'NOTE: With this coupon, the amount you pay will not pay down your deductible.',
  },
  'pharmacy-picker-body': {
    'step2-Note': 'NOTE: With this coupon, the amount you pay will not pay down your deductible.',
  },
  'prices-section': {
    cta: {
      'coupon-note':
        "NOTE: When using a coupon, the amount you pay won't be applied to your insurance deductible.",
    },
  },
};
