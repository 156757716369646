export default function overrideGlobalVariables(tokens) {
  if (tokens?.colors?.light && Object.keys(tokens?.colors?.light).length !== 0) {
    Object.entries(tokens?.colors?.light).map((colorToken) => {
      document.documentElement.style.setProperty('--' + colorToken[0], colorToken[1]);
    });
  }
  if (tokens?.spacing && Object.keys(tokens?.spacing).length !== 0) {
    Object.entries(tokens?.spacing).map((spacingToken) => {
      document.documentElement.style.setProperty('--' + spacingToken[0], spacingToken[1] + 'px');
    });
  }
}
