import { createServer, Factory, trait, Model, Response, hasMany, belongsTo } from 'miragejs';

import { snakeCase } from 'lodash';
import mockConfigResponse from '../test/util/mockConfigResponse.json';
import mockDesignDictResponse from '../test/util/mockDesignDictResponse.json';
import mockMemberClaimsResponse from '../test/util/mockMemberClaimsResponse.json';
import mockMedicineCabinetResponse from '../test/util/mockMedicineCabinetResponse.json';
import mockMembersResponse from '../test/util/mockMembersResponse.json';
import mockSuggestionsResponse from '../test/util/mockSuggestionsResponse.json';
import mockUserIdentityResponse from '../test/util/mockUserIdentityResponse.json';
import { suggestionFactory } from '../mirage/factories/Suggestion/suggestionFactory';
import { opportunityFactory } from '../mirage/factories/Opportunity/opportunityFactory';
import { ApplicationSerializer } from '../mirage/serializers/application';
import { expressSuggestionFactory } from '../mirage/factories/ExpressSuggestion/expressSuggestionFactory';
import { homeDeliveryTransferFactory } from '../mirage/factories/HomeDeliveryTransfer/homeDeliveryTransferFactory';

function sanitizeRoute(route) {
  if (route[0] === '/') {
    return route.slice(1, route.length);
  }

  return route;
}

function getResponseFromFileBasedRoute(route, httpMethod) {
  const sanitizedRoute = sanitizeRoute(route);

  return require(`../test/util/filerouting/api/${sanitizedRoute}.${httpMethod}.json`);
}

/**
 * Initializes a MirageJS route handler that utilizes file-based route under the "test/util/filerouting/api" directory.
 *
 * @param {object} server
 * @param {string} route
 * @param {"get" | "post" | "put" | "delete"} httpMethod
 */
function initializeFileBasedRoute(server, route, httpMethod) {
  server[httpMethod](route, () => {
    return getResponseFromFileBasedRoute(route, httpMethod);
  });
}

/** Define a gate to define alternate routing behavior */
class Gate {
  /**
   * @param check - a boolean or a function which returns a boolean
   * @param failedCheckResponse - returned when the check is false
   */
  constructor(check, failedCheckResponse) {
    this.check = check;
    this.failedCheckResponse = failedCheckResponse;
  }

  /**
   * Returns the mock response if the check passes, otherwise the failedCheckResponse
   * @param mockResponse - the response to return if the check passes
   * @returns {*} - a response to be returned based on the check
   */
  processResponse(mockResponse) {
    return this.check ? mockResponse : this.failedCheckResponse;
  }
}

/**
 * Function for initializing the Home Delivery routes.
 *
 * @param {Object} server - The `this` context provided while in the routes() method for creating the MirageJS server.
 */
function initializeHomeDeliveryRoutes(server) {
  const fileRoute = (route, httpMethod) => initializeFileBasedRoute(server, route, httpMethod);

  fileRoute('/v2/:memberUid/claims/:claimId', 'get');
  fileRoute('/home-delivery/:memberUid/allergies', 'get');
  fileRoute('/home-delivery/:memberUid/medications', 'get');
  fileRoute('/home-delivery/:memberUid/medical-conditions', 'get');
  fileRoute('/home-delivery/:memberUid/insurance', 'get');
  fileRoute('/home-delivery/:memberUid/shipping-addresses', 'get');
  fileRoute('/v2/:memberUid/home-delivery-transfers', 'get');

  fileRoute('/home-delivery/:memberUid/insurance', 'put');
  fileRoute('/home-delivery/:memberUid/shipping-addresses', 'put');

  fileRoute('/home-delivery/:memberUid/home-delivery-request', 'post');
}

export function makeServer({ environment = 'development' } = {}, isAuthenticated = true) {
  const authGate = new Gate(isAuthenticated, new Response(401));

  const server = createServer({
    environment,
    logging: true,

    serializers: {
      application: ApplicationSerializer,
      opportunity: ApplicationSerializer.extend({
        include: ['suggestions', 'expressSuggestion'],
        // Mirage stores models in camelCase, this is needed to output as snake case express_suggestion
        keyForEmbeddedRelationship(modelName) {
          return snakeCase(modelName);
        },
      }),
      expressSuggestion: ApplicationSerializer.extend({
        include: ['suggestion'],
      }),
    },

    // TODO - add models for member, product, alerts, etc..
    models: {
      config: Model,
      suggestion: Model,
      expressSuggestion: Model.extend({
        suggestion: belongsTo(),
        opportunity: belongsTo(),
      }),
      opportunity: Model.extend({
        suggestions: hasMany(),
        expressSuggestion: belongsTo(),
      }),
      homeDeliveryTransfer: Model.extend(),
    },

    factories: {
      config: Factory.extend({
        ...mockConfigResponse,
        withUniversalSavings: trait({
          afterCreate(config) {
            config.features_enabled.push('universal_savings_flow_beta');
          },
        }),
      }),
      suggestion: suggestionFactory,
      expressSuggestion: expressSuggestionFactory,
      opportunity: opportunityFactory,
      homeDeliveryTransfer: homeDeliveryTransferFactory,
    },

    // TODO - using mirage models and fakerjs library, create seed scripts for common scenarios
    seeds(server) {
      // Initialize the default config
      server.create('config');

      // Create an opportunity with a super alert
      server.create('opportunity', 'withSuperAlert');

      // Create a suggestion with PbmMaxCost Pricing then add it as a Super Alert to an Opportunity
      const pbmSuggestion = server.create('suggestion', 'withPbmMaxCost');
      server.create('opportunity', { suggestions: [pbmSuggestion] }, 'withSuperAlert');

      // Create one homeDeliveryTransfer response of each type
      // ORDER MATTERS! The /medicine-cabinets response is currently hardcoded to match these factories
      server.create('homeDeliveryTransfer', 'withOldResponse');
      server.create('homeDeliveryTransfer', 'withInsurancePharmacy');
      server.create('homeDeliveryTransfer', 'withCashPharmacy');
      server.create('homeDeliveryTransfer', 'withMultiplePharmacies');
    },

    routes() {
      this.passthrough('/assets/**');

      // Checking if Pa11y-ci is running and if it is adding a url prefix
      // that allows
      if (window.location.href.includes('http://localhost:8089/')) {
        this.urlPrefix = 'http://localhost:8089';
      } else {
        this.namespace = 'api';
      }

      this.get('/v2/config', (schema) => {
        return authGate.processResponse({ data: schema.configs.first() });
      });

      this.get('/design-dictionaries/members', () => {
        return authGate.processResponse(mockDesignDictResponse);
      });

      this.get('/v2/mb-vf726np/claims', () => {
        return authGate.processResponse(mockMemberClaimsResponse);
      });

      this.get('/v2/mb-vf726np/combined-alerts', function (schema) {
        // Mirage only runs the serializer if a model is directly returned on a route,
        // so we need to call this.serialize on the opportunities
        const combinedAlerts = {
          alerts: this.serialize(schema.opportunities.all()),
          contact_prescriber_enabled: true,
        };
        return authGate.processResponse(combinedAlerts);
      });

      this.get('/v2/mb-vf726np/medicine-cabinets', () => {
        return authGate.processResponse(mockMedicineCabinetResponse);
      });

      this.get('/v2/mb-vf726np/members', () => {
        return authGate.processResponse(mockMembersResponse);
      });

      this.get('/v2/mb-vf726np/suggestions', function (schema) {
        // Mirage only runs the serializer if a model is directly returned on a route,
        // so we need to call this.serialize on the suggestions
        const suggestionsResponse = {
          ...mockSuggestionsResponse,
          suggestions: this.serialize(schema.suggestions.all()),
        };
        return authGate.processResponse(suggestionsResponse);
      });

      // this.get('/v2/:memberUid/home-delivery-transfers', (schema, request) => {
      //   const hasSeededResponses = schema.homeDeliveryTransfers.first();
      //   if (hasSeededResponses) {
      //     // Using the claimId to match the homeDeliveryTransferId
      //     // This is not the correct way to do it, but it was the quickest
      //     const id = request.queryParams.claimId;
      //     return schema.homeDeliveryTransfers.find(id);
      //   } else {
      //     return getResponseFromFileBasedRoute('/v2/:memberUid/home-delivery-transfers', 'get');
      //   }
      // });

      this.post('/contact-prescriber', () => {
        return authGate.processResponse({
          response: {
            id: 12345,
            truepill_uri: null,
          },
        });
      });

      this.get(`/members/search-history`, () => {
        return Response(404);
      });

      this.put('/members/alerts', () => {
        return {};
      });

      this.get(`/user/identity`, () => {
        return authGate.processResponse(mockUserIdentityResponse);
      });

      initializeHomeDeliveryRoutes(this);

      this.passthrough('https://assets.rxss.design/**');
      this.passthrough('https://images.rxsavingssolutions.com/**');
    },
  });

  return server;
}
