/* @flow */

import { observable, action } from 'mobx';

class WaitStateStore {
  @observable suggestions_called: boolean;

  @observable pharmacies_called: boolean;

  @observable pricing_called: boolean;

  @observable report_loaded: boolean;

  constructor() {
    this.suggestions_called = false;
    this.pharmacies_called = false;
    this.pricing_called = false;
    this.report_loaded = false;
  }

  @action
  resetLoadEvents() {
    this.suggestions_called = false;
    this.pharmacies_called = false;
    this.pricing_called = false;
    this.report_loaded = false;
  }

  @action
  setLoadEvent(attr, val) {
    this[attr] = val;
  }
}

const waitStateStore = new WaitStateStore();

export default waitStateStore;
export { WaitStateStore };
