/* @flow */

import { observable, action } from 'mobx';

class PhaseDetailsModalStore {
  open: Function;

  close: Function;

  memberStore: Object;

  @observable show: boolean;

  constructor(memberStore: Object) {
    this.show = false;
    this.memberStore = memberStore;

    this.open = this.open.bind(this);
    this.close = this.close.bind(this);
  }

  @action
  open() {
    this.show = true;
  }

  @action
  close() {
    this.show = false;
  }
}

export { PhaseDetailsModalStore };
