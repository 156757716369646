import mergeTranslations from './mergeTranslations';

export default function getTranslations(clientLanguageId = 'rxss', langCode) {
  const rxssTranslations = require(`./languages/${langCode}/rxss`).default;
  let customTranslations = {};

  try {
    if (clientLanguageId !== 'rxss') {
      customTranslations = require(`./languages/${langCode}/${clientLanguageId}`).default;
    }
  } catch (error) {
    /**
     * The error is caught when the client Id is not 'rxss' and if the client has
     * no custom translations for the given language(langCode).
     */
  }

  return mergeTranslations(rxssTranslations, customTranslations);
}
