export default {
  form: {
    id: 'form',
    label: 'Form',
    type: 'select',
    values: [
      {
        label: '',
        badge: '',
        text: '',
      },
    ],
  },
  dosage: {
    id: 'dosage',
    label: 'Dosage',
    type: 'select',
    values: [
      {
        label: '',
        badge: '',
        text: '',
      },
    ],
  },
  quantity: {
    id: 'quantity',
    label: 'quantity',
    type: 'select',
    values: [],
  },
  zip: {
    id: 'zip',
    label: 'Zip Code',
    type: 'input',
  },
};
