/* @flow */

import { observable, action, computed } from 'mobx';
import axios from '@utils/axios/axios';
import { titleCaseMemberNames } from '@utils/Functions/titleCaseFunction/titleCaseFunction';
import { MEMBER_UID_PATTERN } from '@utils/Constants/regex.js';
import { ItemStore } from '../itemStore/itemStore';

class MemberStore extends ItemStore {
  /**
   * Represents the authenticated member, will not change during a session until the member logs out
   * @type {Object}
   */
  @observable currentMember: Object;

  /**
   * Represents the member whose data is being viewed, such as on a Dashboard Tab. Can change during a session as a member clicks family member’s tabs
   * @type {Object}
   */
  @observable activeMember: Object;

  /**
   * Array of members related to the currently authenticated member
   * @type {Array<Object>}
   */
  @observable members: Array<Object> = [];

  @observable currentPhases: Object = {};

  @observable loaded: boolean = false;

  hasAlerts: boolean;

  hasMeds: boolean;

  constructor() {
    super('members');
  }

  @action
  setActivePhase() {
    this.members.forEach((member) => {
      if (member.health_plan_info) {
        this.currentPhases[member.id] = member.health_plan_info.current_phase;
      } else {
        this.currentPhases[member.id] = null;
      }
    });
  }

  @action
  updateCurrentMember(memberId: string, newValues: Object) {
    const oldMembers = JSON.parse(JSON.stringify(this.members));
    const oldMember = oldMembers.findIndex((member) => {
      return member.id === memberId;
    });
    oldMembers[oldMember] = Object.assign(oldMembers[oldMember], newValues);
    this.setActiveMember(oldMembers[oldMember]);
    this.members = oldMembers;
  }

  @action
  setCurrentMember(member: Object, members: Array<Object>) {
    this.currentMember = member;
    const dependents = members.filter((aMember) => {
      return aMember.id !== member.id;
    });

    this.members = [member].concat(dependents);

    this.setActivePhase();
  }

  @action
  setActiveMember(member: Object) {
    this.activeMember = member;
    this.loaded = true;
  }

  @action
  setActiveMemberById(id: string) {
    const member = this.members.find((mem) => mem.id === id);
    if (!member) {
      Rollbar.warn(`Attempted to set active member using memberUid not in family: ${id}`);
    }
    this.setActiveMember(member);
  }

  /**
   * Actions to perform after receiving resolved response from members endpoint.
   * Useful to call directly in testing to init store data without needing to mock API calls.
   *
   * @param {Object} response Response object from members endpoint
   * @param {boolean} reset Whether this call is a refresh rather than the initial load of the memberStore
   * @return {String | Array<Model>} The member's first name or list of current family members}
   */
  @action
  initStoreFromResponse(response: Object, reset: boolean) {
    let memberData = response.data.member;
    memberData = titleCaseMemberNames(memberData);
    const currentMembersFamily = memberData.family.concat([memberData]).sort((member) => {
      return member.first_name;
    });
    this.setCurrentMember(memberData, currentMembersFamily);
    if (!reset) {
      this.setActiveMember(memberData);
    }
    this.loaded = true;
    return currentMembersFamily;
  }

  /**
   * Fetch and transform member data to initialize memberStore
   * @param {string} member memberId to be loaded
   * @param reset Whether this call is a refresh rather than the initial load of the memberStore
   * @return {Promise<T | boolean>}
   */
  @action
  async initMember(member: string, reset: boolean) {
    this.loaded = false;
    return axios
      .setCheckAuthorization(true)
      .get(`/v2/${member}/members`)
      .then((response) => {
        return this.initStoreFromResponse(response, reset);
      })
      .catch(() => {
        this.loaded = true;
        return false;
      });
  }

  @action
  updateCurrentPhases(id: string, phase: number) {
    const phases = { ...this.currentPhases };
    phases[id] = phase;
    this.currentPhases = phases;
  }

  @action
  async refresh() {
    const initCurrentMember = await this.initMember(this.currentMember.id, true);
    return initCurrentMember;
  }

  /**
   * Data for members related to the currently authenticated member keyed by their memberId.
   * Stored in key-value pairs of memberId: memberDataObject
   * @return {Object} members objects keyed by their memberId
   */
  @computed
  get membersById(): Object {
    return this.members.reduce((membersById, member) => {
      return { ...membersById, [member.id]: member };
    }, {});
  }

  /**
   * Returns member data object for passed memberUid if it exists on the store.
   * @param id memberUid
   * @return {Object|null} Member data object. Returns null if member does not exist on store (Is not a family member OR invalid memberUid format).
   */
  getMemberById(id: string) {
    const memberId = id.toLowerCase();
    if (!MEMBER_UID_PATTERN.test(memberId)) {
      Rollbar.warn(`Requested Id does not match memberUid format: ${memberId}`);
      return null;
    }

    const member = this.membersById[memberId];
    if (!member) {
      Rollbar.warn(`Requested memberUid not found in family: ${memberId}`);
      return null;
    }
    return member;
  }

  @computed
  get onPrimaryMember() {
    return this.currentMember.id === this.activeMember.id;
  }
}

const memberStore = new MemberStore();

export default memberStore;
export { MemberStore };
