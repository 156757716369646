import { Factory, trait } from 'miragejs';
import { opportunityStatic } from './opportunityStatic';

/**
 * Creates an individual opportunity with customizable suggestions relationship
 * - Will automatically add opportunity to combined-alerts response
 * - Will automatically create one related suggestion if none are passed upon creation
 * - `withSuperAlert` trait takes the first suggestion on the opportunity and makes it into a Super Alert
 */
export const opportunityFactory = Factory.extend({
  ...opportunityStatic,
  withSuperAlert: trait({
    afterCreate(opportunity, server) {
      if (!opportunity.expressSuggestion) {
        const suggestionForSuperAlert = opportunity.suggestions.models[0];
        opportunity.update({
          expressSuggestion: server.create('expressSuggestion', {
            suggestion: suggestionForSuperAlert,
          }),
        });
      }
    },
  }),
  afterCreate(opportunity, server) {
    // If opportunity has no suggestions, create one
    if (opportunity.suggestions.length < 1) {
      opportunity.suggestions = [server.create('suggestion')];
      opportunity.save();
    }
  },
});
