import { formatMoney } from '@utils/Functions';
import queryString from 'query-string';
import BaseAlert from '../BaseAlert/BaseAlert';
import AlertTypes from '../AlertTypes';

class MailOrderAlert extends BaseAlert {
  constructor(info) {
    super(info);
    this.icon = 'mail-order';
    this.modelName = AlertTypes.MAIL_ORDER;
    this._headerText = this.translate('mail-order-alert.header', {
      amount: formatMoney(this.alertObject.refill_savings),
    });
    this.buttonText = this.translate('mail-order-alert.button');
  }

  /**
   * @inheritDoc
   */
  navigate() {
    const {
      id: alertId,
      medication_claim: {
        day_supply: daySupply,
        quantity,
        id: claimId,
        employer_cost: employerCost,
        member_cost: lastPaid,
      },
      savings_type: alertType,
      suggestions,
    } = this.alertObject;
    this.view();
    return {
      pathname: '/prices/mail-order',
      search: queryString.stringify({
        daySupply,
        quantity,
        claimId,
        lastPaid,
        member: this.memberStore.activeMember.id,
        alertType,
        employerCost,
        alert_id: alertId,
        pricing: 'mail_order',
        suggestion: suggestions.find((suggestion) => suggestion.type === 'MO').id,
      }),
    };
  }

  get headerText() {
    return this._headerText;
  }
}

export default MailOrderAlert;
