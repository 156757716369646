/* @flow */

import { observable, action, computed } from 'mobx';
import { isEmpty, chunk, filter, sortBy } from 'lodash';
import { SessionManager } from '@utils/session-manager';
import axios from '@utils/axios/axios';

class LocatorPageStore {
  @observable address: Object;

  @observable geo_location: Object;

  @observable zip: string;

  @observable type: string;

  @observable map_center: Object;

  @observable page: number;

  @observable allPharmacies: Object;

  @observable ready: Boolean;

  @observable fromSettings: Boolean;

  @observable zipCodeError: Boolean;

  @observable addressError: Boolean;

  @observable noPharmaciesAtAddress: Boolean;

  @observable noPharmaciesAtZipcode: Boolean;

  constructor() {
    this.sessionManager = new SessionManager(sessionStorage);
    this.appUrl = window.app_url || global.app_url;

    // Base sessionStorage values for the page
    this.address = this.sessionManager.get('locator.address') || {};
    this.geo_location = this.sessionManager.get('locator.geo_location') || {};
    this.zip = this.sessionManager.get('locator.zip') || null;
    this.type = this.sessionManager.get('locator.type') || null;
    this.map_center = this.sessionManager.get('locator.map_center') || null;
    this.page = this.sessionManager.get('locator.page') || null;
    this.allPharmacies = {};
    this.ready = false;
  }

  unsetParams() {
    this.sessionManager.remove('locator.address');
    this.sessionManager.remove('locator.geo_location');
    this.sessionManager.remove('locator.zip');
    this.sessionManager.remove('locator.type');
    this.sessionManager.remove('locator.map_center');
    this.sessionManager.remove('locator.page');

    this.allPharmacies = {};
    this.address = {};
    this.geo_location = {};
    this.zip = null;
    this.type = null;
    this.map_center = null;
    this.page = null;
    this.ready = false;
  }

  @action resetErrors() {
    this.zipCodeError = false;
    this.addressError = false;
    this.noPharmaciesAtAddress = false;
    this.noPharmaciesAtZipcode = false;
  }

  setParam(key, value) {
    try {
      this.sessionManager.set('locator.' + key, value);
    } catch (error) {
      // /shrug
    }
    this[key] = value;
  }

  @action
  async initPage() {
    this.ready = false;
    const pharmacies = await this.getPharmacies();

    if (!isEmpty(pharmacies)) {
      this.allPharmacies = pharmacies;
    }

    this.ready = true;
  }

  @action
  async getPharmacies() {
    let params = `?order=distance&limit=${this.fromSettings ? 10 : 60}`;
    if (this.type === 'address') {
      params =
        params + `&latitude=${this.geo_location.latitude}&longitude=${this.geo_location.longitude}`;
    } else {
      params = params + `&location=${this.zip}`;
    }

    return axios
      .setCheckAuthorization(true)
      .setIsVerbose(false)
      .get(`/pharmacies${params}`)
      .then((response) => {
        const meta = response.metadata;
        let data = filter(response.response, (pharmacy) => {
          return pharmacy.type !== 'mail_order' && pharmacy.type !== 'specialty_mail_order';
        });
        if (isEmpty(data)) {
          if (this.type === 'address') {
            this.noPharmaciesAtAddress = true;
          } else this.noPharmaciesAtZipcode = true;
        }
        data = sortBy(data, 'distance');
        if (!isEmpty(meta.location)) {
          this.setParam('map_center', meta.location);
        }
        return data;
      })
      .catch((error) => {
        if (error.response.status === 400 && this.fromSettings) {
          if (this.type === 'address') {
            this.addressError = true;
          } else this.zipCodeError = true;
        }
        return {};
      });
  }

  @computed
  get displayPharmacies(): ?Object {
    return chunk(this.allPharmacies, 20)[this.page - 1];
  }

  @computed
  get pageCount(): ?Number {
    return chunk(this.allPharmacies, 20).length;
  }

  @computed
  get selectedPharmacy(): ?Object {
    return false;
  }
}

const locatorPageStore = new LocatorPageStore();

export default locatorPageStore;
export { LocatorPageStore };
