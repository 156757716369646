/* @flow */

import { observable, action } from 'mobx';
import axios from '@utils/axios/axios';

class ContactModalStore {
  @observable show: boolean;

  @observable message: string;

  @observable success: boolean;

  @observable error: boolean;

  @observable noBodyError: boolean;

  @observable errorMessage: any;

  @observable isSendingMessage: boolean;

  constructor() {
    this.show = false;
    this.isSendingMessage = false;
  }

  @action
  open() {
    this.show = true;
    this.message = '';
    this.error = false;
    this.success = false;
    this.noBodyError = false;
    this.errorMessage = false;
  }

  @action
  close() {
    this.show = false;
    this.message = '';
    this.error = false;
    this.success = false;
    this.noBodyError = false;
    this.errorMessage = false;
  }

  @action
  updateMessage(message: string) {
    this.message = message;
  }

  @action
  sendMessage(memberId: string, email: string, body: string) {
    this.error = false;
    this.success = false;
    this.noBodyError = false;

    if (!body) {
      this.noBodyError = true;
      return false;
    }

    // indicate that the api call is in progress
    this.isSendingMessage = true;

    return axios
      .setCheckAuthorization(true)
      .post('/members/notes?id=' + memberId, {
        subject: 'Member Portal Contact Request',
        body,
        email,
      })
      .then(() => {
        this.success = true;
      })
      .catch((error) => {
        // update error message
        this.error = true;
        this.errorMessage =
          error.response && error.response.data && error.response.data.error
            ? error.response.data.error.message
            : 'There was a problem sending your message - please give us a call at the number listed above.';
      })
      .finally(() => {
        this.isSendingMessage = false;
      });
  }
}

export { ContactModalStore };
