import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import moment from 'moment';
import * as SupportedLanguages from './languages/index';
import getTranslations from './getTranslations';

/**
 * Initializes the i18n library with default configurations.
 *
 * @param {Object} param0 - Configuration object.
 * @param {String} param0.clientLanguageId - The client language ID to use(i.e. "bkc")
 *
 * @returns {*} - A configured i18n library.
 */
export default function setupI18nLibrary({ clientLanguageId } = {}) {
  const i18nextConfig = retrieveI18nConfiguration({ clientLanguageId });

  return i18next.use(LanguageDetector).init(i18nextConfig);
}

/**
 * Initializes the configuration used for the i18n library. Generally exported for testing purposes but also allows for
 * consumers to provide additional customizations if needed.
 *
 * @param {Object} param0 - Configuration object.
 * @param {String} param0.clientLanguageId - The client language ID to use(i.e. "bkc")
 *
 * @returns {Object} - An object used to configure this application's i18n library.
 */
export function retrieveI18nConfiguration({ clientLanguageId } = {}) {
  const i18nextConfig = {
    fallbackLng: getFallbackFromNavigatorLanguages(window.navigator.languages), // fallbackLng must match resources key EXACTLY.
    interpolation: {
      format: function (value, format) {
        if (value instanceof Date) {
          let date = moment(value).format(format);
          date = date.replace(/"/g, '');
          return date;
        }

        return value;
      },
    },
    resources: getLanguageResources(clientLanguageId),
    detection: {
      order: ['navigator'],
    },
  };
  return i18nextConfig;
}

/**
 * Loads fall back language based on browsers languages.
 * @param {Array} navigatorLanguages - List of languages loaded by the user in the browser.
 *
 * @returns {String} - Returns the fallback language from the list of navigator languages,
 *  returns English if no supported language is found
 */
export function getFallbackFromNavigatorLanguages(navigatorLanguages) {
  /**
   * This checks for IE as navigator.languages is not supported by IE, as well as checking if there is more than one
   * browser language (if we don't have multiple languages, we cannot fall back to anything, and should thus
   * automatically fall back to English).
   */
  if (!navigatorLanguages || navigatorLanguages.length <= 1) {
    return 'en';
  }

  const supportedLanguageSet = new Set();
  Object.values(SupportedLanguages).forEach((item) => {
    supportedLanguageSet.add(item.code);
  });

  const browserLanguages = navigatorLanguages.map((lang) => lang.split('-')[0]);
  let fallBackLanguages = browserLanguages.filter((value) => supportedLanguageSet.has(value));
  fallBackLanguages = [...new Set(fallBackLanguages)];

  if (!fallBackLanguages.includes('en')) {
    fallBackLanguages.push('en');
  }

  return fallBackLanguages;
}

/**
 * Loads Language resources  .
 *  @param {String} clientLanguageId - The client language ID to use(i.e. "bkc").
 *
 * @returns {Object} - An object that contains the translations for the Supported languages.
 */
export function getLanguageResources(clientLanguageId) {
  const languageResources = {};
  Object.values(SupportedLanguages).forEach((lang) => {
    languageResources[lang.code] = getTranslations(clientLanguageId, lang.code);
  });

  return languageResources;
}
