/**
 * Our analytics class
 */
class AppAnalytics {
  /**
   * Creates a new instance of AppAnalytics.
   * @constructor
   */
  constructor() {
    require('./google');
    setTimeout(() => {
      this.registerPageView(window.location);
    }, 200);
  }

  /**
   * Register page views with necessary providers
   *
   * @param {window.location} location - windows current location
   */
  registerPageView(location) {
    if (window.ga) {
      ga('set', 'page', location.pathname.replace(/mb\-[a-z0-9]{7}/g, 'dependent'));
      ga('send', 'pageview');
    }
  }
}

export default AppAnalytics;
