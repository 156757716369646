/* @flow */

import { observable, action } from 'mobx';

import uuidv4 from 'uuid/v1';
import axios from '@utils/axios/axios';
import { SessionManager } from '@utils/session-manager';

class AutocompleteStore {
  @observable searchValue: string = '';

  @observable autocompleteResults: Array<Object> = [];

  @observable apiBase: string;

  @observable noResults: boolean = false;

  @observable autocompleteResult: Object = {};

  autocompleteQueue: Array<string> = [];

  timeoutId: any = 0;

  constructor() {
    this.apiBase = window.api_url || global.api_url;
  }

  /*
   * Gets autocomplete information
   */
  @action
  async getAutocomplete(term: string) {
    const url = `${this.apiBase}/names?name=${term}`;
    this.searchValue = term;
    const uuid = uuidv4();
    this.autocompleteQueue.unshift(uuid);

    // Makes sure search term is 3 characters and clears out old
    // timeouts until keyevent hasn't happend in 500ms
    if (term.length > 2) {
      clearTimeout(this.timeoutId);
      this.timeoutId = setTimeout(this.setAutoCompleteResults.bind(this, url, uuid), 500);
    } else {
      this.autocompleteResults = [];
      this.noResults = false;
    }
  }

  /*
   * sets autocompleteResults to the result of callAutocomplete
   */
  @action
  async setAutoCompleteResults(url: string, uuid: string) {
    const results = await this.callAutocomplete(url);

    if (!results) {
      this.noResults = true;
    } else if (!results && this.searchValue.length <= 2) {
      this.noResults = false;
    } else {
      this.noResults = false;
    }

    if (this.searchValue.length > 2 && this.autocompleteQueue[0] === uuid) {
      this.autocompleteQueue = [];
      this.autocompleteResults = results || [];
    }
  }

  /*
   * calls the autocomplete endpoint with a search term
   */
  callAutocomplete(url: string): Array<Object> {
    return axios
      .setCheckAuthorization(true)
      .setIsVerbose(false)
      .get(url)
      .then((response) => {
        return (Array.isArray(response) ? response[0] : response).response;
      });
  }

  /*
   * Callss the autocomplete endpoint with a product id
   */
  callAutocompleteByid(url: string) {
    return axios
      .setIsVerbose(false)
      .setCheckAuthorization(true)
      .get(url)
      .then((response) => (Array.isArray(response) ? response[0] : response).response)
      .catch((error) => {
        if (error.response && error.response.status === 404) {
          window.Rollbar.log('404 On Name API', error.response);
          new SessionManager(sessionStorage).clear();
          window.location.replace(window.app_url || global.app_url);
        }
      });
  }

  /**
   *
   * Get correct product from autocomplete
   */
  getAutocompleteResult(result: Object) {
    return this.autocompleteResults.filter((r) => {
      return result.id === r.id;
    })[0];
  }

  async rebuildById(id: number): Object {
    const url = `${this.apiBase}/names?id=${id}`;
    const autocompleteResponse = await this.callAutocompleteByid(url);
    let result = autocompleteResponse;

    if (Array.isArray(autocompleteResponse)) {
      [firstResult, ...result] = autocompleteResponse.filter((item) => {
        return item.id === id;
      });
    }

    // Set values needed for next steps
    this.autocompleteResults.push(firstResult);
    this.autocompleteResult = firstResult;

    return firstResult;
  }

  async initAutocomplete(result: Object): Object {
    this.searchValue = result.name;
    this.autocompleteResult = await this.getAutocompleteResult(result);

    return this.autocompleteResult;
  }
}

const autocompleteStore = new AutocompleteStore();

export default autocompleteStore;
export { AutocompleteStore };
