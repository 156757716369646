/* @flow */

/**
 * Utility function to grab a client configuration value. This is useful in scenarios where you don't need the
 * value to be reactive. If you need a reactive value, it is better to use the Gatekeeper Store. This utility
 * removes the need to inject the Gatekeeper store into every other store.
 *
 * @param {String} key - The key to retrieve.
 * @param {[SessionManager]} sessionManager - An instance of a Session Manager. This is optional and only needs to be
 *    injected in for testing purposes.
 * @returns {*|undefined} - The value returned for that key. If the key is not in client configuration, returns
 *    undefined.
 */
import SessionManager from '@utils/session-manager';

function getClientConfigurationValue(
  key: String,
  sessionManager: SessionManager = new SessionManager(sessionStorage),
) {
  return sessionManager.get('config')[key];
}

export default getClientConfigurationValue;
