import axios from '@utils/axios/axios';
import queryString from 'query-string';

const suggestionApi = {
  getAll(memberId, data) {
    const dataParams = queryString.stringify(data);
    return axios
      .setIsVerbose(false)
      .setMaxCacheAge()
      .get(`/v2/${memberId}/suggestions?${dataParams}`);
  },
};

export default suggestionApi;
