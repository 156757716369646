/* @flow */

import { action, observable } from 'mobx';
import React from 'react';
import { isEmpty } from 'lodash';
import axios from '@utils/axios/axios';
import { SessionStorageManager } from '@utils/storage';
import ConfigAlias from '../../aliases/ConfigAlias';

class GatekeeperStore {
  @observable resources: Object;

  @observable visible: boolean;

  @observable error: any;

  @observable prevPath: Object;

  @observable config: ConfigAlias;

  @observable apiBase: string;

  logoutUrl: string;

  constructor() {
    this.resources = {};
    this.visible = true;
    this.error = false;
    this.blockInitialization = false;
    this.prevPath = {
      location: 'unknown',
      query: {},
    };
    this.config = {
      client_id: '',
      contact_email_addresses: [''],
      contact_phone_numbers: [''],
      employer_id: '',
      features_enabled: [''],
      language_id: '',
      logo_id: '',
      member_id: '',
      redirect_url: '',
    };
    this.apiBase = window.api_url || global.api_url;
    this.logoutUrl = window.logout_url || global.logout_url;
  }

  oneSignalInit(onesignal_info: Object) {
    if (onesignal_info && !isEmpty(onesignal_info)) {
      window.Rollbar.log('Onesignal info added for user', {
        ...onesignal_info,
      });
      axios
        .post('/mobile-platform', onesignal_info)
        .then(() => {
          window.Rollbar.log('Onesignal info added for user', onesignal_info);
        })
        .catch((error) => {
          window.Rollbar.log('Onesignal info failed for user', error);
        });
    }
  }

  /*
   * Check session storage
   * Grab the config endpoint and set theme and translation
   * Or redirect
   * Or handle errors
   */
  @action
  async init(memberStore: Object, translationStore: Object, LogRocket: Object, Rollbar: Object) {
    // PREVENT REINITIALIZATION IF NEEDED TO STOP RELOAD LOOP
    if (this.blockInitialization) {
      return;
    }

    // Get the previously stored config
    let sessionConfig = SessionStorageManager.get('config');

    // Call config endpoint if sessionConfig is empty
    if (isEmpty(sessionConfig)) {
      try {
        const configResponse = await axios.setCheckAuthorization(true).get('/v2/config');
        sessionConfig = configResponse.data.data;
        SessionStorageManager.set('config', sessionConfig);
      } catch (e) {
        Rollbar.log('error', e);
      }
    }

    this.config = sessionConfig;
    await this.implementConfigs(memberStore, translationStore, this.config, LogRocket, Rollbar);
    return { config: this.config };
  }

  async implementConfigs(
    memberStore: Object,
    translationStore: Object,
    config: Object,
    LogRocket: Object,
    Rollbar: Object,
  ) {
    // The "language_id" in Client Configuration points to client prefixes, like "rxss" or "boe". This is not the typical
    // 5-letter language identifier like "en-US".
    translationStore.setTranslation(config.language_id);

    await memberStore.initMember(config.member_id);

    Rollbar.configure({
      payload: {
        person: {
          id: config.member_id,
          employer: config.employer_id,
        },
      },
    });

    LogRocket.identify(config.member_id, {
      employerId: config.employer_id,
    });
  }

  getPreviousPath(location: Object) {
    if (this.prevPath === 'unknown') {
      const pathname = location.pathname.slice(1);

      // TODO: Add logic for specific pages
      switch (pathname) {
        case 'dashboard':
          return 'dashboard';
        case 'search-result':
          return 'Search Results';
        case 'pharmacy-picker':
          return 'Pharmacy Options';
        default:
          return 'dashboard';
      }
    }
    return this.prevPath;
  }

  @action
  logout() {
    if (SessionStorageManager.get('config').isInDemoMode) {
      SessionStorageManager.clear();
    }
    window.location.replace(this.logoutUrl);
  }

  @action
  skip() {
    this.visible = false;
  }

  /**
   * Determines whether the provided feature is disabled for the member
   *
   * @param {string} feature - Name of the feature, hyphenated
   * @returns {boolean} - True if the feature is disabled, false otherwise
   */
  @action.bound
  isFeatureDisabled(feature: string): boolean {
    return !this.isFeatureEnabled(feature);
  }

  /**
   * Determines whether the provided feature is enabled for the member
   *
   * @param {string} feature - Name of the feature, hyphenated
   * @returns {boolean} - True if the feature is enabled, false otherwise
   */
  @action.bound
  isFeatureEnabled(feature: string): boolean {
    return this.config.features_enabled.includes(feature);
  }
}

const gatekeeperStore = new GatekeeperStore();

export default gatekeeperStore;
export { GatekeeperStore };
