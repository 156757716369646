/* @flow */

import moment from 'moment/moment';
import { getReport } from '@utils/Functions';

import { action, observable } from 'mobx';

class DoctorVisitReportStore {
  @observable currentMeds: Object;

  @observable pastMeds: Object;

  @observable savingsOpportunities: Object;

  @observable reportLoading: boolean = false;

  @observable fromDate: Object;

  @observable toDate: Object;

  @observable error: boolean = false;

  endpoint: string = 'visit-prep-report';

  @action getReportForDateRanges(startYear, endYear, startMonth, endMonth, memberId) {
    const startMO = moment().month(startMonth).format('MM');
    const endMO = moment().month(endMonth).format('MM');
    const start = `${startYear}-${startMO}-01`;
    const end = `${endYear}-${endMO}`;
    const endMonthDays = moment(end, 'YYYY-MM').daysInMonth();
    this.getReport(memberId, this.endpoint, `?from_date=${start}&to_date=${end}-${endMonthDays}`);
  }

  @action getReportForCurrent(memberId) {
    this.getReport(memberId, this.endpoint, '?current=true');
  }

  @action generateDocVisitFirstAvailableReport(memberId) {
    this.getReport(memberId, this.endpoint);
  }

  constructor() {
    this.getReport = getReport;
  }
}

const doctorVisitReportStore = new DoctorVisitReportStore();

export default doctorVisitReportStore;
export { DoctorVisitReportStore };
