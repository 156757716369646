import axios from '@utils/axios/axios';
import moment from 'moment';

async function getReport(memberId, endpoint, query = '') {
  this.reportLoading = true;
  this.error = false;
  try {
    const {
      document_info: {
        from: dateFrom,
        to: dateTo,
        current_medications,
        past_medications,
        savings_opportunities,
        maintenance_medication,
        short_term_medication,
        member_cost,
      },
    } = await axios
      .setIsVerbose(false)
      .get(`/v2/${memberId}/medicine-cabinets/${endpoint}${query}`);
    this.reportLoading = false;
    this.fromDate = moment.utc(dateFrom).format('LL');
    this.toDate = moment.utc(dateTo).format('LL');
    switch (endpoint) {
      case 'visit-prep-report':
        this.currentMeds = Object.values(current_medications);
        this.pastMeds = Object.values(past_medications);
        this.savingsOpportunities = Object.values(savings_opportunities);
        break;
      case 'spending-report':
        this.maintenanceMeds = Object.values(maintenance_medication);
        this.shortTermMeds = Object.values(short_term_medication);
        this.memberCost = member_cost;
        break;
    }
  } catch (err) {
    this.reportLoading = false;
    this.error = true;
    Rollbar.warn(err);
  }
}

export default getReport;
