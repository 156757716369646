import moment from 'moment';
import BaseAlert from '../BaseAlert/BaseAlert';
import AlertTypes from '../AlertTypes';

class RefillAlert extends BaseAlert {
  constructor(info) {
    super(info);
    const { alertInfo, hasExpressSuggestion } = info;
    const productInfo = alertInfo.medication_claim.package.product;
    const date = moment(alertInfo.medication_claim.filled_at).format('MMMM Do, YYYY');
    const currentDrug = `${productInfo.primary_name} ${productInfo.strength} ${productInfo.secondary_name}, ${alertInfo.medication_claim.quantity_with_unit}`;
    this.modelName = AlertTypes.REFILL;
    this.icon = 'refill';
    this.alertType = hasExpressSuggestion ? 'express-suggestion' : alertInfo.alert_type;
    this.hasSavings = !!alertInfo.savings_type;
    this.markRefillAsFilled = info.markRefillAsFilled;
    this.showPrescriptionDetailsLink = !alertInfo.savings_type;
    this._footerText =
      alertInfo.savings_type === 'client'
        ? this.translate('refill-reminder.clientSavings.footer')
        : null;
    switch (alertInfo.savings_type) {
      case 'member':
        this._headerText = this.translate('refill-reminder.memberSavings.header');
        this.bodyText = this.translate('refill-reminder.memberSavings.body', { drug: currentDrug });
        break;
      case 'client':
        this._headerText = this.translate('refill-reminder.clientSavings.header');
        this.bodyText = this.translate('refill-reminder.clientSavings.body', { drug: currentDrug });
        break;
      default:
        this._headerText = this.translate('refill-reminder.noSavings.header');
        this.bodyText = this.translate('refill-reminder.noSavings.body', {
          drug: currentDrug,
          date,
        });
        break;
    }
  }

  get headerText() {
    return this._headerText;
  }

  get footerText() {
    return this._footerText;
  }
}

export default RefillAlert;
