import { Serializer } from 'miragejs';

/**
 * Mirage stores ids as strings. Our API returns integer ids. Calling this in our serializer recursively converts all ids in the response to integers.
 * {@link https://stackoverflow.com/a/68692763|See this post for more information}
 */
function convertIdsToNumbers(o) {
  Object.keys(o).forEach((k) => {
    const v = o[k];
    if (Array.isArray(v) || v instanceof Object) convertIdsToNumbers(v);
    if (k === 'id' || /.*Id$/.test(k)) {
      // Only convert to number if successful, so we avoid accidentally coercing memberUids to NaN
      const converted = Number(v);
      o[k] = Number.isNaN(converted) ? v : Number(v);
    }
  });
}

/**
 *  Base serializer configuration to use for all routes. Extendable if specific routes need specific settings.
 *
 *  Configuration includes:
 *  - embed - Nests any included relationships inside the top level response. For example suggestions are nested inside an alert response. {@link https://miragejs.com/api/classes/serializer/#embed|embded docs}
 *  - root -  Sets whether the response object is keyed with the model name {@link https://miragejs.com/api/classes/serializer/#root|root docs}
 *  - serialize - Override to implement custom serialize functionality. We need this to convert Mirage's string ids to integer. {@link https://miragejs.com/api/classes/serializer/#serialize|seralize docs}
 */
export const ApplicationSerializer = Serializer.extend({
  embed: true,
  root: false,
  serialize(object, request) {
    // This is how to call super, as Mirage borrows [Backbone's implementation of extend](http://backbonejs.org/#Model-extend)
    // eslint-disable-next-line prefer-rest-params
    const json = Serializer.prototype.serialize.apply(this, arguments);

    convertIdsToNumbers(json);
    return json;
  },
});
