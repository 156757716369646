/**
 * Checks whether given tags are identical.
 * @param {Object} tag - The tag to match with
 * @param {Object} tagToMatch - The other tag to match with
 * @return {boolean} True if the tag type and text match
 */
export function tagMatches(tag, tagToMatch) {
  if (!!tag && !!tagToMatch) {
    return tag.type === tagToMatch.type && tag.text === tagToMatch.text;
  }

  return false;
}

/**
 * Checks if a matching tag exists in the pharmacy's tags.
 * @param pharmacy - The pharmacy to match tags with
 * @param tagToMatch - The tag to match against
 * @return {boolean} True if there is a matching tag found on the pharmacy
 */
export function tagExists(pharmacy, tagToMatch) {
  if (!!pharmacy && !!pharmacy.tags) {
    return pharmacy?.tags.some((tag) => tagMatches(tag, tagToMatch));
  }

  return false;
}

/**
 * Adds a tag to the given pharmacy only if the tag isn't present.
 * @param {Object} pharmacy - The pharmacy to apply a tag to
 * @param {Object} tagToAdd - The tag to be applied to the specified pharmacy
 * @return {Object} The pharmacy with specified tag applied.
 */
export function addTag(pharmacy, tagToAdd) {
  if (!tagExists(pharmacy, tagToAdd)) {
    pharmacy?.tags?.push(tagToAdd);
  }

  return pharmacy;
}

/**
 * Removes a tag from a given pharmacy if present.
 * @param {Object} pharmacy - The pharmacy to remove a tag from
 * @param {Object} tagToRemove - The tag
 * @return The pharmacy with specified tag removed.
 */
export function removeTag(pharmacy, tagToRemove) {
  if (!!pharmacy && !!pharmacy.tags) {
    pharmacy.tags = pharmacy.tags.filter((tag) => !tagMatches(tag, tagToRemove));
  }

  return pharmacy;
}
