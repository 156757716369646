import axios from 'axios';

const { CancelToken, isCancel: isCancelRaw } = axios;
const isCancel = isCancelRaw.bind(axios);
const baseAxios = axios.create({
  baseURL:
    window.Cypress || window.use_mirage_development_server
      ? '/api'
      : window.api_url || global.api_url,
  withCredentials: true,
});

export { baseAxios, isCancel, CancelToken };
