/* @flow */

import { observable, action } from 'mobx';
import axios from '@utils/axios/axios';

class PrescriberLetterModalStore {
  @observable letterLoading = false;

  @observable suggestion: object;

  @observable product: object;

  @observable suggestedProducts: string;

  memberStore: Object;

  @action showPrescriberLetter(suggestionId) {
    this.letterLoading = true;
    axios
      .setIsVerbose(false)
      .get(
        `/contact-prescriber/savable-document?suggestion_id=${suggestionId}&member_uid=${this.memberStore.currentMember.id}`,
      )
      .then((response) => {
        this.letterLoading = false;
        this.suggestion = response.suggestion;
        this.product = this.suggestion.product;
        this.suggestedProducts = this.suggestion.suggested_products;
      })
      .catch((err) => {
        Rollbar.warn(err);
      });
  }

  constructor(memberStore: Object) {
    this.memberStore = memberStore;
  }
}

const prescriberLetterModalStore = new PrescriberLetterModalStore();
export default prescriberLetterModalStore;
export { PrescriberLetterModalStore };
