import BaseAlert from '../BaseAlert/BaseAlert';
import AlertTypes from '../AlertTypes';

class ContactPrescriberAlert extends BaseAlert {
  constructor(info) {
    super(info);
    const { alertInfo } = info;
    this.modelName = AlertTypes.CONTACT_PRESCRIBER;
    this.icon = alertInfo.alert_type;
    this.showRemoveAlertBar = false;
    this.hasSavings = false;
    this.alertLink = this.translate('contact-prescriber-alert.seeMore');
    this.bodyText = this.translate(
      `contact-prescriber-alert.${
        alertInfo.alert_type === 'contact-prescriber-active' ? 'active' : 'complete'
      }.body`,
    );
    this.data = {
      name: info.memberStore.activeMember.first_name,
      product: this.product.primaryName,
    };
  }

  get headerText() {
    const type = this.alertType.replace('contact-prescriber-', '');
    const text = this.isDependent ? 'dependentHeader' : 'header';
    return this.translate(`contact-prescriber-alert.${type}.${text}`, this.data);
  }
}

export default ContactPrescriberAlert;
