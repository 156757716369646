import { stringToTitleCase } from '@utils/Functions/titleCaseFunction/titleCaseFunction';
import BaseAlert from '../BaseAlert/BaseAlert';
import AlertTypes from '../AlertTypes';

class PermissionRequestAlert extends BaseAlert {
  constructor(info) {
    super(info);
    const { alertInfo, grant, revoke } = info;
    const activity = alertInfo.is_proactive ? 'proactive' : 'reactive';
    this.modelName = AlertTypes.PERMISSION_REQUEST;
    this.icon = 'invite-permission';
    this.showRemoveAlertBar = false;
    this.showInviteDependentButtonArea = true;
    this.hasSavings = false;
    this.termsConditionsError = this.translate('family-permission.alert.mustAgree');
    this.grantPermission = grant;
    this.revokePermission = revoke;
    this.bodyText = this.translate(`family-permission.${activity}-grant.body`, {
      name: stringToTitleCase(alertInfo.requesting_member.first_name),
    });
    this._headerText = this.translate(`family-permission.${activity}-grant.header`, {
      name: stringToTitleCase(alertInfo.requesting_member.first_name),
    });
  }

  get headerText() {
    return this._headerText;
  }
}

export default PermissionRequestAlert;
